import React, { useEffect, useState, useRef } from 'react';

import { IoMdSave } from 'react-icons/io';
import { useSelector, useDispatch } from 'react-redux';
import { theme } from '../../../../EmraxisTheme/Theme';
import DataDecode from '../../../../utils/DataDecode';
import store from '../../../../API/store';
import { setNotification } from '../../../../Redux/features/toast/toastSlice';
import { setDocumentType, setIsCameraUpload, setRefreshPrescription, setRefreshUploadedFiles, setRefreshNotes, setShowDocumentUploadPopup, setShowUploadPopup } from '../../../../Redux/features/uploadDoc/uploadDocSlice';
import FileIcon from '../FileIcon';
import PrescriptionPrintPreview from '../PrescriptionPrintPreview';
import CreatableSelect from 'react-select/creatable';
import { setActiveNavvisitbar, setAutoSaveAll, setSelectedAppointmentId, setpastSelected, setDoctorNotes, setPrescriptions, setUploadedData, setLabNotes, setRadiologyNotes } from '../../../../Redux/features/visitDrawer/visitSideNavbarSlice';

const PrescriptionContent = ({ initialHistory, appointment_id, patient_id, doctor_id }) => {
    const isprescriptions = useSelector(state => state.visitSideNavbar.isprescriptions);
    const [history, setHistory] = useState(initialHistory);
    const [isPrescriptionOpen, setIsPrescriptionOpen] = useState(false);
    const [patient, setPatient] = useState({});
    const [files, setFiles] = useState([]);
    const [file, setFile] = useState(null);
    const [isNewPrescriptionOpen, setIsNewPrescriptionOpen] = useState(false);
    const [loadingSavePrint, setLoadingSavePrint] = useState(false);
    const [medicineDetails, setMedicineDetails] = useState("");
    const [inputValue, setInputValue] = useState('');
    const [printPreview, setPrintPreview] = useState(false);
    const [isAnyChanges, setIsAnyChanges] = useState(false);
    const [confirmBox, setConfirmBox] = useState(false);
    const [deleteFile, setDeleteFile] = useState([]);
    const [loadingSave, setLoadingSave] = useState(false);
    const [enterPrescriptionExist, setEnterPrescriptionExist] = useState(false);
    const [confirmBoxDeleteAll, setConfirmBoxDeleteAll] = useState(false);
    const [submitDisable, setSubmitDisable] = useState(false);
    const [loadingDeleteImage, setLoadingDeleteImage] = useState(false);
    const [checkNewPrescription, setCheckNewPrescription] = useState({
        file: 'initial',
        entry: 'initial'
    });
    const [dataLoading, setDataLoading] = useState(true);
    const [typeScript, setTypeScript] = useState(true);
    const dispatch = useDispatch();
    const [medicineOptions, setMedicineOptions] = useState([]);
    const [editingIndex, setEditingIndex] = useState(null);
    const [refreshData, setRefreshData] = useState(false);
    const [loadInitialMedidcineStatus, setLoadInitialMedidcineStatus] = useState({ medicine: false, existingRows: false });
    const [rows, setRows] = useState([]);
    const [medicineSelectedValues, setMedicineSelectedValues] = useState({});
    const [editPrescription, setEditPrescription] = useState(false);
    const [refreshImage, setRefreshImage] = useState(false);
    const [originalFiles, setOriginalFiles] = useState([]);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [currentRow, setCurrentRow] = useState(null);
    const [appointment, setAppointment] = useState({});
    const [notesFileDeleteID, setNotesFileDeleteID] = useState(0);
    var autoSaveAll = useSelector(state => state.profileSideNavbar.autoSaveAll);
    const [notesFileDeleteCnfBox, setNotesFileDeleteCnfBox] = useState(false);
    const [expandRow, setExpandRow] = useState(true);
    const [newMedicine, setNewMedicine] = useState({
        medicine: '',
        medicine_type: 'Tablet',
        when_medidcine: false,
        morning: false,
        noon: false,
        night: false,
        duration: '',
    });
    var refreshNotes = useSelector(state => state.document_upload.refreshNotes);
    var loggeduser = useSelector(state => state.login.loginuserData);
    var loggedusertoken = useSelector(state => state.login.userBarerToken);
    const previewTheme = useSelector(state => state.theme.colors) || theme.filter(a => a.name === "defaultTheme")[0];
    var refreshPrescription = useSelector(state => state.document_upload.refreshPrescription);
    var refreshUploadedFiles = useSelector(state => state.document_upload.refreshUploadedFiles);
    var showDocumentUploadPopup = useSelector(state => state.document_upload.showDocumentUploadPopup);



    const GetPrescription = () => {

        store.GetPrescription(loggedusertoken, DataDecode.encryptPayload({ appointment_id: history.appointment_id, 'clinic_guid': loggeduser.facilityID }))
            .then((result) => {
                if (result.status == 200) {
                    result.json().then((res) => {
                        let response = DataDecode.decryptResponse(res);
                        let val = JSON.parse(response);
                        setHistory(prevHistory => ({
                            ...prevHistory,
                            prescriptions: val
                        }));
                        if (val.length > 0) {
                            setLoadInitialMedidcineStatus({ ...loadInitialMedidcineStatus, existingRows: true })
                            setTimeout(() => {
                                setCheckNewPrescription({
                                    ...checkNewPrescription,
                                    entry: 'exist'
                                })
                                setExpandRow(true);
                            }, 100)

                            setEnterPrescriptionExist(true)
                            setTypeScript(true);
                        } else {
                            setTimeout(() => {
                                setCheckNewPrescription({
                                    ...checkNewPrescription,
                                    entry: 'na'
                                })
                            }, 100)
                        }
                    })
                } else {
                    setCheckNewPrescription({
                        ...checkNewPrescription,
                        entry: 'na'
                    })
                    setHistory(prevHistory => ({
                        ...prevHistory,
                        prescriptions: [{
                            order_id: 1,
                            medicine: '',
                            medicine_type: 'Tablet',
                            when_medidcine: false,
                            morning: '',
                            noon: '',
                            evening: '',
                            night: '',
                            duration: '',
                            total: 'N/A',
                            other: ''
                        }]
                    }));
                }
            })
            .catch(err => {
                dispatch(setNotification({ message: 'Error occurred', status: 'error', action: true }));
            })

    }


    const fetchMedicineData = () => {
        try {
            store.GetAllMedicine(loggedusertoken, DataDecode.encryptPayload({ 'clinic_guid': loggeduser.facilityID }))
                .then(response => {
                    if (response.status == 200) {
                        response.json().then(res => {
                            const decryptedResponse = DataDecode.decryptResponse(res);
                            const data = JSON.parse(decryptedResponse);
                            if (data.length > 0) {
                                const options = data.map(val => ({
                                    value: val.medicine_id,
                                    label: val.medicine_name,

                                }));
                                setMedicineOptions(options);
                                setLoadInitialMedidcineStatus({ ...loadInitialMedidcineStatus, medicine: true })
                            }
                        })
                    } else {
                        setMedicineOptions([]);
                    }
                })
        } catch (err) {
            dispatch(setNotification({ message: 'Error Occurred!', status: 'error', action: true }));
            setMedicineOptions([]);
        }
    };
    async function validateMedicine(inputValue) {
        const apiUrl = process.env.REACT_APP_EMRAXIS_MEDICINEVALIDATION + encodeURIComponent(inputValue);
        const response = await fetch(apiUrl);
        if (response.status == 200) {
            return true;
        }else {
            return false;
        }
    }
    useEffect(() => {
        setTimeout(() => {
            if (loadInitialMedidcineStatus.existingRows && loadInitialMedidcineStatus.medicine) {
                let updatedMedicine = {};
                history?.prescriptions.map((r) => {
                    let seletedMedicine = medicineOptions.filter(a => a.label?.trim()?.toLowerCase() === r.medicine?.trim()?.toLowerCase())
                    if (seletedMedicine.length > 0) {
                        updatedMedicine[r.order_id] = seletedMedicine[0];
                    }
                });
                setTimeout(() => {
                    setMedicineSelectedValues({
                        ...medicineSelectedValues,
                        ...updatedMedicine
                    });
                }, 100)
            }
        }, 100)
    }, [loadInitialMedidcineStatus])

    useEffect(() => {
        if (loggeduser.facilityID && history.appointment_id) {
            GetPrescription();
            fetchMedicineData();
        }
    }, [loggeduser.facilityID, history.appointment_id]);



    const savePrescription = async (save_type) => {
        let exist = history.prescriptions.filter(a => a.medicine.trim() === "" || a.duration == 0 || a.duration === "");
        if (exist.length > 0 && typeScript) {
            dispatch(setNotification({ message: '* Fields are required!', status: 'error', action: true }));
            return;
        }

        if (!file && !enterPrescriptionExist && exist.length > 0) {
            dispatch(setNotification({ message: 'Please add prescription!', status: 'error', action: true }));
            return;
        }

        setSubmitDisable(true);
        if (save_type === 'save') {
            setLoadingSave(true);
        } else {
            setLoadingSavePrint(true);
        }

        try {
            const editedPrescriptions = history.prescriptions.map((prescription, i) =>
                i === editingIndex ? newMedicine : prescription
            );

            const payload = {
                appointment_id: history.appointment_id,
                clinic_guid: loggeduser.facilityID,
                prescriptions: editedPrescriptions,
                patient_id: history.patient_id,
                doctor_id: history.doctor_id,
                type_script: typeScript,
                medicineDetails: medicineDetails,
                inputValue: inputValue
            };

            const result = await store.AddPrescription(loggedusertoken, DataDecode.encryptPayload(payload));

            setSubmitDisable(false);
            setIsAnyChanges(false);

            if (result.status === 201) {
                setHistory(prevHistory => ({ ...prevHistory, prescriptions: editedPrescriptions }));

                setNewMedicine({
                    order_id: '',
                    medicine: '',
                    medicine_type: '',
                    when_medidcine: false,
                    morning: false,
                    noon: false,
                    night: false,
                    duration: ''
                });

                if (save_type === 'save') {
                    setLoadingSave(false);
                } else {
                    setPrintPreview(true);
                    setLoadingSavePrint(false);
                    setEditPrescription(false);
                    handleClosePopup();
                }
                dispatch(setNotification({ message: "Prescription Added", status: 'success', action: true }));
            } else {
                setLoadingSave(false);
                setLoadingSavePrint(false);
                dispatch(setNotification({ message: 'Error Occurred!', status: 'error', action: true }));
            }
        } catch (err) {
            setSubmitDisable(false);
            setLoadingSave(false);
            setLoadingSavePrint(false);
            dispatch(setNotification({ message: 'Error Occurred!', status: 'error', action: true }));
        }
    };

    useEffect(() => {
        let exist = history.prescriptions.filter(a => a.medicine.trim() !== "" || a.duration != 0 || a.duration !== "");
        if (isAnyChanges && exist.length > 0) {
            savePrescription('save');
        }
    }, [autoSaveAll]);


    const GetPastPrescription = () => {

        store.GetPastPrescription(loggedusertoken, DataDecode.encryptPayload({ 'clinic_guid': loggeduser.facilityID, 'patient_id': history.patient_id, 'appointment_id': history.appointment_id }))
            .then((result) => {
                if (result.status == 200) {
                    result.json().then((res) => {
                        let response = DataDecode.decryptResponse(res);
                        if (response === 'file') {
                            setTimeout(() => {
                                GetPatientFiles();
                            }, 500)
                        }
                        else if (response === 'entry') {
                            setTimeout(() => {
                                GetPrescription();
                            }, 500)
                        }
                    })
                }
            })
            .catch(err => {
                dispatch(setNotification({ message: 'Error Occurred!', status: 'error', action: true }));
            })
    }
    const GetCurrentMedicines = async () => {
        try {
            const result = await store.GetCurrentMedicines(loggedusertoken, DataDecode.encryptPayload({ 'clinic_guid': loggeduser.facilityID, 'patient_id': patient_id }));
            if (result.status === 200) {
                const res = await result.json();
                const response = DataDecode.decryptResponse(res);
                const data = JSON.parse(response);
                setHistory(prevHistory => ({ ...prevHistory, currentMedicines: data }));
            } else {
                setHistory(prevHistory => ({ ...prevHistory, currentMedicines: [] }));
            }
        } catch (err) {
            dispatch(setNotification({ message: 'Error Occurred!', status: 'error', action: true }));
        }
    };


    useEffect(() => {
        GetCurrentMedicines();
    }, [history.patient_id, loggeduser.facilityID])
    const GetPatientFiles = () => {
        store.GetPatientFiles(loggedusertoken, DataDecode.encryptPayload({
            'clinic_guid': loggeduser.facilityID,
            'patient_id': history.patient_id,
            'appointment_id': history.appointment_id
        }))
            .then((result) => {
                if (result.status === 200) {
                    result.json().then((res) => {
                        let response = DataDecode.decryptResponse(res);
                        let data = JSON.parse(response);

                        setFiles([...data, ...(history.PatientFiles || [])]);
                        setOriginalFiles([...data, ...(history.PatientFiles || [])]);
                    });
                } else {
                    setFiles(history.PatientFiles || []);
                    setOriginalFiles(history.PatientFiles || []);
                }
            })
            .catch(err => {
                setFiles(history.PatientFiles || []);
                setOriginalFiles(history.PatientFiles || []);
                dispatch(setNotification({ message: 'Error Occurred', status: 'error', action: true }));
            });
    };
    useEffect(() => {
        if (loggeduser.facilityID && history.appointment_id) {
            GetPatientFiles(loggeduser.facilityID);
            GetPrescription();
        }
    }, [loggeduser, history.appointment_id, refreshImage, refreshUploadedFiles, refreshPrescription]);


    const GetAllPatientPrescriptions = () => {

        setDataLoading(true);

        store.GetAllPatientPrescriptions(loggedusertoken, DataDecode.encryptPayload({
            'clinic_guid': loggeduser.facilityID, 'patient_id': history.patient_id,
            'appointment_id': history.appointment_id
        }))
            .then((result) => {
                setDataLoading(false);
                if (result.status == 200) {
                    result.json().then((res) => {
                        let response = DataDecode.decryptResponse(res);
                        dispatch(setPrescriptions(response));
                    })
                } else {
                    dispatch(setPrescriptions([]))
                }
            })
            .catch(err => {
                setDataLoading(false);
                dispatch(setPrescriptions([]))
                dispatch(setNotification({ message: 'Error occurred', status: 'error', action: true }));
            })
    }
    useEffect(() => {
        GetAllPatientPrescriptions();
    }, []);

    useEffect(() => {
        const fetchPrescriptions = async () => {
            const result = await store.GetPrescription(loggedusertoken, history.appointment_id);
            if (result.status === 200) {
                setHistory(prevHistory => ({
                    ...prevHistory,
                    prescriptions: result.data.prescriptions
                }));
            } else {
                console.error('Error fetching prescriptions:', result.message);
            }
        };

        fetchPrescriptions();
    }, [history.appointment_id]);

    const handleUploadButton = (type) => {
        dispatch(setShowDocumentUploadPopup(true))
        dispatch(setIsCameraUpload(type === "capture" ? true : false));
    }

    useEffect(() => {
        if (showDocumentUploadPopup) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'unset';
        }
    }, [showDocumentUploadPopup]);

    const deleteRow = (id) => {
        const updatedRows = history.prescriptions.filter(row => row.order_id !== id);
        if (updatedRows.length > 0) {
            setHistory(prevHistory => ({
                ...prevHistory,
                prescriptions: updatedRows
            }));

            setMedicineSelectedValues(prevValues => {
                const newValues = { ...prevValues };
                delete newValues[id];
                return newValues;
            });
        } else {
            dispatch(setNotification({ message: 'Minimum one row required!', status: 'error', action: true }));
        }
    };

    const handleClosePopup = () => {
        setIsPopupOpen(false);
        setCurrentRow(null);
        setEditingIndex(null);
    };
    const deleteEnteredPrescriptions = () => {
        setLoadingDeleteImage(true);
        store.DeleteEnteredPrescriptions(loggedusertoken, DataDecode.encryptPayload({ 'clinic_guid': loggeduser.facilityID, 'patient_id': history.patient_id, appointment_id: history.appointment_id }))
            .then((result) => {
                setLoadingDeleteImage(false)
                setConfirmBoxDeleteAll(false);
                if (result.status == 204) {
                    GetPrescription();
                    setEnterPrescriptionExist(false);
                    dispatch(setNotification({ message: 'Deleted Successfully!', status: 'success', action: true }));
                }
                else {
                    dispatch(setNotification({ message: 'Error Occurred!', status: 'error', action: true }));
                }
            })
            .catch((error) => {
                console.error("Error occurred while saving patient details:", error);
            });
    }
    const addRow = () => {
        const prescriptions = history?.prescriptions || [];
        const maxValue = prescriptions.length > 0
            ? Math.max(...prescriptions.map(obj => obj.order_id))
            : 0;
        const newRow = {
            order_id: Math.max(maxValue, prescriptions.length) + 1,
            medicine: '',
            medicine_type: 'Tablet',
            when_medicine: false,
            morning: '',
            noon: '',
            evening: '',
            night: '',
            duration: '',
            total: 'N/A',
            other: ''
        };
        setHistory((prevHistory) => ({
            ...prevHistory,
            prescriptions: [...(prevHistory?.prescriptions || []), newRow]
        }));
    };



    const handleMedicineChange = (id, selectedOption) => {
        const { value, label } = selectedOption;

        setHistory((prevHistory) => ({
            ...prevHistory,
            prescriptions: prevHistory.prescriptions.map(row =>
                row.order_id === id ? { ...row, medicine: label } : row
            )
        }));

        const isNewOption = !medicineOptions.some(option => option.label === label);
        if (isNewOption) {
            const newOption = { value, label };
            setMedicineOptions([...medicineOptions, newOption]);
        }

        setMedicineSelectedValues({
            ...medicineSelectedValues,
            [id]: selectedOption
        });
    };


    const handleCreateChange = async (id, inputValue) => {
        try {
            let checkExixtingRow = rows.filter(a => a.medicine?.toLowerCase() === inputValue?.toLowerCase())
            if (checkExixtingRow?.length > 0) {
                dispatch(setNotification({ message: 'Medicine already added in the list!', status: 'info', action: true }));
            } else {
                const isValidMedicine = await validateMedicine(inputValue);
                if (isValidMedicine) {
                    setHistory((prevHistory) => ({
                        ...prevHistory,
                        prescriptions: prevHistory.prescriptions.map(row =>
                            row.order_id === id ? { ...row, medicine: inputValue } : row
                        )
                    }));
                    setIsAnyChanges(true);
                } else {
                    dispatch(setNotification({ message: 'Please enter valid medicine name!', status: 'error', action: true }));
                }
            }
        } catch (error) {
            dispatch(setNotification({ message: 'Error Occurred!', status: 'error', action: true }));
        }
    };

    const handleChange = (id, event) => {
        const { name, value, type, checked } = event.target;
        let updatedValue = value;
        if (name === 'duration' && !/^\d*$/.test(value)) {
            return;
        }
        setHistory((prevHistory) => ({
            ...prevHistory,
            prescriptions: prevHistory.prescriptions.map(row =>
                row.order_id === id
                    ? { ...row, [name]: type === 'checkbox' ? checked : updatedValue }
                    : row
            )
        }));
    };


    const handleTimingChange = (id, event) => {
        setHistory((prevHistory) => ({
            ...prevHistory,
            prescriptions: prevHistory.prescriptions.map(row =>
                row.order_id === id ? { ...row, [event]: row[event] === "" ? "1" : "" } : row
            )
        }));
    };

    const getAvailableOptions = () => {
        const selectedOptions = Object.values(medicineSelectedValues).map(option => option?.value);
        return medicineOptions.filter(option => !selectedOptions.includes(option.value));
    };
    useEffect(() => {
        if (loggeduser.facilityID && history.appointment_id) {
            GetPastPrescription();
        }


    }, [loggeduser.facilityID, history.appointment_id])

    const deleteNotesFile = () => {
        setLoadingDeleteImage(true);
        store.DeletePatientFile(loggedusertoken, DataDecode.encryptPayload({ 'clinic_guid': loggeduser.facilityID, 'patient_id': history.patient_id, files: notesFileDeleteID }))
            .then((result) => {
                setLoadingDeleteImage(false)
                setNotesFileDeleteCnfBox(false)
                if (result.status == 204) {
                    dispatch(setRefreshNotes(!refreshNotes))
                    dispatch(setNotification({ message: 'Deleted Successfully!', status: 'success', action: true }));
                }
                else {
                    dispatch(setNotification({ message: 'Error Occurred!', status: 'error', action: true }));
                }

            })
            .catch((error) => {
                dispatch(setNotification({ message: 'Error occurred', status: 'error', action: true }));
            });
    }
    const GetPatientNotesFile = () => {
        store.GetPatientFiles(loggedusertoken, DataDecode.encryptPayload({ 'clinic_guid': loggeduser.facilityID, 'patient_id': history.patient_id, 'appointment_id': history.appointment_id }))
            .then((result) => {
                if (result.status == 200) {
                    result.json().then((res) => {
                        let response = DataDecode.decryptResponse(res);
                        setFiles(JSON.parse(response));
                    })
                } else {
                    setFiles([])
                }
            })
            .catch(err => {
                setFiles([])
                dispatch(setNotification({ message: 'Error occurred', status: 'error', action: true }));
            })
    }
    useEffect(() => {
        GetPatientNotesFile();
    }, [refreshNotes])

    useEffect(() => {
        if (loggeduser.facilityID && appointment_id) {
            GetPatientFiles(loggeduser.facilityID);
        }
    }, [loggeduser, appointment_id, refreshImage, refreshNotes, refreshUploadedFiles]);
    return (

        <div>
            {printPreview && <PrescriptionPrintPreview setPrintPreview={setPrintPreview} patient={patient} medicines={history.prescriptions} appointment={appointment} file={file} />}
            {notesFileDeleteCnfBox &&

                <div id="delete" tabIndex="-1" aria-hidden="true" className="fixed z-[9999] lg:left-[40%] lg:w-80 top-[10rem] lg:top-[3rem] lg:translate-x-[50%] translate-y-[50%]">

                    <div className="relative p-4 w-full max-w-md h-full md:h-auto">
                        <div className="relative p-4 text-center bg-white border-gray-400 border-[1px] rounded-lg shadow-lg dark:bg-gray-800 sm:p-5">
                            <svg id="PrescriptionSvgconfirmBoxIcon" className="text-red-600 dark:text-gray-500 w-11 h-11 mb-3.5 mx-auto" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clipRule="evenodd"></path></svg>

                            <p id="PrescriptionPconfirmBoxMessage" className={`mb-4 `} style={{ fontFamily: previewTheme.fontFamily }}>Are you sure you want to delete this file?</p>
                            <div className=" flex justify-center items-center space-x-4">

                                <button id="btnPreCancelCfmdDeleteFile" onClick={() => setNotesFileDeleteCnfBox(false)} disabled={loadingDeleteImage} type="button" className={`py-2 px-3 text-sm font-medium rounded-lg border focus:ring-4 focus:outline-none focus:ring-primary-300 focus:z-10  ease-in-out
                  ${previewTheme.cancelBtnColor} 
                  ${previewTheme.cancelBtnBgColor} 
                  ${loadingDeleteImage ? "opacity-50 cursor-not-allowed" : `hover:${previewTheme.cancelBtnHoverColor}`}
                  `}>
                                    No, cancel
                                </button>
                                <button id="btnPreYesCfmdDeleteFile" type="button" onClick={deleteNotesFile} disabled={loadingDeleteImage} className={`py-2 px-3 text-sm font-medium rounded-lg border focus:ring-4 focus:outline-none focus:ring-primary-300 focus:z-10  ease-in-out
                      ${previewTheme.saveBtnColor} 
                      ${previewTheme.saveBtnBgColor} 
                      ${loadingDeleteImage ? "opacity-50 cursor-not-allowed" : `hover:${previewTheme.saveBtnHoverColor}`}`}
                                >
                                    Yes, I'm sure {loadingDeleteImage &&
                                        <svg id="btnYes" aria-hidden="true" role="status" className="inline w-4 h-4 ml-2 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB" />
                                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor" />
                                        </svg>
                                    }
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {confirmBoxDeleteAll &&
                <div id="delete" tabIndex="-1" aria-hidden="true" className="fixed z-[9999] lg:left-[40%] lg:w-80 top-[10rem] lg:top-[3rem] lg:translate-x-[50%] translate-y-[50%]">
                    <div className="relative p-4 w-full max-w-md h-full md:h-auto">
                        <div className="relative p-4 text-center bg-white border-gray-400 border-[1px] rounded-lg shadow-lg dark:bg-gray-800 sm:p-5">
                            <svg id="btnDeleteAllPre" className="text-red-600 dark:text-gray-500 w-11 h-11 mb-3.5 mx-auto" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clipRule="evenodd"></path></svg>
                            <p id="DeleteAllprescriptions" className="mb-4 text-gray-500 dark:text-gray-300">Are you sure you want to delete all prescriptions?</p>
                            <div className="flex justify-center items-center space-x-4">
                                <button id='BtnDelPrescCancelButton' onClick={() => setConfirmBoxDeleteAll(false)} disabled={loadingDeleteImage} type="button" className="py-2 px-3 text-sm font-medium text-gray-500 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-primary-300 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600">
                                    No, cancel
                                </button>
                                <button id='BtnDelPrescYesButton' type="button" onClick={deleteEnteredPrescriptions} disabled={loadingDeleteImage} className={`py-2 px-3 text-sm font-medium text-center rounded-lg focus:ring-4 focus:outline-none disabled:opacity-50 disabled:cursor-not-allowed ${previewTheme.saveBtnColor} ${previewTheme.saveBtnBgColor} hover:${previewTheme.saveBtnHoverColor}`}>
                                    Yes, I'm sure {loadingDeleteImage &&
                                        <svg id="btnYesConfirmBoxDeleteAll" aria-hidden="true" role="status" className="inline w-4 h-4 ml-2 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB" />
                                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor" />
                                        </svg>
                                    }
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            }

            <div className="bg-gray-100 font-semibold justify-center  flex items-center py-1 mt-4">
                <div className={`font-bold text-xl  text-center ${previewTheme.headingTitleColor}`} style={{ fontFamily: previewTheme.fontFamily }}>
                    Prescriptions
                </div>

                {/* <div className='flex items-center absolute right-8  '>
                    <svg id="btnPreUpload" xmlns="http://www.w3.org/2000/svg" onClick={() => handleUploadButton('upload')} fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" className={`lg:size-7 size-5 lg:mr-8 mr-0 inline cursor-pointer text-red-700`}>
                        <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5m-13.5-9L12 3m0 0 4.5 4.5M12 3v13.5" />
                    </svg>
                    <svg id="btnPreCapture" xmlns="http://www.w3.org/2000/svg" onClick={() => handleUploadButton('capture')} fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" className={`hidden lg:inline size-7 cursor-pointer text-red-700`}>
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6.827 6.175A2.31 2.31 0 0 1 5.186 7.23c-.38.054-.757.112-1.134.175C2.999 7.58 2.25 8.507 2.25 9.574V18a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 18V9.574c0-1.067-.75-1.994-1.802-2.169a47.865 47.865 0 0 0-1.134-.175 2.31 2.31 0 0 1-1.64-1.055l-.822-1.316a2.192 2.192 0 0 0-1.736-1.039 48.774 48.774 0 0 0-5.232 0 2.192 2.192 0 0 0-1.736 1.039l-.821 1.316Z" />
                        <path strokeLinecap="round" strokeLinejoin="round" d="M16.5 12.75a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0ZM18.75 10.5h.008v.008h-.008V10.5Z" />
                    </svg>
                </div> */}
            </div>
            <div className="w-full mt-2">

                <table className='hidden lg:block table-auto w-[100vh] lg:w-full border rounded-md focus:outline-none'>
                    <thead>
                        <tr className='odd:bg-white even:bg-gray-100 border-b-2 border-gray-200'>
                            <th className='text-center py-2 text-sm font-semibold'>#</th>
                            <th className='text-center py-2 text-sm font-semibold'>Medicine*</th>
                            <th className='text-center py-2 text-sm font-semibold'>Form</th>
                            <th className='text-center py-2 text-sm font-semibold text-nowrap px-2'>Before Food</th>
                            <th className='text-center py-2 text-sm font-semibold'>Timing</th>
                            <th className='text-center py-2 text-sm font-semibold'>Duration(D)*</th>
                            <th className='text-center py-2 text-sm font-semibold'>Action</th>

                        </tr>
                    </thead>
                    <tbody>
                        {history?.prescriptions?.map((row, index) => (
                            <tr key={row.order_id} className='odd:bg-white even:bg-gray-100'>
                                <td style={{ width: '5%' }} className='text-center p-2'>{index + 1}</td>
                                <td style={{ width: '20%' }}>
                                    <CreatableSelect
                                        isClearable={history?.prescriptions[index].selectedOption}
                                        id={"selectMedicine" + index}
                                        className='h-10 rounded-md pl-1 w-full'
                                        name="medicine"
                                        styles={{
                                            input: (base) => ({
                                                ...base,
                                                'input:focus': {
                                                    boxShadow: 'none',
                                                },
                                            }),
                                            control: (provided, state) => ({
                                                ...provided,
                                                height: '40px',
                                                minHeight: '15px',
                                                borderColor: state.isFocused ? 'blue' : '#6B7280',
                                                '&:hover': {
                                                    borderColor: state.isFocused ? 'blue' : 'gray',
                                                },
                                            }),

                                            dropdownIndicator: (provided) => ({
                                                ...provided,
                                                padding: '4px',
                                            }),
                                        }}
                                        value={{ value: row.medicine, label: row.medicine }}
                                        onChange={(selectedOption) => handleMedicineChange(row.order_id, selectedOption)}
                                        options={getAvailableOptions(row.id)}
                                        onCreateOption={(inputValue) => handleCreateChange(row.order_id, inputValue)}
                                        getOptionLabel={(option) => option.label}
                                        placeholder=""

                                    />
                                </td>

                                <td style={{ width: '20%' }}>
                                    <select name="medicine_type" className='h-10 ml-2 pl-2 rounded-md w-full text-left'
                                        id={"selectMedicineType" + index}
                                        value={row.medicine_type}
                                        onChange={(event) => handleChange(row.order_id, event)}>
                                        <option value="Tablet">Tablet</option>
                                        <option value="Syrup">Syrup</option>
                                        <option value="Capsule">Capsule</option>
                                        <option value="Ointment">Ointment</option>
                                    </select>
                                </td>

                                <td className='w-[10%]'>
                                    <input
                                        id={"chkWhenMedicine" + index}
                                        type="checkbox"
                                        className="mx-auto flex justify-center items-center"
                                        name="when_medidcine"
                                        checked={row.when_medidcine}
                                        onChange={(event) => handleChange(row.order_id, event)}
                                    />
                                </td>

                                <td className='w-[25%]'>
                                    <div className='flex justify-center'>
                                        <div className='flex justify-center'>
                                            <button
                                                id={"btnTimingMorning" + index}
                                                onClick={() => handleTimingChange(row.order_id, "morning")}
                                                className={row.morning === "1" ? "px-2 rounded-full bg-red-700 text-white py-1" : "px-2 rounded-full bg-gray-300 text-gray-700 py-1"}
                                            >
                                                Morning
                                            </button>
                                            <button
                                                id={"btnTimingNoon" + index}
                                                onClick={() => handleTimingChange(row.order_id, "noon")}
                                                className={row.noon === "1" ? "px-2 rounded-full bg-red-700 text-white py-1 mx-3" : "px-2 rounded-full bg-gray-300 text-gray-700 py-1 mx-3"}
                                            >
                                                Noon
                                            </button>
                                            <button
                                                id={"btnTimingNight" + index}
                                                onClick={() => handleTimingChange(row.order_id, "night")}
                                                className={row.night === "1" ? "px-2 rounded-full bg-red-700 text-white py-1" : "px-2 rounded-full bg-gray-300 text-gray-700 py-1"}
                                            >
                                                Night
                                            </button>
                                        </div>

                                    </div>
                                </td>
                                <td className='w-[10%]'>
                                    <input type='text'
                                        id={"textDuration" + index}
                                        className='h-10 rounded-md w-full text-center'
                                        name="duration"
                                        value={row.duration}
                                        onChange={(event) => handleChange(row.order_id, event)} />
                                </td>
                                <td className='text-center'>
                                    <button id={"btnMedicineDeleteRow" + index} disabled={submitDisable} onClick={() => deleteRow(row.order_id)} title='Delete Row'>
                                        <svg id="btnDeletePre" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6 text-red-500 hover:text-red-700">
                                            <path fillRule="evenodd" d="M16.5 4.478v.227a48.816 48.816 0 0 1 3.878.512.75.75 0 1 1-.256 1.478l-.209-.035-1.005 13.07a3 3 0 0 1-2.991 2.77H8.084a3 3 0 0 1-2.991-2.77L4.087 6.66l-.209.035a.75.75 0 0 1-.256-1.478A48.567 48.567 0 0 1 7.5 4.705v-.227c0-1.564 1.213-2.9 2.816-2.951a52.662 52.662 0 0 1 3.369 0c1.603.051 2.815 1.387 2.815 2.951Zm-6.136-1.452a51.196 51.196 0 0 1 3.273 0C14.39 3.05 15 3.684 15 4.478v.113a49.488 49.488 0 0 0-6 0v-.113c0-.794.609-1.428 1.364-1.452Zm-.355 5.945a.75.75 0 1 0-1.5.058l.347 9a.75.75 0 1 0 1.499-.058l-.346-9Zm5.48.058a.75.75 0 1 0-1.498-.058l-.347 9a.75.75 0 0 0 1.5.058l.345-9Z" clipRule="evenodd" />
                                        </svg>
                                    </button>
                                </td>
                            </tr>
                        ))}
                        <tr className='border-gray-200 border-t-[1px]'>
                            <td colSpan={4} className='w-full text-left p-2'>
                                {history?.prescriptions.filter(a => a.medicine !== "").length > 0 &&
                                    <div className="hidden lg:w-full w-full lg:text-right text-left gap-2 lg:inline-flex justify-start mt-4">

                                    </div>
                                }
                            </td>
                            <td colSpan={3} className='w-full text-right p-2'>
                                {history?.prescriptions.length > 0 &&
                                    <button id={"btnDeleteAllMedicineRow"} title='Delete All' disabled={submitDisable} onClick={() => setConfirmBoxDeleteAll(true)}>
                                        <span className='flex justify-end text-red-600 font-semibold hover:underline pr-2'>Delete All
                                            <svg id="btnDeleteAllPre" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6 text-red-500 hover:text-red-700">
                                                <path fillRule="evenodd" d="M16.5 4.478v.227a48.816 48.816 0 0 1 3.878.512.75.75 0 1 1-.256 1.478l-.209-.035-1.005 13.07a3 3 0 0 1-2.991 2.77H8.084a3 3 0 0 1-2.991-2.77L4.087 6.66l-.209.035a.75.75 0 0 1-.256-1.478A48.567 48.567 0 0 1 7.5 4.705v-.227c0-1.564 1.213-2.9 2.816-2.951a52.662 52.662 0 0 1 3.369 0c1.603.051 2.815 1.387 2.815 2.951Zm-6.136-1.452a51.196 51.196 0 0 1 3.273 0C14.39 3.05 15 3.684 15 4.478v.113a49.488 49.488 0 0 0-6 0v-.113c0-.794.609-1.428 1.364-1.452Zm-.355 5.945a.75.75 0 1 0-1.5.058l.347 9a.75.75 0 1 0 1.499-.058l-.346-9Zm5.48.058a.75.75 0 1 0-1.498-.058l-.347 9a.75.75 0 0 0 1.5.058l.345-9Z" clipRule="evenodd" />
                                            </svg>
                                        </span>
                                    </button>
                                }
                                <button id={"btnAddMedicineRow"} title='Add Row' disabled={submitDisable} onClick={addRow}>
                                    <span className='flex justify-end text-blue-600 font-semibold hover:underline'>Add row <svg id="btnAddRowPre" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6 text-blue-600">
                                        <path fillRule="evenodd" d="M5.625 1.5H9a3.75 3.75 0 0 1 3.75 3.75v1.875c0 1.036.84 1.875 1.875 1.875H16.5a3.75 3.75 0 0 1 3.75 3.75v7.875c0 1.035-.84 1.875-1.875 1.875H5.625a1.875 1.875 0 0 1-1.875-1.875V3.375c0-1.036.84-1.875 1.875-1.875ZM12.75 12a.75.75 0 0 0-1.5 0v2.25H9a.75.75 0 0 0 0 1.5h2.25V18a.75.75 0 0 0 1.5 0v-2.25H15a.75.75 0 0 0 0-1.5h-2.25V12Z" clipRule="evenodd" />
                                        <path d="M14.25 5.25a5.23 5.23 0 0 0-1.279-3.434 9.768 9.768 0 0 1 6.963 6.963A5.23 5.23 0 0 0 16.5 7.5h-1.875a.375.375 0 0 1-.375-.375V5.25Z" />
                                    </svg></span>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>

                {history.prescriptions.filter(a => a.medicine !== "").length > 0 && (
                    <div className="flex gap-2 justify-end">
                        <button
                            disabled={submitDisable}
                            onClick={() => savePrescription("save")}
                            className="flex items-center"
                        >
                            <IoMdSave className='text-green-600 size-6 cursor-pointer' id='SAVEPRESC' />
                            {loadingSave && (
                                <svg id="btnSavePre"
                                    aria-hidden="true"
                                    role="status"
                                    className="inline w-4 h-4 ml-2 text-gray-200 animate-spin dark:text-gray-600"
                                    viewBox="0 0 100 101"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="#1C64F2" />
                                </svg>
                            )}
                        </button>
                        <button
                            disabled={submitDisable}
                            onClick={() => savePrescription("save-print")}
                            id='SAVEPRINTPRESC'
                            className="flex items-center"
                        >
                            <svg id="btnSavePrintPre" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M6 6.94777V2.97754H18V6.94777H6ZM18 12.4068C18.2833 12.4068 18.521 12.3116 18.713 12.121C18.905 11.9304 19.0007 11.6948 19 11.4143C18.9993 11.1337 18.9033 10.8981 18.712 10.7076C18.5207 10.517 18.2833 10.4217 18 10.4217C17.7167 10.4217 17.4793 10.517 17.288 10.7076C17.0967 10.8981 17.0007 11.1337 17 11.4143C16.9993 11.6948 17.0953 11.9307 17.288 12.122C17.4807 12.3132 17.718 12.4082 18 12.4068ZM8 18.8585H16V14.8882H8V18.8585ZM6 20.8436V16.8733H2V7.94033H22V16.8733H18V20.8436H6Z" fill="#176CBB" />
                            </svg>
                            {loadingSavePrint && (
                                <svg id="btnSavePrint"
                                    aria-hidden="true"
                                    role="status"
                                    className="inline w-4 h-4 ml-2 text-gray-200 animate-spin dark:text-gray-600"
                                    viewBox="0 0 100 101"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="#1C64F2" />
                                </svg>
                            )}
                        </button>
                    </div>
                )}


                <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2 mt-4">
                    {files?.filter(a => a.file_type === 'Prescription')?.map((file, index) => {
                        const fileExtension = file.file_path.split('.').pop().toLowerCase();
                        const isImage = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp'].includes(fileExtension);
                        const isAudio = ['mp3', 'wav', 'ogg', 'm4a'].includes(fileExtension);
                        const isVideo = ['mp4', 'webm', 'ogg', 'mov'].includes(fileExtension);

                        return (
                            <div key={index} className="relative p-2 border rounded-sm h-[220px]">
                                <a rel="noopener" target="_blank" href={process.env.REACT_APP_EMRAXIS_S3_URL + "/" + file.file_path} className="block h-full">
                                    <div className="flex justify-center items-center h-full">
                                        {isImage ? (
                                            <img id="imgPrescription"
                                                className='max-h-[180px] max-w-full w-auto h-auto object-contain'
                                                src={process.env.REACT_APP_EMRAXIS_S3_URL + "/" + file.file_path}
                                                alt={file.file_name || "Image"}
                                            />
                                        ) : isAudio ? (
                                            <audio className="w-full max-w-[400px]" controls>
                                                <source src={process.env.REACT_APP_EMRAXIS_S3_URL + "/" + file.file_path} type={`audio/${fileExtension}`} />
                                            </audio>
                                        ) : isVideo ? (
                                            <video className="max-h-[180px] max-w-full w-auto h-auto object-contain" controls>
                                                <source src={process.env.REACT_APP_EMRAXIS_S3_URL + "/" + file.file_path} type={`video/${fileExtension}`} />
                                            </video>
                                        ) : (
                                            <FileIcon
                                                ext={"." + fileExtension}
                                                className='max-h-[180px] w-auto h-auto object-contain'
                                            />
                                        )}
                                    </div>
                                </a>
                                <button id='btnPreDeleteNotes'
                                    className="absolute top-2 left-2 bg-gray-100 rounded-full p-1 shadow"
                                    onClick={() => { setNotesFileDeleteCnfBox(true); setNotesFileDeleteID([{ ...file, is_active: false }]); }}
                                >
                                    <svg id="btnDeletePreNotes" xmlns="http://www.w3.org/2000/svg" className="size-6 text-red-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                                    </svg>
                                </button>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    )
}

export default PrescriptionContent