import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { setNotification } from '../../../../Redux/features/toast/toastSlice';
import store from '../../../../API/store';
import DataDecode from '../../../../utils/DataDecode';
import { setDocumentType, setIsCameraUpload, setRefreshUploadedFiles, setShowUploadPopup } from '../../../../Redux/features/uploadDoc/uploadDocSlice';
import FileIcon from '../FileIcon';
import DateFunction from '../../../../utils/DateFunctions';
import { setProfileSideNavbar, setUploadedDataAvailable } from '../../../../Redux/features/profileDrawer/profileSideNavbarSlice';
import { Checkbox, Label } from "flowbite-react";
import { theme } from '../../../../EmraxisTheme/Theme';

const UploadedData = ({ appointment, clinicGuid, appointment_id, patient_id, page_src, setIsMessagePopupOpen }) => {
    const dispatch = useDispatch();

    var loggedusertoken = useSelector(state => state.login.userBarerToken);
    var refreshUploadedFiles = useSelector(state => state.document_upload.refreshUploadedFiles);
    var refreshNoAppointmentUploadedData = useSelector(state => state.document_upload.refreshNoAppointmentUploadedData);
    var documentType = useSelector(state => state.document_upload.documentType);
    var isUploadedDataAvailable = useSelector(state => state.profileSideNavbar.isUploadedDataAvailable);
    const previewTheme = useSelector(state => state.theme.colors) || theme.filter(a => a.name === "defaultTheme")[0];

    const [drNotes, setDrNotes] = useState('');
    const [loading, setLoading] = useState(false);
    const [notesFileDeleteID, setNotesFileDeleteID] = useState(0);
    const [notesFileDeleteCnfBox, setNotesFileDeleteCnfBox] = useState(false);
    const [files, setFiles] = useState([]);
    const [allUploadedData, setAllUploadedData] = useState([]);
    const [loadingDeleteImage, setLoadingDeleteImage] = useState(false);
    const [dataLoading, setDataLoading] = useState(true);
    const [viewType, setViewType] = useState('grid');
    const [viewMore, setViewMore] = useState(false);
    const [selectAll, setSelectAll] = useState(false);
    const [confirmBox, setConfirmBox] = useState(false);


    const GetAllUploadedData = () => {
        if (allUploadedData.length == 0) {
            setDataLoading(true);
        }
        store.GetAllUploadedData(loggedusertoken, DataDecode.encryptPayload({ 'clinic_guid': clinicGuid, 'patient_id': patient_id }))
            .then((result) => {
                setDataLoading(false);
                if (result.status == 200) {
                    result.json().then((res) => {
                        let response = DataDecode.decryptResponse(res);
                        setAllUploadedData(JSON.parse(response));
                    })
                } else {
                    setAllUploadedData([])
                }
            })
            .catch(err => {
                setDataLoading(false);
                setAllUploadedData([])
                dispatch(setNotification({ message: 'Error occurred', status: 'error', action: true }));
            })
    }

    useEffect(() => {
        if (page_src === "patient") {
            GetAllUploadedData();
        }
    }, [refreshNoAppointmentUploadedData])


    const GetPatientNotesFile = () => {
        store.GetPatientFiles(loggedusertoken, DataDecode.encryptPayload({ 'clinic_guid': clinicGuid, 'patient_id': patient_id, 'appointment_id': appointment_id }))
            .then((result) => {
                if (result.status == 200) {
                    result.json().then((res) => {
                        let response = DataDecode.decryptResponse(res);
                        setFiles(JSON.parse(response));
                    })
                } else {
                    setFiles([])
                }
            })
            .catch(err => {
                setFiles([])
                dispatch(setNotification({ message: 'Error occurred', status: 'error', action: true }));
            })
    }

    useEffect(() => {
        GetPatientNotesFile();
    }, [refreshUploadedFiles])


    useEffect(() => {
        setDrNotes(appointment && appointment?.radiology_notes ? appointment?.radiology_notes : '')
    }, [appointment])


    const deleteNotesFile = () => {
        setLoadingDeleteImage(true);
        if (files.filter(a => a.file_type === "Others").length == 1) {
            let initialState = { personalDetails: false, vitals: false, doctorNotes: false, visitHistory: false, prescription: false, uploadedData: false, labNotes: false, radiology: false };
            dispatch(setProfileSideNavbar({ ...initialState, labNotes: true }))
            dispatch(setUploadedDataAvailable(false))
        }
        store.DeletePatientFile(loggedusertoken, DataDecode.encryptPayload({ 'clinic_guid': clinicGuid, 'patient_id': patient_id, files: notesFileDeleteID }))
            .then((result) => {
                setLoadingDeleteImage(false)
                setNotesFileDeleteCnfBox(false)

                if (result.status == 204) {
                    dispatch(setRefreshUploadedFiles(!refreshUploadedFiles))
                    dispatch(setNotification({ message: 'Deleted Successfully!', status: 'success', action: true }));
                }
                else {
                    dispatch(setNotification({ message: 'Error Occurred!', status: 'error', action: true }));
                }

            })
            .catch((error) => {
                dispatch(setNotification({ message: 'Error occurred', status: 'error', action: true }));
            });
    }

    useEffect(() => {
        // Update selectAll state based on individual item selection
        const allSelected = files.every(item => !item.is_active);
        setSelectAll(allSelected && files.length > 0);
    }, [files]);

    const handleDeleteChange = (id) => {
        setFiles(files.map(item =>
            item.patient_files_id === id ? { ...item, is_active: !item.is_active } : item
        ));
    }

    const handleSelectAll = (e) => {
        setFiles(files.map(item => ({ ...item, is_active: !e.target.checked })));
    }

    const handleDelete = () => {
        const atLeastOneSelected = files.some(item => !item.is_active);
        if (atLeastOneSelected) {
            setConfirmBox(true)
        } else {
            dispatch(setNotification({ message: 'Please select at leat one Image to delete!', status: 'error', action: true }));
        }
    }

    const handleDeleteSubmit = () => {
        setLoadingDeleteImage(true);
        store.DeletePatientFile(loggedusertoken, DataDecode.encryptPayload({ 'clinic_guid': clinicGuid, 'patient_id': patient_id, files: files }))
            .then((result) => {
                setLoadingDeleteImage(false)
                setConfirmBox(false)
                if (result.status == 204) {
                    setTimeout(() => {
                        dispatch(setRefreshUploadedFiles(!refreshUploadedFiles))
                    }, 100)
                    dispatch(setNotification({ message: 'Deleted Successfully!', status: 'success', action: true }));
                }
                else {
                    dispatch(setNotification({ message: 'Error Occurred!', status: 'error', action: true }));
                }

            })
            .catch((error) => {
                console.error("Error occurred while saving patient details:", error);
            });
    }

    const handleUploadButton = (type) => {
        dispatch(setIsCameraUpload(type === "capture" ? true : false));
        dispatch(setDocumentType({ ...documentType, others: true }))
        if (appointment_id == 0 || (appointment && (appointment.status == 'Completed'))) {
            setIsMessagePopupOpen(p => !p);
        } else {
            dispatch(setShowUploadPopup(true))
        }
    }

    return (
        <>
            {confirmBox &&
                <div id="deleteImageConfirmBox" tabIndex="-1" aria-hidden="true" className="fixed z-[9999] lg:left-[40%] lg:w-80 top-[10rem] lg:top-[3rem] lg:translate-x-[50%] translate-y-[50%]">

                    <div className="relative p-4 w-full max-w-md h-full md:h-auto">
                        <div className="relative p-4 text-center bg-white border-gray-400 border-[1px] rounded-lg shadow-lg dark:bg-gray-800 sm:p-5">
                            <svg id="imgCloseLoadingPopup" className="text-red-600 dark:text-gray-500 w-11 h-11 mb-3.5 mx-auto" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clipRule="evenodd"></path></svg>
                            <p className="mb-4 text-gray-500 dark:text-gray-300">Are you sure you want to delete this Images?</p>
                            <div className="flex justify-center items-center space-x-4">
                                <button id="btnNoConfirmDeleteSubmit" onClick={() => setConfirmBox(false)} disabled={loadingDeleteImage} type="button" className={`py-2 px-3 text-sm font-medium rounded-lg border border-gray-200 focus:ring-4 focus:outline-none focus:ring-primary-300 focus:z-10 ${previewTheme.cancelBtnColor} ${previewTheme.cancelBtnBgColor} hover:${previewTheme.cancelBtnHoverColor}`}>
                                    No, cancel
                                </button>
                                <button id="btnYesConfirmDeleteSubmit" type="button" onClick={handleDeleteSubmit} disabled={loadingDeleteImage} className="py-2 px-3 text-sm font-medium text-center text-white bg-red-600 rounded-lg hover:bg-red-700 focus:ring-4 focus:outline-none focus:ring-red-300 dark:bg-red-500 dark:hover:bg-red-600 dark:focus:ring-red-900 disabled:opacity-50 disabled:cursor-not-allowed">
                                    Yes, I'm sure {loadingDeleteImage &&
                                        <svg id="imgLoadingConfirmDeleteSubmit" aria-hidden="true" role="status" className="inline w-4 h-4 ml-2 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB" />
                                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor" />
                                        </svg>
                                    }
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            }

            {notesFileDeleteCnfBox &&

                <div id="delete" tabIndex="-1" aria-hidden="true" className="fixed z-[9999] lg:left-[40%] lg:w-80 top-[10rem] lg:top-[3rem] lg:translate-x-[50%] translate-y-[50%]">

                    <div className="relative p-4 w-full max-w-md h-full md:h-auto">
                        <div className="relative p-4 text-center bg-white border-gray-400 border-[1px] rounded-lg shadow-lg dark:bg-gray-800 sm:p-5">
                            <svg className="text-red-600 dark:text-gray-500 w-11 h-11 mb-3.5 mx-auto" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clipRule="evenodd"></path></svg>
                            <p className="mb-4 text-gray-500 dark:text-gray-300">Are you sure you want to delete this file?</p>
                            <div className=" flex justify-center items-center">
                                <button
                                    id="btnCancelDeleteFile"
                                    disabled={loadingDeleteImage}
                                    className={`mr-2 py-1 px-3 font-semibold rounded-sm shadow-md ${previewTheme.cancelBtnColor} ${previewTheme.cancelBtnBgColor} hover:${previewTheme.cancelBtnHoverColor}`}
                                    onClick={() => {
                                        setNotesFileDeleteCnfBox(false);
                                    }}>No, cancel</button>
                                <button id="btnYesCfmDeleteFile" type="button" onClick={deleteNotesFile} disabled={loadingDeleteImage} className={`py-2 px-3 text-sm font-medium text-center disabled:opacity-50 disabled:cursor-not-allowed ${previewTheme.saveBtnColor} ${previewTheme.saveBtnBgColor} hover:${previewTheme.saveBtnHoverColor}`}>
                                    Yes, I'm sure {loadingDeleteImage &&
                                        <svg aria-hidden="true" role="status" className="inline w-4 h-4 ml-2 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB" />
                                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor" />
                                        </svg>
                                    }
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            }

            <div className='shadow-lg rounded-md ' >
                <div className='flex justify-between bg-[#e2e8f0] text-[#a31b1b] rounded-t-lg text-lg font-semibold py-1 px-2'>
                    <div>
                        <p className='font-semibold text-lg'>Uploadad Data</p>
                    </div>
                    <div className='flex justify-end'>
                        {isUploadedDataAvailable && <>
                            {page_src === "appointment" && <>
                                <div className='flex mt-3'>
                                    <Label id="labelSelectAll" htmlFor="chkBoxSelectAll" className='ml-4 text-gray-700 cursor-pointer pr-2'>Select All</Label>
                                    <Checkbox id="chkBoxSelectAll" checked={selectAll} title="Select all" className='cursor-pointer' onChange={(e) => handleSelectAll(e)} />
                                </div>
                                <div>
                                    <button id="btnDeleteUploadedData" className='mt-2 text-gray-400' onClick={() => handleDelete()}>
                                        <svg id="imgDeleteIcon" xmlns="http://www.w3.org/2000/svg" className='size-6 text-red-500 ml-3 -mb-[5px]' viewBox="0 0 24 24" fill="none">
                                            <path d="M9.808 17H10.808V7.99998H9.808V17ZM13.192 17H14.192V7.99998H13.192V17ZM6 20V5.99998H5V4.99998H9V4.22998H15V4.99998H19V5.99998H18V20H6Z" fill="#EE1D52" />
                                        </svg>
                                    </button>
                                </div></>}
                            <button className={`p-2 ${viewType === 'list' ? 'bg-gray-300' : ''}`} onClick={() => setViewType('list')} id='BtnListViewButton'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M20 7H4M15 12H4M9 17H4" stroke={viewType === 'list' ? '#A31B1B' : '#666666'} strokeWidth="1.5" strokeLinecap="round" />
                                </svg>
                            </button>
                            <button className={`p-2 ${viewType === 'grid' ? 'bg-gray-300' : ''}`} onClick={() => setViewType('grid')} id='BtnGridViewButton'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill={viewType === 'grid' ? '#A31B1B' : '#666666'}>
                                    <g clipPath="url(#clip0_1767_3218)">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M9 13C9.53043 13 10.0391 13.2107 10.4142 13.5858C10.7893 13.9609 11 14.4696 11 15V19C11 19.5304 10.7893 20.0391 10.4142 20.4142C10.0391 20.7893 9.53043 21 9 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V15C3 14.4696 3.21071 13.9609 3.58579 13.5858C3.96086 13.2107 4.46957 13 5 13H9ZM19 13C19.5304 13 20.0391 13.2107 20.4142 13.5858C20.7893 13.9609 21 14.4696 21 15V19C21 19.5304 20.7893 20.0391 20.4142 20.4142C20.0391 20.7893 19.5304 21 19 21H15C14.4696 21 13.9609 20.7893 13.5858 20.4142C13.2107 20.0391 13 19.5304 13 19V15C13 14.4696 13.2107 13.9609 13.5858 13.5858C13.9609 13.2107 14.4696 13 15 13H19ZM9 3C9.53043 3 10.0391 3.21071 10.4142 3.58579C10.7893 3.96086 11 4.46957 11 5V9C11 9.53043 10.7893 10.0391 10.4142 10.4142C10.0391 10.7893 9.53043 11 9 11H5C4.46957 11 3.96086 10.7893 3.58579 10.4142C3.21071 10.0391 3 9.53043 3 9V5C3 4.46957 3.21071 3.96086 3.58579 3.58579C3.96086 3.21071 4.46957 3 5 3H9ZM19 3C19.5304 3 20.0391 3.21071 20.4142 3.58579C20.7893 3.96086 21 4.46957 21 5V9C21 9.53043 20.7893 10.0391 20.4142 10.4142C20.0391 10.7893 19.5304 11 19 11H15C14.4696 11 13.9609 10.7893 13.5858 10.4142C13.2107 10.0391 13 9.53043 13 9V5C13 4.46957 13.2107 3.96086 13.5858 3.58579C13.9609 3.21071 14.4696 3 15 3H19Z" fill={viewType === 'grid' ? '#A31B1B' : '#666666'} />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_1767_3218">
                                            <rect width="24" height="24" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>
                            </button>
                        </>}
                        <span title="Upload">
                            <svg id="DesktopBtnUploadUploadedData" xmlns="http://www.w3.org/2000/svg" onClick={() => handleUploadButton('upload')} fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" className={`size-7 mt-1 mx-2 inline cursor-pointer text-red-700`}>
                                <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5m-13.5-9L12 3m0 0 4.5 4.5M12 3v13.5" />
                            </svg>
                        </span>
                        <span title='Capture'>
                            <svg id="DesktopBtnCaptureUploadedData" xmlns="http://www.w3.org/2000/svg" onClick={() => handleUploadButton('capture')} fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" className={`inline mt-1 size-7 cursor-pointer text-red-700`}>
                                <path strokeLinecap="round" strokeLinejoin="round" d="M6.827 6.175A2.31 2.31 0 0 1 5.186 7.23c-.38.054-.757.112-1.134.175C2.999 7.58 2.25 8.507 2.25 9.574V18a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 18V9.574c0-1.067-.75-1.994-1.802-2.169a47.865 47.865 0 0 0-1.134-.175 2.31 2.31 0 0 1-1.64-1.055l-.822-1.316a2.192 2.192 0 0 0-1.736-1.039 48.774 48.774 0 0 0-5.232 0 2.192 2.192 0 0 0-1.736 1.039l-.821 1.316Z" />
                                <path strokeLinecap="round" strokeLinejoin="round" d="M16.5 12.75a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0ZM18.75 10.5h.008v.008h-.008V10.5Z" />
                            </svg>
                        </span>
                    </div>
                </div>
                {isUploadedDataAvailable &&
                    <>
                        {page_src === "appointment" ?
                            <div className="w-full rounded-lg bg-gray-50 dark:bg-gray-700 mt-2">
                                {appointment_id > 0 && files.filter(a => a.file_type === "Others").length > 0 ?
                                    <>
                                        {viewType === "grid" ? <>

                                            <div className="px-2 py-2 bg-white rounded-t-lg dark:bg-gray-800 min-h-36">
                                                <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-2">
                                                    {
                                                        files.filter(a => a.file_type === "Others").map((file, index) => {
                                                            const fileExtension = file.file_path.split('.').pop().toLowerCase();
                                                            const isImage = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp'].includes(fileExtension);
                                                            const isAudio = ['mp3', 'wav', 'ogg', 'm4a'].includes(fileExtension);
                                                            const isVideo = ['mp4', 'webm', 'ogg', 'mov'].includes(fileExtension);

                                                            return (
                                                                <div className="relative border rounded-sm" key={'image-notes-' + index}>
                                                                    <a rel="noopener" target="_blank" href={process.env.REACT_APP_EMRAXIS_S3_URL + "/" + file.file_path} className=''>
                                                                        {isImage ? (
                                                                            <img
                                                                                className='max-h-[180px] w-auto h-auto object-contain p-5'
                                                                                src={process.env.REACT_APP_EMRAXIS_S3_URL + "/" + file.file_path}
                                                                                alt="Prescription"
                                                                            />
                                                                        ) : isAudio ? (
                                                                            <div className="flex justify-center items-center h-full">  <audio className="w-full max-w-[400px]" controls>
                                                                                <source src={process.env.REACT_APP_EMRAXIS_S3_URL + "/" + file.file_path} type={`audio/${fileExtension}`} />
                                                                                Your browser does not support the audio element.
                                                                            </audio></div>
                                                                        ) : isVideo ? (
                                                                            <video className="max-h-[180px] w-auto h-auto object-contain p-5" controls>
                                                                                <source src={process.env.REACT_APP_EMRAXIS_S3_URL + "/" + file.file_path} type={`video/${fileExtension}`} />
                                                                                Your browser does not support the video element.
                                                                            </video>
                                                                        ) : (
                                                                            <div className="flex justify-center items-center h-full">
                                                                                <FileIcon
                                                                                    ext={"." + fileExtension}
                                                                                    className='max-h-[180px] w-auto h-auto object-contain p-5  '
                                                                                />
                                                                            </div>
                                                                        )}
                                                                    </a>
                                                                    {/* <button
                                                                id={'btnDeleteUploadedData' + index}
                                                                className="absolute top-2 left-2 bg-gray-100 rounded-full p-1 shadow"
                                                                onClick={() => { setNotesFileDeleteCnfBox(true); setNotesFileDeleteID([{ ...file, is_active: false }]); }}
                                                            >
                                                                <svg xmlns="http://www.w3.org/2000/svg" className="size-6 text-red-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                                                                </svg>
                                                            </button> */}
                                                                    <input
                                                                        id="chkBoxDeleteGridSelectAll"
                                                                        aria-label="checkBox"
                                                                        checked={!file.is_active}
                                                                        type="checkbox"
                                                                        className='absolute top-2 left-2 ml-2 cursor-pointer'
                                                                        onChange={() => handleDeleteChange(file.patient_files_id)}
                                                                    />
                                                                </div>
                                                            );
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        </>
                                            :
                                            <table className='min-w-full bg-white border-collapse'>
                                                <thead className=''>
                                                    <tr className='font-semibold text-lg bg-gray-100'>
                                                        <td className='px-4 py-2 shadow-md border-2 border-white text-left w-[40%]'>Date</td>
                                                        <td className='px-4 py-2 shadow-md border-2 border-white text-left w-[40%]'>Format</td>
                                                        <td className='px-4 py-2 shadow-md border-2 border-white text-center w-[20%]'>Delete</td>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        files.filter(a => a.file_type === "Others").map((file, index) => (
                                                            <tr className='odd:bg-white even:bg-gray-100' key={'uploaded-data-' + index}>
                                                                <td id={"btnDateClickViewOtherDetails" + index} className="px-4 py-2 shadow-md border-2 border-white text-left cursor-pointer" ><a rel="noopener" target="_blank" href={process.env.REACT_APP_EMRAXIS_S3_URL + "/" + file.file_path} className=''>{DateFunction.GetDate(appointment.appointment_time)}</a></td>
                                                                <td id={"btnFormatClickViewOtherDetails" + index} className="px-4 py-2 shadow-md border-2 border-white text-left cursor-pointer"><a rel="noopener" target="_blank" href={process.env.REACT_APP_EMRAXIS_S3_URL + "/" + file.file_path} className=''>{file.file_path?.split('.')?.pop()?.toLowerCase()}</a></td>
                                                                <td id={"btnDeleteClickViewOtherDetails" + index} className='text-center'>
                                                                    <input
                                                                        id="chkBoxDeleteListSelectAll"
                                                                        aria-label="checkBox"
                                                                        checked={!file.is_active}
                                                                        type="checkbox"
                                                                        className='ml-2 cursor-pointer'
                                                                        onChange={() => handleDeleteChange(file.patient_files_id)}
                                                                    />
                                                                    {/* <button
                                                                id={'btnDeleteUploadedDataList' + index}
                                                                className="text-center bg-gray-100 rounded-full p-1 shadow"
                                                                onClick={() => { setNotesFileDeleteCnfBox(true); setNotesFileDeleteID([{ ...file, is_active: false }]); }}
                                                            >
                                                                <svg xmlns="http://www.w3.org/2000/svg" className="size-6 text-red-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                                                                </svg>
                                                            </button> */}
                                                                </td>
                                                            </tr>
                                                        ))
                                                    }
                                                </tbody>
                                            </table>
                                        }
                                    </>
                                    :
                                    <p className='text-center text-red-600 p-5'>Data not available!</p>
                                }
                            </div>
                            :
                            <>
                                {allUploadedData.length > 0 ?
                                    <div className="w-full rounded-lg bg-gray-50">
                                        {viewType === "grid" ? <>
                                            {
                                                allUploadedData.map((data, index) => (
                                                    <div className={'border border-gray-200' + (index > 2 && !viewMore ? " hidden" : "")} key={'all-notes-' + index}>
                                                        <div className='bg-red-300 h-6'>
                                                            <p className='text-center font-semibold'><span className='px-1'>{DateFunction.GetDate(data.date)}</span></p>
                                                        </div>

                                                        <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-4 gap-2 p-2">
                                                            {
                                                                data?.files?.map((file, index) => {
                                                                    const fileExtension = file.file_path.split('.').pop().toLowerCase();
                                                                    const isImage = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp'].includes(fileExtension);
                                                                    const isAudio = ['mp3', 'wav', 'ogg', 'm4a'].includes(fileExtension);
                                                                    const isVideo = ['mp4', 'webm', 'ogg', 'mov'].includes(fileExtension);

                                                                    return (
                                                                        <div className="relative border rounded-sm" key={'image-notes-' + index}>
                                                                            <a rel="noopener" target="_blank" href={process.env.REACT_APP_EMRAXIS_S3_URL + "/" + file.file_path} className=''>
                                                                                {isImage ? (
                                                                                    <img
                                                                                        id={"imgUploadedDataFile" + index}
                                                                                        className='max-h-[180px] w-auto h-auto object-contain p-5'
                                                                                        src={process.env.REACT_APP_EMRAXIS_S3_URL + "/" + file.file_path}
                                                                                        alt="Uploaded Data"
                                                                                    />
                                                                                ) : isAudio ? (
                                                                                    <div className="flex justify-center items-center h-full">  <audio id={"audioUploadedDataFile" + index} className="w-full max-w-[400px]" controls>
                                                                                        <source src={process.env.REACT_APP_EMRAXIS_S3_URL + "/" + file.file_path} type={`audio/${fileExtension}`} />
                                                                                        Your browser does not support the audio element.
                                                                                    </audio></div>
                                                                                ) : isVideo ? (
                                                                                    <video id={"videoUploadedDataFile" + index} className="max-h-[180px] w-auto h-auto object-contain p-5" controls>
                                                                                        <source src={process.env.REACT_APP_EMRAXIS_S3_URL + "/" + file.file_path} type={`video/${fileExtension}`} />
                                                                                        Your browser does not support the video element.
                                                                                    </video>
                                                                                ) : (
                                                                                    <div className="flex justify-center items-center h-full">
                                                                                        <FileIcon
                                                                                            id={"fileUploadedDataFile" + index}
                                                                                            ext={"." + fileExtension}
                                                                                            className='max-h-[180px] w-auto h-auto object-contain'
                                                                                        />
                                                                                    </div>
                                                                                )}
                                                                            </a>

                                                                        </div>
                                                                    );
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </> :
                                            <table className='min-w-full bg-white border-collapse'>
                                                <thead className=''>
                                                    <tr className='font-semibold text-lg bg-gray-100'>
                                                        <td className='px-4 py-2 shadow-md border-2 border-white text-left w-[33%]'>Date</td>
                                                        <td className='px-4 py-2 shadow-md border-2 border-white text-left w-[33%]'>Format</td>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        allUploadedData.map((data, index) => (
                                                            <>
                                                                {
                                                                    data?.files?.map((file, index1) => (
                                                                        <tr className={'odd:bg-white even:bg-gray-100' + (index > 2 && !viewMore ? " hidden" : "")} key={'uploaded-data-' + index + index1}>
                                                                            <td id={"btnDateClickViewDetails" + index} className="px-4 py-2 shadow-md border-2 border-white text-left cursor-pointer" ><a rel="noopener" target="_blank" href={process.env.REACT_APP_EMRAXIS_S3_URL + "/" + file.file_path} className=''>{DateFunction.GetDate(data.date)}</a></td>
                                                                            <td id={"btnDoctorClickViewDetails" + index} className="px-4 py-2 shadow-md border-2 border-white text-left cursor-pointer"><a rel="noopener" target="_blank" href={process.env.REACT_APP_EMRAXIS_S3_URL + "/" + file.file_path} className=''>{file.file_path?.split('.')?.pop()?.toLowerCase()}</a></td>
                                                                        </tr>
                                                                    ))
                                                                }
                                                            </>
                                                        ))
                                                    }
                                                </tbody>
                                            </table>
                                        }
                                        <div className='flex justify-end'>
                                            {allUploadedData.length > 3 &&
                                                <button id="btnViewMoreUploadedData" className='text-red-600 p-2' onClick={() => { setViewMore(vm => !vm) }}>{viewMore ? "View Less" : "View More"}</button>
                                            }
                                        </div>
                                    </div>
                                    :
                                    <>
                                        {dataLoading &&
                                            <p className='text-center p-5'>
                                                <svg id="imgUploadedDataLoading" aria-hidden="true" role="status" className="size-6 ml-2 text-red-600 animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB" />
                                                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor" />
                                                </svg>
                                            </p>
                                        }
                                    </>
                                }
                            </>
                        }
                    </>}
            </div>
        </>
    )
}

export default UploadedData