import React, { useState, useEffect, useRef } from 'react'
import dayjs from 'dayjs';
import Popover from "@mui/material/Popover";
import { Container, Grid } from "@mui/material";
import { setAppGridViewAppointmentData, setAppGridViewSelection } from '../../../Redux/features/appointmentGridView/gridviewSlice';
import { useDispatch, useSelector } from 'react-redux';
import AppDetailsPopover from './AppDetailsPopover';
import { Tooltip } from "@mui/material";
import DayViewPicker from './DayViewPicker';
import TimeLineIndicator from './TimeLineIndicator';
import { setSideDateToShow } from '../../../Redux/features/appointmentGridView/gridViewTodaySlice';
import DateFunction from '../../../utils/DateFunctions';

const DayView = ({ appointments, doctors, doctorsTimeslot, setRefreshData, deleteAppointment }) => {

  const viewType = useSelector(state => state.appGridView.appGridViewSelection);
  var loggeduser = useSelector(state => state.login.loginuserData);
  const [timeColumn, setTimeColumn] = useState(90);
  const [daysSelected, setDaysSelected] = useState(1);
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const [currentWeekLbl, setCurrentWeekLbl] = useState("");
  const [dayMonthSelectRef, setDayMonthSelectRef] = useState(null);
  const [appointmentDetailRef, setAppointmentDetailRef] = useState(null);
  const popoverTop = appointmentDetailRef?.getBoundingClientRect().top || 0;
  const popoverRight = viewType == "1day" ? appointmentDetailRef?.getBoundingClientRect().right || 0 : appointmentDetailRef?.getBoundingClientRect().right || 0;

  const [hours, setHours] = useState([]);
  const [days, setDays] = useState([]);
  const today = dayjs().format('ddd'); // Get today's day name (e.g., "Mon")

  const [currentMinute, setCurrentMinute] = useState(dayjs().format('m'));
  const [currentHour, setCurrentHour] = useState(dayjs().format('HH'));

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentMinute(parseInt(dayjs().format('m'))); // Updates every minute
      setCurrentHour(parseInt(dayjs().format('HH'))); // Updates every hour
    }, 60000);
    return () => clearInterval(interval);
  }, []);

  const dispatch = useDispatch();
  const [events, setEvents] = useState([]);
  const sectionRefs = useRef([]);

  const scrollToPosition = (id, yOffset = -100) => {
    const y = sectionRefs.current[id]?.getBoundingClientRect().top + window.scrollY + yOffset;
    window.scrollTo({ top: y, behavior: 'smooth' });
  }

  useEffect(() => {
    let isTodayDateExist = false;
    let daysCount = parseInt(viewType?.charAt(0) || 0);
    let i = 0;
    while (daysCount > i) {
      if (dayjs(selectedDate).add(i, "day").format("DMYY") === dayjs().format("DMYY"))
        isTodayDateExist = true;
      i++;
    }
    if (hours.length > 0 && isTodayDateExist) {
      let currentTimeValue = dayjs().format("hh:00 A");
      const index = hours.findIndex(tim => tim === currentTimeValue);
      scrollToPosition(index || 0, -250)
    } else {
      scrollToPosition(0, -250)
    }
  }, [hours, appointments, selectedDate])

  useEffect(() => {
    if (appointments.length > 0) {
      let event = [];
      appointments.filter(a => a.app_time).map((app, ind) => {
        let startTime = new Date(`1970-01-01T${app.app_time}`);
        event.push({
          appointment: app,
          day: dayjs(new Date(app.appointment_time)).format('ddd'),
          currentDate: dayjs(new Date(app.appointment_time)).format('DDMMYYYY'),
          hour: dayjs(startTime).format('HH'),
          minute: dayjs(startTime).format('mm') >= 30 ? "30" : "00",
          duration: 1
        })
      })
      setEvents(event);
    } else {
      setEvents([])
    }
  }, [appointments])

  //function to generate hours
  const generateHalfHourSlots = (ranges, interval = 30) => {
    const slots = [];
    ranges.forEach(({ start, end }) => {
      let startTime = new Date(`1970-01-01T${start}`);
      const endTime = new Date(`1970-01-01T${end}`);
      while (startTime <= endTime) {
        const timeString = DateFunction.formatTo12Hour(startTime);
        slots.push(timeString);
        startTime.setMinutes(startTime.getMinutes() + interval);
      }
    });
    return slots;
  };

  //function to generate days
  const generateWorkWeekDays = () => {
    const startOfWeek = selectedDate
    let noOfDays = parseInt(viewType[0]);
    setDaysSelected(noOfDays);
    setTimeColumn(parseInt(90 / noOfDays))
    if (noOfDays == 1) {
      setCurrentWeekLbl(`${startOfWeek.add(0, 'day').format('MMM')} ${startOfWeek.add(0, 'day').format('DD')}, ${startOfWeek.add(0, 'day').format('YYYY')}`)
    } else {
      setCurrentWeekLbl(`${startOfWeek.add(0, 'day').format('MMM')} ${startOfWeek.add(0, 'day').format('DD')} - ${startOfWeek.add(noOfDays - 1, 'day').format('DD')}, ${startOfWeek.add(0, 'day').format('YYYY')}`)
    }

    const days = [];
    for (let i = 0; i < noOfDays; i++) {
      days.push({ day_name: startOfWeek.add(i, 'day').format('ddd'), currentDate: startOfWeek.add(i, 'day').format('DDMMYYYY'), day: startOfWeek.add(i, 'day').format('D'), original: startOfWeek.add(i, 'day').format('dddd, MMM D') });
    }
    return days;
  };

  useEffect(() => {
    if (loggeduser) {
      let startTime = (loggeduser?.open_time || "00:00:00");
      let endTime = (loggeduser?.close_time || "23:55:00");
      startTime = `${startTime.split(":")[0]}:00:00`
      setHours(generateHalfHourSlots([{ start: startTime, end: endTime }]))
      setDays(generateWorkWeekDays());
    }
  }, [selectedDate, viewType, loggeduser]);

  const handleAppointmentDetailPopup = (evt, appointment) => {
    dispatch(setAppGridViewAppointmentData(appointment))
    setAppointmentDetailRef(evt.currentTarget);
  }

  const convertTo24Hour = (time) => {
    const [timeString, modifier] = time.split(' ');
    let [hours, minutes] = timeString.split(':');
    hours = parseInt(hours, 10);

    if (modifier === 'PM' && hours < 12) {
      hours += 12;
    }
    if (modifier === 'AM' && hours === 12) {
      hours = 0;
    }

    return `${hours.toString().padStart(2, '0')}:${minutes}`;
  };

  // Helper to check if the event should be displayed in a given time slot
  const renderEvent = (date, hour, isHalf) => {
    const event = events.filter(
      (event) => event.currentDate === date && event.hour === hour.split(":")[0] && event.minute === isHalf
    );
    return event.length > 0 && isHalf ? (
      <>
        {event.map((ev, i) => (
          <div key={'app-event-' + i}
            id={"DivAgantaAppointmentDayView" + i}
            onClick={(evt) => handleAppointmentDetailPopup(evt, ev.appointment)}
            className="bg-[#2823584A] text-[#454242] truncate p-1 mb-2 rounded test-sm cursor-pointer">
            <Tooltip
              id={"TooltipAgantaAppointmentDayView" + i}
              title={ev.appointment.FirstName + " " + ev.appointment.MiddleName?.charAt(0) + " " + ev.appointment.LastName}
              placement="top-start"
            >
              {ev.appointment.FirstName + " " + ev.appointment.MiddleName?.charAt(0) + " " + ev.appointment.LastName}
            </Tooltip>
          </div>
        ))
        }
      </>
    ) : null;
  };

  const nextDay = () => {
    setSelectedDate((prev) => prev.add(daysSelected, 'day'));
  }

  const previousDay = () => {
    setSelectedDate((prev) => prev.add(-daysSelected, 'day'));
  }

  const handleClick = (event) => {
    setDayMonthSelectRef(event.currentTarget);
  };

  const handleAppointmentDetailPopupClose = () => {
    setAppointmentDetailRef(null);
  }

  return (
    <div id="GridDayViewMainDiv">
      {Boolean(appointmentDetailRef) && (
        <Popover
          id={
            Boolean(appointmentDetailRef)
              ? "appointment-details-view-popup"
              : undefined
          }
          open={Boolean(appointmentDetailRef)}
          anchorEl={appointmentDetailRef}
          anchorReference="anchorPosition"
          anchorPosition={{ top: popoverTop, left: popoverRight }}
          anchorOrigin={{
            vertical: "center",
            horizontal: "right",
          }}
          onClose={() => handleAppointmentDetailPopupClose()}
          PaperProps={{
            sx: {
              width: "20rem",
              borderRadius: "8px",
              backgroundColor: "#fff",
              marginBottom: "50px",
              zIndex: "999999",
              position: "sticky",
              bottom: "50px"
            },
          }}
          disablePortal // To avoid incorrect positioning
          getContentAnchorEl={null} // Prevent content anchor issues
          modifiers={[
            {
              name: 'preventOverflow',
              options: {
                boundary: 'viewport', // Ensure it stays within the viewport
              },
            },
            {
              name: 'flip',
              options: {
                altBoundary: true,
                fallbackPlacements: ['top', 'right', 'left'], // Allow it to flip positions
              },
            },
          ]}
        >
          <AppDetailsPopover doctors={doctors} doctorsTimeslot={doctorsTimeslot} setRefreshData={setRefreshData} setAppointmentDetailRef={setAppointmentDetailRef} deleteAppointment={deleteAppointment} />
        </Popover>
      )}

      <div className='sticky top-[9.5rem] bg-white z-20'>
        <header className='bg-[#282358F5] px-2 py-2 flex justify-start' id="GridDayViewMainHeader">
          <button id="BtnGridDayViewToday" onClick={() => { dispatch(setAppGridViewSelection("1day")); setSelectedDate(dayjs()); dispatch(setSideDateToShow(new Date())); }} className='bg-white text-[#A31B1B] px-2 py-1 rounded-md font-semibold ml-3'>Today</button>
          <button id="BtnGridDayViewPrevious" className='text-white font-semibold ml-3' onClick={previousDay}>
            <svg id="ImgGridDayViewPrevious" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" className="size-6">
              <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 15.75 7.5-7.5 7.5 7.5" />
            </svg>
          </button>
          <button id="BtnGridDayViewNext" className='text-white font-semibold ml-3' onClick={nextDay}>
            <svg id="ImgGridDayViewNext" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" className="size-6">
              <path strokeLinecap="round" strokeLinejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
            </svg>
          </button>

          <button id="BtnGridDayViewDatePicker" onClick={handleClick} aria-describedby={Boolean(dayMonthSelectRef)} type="button" className="text-center inline-flex items-center bg-white text-[#A31B1B] px-2 py-1 rounded-md font-semibold ml-4">
            {currentWeekLbl}
            <svg id="ImgGridDayViewDatePicker" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" className="size-6 ms-2">
              <path strokeLinecap="round" strokeLinejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
            </svg>
          </button>
          <Popover
            id={
              Boolean(dayMonthSelectRef)
                ? "date-month-select-calendar"
                : undefined
            }
            open={Boolean(dayMonthSelectRef)}
            anchorEl={dayMonthSelectRef}
            onClose={() => setDayMonthSelectRef(null)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <Container>
              <Grid container spacing={1}>
                <Grid item xs={12} md={6}>
                  <DayViewPicker
                    selectedDate={selectedDate}
                    views={['year', 'month', 'day']}
                    setSelectedDate={setSelectedDate}
                    setDayMonthSelectRef={setDayMonthSelectRef}
                    daysToSelect={daysSelected}
                  />
                </Grid>
              </Grid>
            </Container>
          </Popover>
        </header>
      </div>

      <div>
        <table id="TableGridDayView" className="table-fixed w-full border-collapse border border-gray-300 appointment-grid-weekview-table mt-[4.3rem]">
          <thead id="TheadGridDayView" className='sticky top-[12.47rem] bg-white z-20'>
            <tr id="TrGridDayView">
              {/* Empty top-left cell for time slots */}
              <th id="ThGridDayView" className="border border-gray-300 p-2 w-[10%]"></th>
              {/* Days (Monday to Friday) */}
              {days.map((day, ind) => {
                const isToday = day.original.startsWith(today); // Check if it's today
                return (
                  <th
                    id={"ThGridDayView" + ind}
                    key={day.day + "ind"}
                    className={`border border-gray-300 py-2 w-[${timeColumn}%] ${isToday ? 'bg-gray-200' : ''
                      }`} // Highlight today's date
                  >
                    <div className="font-bold flex justify-start px-2">
                      <p id={"ThGridDayViewDayName" + ind} className='p-1 mr-2'>
                        {day.day_name}
                      </p>
                      <p id={"ThGridDayViewDayDate" + ind} className={`${day.currentDate === dayjs().format('DDMMYYYY') ? 'rounded-full text-white bg-[#A31B1B] p-1 size-8 text-center' : 'text-[#A31B1B] p-1'}`}>
                        {day.day}
                      </p>
                    </div>
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody id="TbodyGridDayView">
            {/* Time slots for each half hour */}
            {hours.map((hour, index) => (
              <tr key={hour} ref={(el) => (sectionRefs.current[index] = el)} id={"TrGridDayView" + index}>
                {/* Time column */}
                {
                  index % 2 === 0 ?
                    <td id={"TrGridDayTimeView" + index} className="border border-b-0 border-gray-300 p-2 text-right">{hour}</td> :
                    <td id={"TrGridDayTimeEmptyView" + index} className="border border-t-0 border-gray-300 p-2 text-right"></td>
                }

                {/* Time slots for each day */}
                {days.map((day, ind) => {
                  const isToday = day.original.startsWith(today); // Check if it's today
                  const isHalfHour = index % 2 === 1; // Every second slot is a half-hour slot
                  return (
                    <React.Fragment key={"day-td-" + ind}>
                      {
                        !isHalfHour ?
                          <td
                            id={"TrGridDayAgentaViewFirstHalf" + index}
                            key={day + hour}
                            className={`w-[${timeColumn}%] border-gray-300 h-16 relative border-l border-r border-solid ${isToday ? 'bg-gray-100' : ''}`} // Dashed top border for half-hour slots
                          >
                            {isToday && parseInt(convertTo24Hour(hour).split(":")[0]) == currentHour && currentMinute >= 0 && currentMinute <= 30 &&
                              <TimeLineIndicator />
                            }
                            <div className='p-2 relative z-10'>
                              {renderEvent(day.currentDate, convertTo24Hour(hour), "00")}
                            </div>
                          </td> :
                          <td
                            id={"TrGridDayAgentaViewSecondHalf" + index}
                            key={day + hour}
                            className={`border w-[${timeColumn}%] border-t-0 border-gray-300 h-16 relative border-l border-solid ${isToday ? 'bg-gray-100' : ''}`} // Dashed top border for half-hour slots
                          >
                            {isToday && parseInt(convertTo24Hour(hour).split(":")[0]) == currentHour && currentMinute > 31 && currentMinute < 60 &&
                              <TimeLineIndicator />
                            }
                            <div className='p-2 relative z-10'>
                              {renderEvent(day.currentDate, convertTo24Hour(hour), "30")}
                            </div>
                          </td>
                      }
                    </React.Fragment>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>
        <div className='pb-96'></div>
      </div>
    </div>
  )
}

export default DayView