import React, { useEffect, useState } from 'react';
import store from '../../API/store';
import DataDecode from '../../utils/DataDecode';
import { useDispatch, useSelector } from 'react-redux';
import { setNotification } from '../../Redux/features/toast/toastSlice';
import { setAddMasterTariff } from '../../Redux/features/popup/popupSlice';
import { theme } from '../../EmraxisTheme/Theme';



const AddMasterTariff = ({ setRefreshData, masterTariff }) => {

    const dispatch = useDispatch();
    var loggeduser = useSelector(state => state.login.loginuserData);
    var loggedusertoken = useSelector(state => state.login.userBarerToken);
    const previewTheme = useSelector(state => state.theme.colors) || theme.defaultTheme;

    const [submitDisable, setSubmitDisable] = useState(false);

    const [newMasterTariff, setNewMasterTariff] = useState({
        master_tariff_id: (masterTariff && masterTariff.master_tariff_id) ? masterTariff.master_tariff_id : 0,
        tariff_name: (masterTariff && masterTariff.tariff_name) ? masterTariff.tariff_name : "",
        amount: (masterTariff && masterTariff.amount) ? masterTariff.amount : 0,
        gst: (masterTariff && masterTariff.gst) ? masterTariff.gst : 0,
        clinic_guid: ''
    });


    //For Editing the appointment
    useEffect(() => {
        if (masterTariff) {
            setNewMasterTariff({
                clinic_guid: loggeduser.facilityID,
                master_tariff_id: masterTariff.master_tariff_id,
                tariff_name: masterTariff.tariff_name,
                amount: masterTariff.amount,
                gst: masterTariff.gst,
            });
        }
    }, [masterTariff])


    const onClose = () => {
        dispatch(setAddMasterTariff(false));
    };


    const clearMasterTariff = () => {
        setNewMasterTariff({
            ...masterTariff,
            master_tariff_id: 0,
            tariff_name: "",
            amount: 0,
            gst: 0
        });
    }

    useEffect(() => {
        if (loggeduser) {
            setNewMasterTariff({ ...newMasterTariff, clinic_guid: loggeduser.facilityID })
        } else {

        }
    }, [loggeduser])


    const handleChange = (event) => {
        const { name, value } = event.target;
        setNewMasterTariff({
            ...newMasterTariff,
            [name]: value
        });
    };

    const handleSubmitTariff = () => {
        if (newMasterTariff.tariff_name.trim() != "" && newMasterTariff.amount != "" && newMasterTariff.amount > 0 && !isNaN(newMasterTariff.gst) && parseInt(newMasterTariff.gst) >= 0) {
            setSubmitDisable(true);
            if (newMasterTariff.master_tariff_id == 0) {
                store.CreateMasterTariff(loggedusertoken, DataDecode.encryptPayload(newMasterTariff))
                    .then((result) => {
                        setSubmitDisable(false)
                        if (result.status == 201) {
                            clearMasterTariff();
                            setRefreshData(r => !r);
                            dispatch(setNotification({ message: 'Tariff Added Successfully!', status: 'success', action: true }));
                            onClose();
                        }
                        else {
                            dispatch(setNotification({ message: 'Error Occured!', status: 'error', action: true }));
                        }
                    })
                    .catch(err => {
                        dispatch(setNotification({ message: 'Error Occurred!', status: 'error', action: true }));
                    })
            } else {
                store.UpdateMasterTariff(loggedusertoken, DataDecode.encryptPayload(newMasterTariff))
                    .then((result) => {
                        setSubmitDisable(false)
                        if (result.status == 204) {
                            clearMasterTariff();
                            dispatch(setNotification({ message: 'Tariff Updated Successfully!', status: 'success', action: true }));
                            setRefreshData(r => !r);
                            onClose();
                        }
                        else {
                            dispatch(setNotification({ message: 'Error Occurred!', status: 'error', action: true }));
                        }
                    })
                    .catch(err => {
                        dispatch(setNotification({ message: 'Error Occurred!', status: 'error', action: true }));
                    })
            }
        }
        else {
            dispatch(setNotification({ message: '* Fields are required!', status: 'error', action: true }));
        }
    }

    return (
        <div id="AddMasterTariffDivtariffPopup" className="fixed inset-0 flex justify-center items-center p-8 z-[99999]">
            <div id="AddMasterTariffDivoverlay" className="absolute inset-0 bg-black opacity-25"></div>
            <div id="AddMasterTariffDivtariffForm" className="relative bg-white shadow-xl p-8 min-[370px] max-h-[80vh] lg:max-h-[80vh] 2xl:max-h-[80vh] overflow-y-auto" style={{ width: "600px" }} >
                <div id="AddMasterTariffDivtariffHeader" className="flex items-center justify-between ">
                    <div id="AddMasterTariffDivtariffTitle" className={`text-black font-bold text-2xl sm:mb:6 ${previewTheme.headingTitleColor}`} style={{ fontFamily: previewTheme.fontFamily }}>{masterTariff?.master_tariff_id == 0 ? "Add Tariff" : "Update Tariff"}</div>
                    <button id="AddMasterTariffBtntariffClose" className={`text-red-700 sm:justify-left`} onClick={onClose}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className={`h-10 w-10 ${previewTheme.popupCloseBtnColor} transition-colors duration-300 ease-in-out hover:${previewTheme.popupCloseBtnHoverColor}`}>
                            <path strokeLinecap="round" strokeLinejoin="round" d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                        </svg>
                    </button>
                </div>

                <div id="AddMasterTariffDivtariffFields" className="grid grid-cols-1 gap-4 sm:grid-cols-2 sm:gap-2">
                    <div id="AddMasterTariffDivtariffNameField" className="">
                        <label id="AddMasterTariffLbltariffName" htmlFor="tariff_name" className="block text-gray-700 mb-2 sm:w-auto sm:text-left lg:w-auto">Tariff Name *</label>
                        <div className="relative">
                            <input id="AddMasterTariffInputtariffName" type="text" name="tariff_name" value={newMasterTariff.tariff_name} onChange={(event) => { handleChange(event) }} className="block border text-sm rounded-md focus:outline-none focus:border-gray-500 px-2 py-2 w-full" />
                        </div>
                    </div>
                    <div id="AddMasterTariffDivtariffCostField" className="">
                        <label id="AddMasterTariffLbltariffCost" htmlFor="amount" className="block text-gray-700 mb-2 sm:w-1 lg:w-auto">Tariff Cost *</label>
                        <div className="relative">
                            <input type="number" id="AddMasterTariffamount" name="amount" value={newMasterTariff.amount} onChange={(event) => { handleChange(event) }} className="block border text-sm rounded-md focus:outline-none focus:border-gray-500 px-2 py-2 w-full" />
                        </div>
                    </div>
                    <div id="AddMasterTariffDivgstField" className="">
                        <label id="AddMasterTariffLblgst" htmlFor="amount" className="block text-gray-700 mb-2 sm:w-1 lg:w-auto">GST(%) *</label>
                        <div className="relative">
                            <input type="number" id="AddMasterTariffInputgst" name="gst" value={newMasterTariff.gst} onChange={(event) => { handleChange(event) }} className="block border text-sm rounded-md focus:outline-none focus:border-gray-500 px-2 py-2 w-full" />
                        </div>
                    </div>
                </div>

                <div id="AddMasterTariffDivtariffActions" className="space-x-4 mt-2 text-right">
                    <button id="AddMasterTariffBtncancelTariff" disabled={submitDisable} className={`px-4 py-2 border rounded-md transition-colors duration-300 ease-in-out ${previewTheme.cancelBtnColor} ${previewTheme.cancelBtnBgColor}  ${submitDisable ? 'opacity-50 cursor-not-allowed' : `hover:${previewTheme.cancelBtnHoverColor}`}`} onClick={onClose}>Cancel</button>
                    <button id="AddMasterTariffBtnsaveTariff" disabled={submitDisable} className={`px-4 py-2 border rounded-md transition-colors duration-300 ease-in-out ${previewTheme.saveBtnColor} ${previewTheme.saveBtnBgColor} ${submitDisable ? 'opacity-50 cursor-not-allowed' : `hover:${previewTheme.saveBtnHoverColor}`}`} onClick={handleSubmitTariff}>Save

                        {submitDisable &&
                            <svg id="AddMasterTariffSvgloadingIndicator" aria-hidden="true" role="status" className="inline w-4 h-4 ml-2 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB" />
                                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor" />
                            </svg>
                        }
                    </button>
                </div>
            </div>
        </div>
    );
};
export default AddMasterTariff;