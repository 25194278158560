import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isInvoiceEnabled: false,
    showAppEditInvoice: true,
    appointmentInvoice: { patient: {}, doctor: {}, appointment: {}, targetFrom: "" }
}

export const popupSlice = createSlice({
    name: 'invoice',
    initialState,
    reducers: {
        setIsInvoiceEnabled: (state, action) => {
            state.isInvoiceEnabled = action.payload
        },
        setAppointmentInvoice: (state, action) => {
            state.appointmentInvoice = action.payload
        },
        setShowAppEditInvoice: (state, action) => {
            state.showAppEditInvoice = action.payload
        }
    }
})

export const { setIsInvoiceEnabled, setAppointmentInvoice, setShowAppEditInvoice } = popupSlice.actions;

export default popupSlice.reducer;