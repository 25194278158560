import React, { useState, useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import { useSelector, useDispatch } from "react-redux";
import store from "../../API/store";
import AddMembers from "../Usermangement/AddMembers";
import DataDecode from "../../utils/DataDecode";
import { setNotification } from "../../Redux/features/toast/toastSlice";
import {
  addFacilityName,
  addFacilityID,
  addFacilityEmail,
  addFacilityColor,
  addFacilityLogo,
} from "../../Redux/features/facility/facilitySlice";
import {
  addFacilityUser,
  addFacilityPhone,
  addUserEmail,
  addUserRole,
} from "../../Redux/features/users/userSlice";
import Toggle from "../Usermangement/Toggle";
import { theme } from "../../EmraxisTheme/Theme";
import { addLoginToken } from "../../Redux/features/login/loginSlice";
import { protectedResources } from "../../authConfig";

const AdminUser = () => {
  const [edit, setedit] = useState("create");
  const [showMenu, setShowMenu] = useState(false);
  const [selectedSort, setSelectedSort] = useState("Newest");
  const [showPopup, setShowPopup] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [userdata, setuserdata] = useState([]);
  const [userDetails, setUserDetails] = useState([]);
  const [refreshData, setRefreshData] = useState(false);
  const [showSearchBar, setShowSearchBar] = useState(false);
  const [deletePopup, setDeletePopup] = useState(false);
  var loggeduser = useSelector((state) => state.login.loginuserData);
  var loggedusertoken = useSelector((state) => state.login.userBarerToken);
  const previewTheme =
    useSelector((state) => state.theme.colors) || theme.defaultTheme;
  const [query, setQuery] = useState("");
  const dispatch = useDispatch();
  const handleSortItemClick = (sortOption) => {
    setSelectedSort(sortOption);
    setShowMenu(false);
  };

  const openPopup = () => {
    dispatch(addFacilityName(loggeduser.facility_name));
    dispatch(addFacilityID(loggeduser.facilityID));
    dispatch(addFacilityEmail(loggeduser.email_id));
    dispatch(addFacilityColor(loggeduser.facility_color));
    dispatch(addFacilityLogo(loggeduser.facility_logo));
    setShowPopup(true);
  };

  useEffect(() => {
    if (showPopup) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [showPopup]);

  const closePopup = () => {
    setShowPopup(false);
  };

  const closeSearch = () => {
    setShowSearchBar(false);
  };
  const { instance } = useMsal();

  // let activeAccount;
  // if (instance) {
  //   activeAccount = instance.getActiveAccount();
  // }
  // const bearer = activeAccount.idToken;

  // const logindetails = {
  //   email_id: activeAccount.idTokenClaims.emails[0],
  //   user_name: activeAccount.idTokenClaims.name,
  // };
  // const request = {
  //   account: activeAccount,
  //   scopes: [
  //     ...protectedResources.apiAdmin.scopes.read,
  //     ...protectedResources.apiAdmin.scopes.write,
  //   ],
  // };
  // Load the user details from the database corresponding to that facility
  const fetchUserData = async () => {
    try {
      // const response = await instance.acquireTokenSilent(request);

      // dispatch(addLoginToken(response.accessToken));

      try {
        const result = await store.GetFacilityUserDetails(
          loggedusertoken,
          DataDecode.encryptPayload(loggeduser.facilityID)
        );

        if (result.status === 200) {
          const res = await result.json();
          // let response = DataDecode.decryptResponse(res)
          // var myObject = JSON.parse(response);
          setuserdata(res);
        } else {
          console.log("Error: Failed to fetch user details.");
        }
      } catch (err) {
        console.log(
          "Error occurred while fetching facility user details:",
          err
        );
      }
    } catch (error) {
      console.log("Token error", error);
    }
  };
  useEffect(() => {
    if (loggeduser && loggeduser.user_role === "Admin") {
      fetchUserData();
    }
  }, [loggeduser, loggedusertoken, showPopup, refreshData]);

  const handlefacilitydelete = (event) => {};

  const handleDeleteUser = async () => {
    if (loggeduser && loggeduser.user_role === "Admin") {
      setDisableButton(true);
       const DeleteUser = {
        UserName: userDetails.user_name,
        EmailId: userDetails.email_id,
      };
      await store
        .DeleteUserFromDBAzureAdb2c(DataDecode.encryptPayload(DeleteUser))
        .then((response) => {
           if (response.status === 200) {
            fetchUserData();
            dispatch(
              setNotification({
                message: "User deleted Successfully",
                status: "success",
                action: true,
              })
            );
          } else {
            dispatch(
              setNotification({
                message: "Failed While deleting user",
                status: "error",
                action: true,
              })
            );
          }
          setDisableButton(false);
          setDeletePopup(false);
        });
    } else {
      dispatch(
        setNotification({
          message: "Admins can only delete the users",
          status: "error",
          action: true,
        })
      );
    }
  };
  const handleFacilityEdit = (userdata) => {
    //allow only the Phone number and role to edit
    //add all the credentials in the redux store
    if (loggeduser.user_name != userdata.user_name) {
      if (userdata.user_status == true && userdata.user_verified == true) {
        dispatch(addFacilityUser(userdata.user_name));
        dispatch(addFacilityPhone(userdata.phonenumber));
        dispatch(addUserEmail(userdata.email_id));
        dispatch(addUserRole(userdata.user_role));
        setedit("edit");
        setShowPopup(true);
      }
    }
  };

  const toggleSearchBar = () => {
    setShowSearchBar(!showSearchBar);
  };

  const searchUsers = () => {
    if (query.length > 0) {
      store
        .SearchUsers(
          loggedusertoken,
          DataDecode.encryptPayload({
            search_string: query,
            clinic_guid: loggeduser.facilityID,
          })
        )
        .then((result) => {
          if (result.status == 200) {
            result.json().then((res) => {
              let response = DataDecode.decryptResponse(res);
              var data = JSON.parse(response);
              setuserdata(data);
            });
          } else {
            setuserdata([]);
          }
        });
    } else {
      fetchUserData();
    }
  };

  useEffect(() => {
    const timeoutId = setTimeout(searchUsers, 300);

    return () => clearTimeout(timeoutId);
  }, [query]);

  return (
    <div className=" mx-auto px-4 py-2 my-20 w-100% ">
      <div className="flex w-full justify-between my-2 mb-4 px-2">
        <div className="flex items-center">
          <header
            id="AdminUserHeaderusers"
            className={`origin-top-left flex font-bold mb-2 text-2xl lg:text-2xl sm:text-lg pl-2.5 pt-1 ${previewTheme.headingTitleColor}`}
            style={{ fontFamily: previewTheme.fontFamily }}
          >
            Users
          </header>
        </div>
        <div className="flex justify-end">
          <div className="flex items-center mr-2">
            <div className="hidden lg:flex relative items-center gap-2">
              <input
                type="text"
                id="AdminUserInputdesktopSearch"
                onChange={(e) => setQuery(e.target.value)}
                className="block pl-10 pr-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none"
                placeholder="User Name, Email ID, Phone"
              />
              <svg
                id="AdminUserSvgdesktopSearchIcon"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-6 h-6 absolute left-3 top-1/2 transform -translate-y-1/2 text-red-700"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"
                />
              </svg>
            </div>
          </div>
          <div className="flex justify-end">
            <button
              id="AdminUserButtonnewUser"
              className={`py-2 px-4 rounded-md flex items-center ${previewTheme.addBtnColor} ${previewTheme.addBtnBgColor} transition-colors duration-300 ease-in-out hover:${previewTheme.addBtnHoverColor}`}
              style={{ fontFamily: previewTheme.fontFamily }}
              onClick={() => {
                openPopup();
                setedit("create");
              }}
            >
              <svg
                id="AdminUserAdminUserSvgeditIcon"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="w-6 h-6 mr-2"
              >
                <path d="M5.25 6.375a4.125 4.125 0 1 1 8.25 0 4.125 4.125 0 0 1-8.25 0ZM2.25 19.125a7.125 7.125 0 0 1 14.25 0v.003l-.001.119a.75.75 0 0 1-.363.63 13.067 13.067 0 0 1-6.761 1.873c-2.472 0-4.786-.684-6.76-1.873a.75.75 0 0 1-.364-.63l-.001-.122ZM18.75 7.5a.75.75 0 0 0-1.5 0v2.25H15a.75.75 0 0 0 0 1.5h2.25v2.25a.75.75 0 0 0 1.5 0v-2.25H21a.75.75 0 0 0 0-1.5h-2.25V7.5Z" />
              </svg>
              Invite New User
            </button>
            <button
              id="AdminUserButtonsearchBar"
              className="flex lg:hidden mt-2"
              onClick={toggleSearchBar}
            >
              <svg
                id="AdminUserSvgsearchbarIcon"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-8 h-8 text-red-800"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
      <div>
        {showSearchBar && (
          <div className="lg:hidden right-0 w-full flex items-center">
            <div className="relative w-full flex items-center">
              <input
                onChange={(e) => setQuery(e.target.value)}
                type="text"
                id="AdminUserInputmobileSearch"
                className="w-full pl-2 pr-10 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none"
                placeholder="User Name, Email ID, Phone"
              />
              <button
                id="AdminUserButtonmobSearchClose"
                onClick={closeSearch}
                className="absolute right-2"
              >
                <svg
                  id="AdminUserSvgmobSearchCloseIcon"
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6 text-gray-500"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M6.293 6.293a1 1 0 0 1 1.414 0L10 8.586l2.293-2.293a1 1 0 1 1 1.414 1.414L11.414 10l2.293 2.293a1 1 0 0 1-1.414 1.414L10 11.414l-2.293 2.293a1 1 0 0 1-1.414-1.414L8.586 10 6.293 7.707a1 1 0 0 1 0-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
            </div>
          </div>
        )}
      </div>
      {showPopup && (
        <AddMembers
          isOpen={showPopup}
          onClose={closePopup}
          formstatus={edit}
          userdata={userdata}
        />
      )}
      <div className="hidden lg:block">
        <table className="table-auto w-full border rounded-md focus:outline-none">
          <thead>
            <tr className="px-5 py-2 text-left">
              <th id="AdminUserTHname" className="px-5 py-2 text-left">
                Name
              </th>
              <th id="AdminUserTHemailId" className="px-2 py-2 text-left">
                EMail ID
              </th>
              <th id="AdminUserTHphoneNumber" className="px-2 py-2 text-left">
                PhoneNumber
              </th>
              <th id="AdminUserTHrole" className="px-2 py-2 text-left">
                Role
              </th>
              <th id="AdminUserTHstatus" className="px-2 py-2 text-left">
                Status
              </th>
              <th id="AdminUserTHactive" className="px-2 py-2 text-left ">
                InActive/Active
              </th>
              <th id="AdminUserTHaction" className="px-2 py-2 text-left">
                Action
              </th>
              <th id="UsersThDeleteAction" className="px-2 py-2 text-left">
                Delete
              </th>
            </tr>
          </thead>
          <tbody>
            {userdata.map((userdata, index) => (
              <tr
                key={"user-table-" + index}
                className="odd:bg-white even:bg-gray-100"
              >
                <td id="AdminUserTDuserName" className="px-2 py-2 text-left">
                  {userdata.user_name || "-"}
                </td>
                <td id="AdminUserTDuserEmail" className="px-2 py-2 text-left">
                  {userdata.email_id || "-"}
                </td>
                <td
                  id="AdminUserTDuserPhoneNumber"
                  className="px-2 py-2 text-left"
                >
                  {userdata.phonenumber || "-"}
                </td>
                <td id="AdminUserTDuserRole" className="px-2 py-2 text-left">
                  {userdata.user_role || "-"}
                </td>
                <td
                  id="AdminUserTDuserVerified"
                  className="px-2 py-2 text-left"
                >
                  {userdata.user_verified == true ? "Verified" : "Pending"}
                </td>
                <td className="px-2 py-2 text-left ">
                  <Toggle
                    id="AdminUserToggleuserData"
                    key={"usersactives-toggle-" + index}
                    user={userdata}
                    setRefreshData={setRefreshData}
                  />
                </td>
                <td className="px-2">
                  <button
                    id="AdminUserButtonfacilityEdit"
                    className=" w-6 h-6 disabled:cursor-not-allowed disabled:opacity-50"
                    disabled={loggeduser.user_name == userdata.user_name}
                    onClick={() => handleFacilityEdit(userdata)}
                  >
                    <svg
                      id="AdminUserSvgfacilityEditIcon"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      className="w-5 h-5"
                    >
                      <path d="M21.731 2.269a2.625 2.625 0 0 0-3.712 0l-1.157 1.157 3.712 3.712 1.157-1.157a2.625 2.625 0 0 0 0-3.712ZM19.513 8.199l-3.712-3.712-12.15 12.15a5.25 5.25 0 0 0-1.32 2.214l-.8 2.685a.75.75 0 0 0 .933.933l2.685-.8a5.25 5.25 0 0 0 2.214-1.32L19.513 8.2Z" />
                    </svg>
                  </button>
                </td>
                <td className="px-2">
                  <button
                    id="DeleteUsersBtnfacility"
                    className="w-6 h-6 text-red-800 disabled:cursor-not-allowed text-[#9B1C1C] disabled:text-[#D88C8C]"
                    disabled={userdata.user_name === loggeduser.user_name}
                    onClick={(e) => {
                      setUserDetails(userdata);
                      setDeletePopup(true);
                    }}
                  >
                    <svg
                      id="userDeleteActionIcon"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      className="w-5 h-5 "
                    >
                      <path
                        fillRule="evenodd"
                        d="M16.5 4.478v.227a48.816 48.816 0 0 1 3.878.512.75.75 0 1 1-.256 1.478l-.209-.035-1.005 13.07a3 3 0 0 1-2.991 2.77H8.084a3 3 0 0 1-2.991-2.77L4.087 6.66l-.209.035a.75.75 0 0 1-.256-1.478A48.567 48.567 0 0 1 7.5 4.705v-.227c0-1.564 1.213-2.9 2.816-2.951a52.662 52.662 0 0 1 3.369 0c1.603.051 2.815 1.387 2.815 2.951Zm-6.136-1.452a51.196 51.196 0 0 1 3.273 0C14.39 3.05 15 3.684 15 4.478v.113a49.488 49.488 0 0 0-6 0v-.113c0-.794.609-1.428 1.364-1.452Zm-.355 5.945a.75.75 0 1 0-1.5.058l.347 9a.75.75 0 1 0 1.499-.058l-.346-9Zm5.48.058a.75.75 0 1 0-1.498-.058l-.347 9a.75.75 0 0 0 1.5.058l.345-9Z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {deletePopup && (
        <div
          id="UserDivconfirmBoxAppointment"
          tabIndex="-1"
          aria-hidden="true"
          className="fixed left-1/2 top-1/2 w-full max-w-sm transform  -translate-x-1/2 -translate-y-1/2 sm:w-80 sm:left-[40%]  sm:top-[3rem] sm:translate-x-[50%] sm:translate-y-[50%] "
        >
          <div
            id="UserssDivconfirmBoxAppointmentContentDialog"
            className="relative p-4 w-full max-w-md h-full md:h-auto"
          >
            <div
              id="UsersDivconfirmBoxContentDialogBox"
              className="relative p-4 text-center bg-white border-gray-400 border-[1px] rounded-lg shadow-lg dark:bg-gray-800 sm:p-5"
            >
              <svg
                id="UsersSvgconfirmBoxIcon"
                className="text-red-800 dark:text-gray-500 w-11 h-11 mb-3.5 mx-auto"
                aria-hidden="true"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <p
                id="UsersPconfirmBoxMessage"
                className={`mb-4 `}
                style={{ fontFamily: previewTheme.fontFamily }}
              >
                Are you sure you want to delete this Account Permanently?
              </p>
              <div
                id="UsersDivconfirmBoxButtons"
                className="flex justify-center items-center space-x-4"
              >
                <button
                  id="UsersButtonconfirmBoxcancel"
                  onClick={() => setDeletePopup(false)}
                  disabled={disableButton}
                  type="button"
                  className={`py-2 px-3 text-sm font-medium rounded-lg border focus:ring-4 focus:outline-none focus:ring-primary-300 focus:z-10  ease-in-out
                  ${previewTheme.cancelBtnColor} 
                  ${previewTheme.cancelBtnBgColor} 
                  ${
                    disableButton
                      ? "opacity-50 cursor-not-allowed"
                      : `hover:${previewTheme.cancelBtnHoverColor}`
                  }
                  `}
                >
                  No, cancel
                </button>

                <button
                  id="UsersButtonconfirmBoxconfirm"
                  type="button"
                  onClick={handleDeleteUser}
                  disabled={disableButton}
                  className={`py-2 px-3 text-sm font-medium rounded-lg border focus:ring-4 focus:outline-none focus:ring-primary-300 focus:z-10  ease-in-out
                      ${previewTheme.saveBtnColor} 
                      ${previewTheme.saveBtnBgColor} 
                      ${
                        disableButton
                          ? "opacity-50 cursor-not-allowed"
                          : `hover:${previewTheme.saveBtnHoverColor}`
                      }`}
                >
                  Yes, I'm sure{" "}
                  {disableButton && (
                    <svg
                      id="UsersSvgconfirmBoxIcon"
                      aria-hidden="true"
                      role="status"
                      className="inline w-4 h-4 ml-2 text-white animate-spin"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="#E5E7EB"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentColor"
                      />
                    </svg>
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="lg:hidden xs:block">
        {userdata.map((userdata, index) => (
          <div className="grid grid-cols-1 shadow-lg border rounded-lg mx-1 my-3">
            <div className="flex justify-between text-[#a31b1b] rounded-t-lg text-lg font-semibold py-2 px-2">
              <div className="">
                <p id="AdminUserPname" className="text-[black] flex-1 py-2">
                  Name :
                  <span
                    id="AdminUserSpanuserName"
                    className="text-gray-600 ml-2"
                  >
                    {userdata.user_name || "-"}
                  </span>
                </p>
                <p id="AdminUserPemail" className="text-[black] flex-1 py-2">
                  Email :
                  <span
                    id="AdminUserSpanuserEmail"
                    className="text-gray-600 ml-2 break-all"
                  >
                    {" "}
                    {userdata.email_id || "-"}
                  </span>
                </p>
                <p id="AdminUserPphone" className="text-[black] flex-1 py-2">
                  Phone :
                  <span
                    id="AdminUserSpanuserPhoneNumber"
                    className="text-gray-600 ml-2"
                  >
                    {" "}
                    {userdata.phonenumber || "-"}
                  </span>
                </p>
                <p id="AdminUserProle" className="text-[black] flex-1 py-2">
                  Role :
                  <span
                    id="AdminUserSpanuserRole"
                    className="text-gray-600 ml-2"
                  >
                    {" "}
                    {userdata.user_role || "-"}
                  </span>
                </p>
                <p id="AdminUserPstatus" className="text-[black] flex-1 py-2">
                  Status :
                  <span
                    id="AdminUserSpanuserVerified"
                    className="text-gray-600 ml-2"
                  >
                    {userdata.user_verified == true ? "Verified" : "Pending"}
                  </span>
                </p>
                <p className="text-[black] flex-1 py-2">
                  {" "}
                  <span className="text-gray-600">
                    <Toggle
                      id="AdminUserToggleuserData"
                      className="!text-lg"
                      key={"usersactives-toggle-" + index}
                      user={userdata}
                      setRefreshData={setRefreshData}
                    />
                  </span>
                </p>
              </div>
              <div>
                <button
                  id="AdminUserButtonEdit"
                  className="disabled:cursor-not-allowed disabled:opacity-50"
                  disabled={loggeduser.user_name == userdata.user_name}
                  onClick={() => handleFacilityEdit(userdata)}
                >
                  <svg
                    id="AdminUserButtonEditIcon"
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                  >
                    <path
                      d="M2.25 12.9375V15.75H5.0625L13.3575 7.455L10.545 4.6425L2.25 12.9375ZM15.5325 5.28C15.602 5.21062 15.6572 5.1282 15.6948 5.03747C15.7325 4.94674 15.7518 4.84948 15.7518 4.75125C15.7518 4.65303 15.7325 4.55576 15.6948 4.46503C15.6572 4.3743 15.602 4.29189 15.5325 4.2225L13.7775 2.4675C13.7081 2.39797 13.6257 2.34281 13.535 2.30518C13.4442 2.26754 13.347 2.24817 13.2488 2.24817C13.1505 2.24817 13.0533 2.26754 12.9625 2.30518C12.8718 2.34281 12.7894 2.39797 12.72 2.4675L11.3475 3.84L14.16 6.6525L15.5325 5.28Z"
                      fill="black"
                    />
                  </svg>
                </button>
              </div>
              <div>
                <button
                  id="MobDeleteUsersButton"
                  className="w-6 h-6 text-red-800"
                  disabled={userdata.user_name === loggeduser.user_name}
                  onClick={(e) => {
                    setUserDetails(userdata);
                    setDeletePopup(true);
                  }}
                >
                  <svg
                    id="MobUserDeleteActionIcon"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    className="w-5 h-5 "
                  >
                    <path
                      fillRule="evenodd"
                      d="M16.5 4.478v.227a48.816 48.816 0 0 1 3.878.512.75.75 0 1 1-.256 1.478l-.209-.035-1.005 13.07a3 3 0 0 1-2.991 2.77H8.084a3 3 0 0 1-2.991-2.77L4.087 6.66l-.209.035a.75.75 0 0 1-.256-1.478A48.567 48.567 0 0 1 7.5 4.705v-.227c0-1.564 1.213-2.9 2.816-2.951a52.662 52.662 0 0 1 3.369 0c1.603.051 2.815 1.387 2.815 2.951Zm-6.136-1.452a51.196 51.196 0 0 1 3.273 0C14.39 3.05 15 3.684 15 4.478v.113a49.488 49.488 0 0 0-6 0v-.113c0-.794.609-1.428 1.364-1.452Zm-.355 5.945a.75.75 0 1 0-1.5.058l.347 9a.75.75 0 1 0 1.499-.058l-.346-9Zm5.48.058a.75.75 0 1 0-1.498-.058l-.347 9a.75.75 0 0 0 1.5.058l.345-9Z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AdminUser;
