import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { IoMdMale, IoMdSave } from "react-icons/io";
import { IoFemale } from "react-icons/io5";
import { theme } from "../../EmraxisTheme/Theme";
import ProfileSideNavbar from "./Profile/desktop/ProfileSideNavbar";
import NewPrescriptions from "./Profile/desktop/NewPrescriptions";
import store from "../../API/store";
import { useLocation } from "react-router-dom";
import DataDecode from "../../utils/DataDecode";
import { setNotification } from "../../Redux/features/toast/toastSlice";
import PastHistoryDetails from "../Patients/Profile/VisitHistory/PastHistoryDetails";
import {
  setAddAppointmentPopup,
  setAppointmentInvoicePrint,
  setPatientAppointment,
} from "../../Redux/features/popup/popupSlice";
import DateFunction from "../../utils/DateFunctions";
import NewPastVisitHistory from "./Profile/desktop/NewPastVisitHistory";
import DoctorNotes from "./Profile/desktop/DoctorNotes";
import {
  setDocumentType,
  setIsCameraUpload,
  setIsWithoutAppointment,
  setShowDocumentUploadPopup,
  setShowUploadPopup,
} from "../../Redux/features/uploadDoc/uploadDocSlice";
import UploadPatientFiles from "./Profile/UploadPatientFiles";
import Vitals from "../Appointments/Vitals";
import ChooseDocument from "./Profile/DocumentUploads/ChooseDocument";
import UploadDocument from "./Profile/DocumentUploads/UploadDocument";
import AddAppointment from "../Appointments/AddAppointment";
import AddPatient from "./AddPatient";
import LabNotes from "./Profile/desktop/LabNotes";
import RadiologyNotes from "./Profile/desktop/RadiologyNotes";
import UploadedData from "./Profile/desktop/UploadedData";
import { setAppointmentInvoice } from "../../Redux/features/invoice/invoiceSlice";
import {
  setActiveNavbar,
  setActiveHistoryNavbar,
  setAutoSaveAll,
  setDoctorNotesDataAvailable,
  setIsGlobalSaveRequired,
  setLabNotesDataAvailable,
  setPrescriptionDataAvailabale,
  setRadiologyDataAvailable,
  setUploadedDataAvailable,
  setVisitHistoryDataAvailable,
} from "../../Redux/features/profileDrawer/profileSideNavbarSlice";
import GenerateInvoice from "../Invoice/GenerateInvoice";

const DesktopPatientProfile = () => {
  var loggeduser = useSelector((state) => state.login.loginuserData);
  var loggedusertoken = useSelector((state) => state.login.userBarerToken);
  var profileSidebar = useSelector((state) => state.profileSideNavbar.sidebar);
  var autoSaveAll = useSelector((state) => state.profileSideNavbar.autoSaveAll);
  var selectedAppointmentId = useSelector(
    (state) => state.visitSideNavbar.selectedAppointmentId
  );
  var patientProfileMenuSelector = useSelector(
    (state) => state.profileSideNavbar.patientProfileMenuSelector
  );
  var newAppointmentId = useSelector(
    (state) => state.profileSideNavbar.newAppointmentId
  );
  var isInvoiceEnabled = useSelector((state) => state.invoice.isInvoiceEnabled);
  var showDocumentUploadPopup = useSelector(
    (state) => state.document_upload.showDocumentUploadPopup
  );
  var showUploadPopup = useSelector(
    (state) => state.document_upload.showUploadPopup
  );
  var refreshUploadedFiles = useSelector(
    (state) => state.document_upload.refreshUploadedFiles
  );
  var refreshDataAvailable = useSelector(
    (state) => state.document_upload.refreshDataAvailable
  );
  var showAddAppointmentpopup = useSelector(
    (state) => state.popup.showAddAppointment
  );
  var patientAddAppointment = useSelector(
    (state) => state.popup.setPatientAddAppointment
  );
  var showAddPatientpopup = useSelector((state) => state.popup.showAddPatient);
  var appointmentData = useSelector(
    (state) => state.popup.patientProfileAppointmentData
  );
  const historySectionRefs = useRef([]);
  var historyVisitData = useSelector(
    (state) => state.profileSideNavbar.historyVisitData
  );
  var isVisitHistoryDataAvailable = useSelector(
    (state) => state.profileSideNavbar.isVisitHistoryDataAvailable
  );
  var isPrescriptionDataAvailabale = useSelector(
    (state) => state.profileSideNavbar.isPrescriptionDataAvailabale
  );
  var isGlobalSaveRequired = useSelector(
    (state) => state.profileSideNavbar.isGlobalSaveRequired
  );
  var isSideNavbarClicked = useSelector(
    (state) => state.profileSideNavbar.isSideNavbarClicked
  );
  var showAppointmentInvoicePrint = useSelector(
    (state) => state.popup.showAppointmentInvoicePrint
  );
  var documentType = useSelector((state) => state.document_upload.documentType);
  var activeNavbar = useSelector(
    (state) => state.profileSideNavbar.activeNavbar
  );
  const previewTheme =
    useSelector((state) => state.theme.colors) ||
    theme.filter((a) => a.name === "defaultTheme")[0];

  const dispatch = useDispatch();
  const sectionRefs = useRef([]);

  const [openVitals, setOpenVitals] = useState(false);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const queryParamValue = queryParams.get("profile");
  const { appointment_id, patient_id, doctor_id, clinic_guid, page_src } =
    JSON.parse(atob(queryParamValue));

  const [patient, setPatient] = useState({});
  const [appointment, setAppointment] = useState({});
  const [doctors, setDoctors] = useState([]);
  const [doctorsTimeslot, setDoctorsTimeSlot] = useState([]);
  const [appointmentId, setAppointmentId] = useState(appointment_id);
  const [doctorId, setDoctorId] = useState(doctor_id);
  const [pageSrc, setPageSrc] = useState(page_src);
  const [vitalData, setVitalData] = useState({
    weight: "",
    height: "",
    body_temperature: "",
    blood_pressure: "",
    pulse_rate: "",
    respiration_rate: "",
    updateStatusRequired: true,
  });

  const [highlightVitals, setHighlightVitals] = useState(false);
  const [files, setFiles] = useState([]);
  const [originalFiles, setOriginalFiles] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [uploadPatientFile, setUploadPatientFile] = useState(false);
  const [refreshImage, setRefreshImage] = useState(false);
  const [invoiceExist, setInvoiceExist] = useState(false);
  const [fileType, setFileType] = useState("All");
  const [editVitalsFirstSec, setEditVitalsFirstSec] = useState(false);
  const [loadVitalsFirstSec, setLoadVitalsFirstSec] = useState(false);
  const [refreshData, setRefreshData] = useState(false);
  const [confirmBoxSignOff, setConfirmBoxSignOff] = useState(false);
  const [showFollowUpPopup, setShowFollowUpPopup] = useState(false);
  const [isMessagePopupOpen, setIsMessagePopupOpen] = useState(null);
  const [signOffDisableButton, setSignOffDisableButton] = useState(false);
  const [isAnyChanges, setIsAnyChanges] = useState(false);
  const [saveAllLoading, setSaveAllLoading] = useState(false);
  const [followUpData, setFollowUpData] = useState({});
  const [viewHistory, setViewHistory] = useState(false);
  const [viewHistoryData, setViewHistoryData] = useState({});
  const scrollToposition = (id, yOffset = -100) => {
    const y =
      sectionRefs.current[id].getBoundingClientRect().top +
      window.scrollY +
      yOffset;
    window.scrollTo({ top: y, behavior: "smooth" });
   };
  const isAppointmentValid = appointment && Object.keys(appointment).length > 0;

  useEffect(() => {
    if (profileSidebar.personalDetails) {
      scrollToposition(0, -200);
     
    } else if (profileSidebar.vitals) {
      scrollToposition(0, -200);
      setHighlightVitals(true);
      setTimeout(() => {
        setHighlightVitals(false);
      }, 3000);
    } else if (profileSidebar.doctorNotes) {
      scrollToposition(1, -90);
    } else if (profileSidebar.visitHistory) {
      scrollToposition(2, -90);
    } else if (profileSidebar.prescription) {
      scrollToposition(3, -90);
    } else if (profileSidebar.uploadedData) {
      scrollToposition(8, -90);
    } else if (profileSidebar.labNotes) {
      scrollToposition(5, -90);
    } else if (profileSidebar.radiology) {
      scrollToposition(6, -90);
    }else if (profileSidebar.pastHistory) {
      scrollToposition(6, -90);
     }
  }, [profileSidebar]);
  useEffect(() => {
    const handleScroll = () => {
      sectionRefs.current.forEach((section) => {
        const rect = section?.getBoundingClientRect();
        if (rect && rect.top <= 200 && rect.bottom > 150) {
          const selectedMenu = section.getAttribute("data-navbar-section");

          dispatch(setActiveNavbar(selectedMenu));
        }
      });
    };

    window.addEventListener("scroll", handleScroll);

    // Initial check when the component mounts
    handleScroll();

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  useEffect(() => {
    const handleScroll = () => {
      historySectionRefs.current.forEach((section) => {
        const rect = section?.getBoundingClientRect();
        if (rect && rect.top <= 200 && rect.bottom > 0) {
          const selectedMenu = section.getAttribute(
            "data-navbar-section-history"
          );
        }
      });
    };

    window.addEventListener("scroll", handleScroll);

    // Initial check when the component mounts
    handleScroll();

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (
      appointment_id == 0 &&
      appointmentData?.appointment_id &&
      appointmentData.appointment_id > 0
    ) {
      setAppointmentId(appointmentData.appointment_id);
      setAppointment(appointmentData);
      setPageSrc("appointment");
      let data = btoa(
        JSON.stringify({
          appointment_id: appointmentData.appointment_id,
          patient_id: patient_id,
          doctor_id: appointmentData.doctor_id || 0,
          clinic_guid: loggeduser.facilityID,
          page_src: "appointment",
        })
      );
      window.history.pushState(null, null, `/patient-profile?profile=${data}`);
      const urlChangeEvent = new Event("urlChange");
      window.dispatchEvent(urlChangeEvent);
    } else {
      setAppointmentId(appointment_id);
    }
  }, [appointment_id, appointmentData]);

  useEffect(() => {
    if (
      doctor_id == 0 &&
      appointmentData?.doctor_id &&
      appointmentData.doctor_id > 0
    ) {
      setDoctorId(appointmentData.doctor_id);
    } else {
      setDoctorId(doctor_id);
    }
  }, [doctor_id, appointmentData]);
  useEffect(() => {
    setPageSrc(page_src);
  }, [page_src]);
  const GetVitals = (guid) => {
    store
      .GetPatientVitals(
        loggedusertoken,
        DataDecode.encryptPayload({
          clinic_guid: guid,
          patient_id: patient_id,
          appointment_id: appointmentId,
        })
      )
      .then((result) => {
        if (result.status == 200) {
          result.json().then((res) => {
            let response = DataDecode.decryptResponse(res);
            let vitals = JSON.parse(response);
            setVitalData({ ...vitals, updateStatusRequired: true });
          });
        } else {
          setVitalData({
            weight: "",
            height: "",
            body_temperature: "",
            blood_pressure: "",
            pulse_rate: "",
            respiration_rate: "",
            clinic_guid: guid,
            appointment_id: appointmentId,
            patient_id: patient_id,
            updateStatusRequired: true,
          });
        }
      })
      .catch((err) => {
        dispatch(
          setNotification({
            message: "Error Occurred",
            status: "error",
            action: true,
          })
        );
      });
  };
  const GetDoctors = (guid) => {
    store
      .getDoctorForAppointment(
        loggedusertoken,
        DataDecode.encryptPayload({ clinic_guid: guid })
      )
      .then((result) => {
        if (result.status == 200) {
          result.json().then((res) => {
            let response = DataDecode.decryptResponse(res);
            let data = JSON.parse(response);
            setDoctors(data.drDetails);
            setDoctorsTimeSlot(data.drDetailstimeSlots);
          });
        } else {
          setDoctors([]);
          setDoctorsTimeSlot([]);
        }
      })
      .catch((err) => {
        dispatch(
          setNotification({
            message: "Error Occurred",
            status: "error",
            action: true,
          })
        );
      });
  };
  useEffect(() => {
    if (patientAddAppointment?.isOpenAppointment) {
      dispatch(setAddAppointmentPopup(true));
    }
  }, [patientAddAppointment]);
  const GetPatientFiles = (guid) => {
    store
      .GetPatientFiles(
        loggedusertoken,
        DataDecode.encryptPayload({
          clinic_guid: guid,
          patient_id: patient_id,
          appointment_id: appointmentId,
        })
      )
      .then((result) => {
        if (result.status == 200) {
          result.json().then((res) => {
            let response = DataDecode.decryptResponse(res);
            let data = JSON.parse(response);
            setFiles(data);
            setOriginalFiles(data);
            // setExpandRow(data.length > 0 ? true : false)
          });
        } else {
          setFiles([]);
          setOriginalFiles([]);
        }
      })
      .catch((err) => {
        setFiles([]);
        setOriginalFiles([]);
        dispatch(
          setNotification({
            message: "Error Occurred",
            status: "error",
            action: true,
          })
        );
      });
  };

  const CheckIsInvoiceExist = () => {
    store
      .CheckIsInvoiceExist(
        loggedusertoken,
        DataDecode.encryptPayload({
          clinic_guid: loggeduser.facilityID,
          patient_id: patient_id,
          appointment_id: appointmentId,
          invoice_for: "Appointment",
        })
      )
      .then((result) => {
        if (result.status == 200) {
          setInvoiceExist(true);
        } else {
          setInvoiceExist(false);
        }
      })
      .catch((err) => {
        dispatch(
          setNotification({
            message: "Error Occurred",
            status: "error",
            action: true,
          })
        );
      });
  };

  useEffect(() => {
    if (fileType === "All") {
      setFiles(originalFiles);
    } else {
      let file = originalFiles.filter((a) => a.file_type === fileType);
      setFiles(file);
    }
  }, [fileType, originalFiles]);

  useEffect(() => {
    if (loggeduser) {
      GetPatientFiles(loggeduser.facilityID);
    }
  }, [loggeduser, refreshImage, refreshUploadedFiles]);

  useEffect(() => {
    if (loggeduser && appointmentId > 0) {
      GetVitals(loggeduser.facilityID);
      setVitalData({
        ...vitalData,
        clinic_guid: loggeduser.facilityID,
        appointment_id: appointmentId,
        patient_id: patient_id,
        updateStatusRequired: true,
      });
    }
  }, [loggeduser, openVitals, appointmentId]);

  useEffect(() => {
    if (loggeduser) {
      CheckIsInvoiceExist();
      GetDoctors(loggeduser.facilityID);
    }
  }, [loggeduser]);

  const GetPatient = (guid) => {
    store
      .GetPatient(
        loggedusertoken,
        DataDecode.encryptPayload({ clinic_guid: guid, patient_id: patient_id })
      )
      .then((result) => {
        if (result.status == 200) {
          result.json().then((res) => {
            let response = DataDecode.decryptResponse(res);
            setPatient(JSON.parse(response));
          });
        } else {
          setPatient({});
        }
      })
      .catch((err) => {
        dispatch(
          setNotification({
            message: "Error Occurred!",
            status: "error",
            action: true,
          })
        );
      });
  };
  const GetAppointmnet = (guid) => {
    store
      .GetAppointmnet(
        loggedusertoken,
        DataDecode.encryptPayload({
          clinic_guid: guid,
          appointment_id: appointmentId,
        })
      )
      .then((result) => {
        if (result.status == 200) {
          result.json().then((res) => {
            let response = DataDecode.decryptResponse(res);
            setAppointment(JSON.parse(response));
          });
        } else {
          // setAppointment({})
        }
      })
      .catch((err) => {
        dispatch(
          setNotification({
            message: "Error Occurred!",
            status: "error",
            action: true,
          })
        );
      });
  };

  const GetAllPatientDataAavailable = (guid) => {
    store
      .GetAllPatientDataAavailable(
        loggedusertoken,
        DataDecode.encryptPayload({
          clinic_guid: guid,
          appointment_id: appointmentId,
          patient_id: patient_id,
        })
      )
      .then((result) => {
        if (result.status == 200) {
          result.json().then((res) => {
            let response = DataDecode.decryptResponse(res);
            let data = JSON.parse(response);
            dispatch(
              setDoctorNotesDataAvailable(data.isDoctorNotesDataAvailable)
            );
            dispatch(
              setVisitHistoryDataAvailable(data.isVisitHistoryDataAvailable)
            );
            dispatch(
              setPrescriptionDataAvailabale(data.isPrescriptionDataAvailabale)
            );
            dispatch(setUploadedDataAvailable(data.isUploadedDataAvailable));
            dispatch(setLabNotesDataAvailable(data.isLabNotesDataAvailable));
            dispatch(setRadiologyDataAvailable(data.isRadiologyDataAvailable));
          });
        } else {
          dispatch(setDoctorNotesDataAvailable(false));
          dispatch(setVisitHistoryDataAvailable(false));
          dispatch(setPrescriptionDataAvailabale(false));
          dispatch(setUploadedDataAvailable(false));
          dispatch(setLabNotesDataAvailable(false));
          dispatch(setRadiologyDataAvailable(false));
        }
      })
      .catch((err) => {
        dispatch(
          setNotification({
            message: "Error Occurred!",
            status: "error",
            action: true,
          })
        );
      });
  };

  useEffect(() => {
    if (loggeduser && patient_id > 0)
      GetAllPatientDataAavailable(loggeduser.facilityID);
  }, [
    loggeduser,
    refreshData,
    refreshUploadedFiles,
    appointmentId,
    refreshDataAvailable,
  ]);

  const handleUpdateVitals = () => {
    setLoadVitalsFirstSec(true);
    store
      .UpdateVitals(loggedusertoken, DataDecode.encryptPayload(vitalData))
      .then((result) => {
        setLoadVitalsFirstSec(false);
        if (result.status == 204) {
          dispatch(
            setNotification({
              message: "Vitals Updated Successfully!",
              status: "success",
              action: true,
            })
          );
          setEditVitalsFirstSec(false);
        } else {
          dispatch(
            setNotification({
              message: "Vitals Not Updated!",
              status: "error",
              action: true,
            })
          );
        }
      })
      .catch((err) => {
        setLoadVitalsFirstSec(false);
        dispatch(
          setNotification({
            message: "Error Occurred!",
            status: "error",
            action: true,
          })
        );
      });
  };

  useEffect(() => {
    GetPatient(loggeduser.facilityID);
  }, [loggeduser]);

  useEffect(() => {
    GetAppointmnet(loggeduser.facilityID);
  }, [loggeduser, refreshData]);

  const handleUploadButton = (type) => {
    dispatch(setDocumentType({ ...documentType, prescription: true }));
    dispatch(setIsCameraUpload(type === "capture" ? true : false));
    if (
      appointmentId == 0 ||
      (appointment && appointment.status == "Completed")
    ) {
      handleMessagePopupOpen(true);
    } else {
      dispatch(setShowUploadPopup(true));
    }
  };

  const handleMessagePopupOpen = () => {
    setIsMessagePopupOpen(!isMessagePopupOpen);
  };

  const generateInvoice = () => {
    dispatch(setAppointmentInvoicePrint(true));
    dispatch(
      setAppointmentInvoice({
        patient: patient,
        doctor: appointment.doctor_id,
        appointment: appointment,
        targetFrom: "Appointment",
      })
    );
  };

  const completeSignOff = () => {
    setSignOffDisableButton(true);
    dispatch(setAutoSaveAll(!autoSaveAll));
    if (isAnyChanges) {
      handleUpdateVitals();
    }
    store
      .CompleteSignOff(
        loggedusertoken,
        DataDecode.encryptPayload({
          clinic_guid: loggeduser.facilityID,
          appointment_id: appointmentId,
          _time: DateFunction.SetDbCurrentTimeStamp(),
          patient_id: patient_id,
        })
      )
      .then((result) => {
        setSignOffDisableButton(false);
        setConfirmBoxSignOff(false);
        setIsAnyChanges(false);
        if (result.status == 204) {
          setRefreshData((o) => !o);
          dispatch(
            setNotification({
              message: "Successfully Signed Off!",
              status: "success",
              action: true,
            })
          );
          setShowFollowUpPopup(true);
        } else {
          dispatch(
            setNotification({
              message: "Error Occurred!",
              status: "error",
              action: true,
            })
          );
        }
      })
      .catch((err) => {
        dispatch(
          setNotification({
            message: "Error Occurred!",
            status: "error",
            action: true,
          })
        );
      });
  };

  const handleGloablSave = () => {
    if (isGlobalSaveRequired) {
      setSaveAllLoading(true);
      dispatch(setAutoSaveAll(!autoSaveAll));
    }
    if (isAnyChanges) {
      setSaveAllLoading(true);
      handleUpdateVitals();
    }
    setTimeout(() => {
      dispatch(setIsGlobalSaveRequired(false));
      setSaveAllLoading(false);
    }, 2000);
  };

  useEffect(() => {
    handleGloablSave();
  }, [isSideNavbarClicked]);

  window.addEventListener("beforeunload", (event) => {
    handleGloablSave();
  });

  const openAppointmentPopup = () => {
    dispatch(setAddAppointmentPopup(true));
    dispatch(
      setPatientAppointment({
        isOpenAppointment: true,
        patient: patient,
        path: "appointment",
      })
    );
  };

  const handleUploadWithoutAppointment = () => {
    handleMessagePopupOpen(false);
    dispatch(setShowUploadPopup(true));
    dispatch(setIsWithoutAppointment(true));
  };
  const imageUrl = `${process.env.REACT_APP_EMRAXIS_S3_URL}/${patient.patient_pic}`;
  const handleFollowUp = () => {
    openAppointmentPopup();
    setShowFollowUpPopup(false);
    let app = {
      appointment_id: 0,
      patient_id: appointment.patient_id,
      patient_name: patient.patient_name,
      doctor_id: doctorId,
      mobile_number: appointment.mobile_number,
      appointment_time: null,
      appointment_type: "Follow Up",
      doctor_name: appointment.doctor_name,
      clinic_guid: appointment.clinic_guid,
    };
    setFollowUpData(app);
    setTimeout(() => {
      dispatch(setAddAppointmentPopup(true));
      dispatch(
        setPatientAppointment({
          isOpenAppointment: true,
          patient: patient,
          path: "appointment",
        })
      );
    }, 100);
  };
  return (
    <div>
      {loggeduser && clinic_guid === loggeduser.facilityID ? (
        <div className="mx-auto py-8 my-10 pt-10">
          <div className="flex">
            <div className="flex-none w-[14rem] border-r border-gray-200">
              <div className="fixed w-[14rem]">
                <ProfileSideNavbar
                  appointment={appointment}
                  patient_id={patient_id}
                  clinic_guid={clinic_guid}
                  refreshData={refreshData}
                  page_src={pageSrc}
                  appointment_id={appointmentId}
                />
              </div>
            </div>
            <div className="flex-auto w-auto">
              <div className="origin-top-left flex font-bold mt-2 lg:text-black text-red-700 lg:text-xl sm:text-xl justify-between items-center">
                <svg
                  id="btnProfileBackButton"
                  onClick={() => {
                    window.history.back();
                  }}
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="size-6 block lg:hidden"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m11.25 9-3 3m0 0 3 3m-3-3h7.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                  />
                </svg>

                <div className="flex justify-between w-full">
                  <div>
                    <span
                      className={`ml-3 ${previewTheme.headingTitleColor}`}
                      style={{ fontFamily: previewTheme.fontFamily }}
                    >
                      Patient Details
                    </span>
                  </div>
                  <div className="flex lg:mr-10 mr-4"></div>
                </div>
              </div>

              <section
                id="secPatientProfile"
                className="gap-2"
                ref={(el) => (sectionRefs.current[0] = el)}
                data-navbar-section="personal-details"
              >
                {pageSrc === "appointment" ? (
                  <div className="grid grid-cols-3 gap-4 m-5">
                    <div className="">
                      <div className="flex justify-between">
                        <div className="w-1/3">
                          {patient.patient_pic ? (
                            <img
                              id="PatientProfileIMGuserImage"
                              className="flex bg-slate-300 rounded-2xl mb-4"
                              src={`${process.env.REACT_APP_EMRAXIS_S3_URL}/${patient.patient_pic}`}
                              alt="patient"
                            />
                          ) : (
                            <img
                              id="PatientProfileIMGdefaultImage"
                              className="flex bg-slate-300 rounded-2xl mb-4"
                              src="/image/user.png"
                              alt="default user"
                            />
                          )}
                        </div>
                        <div className="ml-2 mt-2 w-2/3">
                          <h3 className="font-semibold text-lg break-words">
                          {patient?.FirstName + " " + patient?.MiddleName?.charAt(0) + " " + patient?.LastName}
                          </h3>
                          <p className="flex justify-start font-medium">
                            {patient?.gender?.toLowerCase() == "female" ? (
                              <span className="flex py-1 capitalize">
                                <IoFemale className="text-white bg-[#f7329e] mt-[5px] mr-2 rounded" />
                                {patient?.gender}
                              </span>
                            ) : (
                              <span className="flex py-1 capitalize">
                                <IoMdMale className="text-white bg-sky-400 mt-[5px] mr-2 rounded" />
                                {patient?.gender}
                              </span>
                            )}
                          </p>
                          <p className="text-gray-600 font-medium break-all">
                            DOB:{" "}
                            {patient &&
                              patient?.dob &&
                              DateFunction.GetDate(patient?.dob)}
                          </p>
                          <p className="text-gray-600 font-medium">
                            Age:{" "}
                            {patient &&
                              patient?.dob &&
                              DateFunction.GetAge(patient?.dob)}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="border rounded-md border-gray-200 shadow-md p-4">
                      <p className="text-sm text-gray-600">Patient ID</p>
                      <p className="font-medium break-all">
                        {patient?.patient_op_number}
                      </p>
                      <p className="text-sm text-gray-600">Email</p>
                      <p className="font-medium break-all">
                        {patient?.email_id}
                      </p>
                      <p className="text-sm text-gray-600">Mobile</p>
                      <p className="font-medium break-all">
                        {patient?.mobile_number}
                      </p>
                      <p className="text-sm text-gray-600">Blood Group</p>
                      <p className="font-medium">{patient?.blood_group}</p>
                    </div>
                    <div
                      className={
                        `border rounded-md  shadow-md p-4 ` +
                        (highlightVitals
                          ? "bg-red-100 border-2 border-red-800"
                          : "border-gray-200")
                      }
                    >
                      <div className="flex justify-between">
                        <div className="w-full">
                          <table className="table-fixed">
                            <tbody>
                              <tr>
                                <td className="text-sm text-gray-600 text-wrap w-[50%]">
                                  Body Temperature{" "}
                                  {editVitalsFirstSec && <span>(&deg; F)</span>}
                                </td>
                                <td className="font-medium px-2 w-[50%]">
                                  {editVitalsFirstSec ? (
                                    <input
                                      type="text"
                                      id="textBodyTemperature"
                                      onChange={(e) => {
                                        setVitalData({
                                          ...vitalData,
                                          body_temperature: e.target.value,
                                        });
                                        setIsAnyChanges(true);
                                      }}
                                      name="body_temperature"
                                      value={vitalData?.body_temperature}
                                      className="w-full border text-sm focus:outline-none focus:border-gray-500 px-2 py-1"
                                    />
                                  ) : (
                                    <>
                                      {vitalData?.body_temperature && (
                                        <span>
                                          : {vitalData?.body_temperature}&deg; F
                                        </span>
                                      )}
                                    </>
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td className="text-sm text-gray-600">
                                  Weight{" "}
                                  {editVitalsFirstSec && <span>(kg)</span>}
                                </td>
                                <td className="font-medium px-2">
                                  {editVitalsFirstSec ? (
                                    <input
                                      type="text"
                                      id="textWeight"
                                      onChange={(e) => {
                                        setVitalData({
                                          ...vitalData,
                                          weight: e.target.value,
                                        });
                                        setIsAnyChanges(true);
                                      }}
                                      name="weight"
                                      value={vitalData?.weight}
                                      className="w-full border text-sm focus:outline-none focus:border-gray-500 px-2 py-1"
                                    />
                                  ) : (
                                    <>
                                      {vitalData?.weight && (
                                        <span>: {vitalData?.weight} kg</span>
                                      )}
                                    </>
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td className="text-sm text-gray-600">
                                  Height{" "}
                                  {editVitalsFirstSec && <span>(cm)</span>}
                                </td>
                                <td className="font-medium px-2">
                                  {editVitalsFirstSec ? (
                                    <input
                                      type="text"
                                      id="textHeight"
                                      onChange={(e) => {
                                        setVitalData({
                                          ...vitalData,
                                          height: e.target.value,
                                        });
                                        setIsAnyChanges(true);
                                      }}
                                      name="height"
                                      value={vitalData?.height}
                                      className="w-full border text-sm focus:outline-none focus:border-gray-500 px-2 py-1"
                                    />
                                  ) : (
                                    <>
                                      {vitalData?.height && (
                                        <span>: {vitalData?.height} cm</span>
                                      )}
                                    </>
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td className="text-sm text-gray-600">
                                  Blood Pressure{" "}
                                  {editVitalsFirstSec && <span>(mmHG)</span>}
                                </td>
                                <td className="font-medium px-2">
                                  {editVitalsFirstSec ? (
                                    <input
                                      type="text"
                                      id="textBloodPressure"
                                      onChange={(e) => {
                                        setVitalData({
                                          ...vitalData,
                                          blood_pressure: e.target.value,
                                        });
                                        setIsAnyChanges(true);
                                      }}
                                      name="blood_pressure"
                                      value={vitalData?.blood_pressure}
                                      className="w-full border text-sm focus:outline-none focus:border-gray-500 px-2 py-1"
                                    />
                                  ) : (
                                    <>
                                      {vitalData?.blood_pressure && (
                                        <span>
                                          : {vitalData?.blood_pressure} mmHG
                                        </span>
                                      )}
                                    </>
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td className="text-sm text-gray-600">
                                  Pulse{" "}
                                  {editVitalsFirstSec && <span>(bpm)</span>}
                                </td>
                                <td className="font-medium px-2">
                                  {editVitalsFirstSec ? (
                                    <input
                                      type="text"
                                      id="textPulseRate"
                                      onChange={(e) => {
                                        setVitalData({
                                          ...vitalData,
                                          pulse_rate: e.target.value,
                                        });
                                        setIsAnyChanges(true);
                                      }}
                                      name="pulse_rate"
                                      value={vitalData?.pulse_rate}
                                      className="w-full border text-sm focus:outline-none focus:border-gray-500 px-2 py-1"
                                    />
                                  ) : (
                                    <>
                                      {vitalData?.pulse_rate && (
                                        <span>
                                          : {vitalData?.pulse_rate} bpm
                                        </span>
                                      )}
                                    </>
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td className="text-sm text-gray-600">
                                  Oxygen{" "}
                                  {editVitalsFirstSec && <span>(%)</span>}
                                </td>
                                <td className="font-medium px-2">
                                  {editVitalsFirstSec ? (
                                    <input
                                      type="text"
                                      id="textRespirationRate"
                                      onChange={(e) => {
                                        setVitalData({
                                          ...vitalData,
                                          respiration_rate: e.target.value,
                                        });
                                        setIsAnyChanges(true);
                                      }}
                                      name="respiration_rate"
                                      value={vitalData?.respiration_rate}
                                      className="w-full border text-sm focus:outline-none focus:border-gray-500 px-2 py-1"
                                    />
                                  ) : (
                                    <>
                                      {vitalData?.respiration_rate && (
                                        <span>
                                          : {vitalData?.respiration_rate} %
                                        </span>
                                      )}
                                    </>
                                  )}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div className="w-5">
                          {appointment?.status !== "Completed" &&
                            pageSrc === "appointment" && (
                              <p className="-mt-2 text-right">
                                {loadVitalsFirstSec ? (
                                  <svg
                                    id="btnEditVitals"
                                    aria-hidden="true"
                                    role="status"
                                    className="size-5 text-green-600 animate-spin"
                                    viewBox="0 0 100 101"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                      fill="#E5E7EB"
                                    />
                                    <path
                                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                      fill="currentColor"
                                    />
                                  </svg>
                                ) : (
                                  <>
                                    {editVitalsFirstSec ? (
                                      <IoMdSave
                                        id="btnSaveVitals"
                                        className="text-green-600 size-5 cursor-pointer"
                                        onClick={handleUpdateVitals}
                                      />
                                    ) : (
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        onClick={() => {
                                          appointmentId == 0
                                            ? handleMessagePopupOpen(true)
                                            : setEditVitalsFirstSec(true);
                                        }}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        className="size-5 cursor-pointer"
                                      >
                                        <path
                                          d="M7.243 17.997H3V13.754L14.435 2.31903C14.6225 2.13156 14.8768 2.02625 15.142 2.02625C15.4072 2.02625 15.6615 2.13156 15.849 2.31903L18.678 5.14703C18.771 5.2399 18.8447 5.35019 18.8951 5.47159C18.9454 5.59299 18.9713 5.72312 18.9713 5.85453C18.9713 5.98595 18.9454 6.11607 18.8951 6.23747C18.8447 6.35887 18.771 6.46916 18.678 6.56203L7.243 17.997ZM3 19.997H21V21.997H3V19.997Z"
                                          fill="#A31B1B"
                                        />
                                      </svg>
                                    )}
                                  </>
                                )}
                              </p>
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="grid grid-cols-2 gap-4 m-5">
                    <div className="">
                      <div className="flex justify-between">
                        <div className="w-1/3">
                          {patient.patient_pic ? (
                            <img
                              id="PatientProfileIMGuserImage"
                              className="flex bg-slate-300 rounded-2xl mb-4"
                              src={`${process.env.REACT_APP_EMRAXIS_S3_URL}/${patient.patient_pic}`}
                              alt="patient"
                            />
                          ) : (
                            <img
                              id="PatientProfileIMGdefaultImage"
                              className="flex bg-slate-300 rounded-2xl mb-4"
                              src="/image/user.png"
                              alt="default user"
                            />
                          )}
                        </div>
                        <div className="ml-2 mt-2 w-2/3">
                          <h3 className="text-md py-1 break-words">
                            Name:{" "}
                            <span className="font-semibold">{`${
                              patient.FirstName +
                              " " +
                              patient.MiddleName?.charAt(0) +
                              " " +
                              patient.LastName
                            }`}</span>
                          </h3>
                          <p className="text-sm py-1 text-gray-600 break-all">
                            Age:{" "}
                            {patient && patient?.gender
                              ? patient?.gender?.substring(0, 1)
                              : ""}{" "}
                            {patient &&
                              patient?.dob &&
                              DateFunction.GetAge(patient?.dob)}
                          </p>
                          <p className="text-sm py-1 text-gray-600 break-all">
                            DOB:{" "}
                            {patient &&
                              patient?.dob &&
                              DateFunction.GetDate(patient?.dob)}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="p-4">
                      <p className="text-sm py-1 text-gray-600 break-all">
                        Patient ID: {patient?.patient_op_number}
                      </p>
                      <p className="text-sm py-1 text-gray-600 break-all">
                        Email: {patient?.email_id}
                      </p>
                      <p className="text-sm py-1 text-gray-600 break-all">
                        Mobile: {patient?.mobile_number}
                      </p>
                      <p className="text-sm py-1 text-gray-600 break-all">
                        Blood Group: {patient?.blood_group}
                      </p>
                    </div>
                  </div>
                )}
              </section>

              <section
                id="secDoctorNotes"
                className={`w-full mt-4 px-[1rem] ${
                  appointment?.status === "Completed" ? "hidden" : "block"
                }`}
                ref={(el) => (sectionRefs.current[1] = el)}
                data-navbar-section="doctor-notes"
              >
                <DoctorNotes
                  appointment={appointment}
                  clinicGuid={clinic_guid}
                  appointment_id={appointmentId}
                  patient_id={patient_id}
                  page_src={pageSrc}
                  setIsMessagePopupOpen={setIsMessagePopupOpen}
                />
              </section>

              <section
                id="secPrescription"
                className={`w-full mt-4 ${
                  appointment?.status === "Completed" ? "hidden" : "block"
                }`}
                ref={(el) => (sectionRefs.current[3] = el)}
                data-navbar-section="prescription"
              >
                <div className="">
                  <div className="flex justify-between bg-[#e2e8f0] text-[#a31b1b] rounded-t-lg text-lg font-semibold py-1 px-2 mx-4">
                    <div>
                      <p className="font-semibold text-lg">Prescription</p>
                    </div>
                    <div>
                      <span title="Upload">
                        <svg
                          id="DesktopBtnUploadPrescriptionData"
                          xmlns="http://www.w3.org/2000/svg"
                          onClick={() => handleUploadButton("upload")}
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="2"
                          stroke="currentColor"
                          className={`size-7 mr-2 inline cursor-pointer text-red-700`}
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5m-13.5-9L12 3m0 0 4.5 4.5M12 3v13.5"
                          />
                        </svg>
                      </span>
                      <span title="Capture">
                        <svg
                          id="DesktopBtnCapturePrescriptionData"
                          xmlns="http://www.w3.org/2000/svg"
                          onClick={() => handleUploadButton("capture")}
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="2"
                          stroke="currentColor"
                          className={`inline size-7 cursor-pointer text-red-700`}
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M6.827 6.175A2.31 2.31 0 0 1 5.186 7.23c-.38.054-.757.112-1.134.175C2.999 7.58 2.25 8.507 2.25 9.574V18a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 18V9.574c0-1.067-.75-1.994-1.802-2.169a47.865 47.865 0 0 0-1.134-.175 2.31 2.31 0 0 1-1.64-1.055l-.822-1.316a2.192 2.192 0 0 0-1.736-1.039 48.774 48.774 0 0 0-5.232 0 2.192 2.192 0 0 0-1.736 1.039l-.821 1.316Z"
                          />
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M16.5 12.75a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0ZM18.75 10.5h.008v.008h-.008V10.5Z"
                          />
                        </svg>
                      </span>
                    </div>
                  </div>
                  {isPrescriptionDataAvailabale && (
                    <div className="mx-4 mt-4">
                      <NewPrescriptions
                        appointment={appointment}
                        clinicGuid={clinic_guid}
                        patient={patient}
                        patient_id={patient_id}
                        doctor_id={doctorId}
                        appointment_id={appointmentId}
                        page_src={pageSrc}
                      />
                    </div>
                  )}
                </div>
              </section>

              <section
                id="secLabNotes"
                className={`w-full mt-4 px-[1rem] ${
                  appointment?.status === "Completed" ? "hidden" : "block"
                }`}
                ref={(el) => (sectionRefs.current[5] = el)}
                data-navbar-section="lab-notes"
              >
                <LabNotes
                  appointment={appointment}
                  clinicGuid={clinic_guid}
                  appointment_id={appointmentId}
                  patient_id={patient_id}
                  page_src={pageSrc}
                  setIsMessagePopupOpen={setIsMessagePopupOpen}
                />
              </section>

              <section
                id="secRadiologyNotes"
                className={`w-full mt-4 px-[1rem] ${
                  appointment?.status === "Completed" ? "hidden" : "block"
                }`}
                ref={(el) => (sectionRefs.current[6] = el)}
                data-navbar-section="radiology"
              >
                <RadiologyNotes
                  appointment={appointment}
                  clinicGuid={clinic_guid}
                  appointment_id={appointmentId}
                  patient_id={patient_id}
                  page_src={pageSrc}
                  setIsMessagePopupOpen={setIsMessagePopupOpen}
                />
              </section>
              <section
                id="secUploadedData"
                className={`w-full mt-4 px-[1rem] ${
                  appointment?.status === "Completed" ? "hidden" : "block"
                }`}
                ref={(el) => (sectionRefs.current[8] = el)}
                data-navbar-section="uploaded-data"
              >
                <UploadedData
                  appointment={appointment}
                  clinicGuid={clinic_guid}
                  appointment_id={appointmentId}
                  patient_id={patient_id}
                  page_src={pageSrc}
                  setIsMessagePopupOpen={setIsMessagePopupOpen}
                />
              </section>

              <section
                className="w-full mt-4 px-4"
                ref={(el) => (sectionRefs.current[7] = el)}
                data-navbar-section="pastHistory"
              >
                {isVisitHistoryDataAvailable && (
                  <PastHistoryDetails
                    setViewHistory={setViewHistory}
                    viewHistoryData={viewHistoryData}
                    patient={patient}
                    doctors={doctors}
                    appointment_id={appointmentId}
                    patient_id={patient_id}
                    appointment={appointment}
                    historySectionRefs={historySectionRefs}
                  />
                )}
               
              </section>

              <div className="h-screen"></div>
            </div>
            {appointment.status !== "Completed" && patientProfileMenuSelector !== "patient" && patientProfileMenuSelector === "appointment" &&
            (activeNavbar === "doctor-notes" ||
                activeNavbar === "personal-details" || activeNavbar === "vitals" ||
                activeNavbar === "prescription" || activeNavbar === "lab-notes" || activeNavbar === "radiology" || activeNavbar === "uploaded-data" ) &&
              appointmentId > 0 &&
              (isGlobalSaveRequired || isAnyChanges) && (
                <div className="fixed bottom-40 right-4 z-40">
                  <button
                    id="btnSaveAllAppointment"
                    className=" bg-green-600 hover:bg-green-700 text-white p-2 pl-4 pr-4 rounded-full shadow-lg transition-colors"
                    onClick={handleGloablSave}
                  >
                    Save All
                    {saveAllLoading && (
                      <svg
                        aria-hidden="true"
                        id="imgSaveAllAppointment"
                        role="status"
                        className="inline w-4 h-4 ml-2 text-white animate-spin"
                        viewBox="0 0 100 101"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                          fill="#E5E7EB"
                        />
                        <path
                          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                          fill="currentColor"
                        />
                      </svg>
                    )}
                  </button>
                </div>
              )}

            {appointment.status !== "Completed" &&  patientProfileMenuSelector !== "patient" && patientProfileMenuSelector === "appointment" &&
              (activeNavbar === "doctor-notes" ||
                activeNavbar === "personal-details" || activeNavbar === "vitals" ||
                activeNavbar === "prescription" || activeNavbar === "lab-notes" || activeNavbar === "radiology" || activeNavbar === "uploaded-data" ) 
                &&
               (
                <div className="fixed bottom-24 right-4 z-40">
                  {isInvoiceEnabled && (
                    <button
                      id="btnGenerateInvoice"
                      className=" bg-blue-600 hover:bg-blue-700 text-white p-2 pl-4 pr-4 rounded-full shadow-lg transition-colors mr-3 mb-2"
                      onClick={() => {
                        generateInvoice();
                      }}
                    >
                      {invoiceExist ? "Update Invoice" : "Generate Invoice"}
                    </button>
                  )}
                  <button
                    id="btnFinishAppointment"
                    className=" bg-green-600 hover:bg-green-700 text-white p-2 pl-4 pr-4 rounded-full shadow-lg transition-colors"
                    onClick={() => setConfirmBoxSignOff(true)}
                  >
                    Finish Appointment
                  </button>
                </div>
              )}
          </div>
          {confirmBoxSignOff && (
            <div
              id="confirm-signOff"
              tabIndex="-1"
              aria-hidden="true"
              className="fixed lg:left-[40%] lg:w-80 top-[10rem] lg:top-[3rem] lg:translate-x-[50%] translate-y-[50%] z-[999999]"
            >
              <div className="relative p-4 w-full max-w-md h-full md:h-auto">
                <div className="relative p-4 text-center bg-white border-gray-400 border-[1px] rounded-lg shadow-lg dark:bg-gray-800 sm:p-5">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    className="text-blue-600 dark:text-gray-500 w-11 h-11 mb-3.5 mx-auto"
                  >
                    <path
                      fillRule="evenodd"
                      d="M8.603 3.799A4.49 4.49 0 0 1 12 2.25c1.357 0 2.573.6 3.397 1.549a4.49 4.49 0 0 1 3.498 1.307 4.491 4.491 0 0 1 1.307 3.497A4.49 4.49 0 0 1 21.75 12a4.49 4.49 0 0 1-1.549 3.397 4.491 4.491 0 0 1-1.307 3.497 4.491 4.491 0 0 1-3.497 1.307A4.49 4.49 0 0 1 12 21.75a4.49 4.49 0 0 1-3.397-1.549 4.49 4.49 0 0 1-3.498-1.306 4.491 4.491 0 0 1-1.307-3.498A4.49 4.49 0 0 1 2.25 12c0-1.357.6-2.573 1.549-3.397a4.49 4.49 0 0 1 1.307-3.497 4.49 4.49 0 0 1 3.497-1.307Zm7.007 6.387a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z"
                      clipRule="evenodd"
                    />
                  </svg>
                  <p className="mb-4 text-gray-500 dark:text-gray-300">
                    Are you sure to sign off the appointment?
                  </p>
                  <div className="flex justify-center items-center space-x-4">
                    <button
                      id="btnNoCfmFinishPopup"
                      onClick={() => setConfirmBoxSignOff(false)}
                      disabled={signOffDisableButton}
                      type="button"
                      className={`py-2 px-3 text-sm font-medium rounded-lg border border-gray-200 focus:ring-4 focus:outline-none focus:ring-primary-300 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600 ${previewTheme.cancelBtnColor} ${previewTheme.cancelBtnBgColor} hover:${previewTheme.cancelBtnHoverColor}`}
                    >
                      No, cancel
                    </button>
                    <button
                      type="button"
                      id="btnYesCfmFinishPopup"
                      onClick={completeSignOff}
                      disabled={signOffDisableButton}
                      className="py-2 px-3 text-sm font-medium text-center text-white bg-blue-600 rounded-lg hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-500 dark:hover:bg-blue-600 dark:focus:ring-blue-900 disabled:opacity-50 disabled:cursor-not-allowed"
                    >
                      Yes, I'm sure{" "}
                      {signOffDisableButton && (
                        <svg
                          aria-hidden="true"
                          role="status"
                          className="inline w-4 h-4 ml-2 text-white animate-spin"
                          viewBox="0 0 100 101"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                            fill="#E5E7EB"
                          />
                          <path
                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                            fill="currentColor"
                          />
                        </svg>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
          {showFollowUpPopup && (
            <div
              id="follow-up-popup"
              tabIndex="-1"
              aria-hidden="true"
              className="fixed lg:left-[35%] lg:w-[350px] top-[10rem] lg:top-[3rem] lg:translate-x-[50%] translate-y-[50%] z-[999999]"
            >
              <div className="relative p-6 w-full max-w-lg h-full md:h-auto">
                <div className="relative p-6 text-center bg-white border-gray-400 border-[1px] rounded-lg shadow-lg dark:bg-gray-800 sm:p-6">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-12 h-12 text-blue-600 mb-3.5 mx-auto"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99"
                    />
                  </svg>
                  <p className="mb-4 text-gray-500 dark:text-gray-300">
                    Schedule a follow-up appointment?
                  </p>
                  <div className="flex justify-center items-center space-x-4">
                    <button
                      id="btnNoFollowUp"
                      onClick={() => setShowFollowUpPopup(false)}
                      type="button"
                      className="py-2 px-4 text-sm font-medium text-gray-500 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-primary-300 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
                    >
                      No, thanks
                    </button>
                    <button
                      id="btnYesFollowUp"
                      onClick={handleFollowUp}
                      type="button"
                      className="py-2 px-4 text-sm font-medium text-center text-white bg-blue-600 rounded-lg hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-500 dark:hover:bg-blue-600 dark:focus:ring-blue-900"
                    >
                      Yes, schedule
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
          {uploadPatientFile && (
            <UploadPatientFiles
              setUploadPatientFile={setUploadPatientFile}
              patient_id={patient_id}
              appointment_id={appointmentId}
              setRefreshImage={setRefreshImage}
            />
          )}
          {openVitals && (
            <Vitals
              openVitals={openVitals}
              setOpenVitals={setOpenVitals}
              patient={patient}
              appointment_id={appointmentId}
              setRefreshData={setRefreshData}
            />
          )}
          {showDocumentUploadPopup && (
            <ChooseDocument appointmentStatus={appointment.status} />
          )}
          {showUploadPopup && (
            <UploadDocument
              patient_id={patient_id}
              appointment_id={newAppointmentId}
            />
          )}
          {showAddAppointmentpopup && (
            <AddAppointment
              setRefreshData={setRefreshData}
              doctors={doctors}
              appointment={followUpData}
              doctorsTimeslot={doctorsTimeslot}
            />
          )}
          {showAddPatientpopup && (
            <AddPatient
              doctors={doctors}
              setRefreshData={setRefreshData}
              patient={{}}
            />
          )}
          {showAppointmentInvoicePrint && <GenerateInvoice />}
        </div>
      ) : (
        <p className="text-red-600 font-semibold text-lg p-10 text-center my-20">
          You are not authorized to perform this action!
        </p>
      )}
      {isMessagePopupOpen && (
        <div className="fixed lg:left-[40%] w-80 left-[10%] top-[30%] z-[50]">
          <div className="relative p-4 w-full max-w-md h-full md:h-auto">
            <div className="relative p-4 text-center bg-white border-gray-400 border-[1px] rounded-lg shadow-lg dark:bg-gray-800 sm:p-5">
              <div className="mb-4 text-gray-500 dark:text-gray-300">
                <p>No Appointments scheduled.</p>
                <p>Do you want to schedule an appointment?</p>
              </div>
              <div className="flex justify-center items-center space-x-4">
                <button
                  id="btnCancelNoAppointmentPopup"
                  onClick={() => {
                    handleUploadWithoutAppointment();
                  }}
                  className="py-2 px-3 text-sm font-medium text-gray-500 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-primary-300 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
                >
                  No, Proceed
                </button>
                <button
                  id="btnYesNoAppointmentPopup"
                  type="button"
                  onClick={() => {
                    handleMessagePopupOpen(false);
                    openAppointmentPopup();
                  }}
                  className={`px-3 py-2 text-sm border rounded-md transition-colors duration-300 ease-in-out disabled:opacity-50 disabled:cursor-not-allowed 
                                    ${previewTheme.saveBtnColor} ${previewTheme.saveBtnBgColor} hover:${previewTheme.saveBtnHoverColor}`}
                >
                  Yes, Proceed
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DesktopPatientProfile;
