import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import store from '../../API/store';
import AddFacility from './AddFacility';
import AddMembers from '../Usermangement/AddMembers';


const Facility = () => {
    var loggeduser = useSelector(state => state.login.loginuserData);
    var loggedusertoken = useSelector(state => state.login.userBarerToken);
    const [showMenu, setShowMenu] = useState(false);
    const [selectedSort, setSelectedSort] = useState('Newest');
    const [showPopup, setShowPopup] = useState(false);
    const [patients, setPatients] = useState([]);
    const [facilitydata, setfacilitydata] = useState([]);
    const [facility, setfacility] = useState([]);
    const [refreshData, setRefreshData] = useState("");
    

    const handleSortItemClick = (sortOption) => {
        setSelectedSort(sortOption);
        setShowMenu(false);
    };

    const openPopup = () => {
        setShowPopup(true);
    };

    const closePopup = () => {
        setShowPopup(false);
        //setShowuserPopup(false);
    };
   

   
    //load the facility details from the database
    useEffect(() => {

        const fetchFacilitydata = async () => {
            try {
                
                await store.GetFacilityDetails(loggedusertoken)
                    .then((result) => {
                        result.json().then((res) => {
                            setfacilitydata(res);
                        })

                    })
                    .catch(err => {
                        console.log(err)
                    })
            }
            catch (err) {
                console.log("Error occured while processing the Facility Data", err)
            }
        };
        const fetchDoctorsCount = async () => {
            try {
                await store.GetDoctorsCount(loggedusertoken)
                    .then((result) => {
                        result.json().then((res) => {
                            //setdoctorscount(res);
                        })

                    })
                    .catch(err => {
                        console.log(err)
                    })
            }
            catch (err) {
                console.log("Error occured while processing the Facility Data", err)
            }
        };
        const fetchPatientCount = async () => {
            try {
                await store.GetPatientCount(loggedusertoken)
                    .then((result) => {
                        result.json().then((res) => {
                            //setpatientcount(res);
                        })

                    })
                    .catch(err => {
                        console.log(err)
                    })
            }
            catch (err) {
                console.log("Error occured while processing the Facility Data", err)
            }
        };

        if (facilitydata.length == 0 && loggeduser != null && loggeduser.email_id == process.env.REACT_APP_EMRAXIS_SuperAdmin) {
            fetchFacilitydata();
            //fetchDoctorsCount();
            //fetchPatientCount();
        }

    }, [refreshData])

    const handlefacilitydelete = (event) => {



    }

    const handlefacilityedit = (event) => {

    }


    return (
        <div id="FacilityDivfacilityContainer"  className=" mx-auto px-4 py-2 my-20 w-100% ">
            <div id="FacilityDivfacilityHeader" className="flex items-center">
                <header id="FacilityHeaderfacilityTitle" className="ml-4 font-semibold text-lg">Facility</header>
            </div>
            <header id="FacilityHeaderfacilityActions" className="flex justify-between items-center my-2 mb-4 px-2">
                <div id="FacilityDivaddFacilityButton" className="flex items-center">
                    <button id="FacilityBtnaddFacility" className="bg-red-700 hover:bg-red-800 text-white py-2 px-4 rounded-md flex items-center" onClick={openPopup}>
                        <svg id="FacilitySvgaddFacilityIcon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6 mr-2">
                            <path d="M5.25 6.375a4.125 4.125 0 1 1 8.25 0 4.125 4.125 0 0 1-8.25 0ZM2.25 19.125a7.125 7.125 0 0 1 14.25 0v.003l-.001.119a.75.75 0 0 1-.363.63 13.067 13.067 0 0 1-6.761 1.873c-2.472 0-4.786-.684-6.76-1.873a.75.75 0 0 1-.364-.63l-.001-.122ZM18.75 7.5a.75.75 0 0 0-1.5 0v2.25H15a.75.75 0 0 0 0 1.5h2.25v2.25a.75.75 0 0 0 1.5 0v-2.25H21a.75.75 0 0 0 0-1.5h-2.25V7.5Z" />
                        </svg>
                        Invite New Facility
                    </button>
                </div>
                <div id="FacilityDivsearchContainer" className="flex items-center">
                    <div id="FacilityDivsearchInput"  className="relative items-center mr-2">
                        <input type="text" id="FacilityInputsearch" className="block pl-10 pr-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none" placeholder="Search here" />
                        <svg id="FacilitySvgsearchIcon" className="absolute left-3 top-1/2 transform -translate-y-1/2 h-6 w-6 text-red-700" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 4c-4.418 0-8 3.582-8 8s3.582 8 8 8 8-3.582 8-8-3.582-8-8-8z" />
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-4.35-4.35" />
                        </svg>
                    </div>
                </div>
            </header>
            {showPopup && <AddFacility id="FacilityAddfacilityPopup" isOpen={showPopup} onClose={closePopup} setRefreshData={setRefreshData} setfacility={facility}/>}
            <table className="table-auto block w-full border rounded-md focus:outline-none focus:border-gray-200">
                <thead>
                    <tr>
                        <th id="FacilityThfacilityID" className="px-10 py-2 text-left">Facility ID</th>
                        <th id="FacilityThfacilityName" className="px-10 py-2 text-left">Facility Name</th>
                        <th id="FacilityThsubscribedPlan" className="px-10 py-2 text-left">Subscribed Plan</th>
                        <th id="FacilityThfacilityAdmin" className="px-10 py-2 text-left">Facility Admin</th>
                        <th id="FacilityThfacilityUsers" className="px-10 py-2 text-left">Facility Users</th>
                        <th id="FacilityThaction" className="px-10 py-2 text-left">Action</th>
                    </tr>
                </thead>
                <tbody>

                    {facilitydata.map((facility, index) => (
                        <tr key={index}>
                            <td id="FacilityTdFacilityCode" className="px-10 py-2">{facility.facility_code}</td>
                            <td id="FacilityTdFacilityName" className="px-10 py-2">{facility.facility_name}</td>
                            <td id="FacilityTdFacilityPlan" className="px-10 py-2">{facility.facility_plan || '-'}</td>
                            <td id="FacilityTdFacilityAdmin" className="px-10 py-2">{facility.facility_admin || '-'}</td>
                            <td id="FacilityTdFacilityUsers" className="px-10 py-2">{facility.facility_users || '-'}</td>
                            <td id="FacilityTdAction" className="px-10">
                                <button id="FacilityBtndeleteFacility" className='w-6 h-6' onClick={(event) => { handlefacilitydelete(facility) }}>
                                    <svg id="FacilitySvgdeleteFacilityIcon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <path d="M9.808 17H10.808V7.99998H9.808V17ZM13.192 17H14.192V7.99998H13.192V17ZM6 20V5.99998H5V4.99998H9V4.22998H15V4.99998H19V5.99998H18V20H6Z" fill="#EE1D52" />
                                    </svg>
                                </button>
                                <button id="FacilityBtneditFacility" className=' mx-2 w-6 h-6' onclick={(event) => { handlefacilityedit(facility) }}>
                                    <svg id="FacilitySvgeditFacilityIcon" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                        <path d="M2.25 12.9375V15.75H5.0625L13.3575 7.455L10.545 4.6425L2.25 12.9375ZM15.5325 5.28C15.602 5.21062 15.6572 5.1282 15.6948 5.03747C15.7325 4.94674 15.7518 4.84948 15.7518 4.75125C15.7518 4.65303 15.7325 4.55576 15.6948 4.46503C15.6572 4.3743 15.602 4.29189 15.5325 4.2225L13.7775 2.4675C13.7081 2.39797 13.6257 2.34281 13.535 2.30518C13.4442 2.26754 13.347 2.24817 13.2488 2.24817C13.1505 2.24817 13.0533 2.26754 12.9625 2.30518C12.8718 2.34281 12.7894 2.39797 12.72 2.4675L11.3475 3.84L14.16 6.6525L15.5325 5.28Z" fill="black" />
                                    </svg>

                                </button>
                            </td>

                        </tr>
                    ))}



                </tbody>
            </table>
        </div>
    );
};

export default Facility;