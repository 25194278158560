import React, { useEffect, useState } from 'react'
import { LiaRupeeSignSolid } from "react-icons/lia";
import { PiPencil, PiPrinterFill } from "react-icons/pi";
import { useDispatch, useSelector } from 'react-redux';
import { setAppointmentInvoicePrint } from '../../Redux/features/popup/popupSlice';
import GenerateInvoiceTable from './GenerateInvoiceTable';
import DateFunction from '../../utils/DateFunctions';
import { setShowAppEditInvoice } from '../../Redux/features/invoice/invoiceSlice';
import { setNotification } from '../../Redux/features/toast/toastSlice';
import store from '../../API/store';
import DataDecode from '../../utils/DataDecode';

const GenerateInvoice = () => {

    const dispatch = useDispatch();
    var loggeduser = useSelector(state => state.login.loginuserData);
    var loggedusertoken = useSelector(state => state.login.userBarerToken);

    const [totalAmount, setTotalAmount] = useState(0.00);
    const [amount, setAmount] = useState(0.00);
    const [discountAmount, setDiscountAmount] = useState(0.00);
    const [gstAmount, setGstAmount] = useState(0.00);
    const [rows, setRows] = useState([]);
    const [submitDisable, setSubmitDisable] = useState(false);
    const [loadingSave, setLoadingSave] = useState(false);
    const [tariffLoad, setTariffLoad] = useState(false);
    var invoiceData = useSelector(state => state.invoice.appointmentInvoice);
    const [invoiceTerms, setInvoiceTerms] = useState({ 'clinic_guid': "", terms: '', terms_id: 0 });
    const [discountIsChecked, setDiscountIsChecked] = useState(false);
    const [discountType, setDiscountType] = useState("");
    const [groupedGSTData, setGroupedGSTData] = useState([]);

    useEffect(() => {
        const grouped = groupByGST(rows);
        setGroupedGSTData(grouped);
    }, [rows]);

    const groupByGST = (data) => {
        const grouped = data.reduce((acc, item) => {
            const gst = item.gst;
            if (!acc[gst]) {
                acc[gst] = { taxableValue: 0, gstValue: 0 };
            }
            const taxableValue = item.amount * item.quantity;
            const gstValue = (taxableValue * gst) / 100;
            acc[gst].taxableValue += taxableValue;
            acc[gst].gstValue += gstValue;
            return acc;
        }, {});

        return Object.entries(grouped).map(([gst, values]) => ({
            gst: gst,
            taxableValue: values.taxableValue,
            gstValue: values.gstValue
        }));
    };

    const onClose = () => {
        dispatch(setAppointmentInvoicePrint(false))
    }

    const GetInvoiceTerms = () => {
        try {
            store.GetInvoiceTerms(loggedusertoken, DataDecode.encryptPayload({ 'clinic_guid': loggeduser.facilityID }))
                .then((result) => {
                    if (result.status === 200) {
                        result.json().then((res) => {
                            let response = DataDecode.decryptResponse(res);
                            var data = JSON.parse(response);
                            setInvoiceTerms(data);
                        });
                    }
                });
        } catch (err) {
            dispatch(setNotification({ message: 'Error Occurred!', status: 'error', action: true }));
        }
    };

    useEffect(() => {
        if (loggeduser) {
            GetInvoiceTerms();
        }
    }, [loggeduser])

    const printDiv = (divId) => {
        const content = document.getElementById(divId).innerHTML;
        const printWindow = window.open('', '', 'height=600,width=800');
        printWindow.document.write('<html><head><title>Invoice</title>');
        printWindow.document.write('<link href="https://cdn.jsdelivr.net/npm/tailwindcss@2.2.19/dist/tailwind.min.css" rel="stylesheet">');
        printWindow.document.write('<style>@media print { body { -webkit-print-color-adjust: exact; } }</style>');
        printWindow.document.write('</head><body >');
        printWindow.document.write(content);
        printWindow.document.write('</body></html>');
        printWindow.document.close();
        setTimeout(() => {
            printWindow.print();
            printWindow.close();
            onClose()
        }, 1000); // Adjust delay as needed
    };

    const [inData, setIndata] = useState({
        patient_op_number: '',
        invoice_id: 0,
        patient_id: '', appointmnet_id: '', address: '', invoice_no: 'N/A', invoice_on: DateFunction.GetDate(new Date()),
        payment_status: 'Pending', payment_mode: ''
    })
    var isAppEditInvoice = useSelector(state => state.invoice.showAppEditInvoice);

    const setCLoseEditInvoice = () => {
        let exist = rows.filter(a => a.description.trim() === "")
        if (exist.length > 0) {
            dispatch(setNotification({ message: '* Fields are required!', status: 'error', action: true }));
        }
        else {
            dispatch(setShowAppEditInvoice(false))
        }
    }

    const setOpenEditInvoice = () => {
        dispatch(setShowAppEditInvoice(true))
    }

    const GetInvoice = (app_id, pat_id) => {
        setTariffLoad(true)
        store.GetInvoice(loggedusertoken, DataDecode.encryptPayload({ clinic_guid: loggeduser.facilityID, invoice_for: invoiceData.targetFrom, appointment_id: app_id, patient_id: pat_id }))
            .then((result) => {
                setTariffLoad(false)
                if (result.status == 200) {
                    result.json().then((res) => {
                        let response = DataDecode.decryptResponse(res);
                        var data = JSON.parse(response);
                        setRows(data.invoiceItems);
                        setIndata({
                            ...inData, invoice_on: DateFunction.GetDate(data.userInvoice.invoice_on),
                            invoice_no: data.userInvoice.invoice_code,
                            invoice_id: data.userInvoice.invoice_id,
                            payment_status: data.userInvoice.payment_status, payment_mode: data.userInvoice.payment_mode,
                            appointment_id: invoiceData.appointment.appointment_id,
                            patient_id: invoiceData.appointment.patient_id,
                            patient_op_number: invoiceData.patient.patient_op_number,
                            FirstName: invoiceData.patient.FirstName,
                            MiddleName: invoiceData.patient.MiddleName,
                            LastName: invoiceData.patient.LastName,
                            mobile_number: invoiceData.patient.mobile_number,
                            address: invoiceData.patient.address
                        })
                        setTotalAmount(data.userInvoice.total_amount)
                        setAmount(data.userInvoice.amount)
                        setGstAmount(data.userInvoice.gst)
                        setDiscountType(data.userInvoice.discount_type)
                        setDiscountIsChecked(parseInt(data.userInvoice.discount || 0) > 0)
                        setDiscountAmount(data.userInvoice.discount)
                        dispatch(setShowAppEditInvoice(false))
                    })
                }
                else if (result.status == 404) {
                    setRows([])
                    dispatch(setShowAppEditInvoice(true))
                }
                // else{
                //     dispatch(setShowAppEditInvoice(true))
                // }
            })
            .catch(err => {
                dispatch(setNotification({ message: 'Error Occurred!', status: 'error', action: true }));
            })
    }

    useEffect(() => {
        if (invoiceData.patient) {
            setIndata({
                ...inData,
                appointment_id: invoiceData.appointment.appointment_id,
                patient_id: invoiceData.appointment.patient_id,
                patient_op_number: invoiceData.patient.patient_op_number,
                FirstName: invoiceData.patient.FirstName,
                MiddleName: invoiceData.patient.MiddleName,
                LastName: invoiceData.patient.LastName,
                mobile_number: invoiceData.patient.mobile_number,
                address: invoiceData.patient.address
            });
            setTimeout(() => {
                GetInvoice(invoiceData.appointment.appointment_id, invoiceData.appointment.patient_id);
            })
        }
    }, [invoiceData.patient])

    const saveInvoice = (printRequired) => {
        if (rows.length > 0) {
            setSubmitDisable(true);
            setLoadingSave(true);
            let payload = {
                appointment_id: inData.appointment_id,
                clinic_guid: loggeduser.facilityID,
                invoices: rows,
                patient_id: inData.patient_id,
                payment_mode: inData.payment_mode,
                payment_status: inData.payment_status,
                invoice_id: inData.invoice_id,
                amount: amount,
                discount: discountAmount,
                gst: gstAmount,
                totalAmount: totalAmount,
                invoice_on: DateFunction.SetDbCurrentTimeStamp(),
                targetFrom: invoiceData.targetFrom,
                discount_type: discountType,
                discount: discountAmount
            }
            if (inData.invoice_id == 0) {
                store.SaveAppointmnetInvoice(loggedusertoken, DataDecode.encryptPayload(payload))
                    .then((result) => {
                        setSubmitDisable(false);
                        setLoadingSave(false);
                        if (result.status == 201) {
                            result.json().then((res) => {
                                let response = DataDecode.decryptResponse(res);
                                let data = JSON.parse(response);
                                setIndata({ ...inData, invoice_no: data?.invoice_code })
                            });
                            dispatch(setNotification({ message: "Invoice Created", status: 'success', action: true }));
                            setTimeout(() => {
                                if (printRequired) {
                                    printDiv('content-to-print')
                                } else {
                                    onClose();
                                }
                            }, 100)
                        }
                        else {
                            setLoadingSave(false);
                            dispatch(setNotification({ message: 'Error Occured!', status: 'error', action: true }));
                        }
                    })
                    .catch(err => {
                        dispatch(setNotification({ message: 'Error Occurred!', status: 'error', action: true }));
                    })
            } else {
                store.UpdateAppointmnetInvoice(loggedusertoken, DataDecode.encryptPayload(payload))
                    .then((result) => {
                        setSubmitDisable(false);
                        setLoadingSave(false);
                        if (result.status == 204) {
                            dispatch(setNotification({ message: "Invoice Updated", status: 'success', action: true }));
                            setTimeout(() => {
                                if (printRequired) {
                                    printDiv('content-to-print')
                                } else {
                                    onClose();
                                }
                            }, 100)
                        }
                        else {
                            setLoadingSave(false);
                            dispatch(setNotification({ message: 'Error Occured!', status: 'error', action: true }));
                        }
                    })
                    .catch(err => {
                        dispatch(setNotification({ message: 'Error Occurred!', status: 'error', action: true }));
                    })
            }
        } else {
            dispatch(setNotification({ message: 'Please add data!', status: 'error', action: true }));
        }
    }

    const handleDiscountCheckbox = evt => {
        setDiscountIsChecked(evt.target.checked);
        if (!evt.target.checked) {
            setDiscountType("");
            setDiscountAmount(0);
            setTotalAmount(amount + gstAmount)
        }
    }

    const handleDiscountTypeChange = evt => {
        if (/^\d+%?$/.test(evt.target.value || evt.target.value === '')) {
            setDiscountType(evt.target.value)
            if (evt.target.value.includes("%")) {
                let dis = parseFloat(evt.target.value)
                setDiscountAmount((((amount + gstAmount) * dis) / 100));
                setTotalAmount((amount + gstAmount) - (((amount + gstAmount) * dis) / 100))
            } else {
                setDiscountAmount(parseFloat(evt.target.value))
                setTotalAmount((amount + gstAmount) - parseFloat(evt.target.value))
            }
        }
    }

    return (
        <>
            <div id="GenerateInvoiceDivgenerateInvoicemodal" className="fixed inset-0 overflow-hidden flex justify-center items-center z-50">
                <div id="GenerateInvoiceDivmodalOverlay" className="absolute inset-0 bg-black opacity-25"></div>
                <div id="GenerateInvoiceDivmodalContent" className="relative bg-white shadow-xl p-8 max-h-[80vh] lg:max-h-[70vh] 2xl:max-h-[70vh] overflow-y-auto" style={{ width: "900px" }}>
                    <div id="GenerateInvoiceDivmodalHeader" className="flex justify-between items-center mb-4 w-full" style={{ width: '800px' }}>
                        <div id="GenerateInvoiceDivmodalTitle" className="text-black font-bold text-2xl">Print Preview</div>
                        <button id="GenerateInvoiceButtoncloseModal" className="text-red-800" onClick={onClose}>
                            <svg id="GenerateInvoiceSvgcloseModalIcon" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-10 h-10">
                                <path strokeLinecap="round" strokeLinejoin="round" d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                            </svg>
                        </button>
                    </div>

                    <div id="content-to-print" className="max-w-4xl mx-auto bg-white p-2 rounded-lg" style={{ width: "800px" }} >
                        <div id="GenerateInvoiceDivheaderSection" className=' mx-auto px-2 py-2 my-2 w-100% '>
                            <div id="GenerateInvoiceDivfacilityLogo" className='float-right'>
                                {loggeduser.facility_logo &&
                                    <img id="GenerateInvoiceImgfacilityLogo" src={process.env.REACT_APP_EMRAXIS_S3_URL + "/" + loggeduser.facility_logo} className='h-10 w-auto' />
                                }
                            </div>
                            <div id="GenerateInvoiceDivinvoiceTitle" className='flex justify-between'>
                                <div id="GenerateInvoiceDivinvoiceTitleText" className='text-3xl font-semibold text-blue-600'>
                                    Invoice
                                </div>

                            </div>
                            <div id="GenerateInvoiceDivinvoiceDetails" className='my-3'>
                                <table id="GenerateInvoiceTableinvoiceDetailsTable" className="table-auto">
                                    <tbody>
                                        <tr id="GenerateInvoiceTrinvoiceNoRow">
                                            <td id="GenerateInvoiceTdinvoiceNoText" className='pr-[20px] py-[5px] text-gray-500'>Invoice No.</td>
                                            <td id="GenerateInvoiceTdinvoiceNo">{inData.invoice_no}</td>
                                        </tr>
                                        <tr>
                                            <td id="GenerateInvoiceTdinvoiceDateText" className='pr-[20px] py-[5px] text-gray-500'>Invoice Date</td>
                                            <td id="GenerateInvoiceTdinvoiceDate">{inData.invoice_on}</td>
                                        </tr>
                                        <tr>
                                            <td id="GenerateInvoiceTdpatientIDText" className='pr-[20px] py-[5px] text-gray-500'>Patient ID</td>
                                            <td id="GenerateInvoiceTdpatientOPNumText">#{inData.patient_op_number}</td>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>

                            <div className="flex flex-wrap">
                                <div className="flex-1 min-w-[60%] md:w-1/2">
                                    <p id="GenerateInvoicePbilledByText" className='text-red-500 font-semibold pb-2 '>Billed By</p>
                                    <p id="GenerateInvoicePuserName" className='text-gray-600 font-bold pb-2 '>{loggeduser?.user_name}</p>
                                    {loggeduser?.facility_address && <p id="GenerateInvoicePfacilityAddress" className='font-normal '>{loggeduser?.facility_address}</p>}
                                    {loggeduser?.phonenumber && <p id="GenerateInvoicePphoneNumber" className='font-normal '><span className='text-gray-500'>Phone: </span>{loggeduser?.phonenumber}</p>}
                                    {loggeduser?.email_id && <p id="GenerateInvoicePphoneNumber" className='font-normal '><span className='text-gray-500'>Email: </span>{loggeduser?.email_id}</p>}
                                </div>
                                <div className="flex-1 md:w-1/2">
                                    <p id="GenerateInvoicePbilledToText" className='text-red-500 font-semibold pb-2 '>Billed To</p>
                                    <p id="GenerateInvoicePbilledToName" className='text-gray-600 font-bold pb-2 '>{inData.FirstName + " " + inData.MiddleName?.charAt(0) + " " + inData.LastName}</p>
                                    <p id="GenerateInvoicePbilledToAddress" className='font-normal '>{inData.address}</p>
                                    <p id="GenerateInvoicePbilledToAddress" className='font-normal '><span className='text-gray-500'>Phone: </span>{inData.mobile_number}</p>
                                </div>
                            </div>

                            {tariffLoad ?
                                <svg id="GenerateInvoiceSvgloadingSpinner" aria-hidden="true" role="status" className="w-6 h-6 ml-2 text-red-600 animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB" />
                                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor" />
                                </svg>
                                :
                                <>
                                    {(isAppEditInvoice || rows.length == 0) ?
                                        <GenerateInvoiceTable rows={rows} setRows={setRows} setTotalAmount={setTotalAmount} setAmount={setAmount} setGstAmount={setGstAmount} /> :
                                        <>
                                            <div className='mt-4'>
                                                <table className="border rounded-md focus:outline-none focus:border-gray-200 w-full">
                                                    <thead className='bg-red-700 px-3 text-white rounded-xl'>
                                                        <tr>
                                                            <th id="GenerateInvoiceThindex" className="rounded-tl-md py-2 text-center">#</th>
                                                            <th id="GenerateInvoiceThitem" className="py-2 text-center">Item</th>
                                                            <th id="GenerateInvoiceThDate" className="py-2 text-center">Date</th>
                                                            <th id="GenerateInvoiceThamount" className="py-2 text-center">Amount</th>
                                                            <th id="GenerateInvoiceThquantity" className="py-2 text-center">Quantity</th>
                                                            <th id="GenerateInvoiceThgstRate" className="py-2 text-center">GST Rate</th>
                                                            <th id="GenerateInvoiceThgstAmount" className="py-2 text-center">GST Amount</th>
                                                            <th id="GenerateInvoiceThtotal" className="rounded-tr-md py-2 text-center">Total</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {rows.map((row, index) => (
                                                            <tr key={'inv-item-' + index}>
                                                                <td id={"GenerateInvoiceTdindex" + index} className="py-1 px-2">{index + 1}</td>
                                                                <td id={"GenerateInvoiceTddescription" + index} className="py-1 px-2">{row.description}</td>
                                                                <td id={"GenerateInvoiceTdDate" + index} className="py-1 px-2">{row.date && DateFunction.GetDate(row.date)}</td>
                                                                <td id={"GenerateInvoiceTdamount" + index} className="py-1 px-2 text-right">{row.amount}</td>
                                                                <td id={"GenerateInvoiceTdquantity" + index} className="py-1 px-2 text-right">{row.quantity}</td>
                                                                <td id={"GenerateInvoiceTdgst" + index} className="py-1 px-2 text-right">{row.gst}</td>
                                                                <td id={"GenerateInvoiceTdamount" + index} className="py-1 px-2 text-right">{(row.total - (row.amount * row.quantity)).toFixed(2)}</td>
                                                                <td id={"GenerateInvoiceTdtotal" + index} className="py-1 px-2 text-right"><p><LiaRupeeSignSolid className='mt-[0px] inline' /><span>{row.total.toFixed(2)}</span></p></td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </>
                                    }
                                </>
                            }
                            {(isAppEditInvoice || rows.length == 0) &&
                                <div className='p-1'>
                                    <input type='checkbox' onChange={handleDiscountCheckbox} checked={discountIsChecked} /> Discount
                                </div>
                            }
                            <div className='mt-4 grid grid-cols-3 gap-2'>
                                <div className='col-span-2'>
                                    {invoiceTerms.terms &&
                                        <div className='border-slate-200 border rounded px-4 py-6 lg:mr-6 drop-shadow-2xl justify'>
                                            <p id="GenerateInvoicePtermsConditionsText" className='text-red-600 font-semibold pb-2 '>Terms and Conditions</p>
                                            <p id="GenerateInvoicePinvoiceTerms" className=' pb-2 font-normal text-justify whitespace-pre-line'>{invoiceTerms.terms}</p>
                                        </div>
                                    }
                                </div>
                                <div className=''>
                                    <table className="w-full">
                                        <tbody>
                                            <tr className=''>
                                                <td id="GenerateInvoiceTdsubTotal" className='py-2 text-gray-500 text-left'>Sub Total</td>
                                                <td id="GenerateInvoiceTdsignBold" className='py-2 text-gray-500 text-rigt'><LiaRupeeSignSolid className='inline mr-1' /></td>
                                                <td id="GenerateInvoiceTdamountFixed" className='pt-2 text-right'>{amount.toFixed(2)}</td>
                                            </tr>
                                            {
                                                isAppEditInvoice && discountIsChecked &&
                                                <tr className=''>
                                                    <td id="GenerateInvoiceTddiscount" className='py-2 text-gray-500'>Discount</td>
                                                    <td className='py-2 text-gray-500 text-right' colSpan={2}>
                                                        <input type='text' id="GenerateInvoiceInputdiscountChange"
                                                            className='h-8 rounded-md pl-1 w-24 text-right'
                                                            name="discount"
                                                            min={0}
                                                            value={discountType}
                                                            onChange={(event) => handleDiscountTypeChange(event)} />
                                                    </td>
                                                </tr>
                                            }
                                            {!isAppEditInvoice && discountAmount > 0 &&
                                                <tr className=''>
                                                    <td id="GenerateInvoiceTdiscountType" className='py-2 text-gray-500'>Discount{discountType.includes("%") && ` (${discountType})`}</td>
                                                    <td id="GenerateInvoiceTdliaRupees" className='py-2 text-gray-500 text-rigt'><LiaRupeeSignSolid className='inline mr-1' /></td>
                                                    <td id="GenerateInvoiceTddiscountamountFixed" className='pt-2 text-right'>{discountAmount.toFixed(2)}</td>
                                                </tr>
                                            }
                                            <tr className=''>
                                                <td id="GenerateInvoicetdgstText" className='py-2 text-gray-500'>GST</td>
                                                <td id="GenerateInvoiceTdliaRupeesGst" className='py-2 text-gray-500 text-rigt'><LiaRupeeSignSolid className='inline mr-1' /></td>
                                                <td id="GenerateInvoiceTdgstAmountFixed" className='pt-2 text-right'>{gstAmount.toFixed(2)}</td>
                                            </tr>
                                            <tr className=' border-y-2 border-y-neutral-400 my-2 text-black font-semibold'>
                                                <td id="GenerateInvoicetdamountDueText" className='py-2'>Amount Due</td>
                                                <td id="GenerateInvoiceTdliaRupeeDue" className='py-2 text-gray-500 text-rigt'><LiaRupeeSignSolid className='inline mr-1' /></td>
                                                <td id="GenerateInvoiceTddueAmountFixed" className='pt-2 text-right'>{totalAmount.toFixed(2)}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className='mt-4 hidden'>
                                {
                                    groupedGSTData.length > 0 &&
                                    <table className="border border-gray-400 rounded-md w-full text-sm">
                                        <thead className='bg-gray-300 px-1 text-gray-700 rounded-xl'>
                                            <tr className=''>
                                                <th id="GenerateInvoiceThtaxableValue" className="py-1 text-center font-normal border-b border-gray-400" rowSpan={2}>Taxable Value</th>
                                                <th id="GenerateInvoiceThcentralTax" className="py-1 text-center border-l border-b border-gray-400 font-normal" colSpan={2}>Central Tax</th>
                                                <th id="GenerateInvoiceThstateTax" className="py-1 text-center border-l border-b border-gray-400 font-normal" colSpan={2}>State Tax</th>
                                                <th id="GenerateInvoiceThtotal" className="py-1 text-center border-l border-gray-400 font-normal">Total</th>
                                            </tr>
                                            <tr>
                                                <th id="GenerateInvoiceThrate" className="py-1 text-center border-l border-b border-gray-400 font-normal">Rate</th>
                                                <th id="GenerateInvoiceThamount" className="py-1 text-center border-l border-b border-gray-400 font-normal">Amount</th>
                                                <th id="GenerateInvoiceThrate2" className="py-1 text-center border-l border-b border-gray-400 font-normal">Rate</th>
                                                <th id="GenerateInvoiceThamount2" className="py-1 text-center border-l border-b border-gray-400 font-normal">Amount</th>
                                                <th id="GenerateInvoiceThtaxAmount" className="py-1 text-center border-l border-b border-gray-400 font-normal">Tax Amount</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                groupedGSTData.filter(a => a.gst > 0).map((grp, ind) => (
                                                    <tr key={'gst-tab-' + ind} className=''>
                                                        <th id="GenerateInvoiceThfloatTaxvalue" className="py-1 px-2 text-left border-l border-b border-gray-400 font-medium">{parseFloat(grp.taxableValue || 0).toFixed(2)}</th>
                                                        <th id="GenerateInvoiceThgst" className="py-1 px-2 text-center border-l border-b border-gray-400 font-normal">{(grp.gst / 2)}%</th>
                                                        <th id="GenerateInvoiceThgstValue" className="py-1 px-2 text-right border-l border-b border-gray-400 font-normal">{parseFloat(grp.gstValue / 2).toFixed(2)}</th>
                                                        <th id="GenerateInvoiceThgrpGst" className="py-1 px-2 text-center border-l border-b border-gray-400 font-normal">{grp.gst / 2}%</th>
                                                        <th id="GenerateInvoiceThfloatGstvalue" className="py-1 px-2 text-right border-l border-b border-gray-400 font-normal">{parseFloat(grp.gstValue / 2).toFixed(2)}</th>
                                                        <th id="GenerateInvoiceThfloatValue" className="py-1 px-2 text-right border-l border-b border-gray-400 font-normal">{parseFloat(grp.gstValue).toFixed(2)}</th>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </table>
                                }
                            </div>
                            {/* <div className='mt-4 lg:hidden xs:block'>
                                <div className='border-slate-200 border rounded px-4 py-2 lg:mr-6 drop-shadow-2xl lg:w-[60%] xs:w-[40%]'>
                                    <p className='text-[#af3d3c] font-semibold pb-2 '>Terms and Conditions</p>
                                    <p className=' pb-2 font-normal text-justify'>Please pay within 15 days from date of invoice, overdue interest @14% will be charged on delayed payments.</p>
                                    <p className='font-normal text-justify'>Please quote invoice number when remitting funds.</p>
                                </div>
                            </div> */}

                        </div>
                    </div>
                    <div className="mt-4 mb-12 flex items-center justify-end" style={{ width: '800px' }}>
                        {isAppEditInvoice ?
                            <button id="GenerateInvoiceBtneditInvoice" className=" bg-red-700 hover:bg-red-800 text-white py-2 px-4 rounded-md items-center" onClick={setCLoseEditInvoice}>
                                Submit
                            </button>
                            : <>
                                <div className="flex me-4">
                                    <div className="me-1">
                                        <p id="GenerateInvoicePpaymentstatus" className='pb-1'>Payment Status</p>
                                        <div className='flex items-center '>
                                            <input id="GenerateInvoiceInputpaymentstatusPending" type="radio" value="Pending"
                                                checked={inData.payment_status === "Pending"}
                                                onChange={(e) => { setIndata({ ...inData, payment_status: e.target.value }) }}
                                                name="payment_status"
                                                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                            <label id="GenerateInvoiceLabelpending" htmlFor="payment_status-pending" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-500">Pending</label>
                                        </div>
                                    </div>
                                    <div className="me-1">
                                        <p className='pb-1'>&nbsp;</p>
                                        <div className='flex items-center '>
                                            <input id="GenerateInvoiceInputpaymentstatusPaid" type="radio" value="Paid"
                                                checked={inData.payment_status === "Paid"}
                                                onChange={(e) => { setIndata({ ...inData, payment_status: e.target.value }) }}
                                                name="payment_status" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                            <label id="GenerateInvoiceLabelpaid" htmlFor="payment_status-paid" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-500">Paid</label>
                                        </div>
                                    </div>
                                    <div className="me-2 ms-7">
                                        <p id="GenerateInvoicePpaymentMode" className='pb-1'>Payment Mode</p>
                                        <div className='flex items-center '>
                                            <input id="GenerateInvoiceInputpaymentmodeCash" type="radio" value="UPI"
                                                checked={inData.payment_mode === "UPI"}
                                                disabled={inData.payment_status === "Pending"}
                                                onChange={() => { }}
                                                onClick={(e) => {
                                                    if (inData.payment_mode === "UPI") {
                                                        setIndata({ ...inData, payment_mode: "" })
                                                    } else {
                                                        setIndata({ ...inData, payment_mode: "UPI" })
                                                    }
                                                }}
                                                name="payment_mode" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 disabled:cursor-not-allowed" />
                                            <label id='Labelupi' htmlFor="payment_mode-cash" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-500">UPI</label>
                                        </div>
                                    </div>
                                    <div className="-ms-10">
                                        <p className='pb-1'>&nbsp;</p>
                                        <div className='flex items-center '>
                                            <input id="GenerateInvoiceInputpaymentmodeCard" type="radio" value="Card"
                                                checked={inData.payment_mode === "Card"}
                                                disabled={inData.payment_status === "Pending"}
                                                onChange={() => { }}
                                                onClick={(e) => {
                                                    if (inData.payment_mode === "Card") {
                                                        setIndata({ ...inData, payment_mode: "" })
                                                    } else {
                                                        setIndata({ ...inData, payment_mode: "Card" })
                                                    }
                                                }}
                                                name="payment_mode" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 disabled:cursor-not-allowed" />
                                            <label id="GenerateInvoiceLabelcard" htmlFor="payment_mode-card" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-500">Card</label>
                                        </div>
                                    </div>
                                    <div className="ms-2">
                                        <p className='pb-1'>&nbsp;</p>
                                        <div className='flex items-center '>
                                            <input id="GenerateInvoiceInputpaymentmodeCard" type="radio" value="Cash"
                                                checked={inData.payment_mode === "Cash"}
                                                disabled={inData.payment_status === "Pending"}
                                                onChange={() => { }}
                                                onClick={(e) => {
                                                    if (inData.payment_mode === "Cash") {
                                                        setIndata({ ...inData, payment_mode: "" })
                                                    } else {
                                                        setIndata({ ...inData, payment_mode: "Cash" })
                                                    }
                                                }}
                                                name="payment_mode" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 disabled:cursor-not-allowed" />
                                            <label id="GenerateInvoiceLabelcash" htmlFor="payment_mode-card" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-500">Cash</label>
                                        </div>
                                    </div>
                                </div>
                                <button id="GenerateInvoiceBtnpencil" disabled={submitDisable} className='text-xl	py-[10px] bg-blue-600 px-[20px] mr-[10px] rounded disabled:opacity-50 disabled:cursor-not-allowed' onClick={setOpenEditInvoice}>
                                    <PiPencil className='text-white' />
                                </button>
                                <button id="GenerateInvoiceBtnprintFill" disabled={submitDisable} className='text-xl	py-[10px] bg-[green] px-[20px] mr-[10px] rounded disabled:opacity-50 disabled:cursor-not-allowed' onClick={() => { saveInvoice(true) }}>
                                    <PiPrinterFill className='text-white' />
                                </button>
                                <button id="GenerateInvoiceBtnsave" disabled={submitDisable} className=" bg-red-700 hover:bg-red-800 text-white py-2 px-4 rounded-md items-center disabled:opacity-50 disabled:cursor-not-allowed" onClick={() => saveInvoice(false)}>
                                    Save
                                    {loadingSave &&
                                        <svg aria-hidden="true" role="status" className="inline w-4 h-4 ml-2 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB" />
                                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor" />
                                        </svg>
                                    }
                                </button>
                            </>}
                    </div>
                </div>
            </div >
        </>
    )
}

export default GenerateInvoice