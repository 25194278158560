import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { setNotification } from '../../Redux/features/toast/toastSlice';
import CreatableSelect from 'react-select/creatable';
import store from '../../API/store';
import DataDecode from '../../utils/DataDecode';
import InvoiceItemDatePicker from './InvoiceItemDatePicker';
import DateFunction from '../../utils/DateFunctions';


const GenerateInvoiceTable = ({ rows, setRows, setTotalAmount, setAmount, setGstAmount }) => {

    var loggeduser = useSelector(state => state.login.loginuserData);
    var loggedusertoken = useSelector(state => state.login.userBarerToken);
    var invoiceData = useSelector(state => state.invoice.appointmentInvoice);
    const dispatch = useDispatch()

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            height: '2.1rem', // Tailwind h-8 equivalent
            minHeight: '2.1rem', // Ensure minimum height is set
            border: '2px solid #E5E7EB', // Tailwind border-gray-300
            borderRadius: '0.375rem', // Tailwind rounded-md
            padding: '0 0.5rem', // Tailwind px-2
            boxShadow: state.isFocused ? '0 0 0 3px rgba(66, 153, 225, 0.5)' : 'none', // Tailwind ring
            '&:hover': {
                borderColor: '#D1D5DB', // Tailwind border-gray-400
            },
        }),
        valueContainer: (provided) => ({
            ...provided,
            height: '2rem', // Tailwind h-8 equivalent
            padding: '-1rem 0.5rem', // Tailwind px-2
        }),
        input: (provided) => ({
            ...provided,
            margin: '0px', // Remove margin to align with Tailwind h-8
            'input:focus': {
                boxShadow: 'none',
            },
        }),
        indicatorSeparator: () => ({
            display: 'none', // Remove the indicator separator
        }),
        dropdownIndicator: (provided) => ({
            ...provided,
            padding: '0.5rem', // Tailwind p-2
        }),
        menu: (provided) => ({
            ...provided,
            borderRadius: '0.375rem', // Tailwind rounded-md
            boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)', // Tailwind shadow-lg
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? '#3B82F6' : state.isFocused ? '#DBEAFE' : undefined, // Tailwind bg-blue-500 and bg-blue-100
            color: state.isSelected ? 'white' : 'black', // Tailwind text-white
            padding: '0.5rem', // Tailwind p-2
        }),
        placeholder: (provided) => ({
            ...provided,
            color: '#9CA3AF', // Tailwind text-gray-400
        }),
        singleValue: (provided) => ({
            ...provided,
            color: '#1F2937', // Tailwind text-gray-800
        }),
    };

    const [doctors, setDoctors] = useState([]);
    const [allTariffs, setAllTariffs] = useState([]);
    const [tariffOptions, setTariffOptions] = useState([]);


    const GetMasterTariff = () => {
        store.GetAllMasterTariff(loggedusertoken, DataDecode.encryptPayload({ 'clinic_guid': loggeduser.facilityID }))
            .then((result) => {
                if (result.status == 200) {
                    result.json().then((res) => {
                        let response = DataDecode.decryptResponse(res);
                        var myObject = JSON.parse(response);
                        setAllTariffs(myObject);
                    })
                } else {
                    setAllTariffs([]);
                }
            })
            .catch(err => {
                setAllTariffs([]);
                dispatch(setNotification({ message: 'Error Occurred!', status: 'error', action: true }));
            })
    }

    const GetDoctors = () => {
        try {
            store.GetAllDoctors(loggedusertoken, DataDecode.encryptPayload({ 'clinic_guid': loggeduser.facilityID, page: 0 }))
                .then((result) => {
                    if (result.status === 200) {
                        result.json().then((res) => {
                            let response = DataDecode.decryptResponse(res);
                            var myObject = JSON.parse(response);
                            setDoctors(myObject.doctors);
                        });
                    } else {
                        setDoctors([]);
                    }
                });
        } catch (err) {
            setDoctors([]);
            dispatch(setNotification({ message: 'Error Occurred!', status: 'error', action: true }));
        }
    };


    useEffect(() => {
        if (loggeduser) {
            GetMasterTariff();
            GetDoctors();
        }
    }, [loggeduser])

    useEffect(() => {
        if (doctors.length > 0 && allTariffs.length > 0) {
            const options = allTariffs.map(item => {
                let amount = item.amount;
                if (item.is_doctor_consultancy) {
                    var doct = doctors.filter(a => a.doctor_id === invoiceData.doctor);
                    amount = doct.length > 0 ? doct[0].consulting_fees : 0
                }
                if (amount > 0)
                    return ({
                        label: item.tariff_name + ' (' + amount + ')',
                        value: item.master_tariff_id + '~' + amount + '~' + item.gst,
                    })
            });
            setTariffOptions(options)
        }
    }, [doctors, allTariffs])

    useEffect(() => {
        if (rows.length == 0) {
            setRows([{ invoice_item_id: 0, order_id: rows.length + 1, item: '', description: '', amount: 0, quantity: 1, discount: '0', total: 0, selectedTariff: null, master_tariff_id: 0, gst: 0, date: null }])
        }
    }, [rows])

    const addRow = () => {
        const newRow = { invoice_item_id: 0, order_id: rows.length + 1, item: '', description: '', amount: 0, quantity: 1, discount: '0', total: 0, selectedTariff: null, master_tariff_id: 0, gst: 0, date: null };
        setRows([...rows, newRow]);
    };

    const deleteRow = (id) => {
        const updatedRows = rows.filter(row => row.order_id !== id);
        if (updatedRows.length > 0) {
            const totalamount = updatedRows.reduce((sum, item) => sum + item.total, 0);
            const amount = updatedRows.reduce((sum, item) => sum + (item.amount * item.quantity), 0);
            setTotalAmount(totalamount)
            setAmount(amount)
            setGstAmount(totalamount - amount)
            setRows(updatedRows);
        } else {
            dispatch(setNotification({ message: 'Minimum one row required!', status: 'error', action: true }));
        }
    };

    const handleChange = (id, event, value) => {
        const { name } = event.target;

        const newRows = rows.map((row, i) => {
            if (row.order_id == id) {
                const newRow = { ...row, [name]: value };
                if (newRow.gst > 0) {
                    let dis = parseFloat(newRow.gst || 0)
                    newRow.total = (newRow.amount * newRow.quantity) + ((newRow.amount * newRow.quantity) * dis) / 100;
                }
                else
                    newRow.total = (newRow.amount * newRow.quantity);
                return newRow;
            }
            return row;
        });

        const totalamount = newRows.reduce((sum, item) => sum + item.total, 0);
        const amount = newRows.reduce((sum, item) => sum + (item.amount * item.quantity), 0);
        setTotalAmount(totalamount)
        setAmount(amount)
        setGstAmount(totalamount - amount)
        setRows(newRows);
    };

    const handleCreate = (id, inputValue) => {
        const newOption = { value: inputValue.toLowerCase() + '~0~0', label: inputValue };
        setTariffOptions((prevOptions) => [...prevOptions, newOption]);

        const newRows = rows.map((row, i) => {
            if (row.order_id == id) {
                const newRow = { ...row, selectedTariff: newOption, description: newOption.label };
                if (newRow.gst > 0) {
                    let dis = parseFloat(newRow.gst)
                    newRow.total = (newRow.amount * newRow.quantity) + ((newRow.amount * newRow.quantity) * dis) / 100;
                }
                else
                    newRow.total = (newRow.amount * newRow.quantity);
                return newRow;
            }
            return row;
        });

        const totalamount = newRows.reduce((sum, item) => sum + item.total, 0);
        const amount = newRows.reduce((sum, item) => sum + (item.amount * item.quantity), 0);
        setTotalAmount(totalamount)
        setAmount(amount)
        setGstAmount(totalamount - amount)
        setRows(newRows);
    };


    const handleSelectTariff = (id, selectedOption) => {
        const newRows = rows.map((row, i) => {
            if (row.order_id == id) {
                const newRow = { ...row, selectedTariff: selectedOption, description: selectedOption.label.substring(0, selectedOption.label.lastIndexOf(" ")), master_tariff_id: selectedOption.value?.split("~")[0], amount: selectedOption.value?.split("~")[1], gst: selectedOption.value?.split("~")[2] };
                if (newRow.gst > 0) {
                    let dis = parseFloat(newRow.gst)
                    newRow.total = (newRow.amount * newRow.quantity) + ((newRow.amount * newRow.quantity) * dis) / 100;
                }
                else
                    newRow.total = (newRow.amount * newRow.quantity);
                return newRow;
            }
            return row;
        });

        const totalamount = newRows.reduce((sum, item) => sum + item.total, 0);
        const amount = newRows.reduce((sum, item) => sum + (item.amount * item.quantity), 0);
        setTotalAmount(totalamount)
        setAmount(amount)
        setGstAmount(totalamount - amount)
        setRows(newRows);
    };

    const handleDateChange = (date, order_id) => {
        const newRows = rows.map((row, i) => {
            if (row.order_id == order_id) {
                const newRow = { ...row, date: DateFunction.SetDbDateTime(date) };
                return newRow;
            }
            return row;
        });
        setRows(newRows);
    }

    return (
        <div>
            <table className='table-auto w-[100vh] lg:w-full border rounded-md focus:outline-none'>
                <thead>
                    <tr className='odd:bg-white even:bg-gray-100'>
                        <th id="GenerateInvoiceTableThindex" className='text-center pb-2 text-sm font-semibold'>#</th>
                        <th id="GenerateInvoiceTableThdescription" className='text-center pb-2 text-sm font-semibold'>Description <span className='text-red-600 pr-1'>*</span></th>
                        <th id="GenerateInvoiceTableThdescription" className='text-center pb-2 text-sm font-semibold'>Date </th>
                        <th id="GenerateInvoiceTableThamount" className='text-center pb-2 text-sm font-semibold'>Amount <span className='text-red-600 pr-1'>*</span></th>
                        <th id="GenerateInvoiceTableThqty" className='text-center pb-2 text-sm font-semibold'>Qty</th>
                        <th id="GenerateInvoiceTableThgst" className='text-center pb-2 text-sm font-semibold'>GST</th>
                        <th id="GenerateInvoiceTableThtotal" className='text-center pb-2 text-sm font-semibold'>Total</th>
                        <th id="GenerateInvoiceTableThaction" className='text-center pb-2 text-sm font-semibold'>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {rows.map((row, index) => (
                        <tr key={row.order_id} className='odd:bg-white even:bg-gray-100'>
                            <td className='text-center p-2 w-[5%]'>{index + 1}</td>
                            <td className='w-[20%]'>
                                <CreatableSelect
                                    styles={customStyles}
                                    placeholder="Select Tariff"
                                    // isClearable={true}
                                    isSearchable={true}
                                    options={tariffOptions}
                                    onChange={(evt) => handleSelectTariff(row.order_id, evt)}
                                    value={row.selectedTariff}
                                    onCreateOption={(evt) => handleCreate(row.order_id, evt)}
                                />
                            </td>
                            <td className='w-[15%]'>
                                <InvoiceItemDatePicker handleDateChange={handleDateChange} rowData={row} />
                            </td>
                            <td className='w-[10%]'>
                                <input type='number' id="GenerateInvoiceTableInputamount"
                                    className='h-8 rounded-md pl-1 w-full'
                                    name="amount"
                                    min={1}
                                    onBlur={(e) => {
                                        if (e.target.value === "" || e.target.value === "0" || parseFloat(e.target.value) < 0) {
                                            handleChange(row.order_id, e, parseFloat(0))
                                        }
                                    }}
                                    value={row.amount}
                                    onChange={(event) => handleChange(row.order_id, event, parseFloat(event.target.value))} />
                            </td>
                            <td className='w-[10%]'>
                                <input type='number' id="GenerateInvoiceTableInputquantity"
                                    className='h-8 rounded-md pl-1 w-full'
                                    name="quantity"
                                    min={1}
                                    onBlur={(e) => {
                                        if (e.target.value === "" || e.target.value === "0" || parseFloat(e.target.value) < 0) {
                                            handleChange(row.order_id, e, parseFloat(1))
                                        }
                                    }}
                                    value={row.quantity}
                                    onChange={(event) => handleChange(row.order_id, event, parseFloat(event.target.value))} />
                            </td>
                            <td className='w-[15%]'>
                                <input type='number' id="GenerateInvoiceTableInputgst"
                                    className='h-8 rounded-md pl-1 w-full'
                                    name="gst"
                                    min={0}
                                    onBlur={(e) => {
                                        if (e.target.value === "" || e.target.value === "0" || parseFloat(e.target.value) < 0) {
                                            handleChange(row.order_id, e, parseFloat(0))
                                        }
                                    }}
                                    value={row.gst}
                                    onChange={(event) => handleChange(row.order_id, event, parseFloat(event.target.value))} />
                            </td>
                            <td className='w-[15%] text-right'>
                                {isNaN(row.total) ? "0.00" : row.total.toFixed(2)}
                            </td>

                            <td className='text-center w-[10%]'>
                                <button id="GenerateInvoiceTableBtndeleteRow" onClick={() => deleteRow(row.order_id)} title='Delete Row'>
                                    <svg id="GenerateInvoiceTableBtndeleteRowIcon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6 text-red-500 hover:text-red-700">
                                        <path fillRule="evenodd" d="M16.5 4.478v.227a48.816 48.816 0 0 1 3.878.512.75.75 0 1 1-.256 1.478l-.209-.035-1.005 13.07a3 3 0 0 1-2.991 2.77H8.084a3 3 0 0 1-2.991-2.77L4.087 6.66l-.209.035a.75.75 0 0 1-.256-1.478A48.567 48.567 0 0 1 7.5 4.705v-.227c0-1.564 1.213-2.9 2.816-2.951a52.662 52.662 0 0 1 3.369 0c1.603.051 2.815 1.387 2.815 2.951Zm-6.136-1.452a51.196 51.196 0 0 1 3.273 0C14.39 3.05 15 3.684 15 4.478v.113a49.488 49.488 0 0 0-6 0v-.113c0-.794.609-1.428 1.364-1.452Zm-.355 5.945a.75.75 0 1 0-1.5.058l.347 9a.75.75 0 1 0 1.499-.058l-.346-9Zm5.48.058a.75.75 0 1 0-1.498-.058l-.347 9a.75.75 0 0 0 1.5.058l.345-9Z" clipRule="evenodd" />
                                    </svg>
                                </button>
                            </td>
                        </tr>
                    ))}
                    <tr className='border-gray-200 border-t-[1px]'>
                        <td colSpan={8} className='w-full text-right p-2'>
                            <button id="GenerateInvoiceTableBtnaddRow" title='Add Row' onClick={addRow}>
                                <span id="GenerateInvoiceTableSpanaddRow" className='flex justify-end text-blue-600 font-semibold hover:underline'>Add row <svg id="GenerateInvoiceTableSvgaddRowIcon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6 text-blue-600">
                                    <path fillRule="evenodd" d="M5.625 1.5H9a3.75 3.75 0 0 1 3.75 3.75v1.875c0 1.036.84 1.875 1.875 1.875H16.5a3.75 3.75 0 0 1 3.75 3.75v7.875c0 1.035-.84 1.875-1.875 1.875H5.625a1.875 1.875 0 0 1-1.875-1.875V3.375c0-1.036.84-1.875 1.875-1.875ZM12.75 12a.75.75 0 0 0-1.5 0v2.25H9a.75.75 0 0 0 0 1.5h2.25V18a.75.75 0 0 0 1.5 0v-2.25H15a.75.75 0 0 0 0-1.5h-2.25V12Z" clipRule="evenodd" />
                                    <path d="M14.25 5.25a5.23 5.23 0 0 0-1.279-3.434 9.768 9.768 0 0 1 6.963 6.963A5.23 5.23 0 0 0 16.5 7.5h-1.875a.375.375 0 0 1-.375-.375V5.25Z" />
                                </svg></span>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export default GenerateInvoiceTable