import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { setNotification } from '../../../../Redux/features/toast/toastSlice';
import store from '../../../../API/store';
import DataDecode from '../../../../utils/DataDecode';
import { setDocumentType, setIsCameraUpload, setRefreshUploadedFiles, setShowUploadPopup } from '../../../../Redux/features/uploadDoc/uploadDocSlice';
import FileIcon from '../FileIcon';
import DateFunction from '../../../../utils/DateFunctions';
import { Checkbox, Label } from "flowbite-react";
import { theme } from '../../../../EmraxisTheme/Theme';


const UploadedDataMobile = ({ appointment, clinicGuid, appointment_id, patient_id, page_src, setIsMessagePopupOpen}) => {
    const dispatch = useDispatch();

    var loggeduser = useSelector(state => state.login.loginuserData);
    var loggedusertoken = useSelector(state => state.login.userBarerToken);
    var refreshUploadedFiles = useSelector(state => state.document_upload.refreshUploadedFiles);
    var refreshNoAppointmentUploadedData = useSelector(state => state.document_upload.refreshNoAppointmentUploadedData);
    var documentType = useSelector(state => state.document_upload.documentType);
    const previewTheme = useSelector(state => state.theme.colors) || theme.filter(a => a.name === "defaultTheme")[0];

    const [drNotes, setDrNotes] = useState('');
    const [loading, setLoading] = useState(false);
    const [notesFileDeleteID, setNotesFileDeleteID] = useState(0);
    const [notesFileDeleteCnfBox, setNotesFileDeleteCnfBox] = useState(false);
    const [files, setFiles] = useState([]);
    const [allUploadedData, setAllUploadedData] = useState([]);
    const [loadingDeleteImage, setLoadingDeleteImage] = useState(false);
    const [dataLoading, setDataLoading] = useState(true);
    const [expandRow, setExpandRow] = useState(true);
    const [viewType, setViewType] = useState('grid');
    const [selectAll, setSelectAll] = useState(false);
    const [refreshImage, setRefreshImage] = useState(false);
    const [showAll, setShowAll] = useState(false);

    const GetAllUploadedData = () => {
        if (allUploadedData.length == 0) {
            setDataLoading(true);
        }
        store.GetAllUploadedData(loggedusertoken, DataDecode.encryptPayload({ 'clinic_guid': clinicGuid, 'patient_id': patient_id }))
            .then((result) => {
                setDataLoading(false);
                if (result.status == 200) {
                    result.json().then((res) => {
                        let response = DataDecode.decryptResponse(res);
                        setAllUploadedData(JSON.parse(response));
                    })
                } else {
                    setAllUploadedData([])
                }
            })
            .catch(err => {
                setDataLoading(false);
                setAllUploadedData([])
                dispatch(setNotification({ message: 'Error occurred', status: 'error', action: true }));
            })
    }

    useEffect(() => {
        if (page_src === "patient") {
            GetAllUploadedData();
        }
    }, [refreshNoAppointmentUploadedData])


    const GetPatientNotesFile = () => {
        store.GetPatientFiles(loggedusertoken, DataDecode.encryptPayload({ 'clinic_guid': clinicGuid, 'patient_id': patient_id, 'appointment_id': appointment_id }))
            .then((result) => {
                if (result.status == 200) {
                    result.json().then((res) => {
                        let response = DataDecode.decryptResponse(res);
                        setFiles(JSON.parse(response));
                    })
                } else {
                    setFiles([])
                }
            })
            .catch(err => {
                setFiles([])
                dispatch(setNotification({ message: 'Error occurred', status: 'error', action: true }));
            })
    }

    useEffect(() => {
        GetPatientNotesFile();
    }, [refreshUploadedFiles])


    useEffect(() => {
        setDrNotes(appointment && appointment?.radiology_notes ? appointment?.radiology_notes : '')
    }, [appointment])


    // const deleteNotesFile = () => {
    //     setLoadingDeleteImage(true);
    //     store.DeletePatientFile(loggedusertoken, DataDecode.encryptPayload({ 'clinic_guid': clinicGuid, 'patient_id': patient_id, files: notesFileDeleteID }))
    //         .then((result) => {
    //             setLoadingDeleteImage(false)
    //             setNotesFileDeleteCnfBox(false)
    //             if (result.status == 204) {
    //                 dispatch(setRefreshUploadedFiles(!refreshUploadedFiles))
    //                 dispatch(setNotification({ message: 'Deleted Successfully!', status: 'success', action: true }));
    //             }
    //             else {
    //                 dispatch(setNotification({ message: 'Error Occurred!', status: 'error', action: true }));
    //             }

    //         })
    //         .catch((error) => {
    //             dispatch(setNotification({ message: 'Error occurred', status: 'error', action: true }));
    //         });
    // }
    const handleDeleteSubmit = () => {
        setLoadingDeleteImage(true);
        store.DeletePatientFile(loggedusertoken, DataDecode.encryptPayload({ 'clinic_guid': loggeduser.facilityID, 'patient_id': patient_id, files: files }))
            .then((result) => {
                setLoadingDeleteImage(false)
                setNotesFileDeleteCnfBox(false)
                if (result.status == 204) {
                    setTimeout(() => {
                        dispatch(setRefreshUploadedFiles(!refreshUploadedFiles))
                    }, 100)
                    dispatch(setNotification({ message: 'Deleted Successfully!', status: 'success', action: true }));
                }
                else {
                    dispatch(setNotification({ message: 'Error Occurred!', status: 'error', action: true }));
                }

            })
            .catch((error) => {
                console.error("Error occurred while saving patient details:", error);
            });
    }

    const handleSvgClick = () => {
        setExpandRow(!expandRow)
    }

    const handleDelete = () => {
        const atLeastOneSelected = files.some(item => !item.is_active);
        if (atLeastOneSelected) {
            setNotesFileDeleteCnfBox(true)
        } else {
            dispatch(setNotification({ message: 'Please select at least one Image to delete!', status: 'error', action: true }));
        }
    }
    const handleSelectAll = (e) => {
        setFiles(files.map(item => ({ ...item, is_active: !e.target.checked })));
    }
    useEffect(() => {
        const allSelected = files.every(item => !item.is_active);
        setSelectAll(allSelected && files.length > 0);
    }, [files]);
    const handleChange = (id) => {
        setFiles(files.map(item =>
            item.patient_files_id === id ? { ...item, is_active: !item.is_active } : item
        ));
    }

    const handleUploadButton = (type) => {
        dispatch(setDocumentType({ ...documentType, others: true }))
        dispatch(setIsCameraUpload(type === "capture" ? true : false));
        if (appointment_id == 0 || (appointment && (appointment.status == 'Completed'))) {
            setIsMessagePopupOpen(p => !p);
        } else {
            dispatch(setShowUploadPopup(true))
        }
    }

    return (
        <>
            {notesFileDeleteCnfBox &&
                <div id="delete" tabIndex="-1" aria-hidden="true" className="fixed z-[9999] lg:left-[40%] lg:w-80 top-[10rem] lg:top-[3rem] lg:translate-x-[50%] translate-y-[50%]">

                    <div className="relative p-4 w-full max-w-md h-full md:h-auto">
                        <div className="relative p-4 text-center bg-white border-gray-400 border-[1px] rounded-lg shadow-lg dark:bg-gray-800 sm:p-5">
                            <svg className="text-red-600 dark:text-gray-500 w-11 h-11 mb-3.5 mx-auto" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clipRule="evenodd"></path></svg>
                            <p className="mr-2 py-2 px-3 text-sm font-medium text-gray-500 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-primary-300 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600">Are you sure you want to delete this Images?</p>
                            <div className="flex justify-center items-center space-x-4">
                                <button id='BtnUploadDeleteCancelButton' onClick={() => setNotesFileDeleteCnfBox(false)} disabled={loadingDeleteImage} type="button" className={`py-2 px-3 text-sm font-medium rounded-lg border border-gray-200  focus:ring-4 focus:outline-none focus:ring-primary-300  focus:z-10 ${previewTheme.cancelBtnColor} ${previewTheme.cancelBtnBgColor} hover:${previewTheme.cancelBtnHoverColor}`}>
                                    No, cancel
                                </button>
                                <button id='BtnUploadDeleteSubmitButton' type="button" onClick={handleDeleteSubmit} disabled={loadingDeleteImage} className={`py-2 px-3 text-sm font-medium text-center rounded-lg focus:ring-4 focus:outline-none disabled:opacity-50 disabled:cursor-not-allowed ${previewTheme.saveBtnColor} ${previewTheme.saveBtnBgColor} hover:${previewTheme.saveBtnHoverColor}`}>
                                    Yes, I'm sure {loadingDeleteImage &&
                                        <svg aria-hidden="true" role="status" className="inline w-4 h-4 ml-2 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB" />
                                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor" />
                                        </svg>
                                    }
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            }

            <div className='shadow-md rounded-lg'>
                <div className='flex lg:w-[100%] bg-slate-200 justify-between text-lg rounded-t-lg font-semibold px-2'>
                    <div className='flex items-center text-[#a31b1b]' id='BtnExpandUpload' onClick={handleSvgClick}>
                        <span>Uploadad Data</span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" className=''>
                            <path d="M5.8335 8.33289L10.0002 12.4996L14.1668 8.33289H5.8335Z" fill="#a31b1b" />
                        </svg>
                    </div>
                    <div className='flex items-center'>
                        <button className={`p-2 ${viewType === 'list' ? 'bg-gray-300' : ''}`} onClick={() => setViewType('list')} id='BtnListViewButton'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M20 7H4M15 12H4M9 17H4" stroke={viewType === 'list' ? '#A31B1B' : '#666666'} strokeWidth="1.5" strokeLinecap="round" />
                            </svg>
                        </button>
                        <button className={`p-2 ${viewType === 'grid' ? 'bg-gray-300' : ''}`} onClick={() => setViewType('grid')} id='BtnGridViewButton'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill={viewType === 'grid' ? '#A31B1B' : '#666666'}>
                                <g clipPath="url(#clip0_1767_3218)">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M9 13C9.53043 13 10.0391 13.2107 10.4142 13.5858C10.7893 13.9609 11 14.4696 11 15V19C11 19.5304 10.7893 20.0391 10.4142 20.4142C10.0391 20.7893 9.53043 21 9 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V15C3 14.4696 3.21071 13.9609 3.58579 13.5858C3.96086 13.2107 4.46957 13 5 13H9ZM19 13C19.5304 13 20.0391 13.2107 20.4142 13.5858C20.7893 13.9609 21 14.4696 21 15V19C21 19.5304 20.7893 20.0391 20.4142 20.4142C20.0391 20.7893 19.5304 21 19 21H15C14.4696 21 13.9609 20.7893 13.5858 20.4142C13.2107 20.0391 13 19.5304 13 19V15C13 14.4696 13.2107 13.9609 13.5858 13.5858C13.9609 13.2107 14.4696 13 15 13H19ZM9 3C9.53043 3 10.0391 3.21071 10.4142 3.58579C10.7893 3.96086 11 4.46957 11 5V9C11 9.53043 10.7893 10.0391 10.4142 10.4142C10.0391 10.7893 9.53043 11 9 11H5C4.46957 11 3.96086 10.7893 3.58579 10.4142C3.21071 10.0391 3 9.53043 3 9V5C3 4.46957 3.21071 3.96086 3.58579 3.58579C3.96086 3.21071 4.46957 3 5 3H9ZM19 3C19.5304 3 20.0391 3.21071 20.4142 3.58579C20.7893 3.96086 21 4.46957 21 5V9C21 9.53043 20.7893 10.0391 20.4142 10.4142C20.0391 10.7893 19.5304 11 19 11H15C14.4696 11 13.9609 10.7893 13.5858 10.4142C13.2107 10.0391 13 9.53043 13 9V5C13 4.46957 13.2107 3.96086 13.5858 3.58579C13.9609 3.21071 14.4696 3 15 3H19Z" fill={viewType === 'grid' ? '#A31B1B' : '#666666'} />
                                </g>
                                <defs>
                                    <clipPath id="clip0_1767_3218">
                                        <rect width="24" height="24" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>
                        </button>
                        {page_src === "appointment" ? (
                            <>
                                <button className='p-2' onClick={() => handleDelete()} id='BtnDeleteButton'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 17 18" fill="none">
                                        <path d="M16.15 2.99999H13.515C13.1083 1.25518 11.3688 0.00225 9.34997 0H7.64996C5.63112 0.00225 3.89162 1.25518 3.48497 2.99999H0.849987C0.380548 2.99999 0 3.33577 0 3.74998C0 4.16419 0.380548 4.5 0.849987 4.5H1.69997V14.25C1.7028 16.32 3.60395 17.9975 5.94999 18H11.05C13.396 17.9975 15.2972 16.32 15.3 14.25V4.5H16.15C16.6194 4.5 17 4.16422 17 3.75001C17 3.3358 16.6194 2.99999 16.15 2.99999ZM7.65 12.75C7.65 13.1642 7.26945 13.5 6.80001 13.5C6.33053 13.5 5.94999 13.1642 5.94999 12.75V8.25001C5.94999 7.8358 6.33053 7.50002 6.79997 7.50002C7.26941 7.50002 7.64996 7.8358 7.64996 8.25001V12.75H7.65ZM11.05 12.75C11.05 13.1642 10.6694 13.5 10.2 13.5C9.73056 13.5 9.35001 13.1642 9.35001 12.75V8.25001C9.35001 7.8358 9.73056 7.50002 10.2 7.50002C10.6694 7.50002 11.05 7.8358 11.05 8.25001V12.75ZM5.24535 2.99999C5.60689 2.10171 6.56968 1.50114 7.65 1.49998H9.35001C10.4303 1.50114 11.3931 2.10171 11.7547 2.99999H5.24535Z" fill="#A31B1B" fillOpacity="0.91" />
                                    </svg>
                                </button>
                                <div className='flex items-center' id='BtnSelectButton'>
                                    <Label htmlFor="select-all" className='text-gray-400'></Label>
                                    <Checkbox
                                        id="select-all"
                                        checked={selectAll}
                                        title="Select all"
                                        className='p-2'
                                        onChange={(e) => handleSelectAll(e)}
                                    />
                                </div>
                            </>
                        ) : (
                            <></>
                        )}
                        <svg xmlns="http://www.w3.org/2000/svg" id='MobBtnUploadUploadedData' onClick={() => handleUploadButton('upload')} fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" className={`size-5 mx-2 inline cursor-pointer text-red-700`}>
                            <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5m-13.5-9L12 3m0 0 4.5 4.5M12 3v13.5" />
                        </svg>
                        <svg xmlns="http://www.w3.org/2000/svg" id='MobBtnCaptureUploadedData' onClick={() => handleUploadButton('capture')} fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" className={`inline size-5 cursor-pointer text-red-700`}>
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6.827 6.175A2.31 2.31 0 0 1 5.186 7.23c-.38.054-.757.112-1.134.175C2.999 7.58 2.25 8.507 2.25 9.574V18a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 18V9.574c0-1.067-.75-1.994-1.802-2.169a47.865 47.865 0 0 0-1.134-.175 2.31 2.31 0 0 1-1.64-1.055l-.822-1.316a2.192 2.192 0 0 0-1.736-1.039 48.774 48.774 0 0 0-5.232 0 2.192 2.192 0 0 0-1.736 1.039l-.821 1.316Z" />
                            <path strokeLinecap="round" strokeLinejoin="round" d="M16.5 12.75a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0ZM18.75 10.5h.008v.008h-.008V10.5Z" />
                        </svg>
                    </div>
                </div>
                {expandRow && (
                    <>
                        {page_src === "appointment" ?
                            <div className="w-ful">
                                {/* appointments to patient profile */}
                                {viewType === 'grid' && (
                                    <>
                                        {appointment_id > 0 && files.filter(a => a.file_type === "Others").length > 0 &&
                                            <>
                                                <div className="px-4 py-2 bg-white rounded-t-lg dark:bg-gray-800 min-h-36" id='AppGridView'>
                                                    <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-4 gap-2 p-2">
                                                        {
                                                            files.filter(a => a.file_type === "Others").map((file, index) => {
                                                                const fileExtension = file.file_path.split('.').pop().toLowerCase();
                                                                const isImage = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp'].includes(fileExtension);
                                                                const isAudio = ['mp3', 'wav', 'ogg', 'm4a'].includes(fileExtension);
                                                                const isVideo = ['mp4', 'webm', 'ogg', 'mov'].includes(fileExtension);

                                                                return (
                                                                    <div className="relative p-2 border rounded-sm" key={'image-notes-' + index}>
                                                                        <a rel="noopener" target="_blank" href={process.env.REACT_APP_EMRAXIS_S3_URL + "/" + file.file_path} className=''>
                                                                            {isImage ? (
                                                                                <img
                                                                                    className='max-h-[180px] w-auto h-auto object-contain p-5'
                                                                                    src={process.env.REACT_APP_EMRAXIS_S3_URL + "/" + file.file_path}
                                                                                    alt="Prescription"
                                                                                />
                                                                            ) : isAudio ? (
                                                                                <div className="flex justify-center items-center h-full">  <audio className="w-full max-w-[400px]" controls>
                                                                                    <source src={process.env.REACT_APP_EMRAXIS_S3_URL + "/" + file.file_path} type={`audio/${fileExtension}`} />
                                                                                    Your browser does not support the audio element.
                                                                                </audio></div>
                                                                            ) : isVideo ? (
                                                                                <video className="max-h-[180px] w-auto h-auto object-contain p-5" controls>
                                                                                    <source src={process.env.REACT_APP_EMRAXIS_S3_URL + "/" + file.file_path} type={`video/${fileExtension}`} />
                                                                                    Your browser does not support the video element.
                                                                                </video>
                                                                            ) : (
                                                                                <div className="flex justify-center items-center h-full">
                                                                                    <FileIcon
                                                                                        ext={"." + fileExtension}
                                                                                        className='max-h-[180px] w-auto h-auto object-contain p-5  '
                                                                                    />
                                                                                </div>
                                                                            )}
                                                                        </a>
                                                                        <button
                                                                            id={'btnDeleteUploadedData' + index}
                                                                            className="absolute top-2 left-2"
                                                                        // onClick={() => { setNotesFileDeleteCnfBox(true); setNotesFileDeleteID([{ ...file, is_active: false }]); }}
                                                                        >
                                                                            <input
                                                                                aria-label="checkBox"
                                                                                checked={!file.is_active}
                                                                                type="checkbox"
                                                                                className="ml-2 cursor-pointer"
                                                                                onChange={() => handleChange(file.patient_files_id)}
                                                                            />
                                                                        </button>
                                                                    </div>
                                                                );
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            </>
                                        }
                                    </>
                                )}
                                {viewType === 'list' && (
                                    <div id='AppListView'>
                                        {files.filter(a => a.file_type === "Others").length > 0 && (
                                            <table className="min-w-full border">
                                                <thead>
                                                    <tr className='border bg-slate-200'>
                                                        <th className="px-4 py-2 text-left"></th>
                                                        <th className="px-4 py-2 text-left text-red-700">Visit Details</th>
                                                        <th className="px-4 py-2 text-left text-red-700">Format</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {files.filter(a => a.file_type === "Others").map((file) => {
                                                        return (
                                                            <tr key={file.patient_files_id} className="border-b odd:bg-white even:bg-gray-100">
                                                                <td className="px-4 py-2 text-left">
                                                                    <input
                                                                        type="checkbox"
                                                                        checked={!file.is_active}
                                                                        onChange={() => handleChange(file.patient_files_id)}
                                                                    />

                                                                </td>
                                                                <td className="px-4 py-2 text-left">
                                                                    {/* <span>{file.file_type},</span> */}
                                                                    <span>{DateFunction.GetDate(file.created_on)}</span>
                                                                </td>
                                                                <td className="px-4 py-2 text-left">{file.file_path.split('.').pop().toUpperCase()}</td>
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            </table>
                                        )}
                                    </div>
                                )}
                            </div>
                            :

                            <>
                                {/* patients to patient profile */}
                                {viewType === 'grid' && (
                                    <>
                                        {allUploadedData.length > 0 ?
                                            <div className="" id='PatGridView'>
                                                {allUploadedData.slice(0, showAll ? undefined : 3).map((data, index) => (
                                                    <div className='border border-gray-200 p-1' key={'all-notes-' + index}>
                                                        <div className='bg-red-300 h-6 ring-2 ring-red-400 rounded-sm'>
                                                            <p className='text-center font-semibold text-blue-900'><span className='px-1'>{DateFunction.GetDate(data.date)}</span></p>
                                                        </div>

                                                        <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-4 gap-2 p-2">
                                                            {data?.files?.map((file, index) => {
                                                                const fileExtension = file.file_path.split('.').pop().toLowerCase();
                                                                const isImage = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp'].includes(fileExtension);
                                                                const isAudio = ['mp3', 'wav', 'ogg', 'm4a'].includes(fileExtension);
                                                                const isVideo = ['mp4', 'webm', 'ogg', 'mov'].includes(fileExtension);

                                                                return (
                                                                    <div className="relative p-2 border rounded-sm" key={'image-notes-' + index}>

                                                                        <a rel="noopener" target="_blank" href={process.env.REACT_APP_EMRAXIS_S3_URL + "/" + file.file_path} className=''>
                                                                            {isImage ? (
                                                                                <img
                                                                                    id={"imgUploadedDataFile" + index}
                                                                                    className='max-h-[180px] w-auto h-auto object-contain p-5'
                                                                                    src={process.env.REACT_APP_EMRAXIS_S3_URL + "/" + file.file_path}
                                                                                    alt="Uploaded Data"
                                                                                />
                                                                            ) : isAudio ? (
                                                                                <div className="flex justify-center items-center h-full">  <audio id={"audioUploadedDataFile" + index} className="w-full max-w-[400px]" controls>
                                                                                    <source src={process.env.REACT_APP_EMRAXIS_S3_URL + "/" + file.file_path} type={`audio/${fileExtension}`} />
                                                                                    Your browser does not support the audio element.
                                                                                </audio></div>
                                                                            ) : isVideo ? (
                                                                                <video id={"videoUploadedDataFile" + index} className="max-h-[180px] w-auto h-auto object-contain p-5" controls>
                                                                                    <source src={process.env.REACT_APP_EMRAXIS_S3_URL + "/" + file.file_path} type={`video/${fileExtension}`} />
                                                                                    Your browser does not support the video element.
                                                                                </video>
                                                                            ) : (
                                                                                <div className="flex justify-center items-center h-full">
                                                                                    <FileIcon
                                                                                        id={"fileUploadedDataFile" + index}
                                                                                        ext={"." + fileExtension}
                                                                                        className='max-h-[180px] w-auto h-auto object-contain p-5  '
                                                                                    />
                                                                                </div>
                                                                            )}
                                                                            <div className='absolute bottom-2 mx-auto w-full'>
                                                                                <p id="labelUploadedFileType" className='text-center p-2 h-6'>{file.file_type !== "Others" && (file.file_type === "Notes" ? "Doctor Notes" : file.file_type)}</p>
                                                                            </div>
                                                                        </a>
                                                                    </div>
                                                                );
                                                            })
                                                            }
                                                        </div>
                                                    </div>
                                                ))}
                                                <div className='flex justify-end'>
                                                    {allUploadedData.length > 3 && (
                                                        <button id='BtnUploadshowAll'
                                                            className="p-2 text-red-600"
                                                            onClick={() => setShowAll(!showAll)}
                                                        >
                                                            {showAll ? "View Less" : "View More"}
                                                        </button>
                                                    )}
                                                </div>
                                            </div>
                                            :
                                            <>
                                                {dataLoading &&
                                                    <p className='text-center p-5'>
                                                        <svg id="imgUploadedDataLoading" aria-hidden="true" role="status" className="size-6 ml-2 text-red-600 animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB" />
                                                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor" />
                                                        </svg>
                                                    </p>
                                                }
                                            </>
                                        }
                                    </>
                                )}
                                {viewType === 'list' && (
                                    <div className='p-2' id='PatListView'>
                                        {allUploadedData.length > 0 ?
                                         <>
                                            <table className="min-w-full bg-white border text-sm">
                                                <thead>
                                                    <tr className='border bg-slate-200'>
                                                        <th className="px-2 py-1 text-left text-red-700">Visit Details</th>
                                                        <th className="px-2 py-1 text-left text-red-700">Document Type</th>
                                                        <th className="px-2 py-1 text-left text-red-700">Format</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {allUploadedData.slice(0, showAll ? undefined : 3).map((data) =>
                                                        data?.files?.map((file, index) => {
                                                            const handleDownload = () => {
                                                                const link = document.createElement('a');
                                                                link.href = process.env.REACT_APP_EMRAXIS_S3_URL + "/" + file.file_path;
                                                                link.download = file;
                                                                document.body.appendChild(link);
                                                                link.click();
                                                                document.body.removeChild(link);
                                                            };
                                                            return (
                                                                <tr key={'all-notes-' + index} className="border-b odd:bg-white even:bg-gray-100" onClick={handleDownload}>
                                                                    <td className=" px-2 py-1 text-left">
                                                                        {DateFunction.GetDate(data.date)}
                                                                    </td>
                                                                    <td className=" px-2 py-1 text-left">
                                                                        {file.file_type !== "Others" && (file.file_type === "Notes" ? "Doctor Notes" : file.file_type)}
                                                                    </td>
                                                                    <td className="px-2 py-1 text-left">{file.file_path.split('.').pop().toUpperCase()}</td>
                                                                </tr>
                                                            );
                                                        })
                                                    )}
                                                </tbody>
                                            </table>
                                            <div className='flex justify-end'>
                                                        {allUploadedData.length > 3 && (
                                                            <button id='BtnUploadshowAllList'
                                                                className="p-2 text-red-600"
                                                                onClick={() => setShowAll(!showAll)}
                                                            >
                                                                {showAll ? "View Less" : "View More"}
                                                            </button>
                                                        )}
                                                    </div>
                                            </>
                                            :
                                            <>
                                                {dataLoading &&
                                                    <p className='text-center p-5'>
                                                        <svg aria-hidden="true" role="status" className="size-6 ml-2 text-red-600 animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB" />
                                                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor" />
                                                        </svg>
                                                    </p>                                                    
                                                }
                                            </>
                                        }
                                    </div>
                                )}
                            </>
                        }
                    </>
                )}
            </div>
        </>
    )
}

export default UploadedDataMobile