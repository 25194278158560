import React, { useEffect, useState, useRef } from 'react';
import AddDoctor from './AddDoctor';
import * as XLSX from 'xlsx';
import store from '../../API/store';
import { useDispatch, useSelector } from 'react-redux';
import DataDecode from '../../utils/DataDecode';
import { setNotification } from '../../Redux/features/toast/toastSlice';
import DateFunction from '../../utils/DateFunctions';
import { Spinner } from "../../components/Spinner";
import InfiniteScroll from 'react-infinite-scroll-component';
import { theme } from '../../EmraxisTheme/Theme';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';


const Doctors = () => {
  const dispatch = useDispatch();
  const [showMenu, setShowMenu] = useState(false);
  const [selectedSort, setSelectedSort] = useState('Newest');

  const [showPopup, setShowPopup] = useState(false);
  const [doctors, setDoctors] = useState([]);
  const [selectedDoctor, setSelectedDoctor] = useState(null);
  const [doctorName, setDoctorName] = useState('Dr. Samantha');
  const [doctorDepartment, setDoctorDepartment] = useState('urology');
  const [editing, setEditing] = useState(false);
  const [tempName, setTempName] = useState('');
  const [tempDepartment, setTempDepartment] = useState('');
  const [refreshData, setRefreshData] = useState(false);
  const [confirmBox, setConfirmBox] = useState(false);
  const [deleteId, setDeleteId] = useState(0);
  const [disableButton, setDisableButton] = useState(false);
  const [departments, setDepartments] = useState([]);
  const [totalpages, settotalpages] = useState(0);
  const [selectedpage, setselectedpage] = useState(1);
  const [query, setQuery] = useState('');
  const [hasMore, setHasMore] = useState(true);
  const [openMenuIndex, setOpenMenuIndex] = useState(null);
  const [showSearchBar, setShowSearchBar] = useState(false);
  const menuRef = useRef();

  var loggeduser = useSelector(state => state.login.loginuserData);
  var loggedusertoken = useSelector(state => state.login.userBarerToken);
  const previewTheme = useSelector(state => state.theme.colors) || theme.defaultTheme;
  const toggleSearchBar = () => {
    setShowSearchBar(!showSearchBar);
  };
  const closeSearch = () => {
    setShowSearchBar(false);
  };
  useEffect(() => {
    if (loggeduser) {
      GetDepartments(loggeduser.facilityID);
    }
  }, [loggeduser])

  const GetDepartments = (guid) => {
    store.GetDepartmentForDoctor(loggedusertoken, DataDecode.encryptPayload({ 'clinic_guid': guid }))
      .then((result) => {
        if (result.status == 200) {
          result.json().then((res) => {
            let response = DataDecode.decryptResponse(res);
            let data = JSON.parse(response)
            var depRes = data?.map(function (val) {
              return {
                value: val.department_id,
                label: val.department_name

              }

            });
            setDepartments(depRes);
          })
        } else {
          setDepartments([])
        }
      })
      .catch(err => {
        dispatch(setNotification({ message: 'Error Occurred!', status: 'error', action: true }));
      })
  }

  const fetchDoctorData = () => {
    try {
      store.GetAllDoctors(loggedusertoken, DataDecode.encryptPayload({ 'clinic_guid': loggeduser.facilityID, page: selectedpage }))
        .then((result) => {
          if (result.status === 200) {
            result.json().then((res) => {
              let response = DataDecode.decryptResponse(res);
              var myObject = JSON.parse(response);
              if (selectedpage == 1) {
                setDoctors(myObject.doctors);
                settotalpages(Math.ceil(myObject.totaldoctors / 10))

              }
              else {
                setDoctors(prevItems => [...prevItems, ...myObject.doctors]);
                settotalpages(Math.ceil(myObject.totaldoctors / 10))


              }

            });
          } else {
            setDoctors([]);
            settotalpages(0);
            setselectedpage(1)
          }
        });
    } catch (err) {
      dispatch(setNotification({ message: 'Error Occurred!', status: 'error', action: true }));
    }
  };


  const searchdoctor = () => {
    store.searchDoctor(loggedusertoken, DataDecode.encryptPayload({ 'search_string': query, 'clinic_guid': loggeduser.facilityID, 'page': selectedpage }))
      .then((result) => {
        if (result.status == 200) {
          result.json().then((res) => {
            let response = DataDecode.decryptResponse(res);
            var myObject = JSON.parse(response);
            if (selectedpage == 1) {
              setDoctors(myObject.doctors);
              settotalpages(Math.ceil(myObject.totaldoctors / 10))

            }
            else {
              setDoctors(prevItems => [...prevItems, ...myObject.doctors]);
              settotalpages(Math.ceil(myObject.totaldoctors / 10))


            }

          })
        }
        else {
          setDoctors([]);
          settotalpages(0);
          setselectedpage(1)
        }
      })
  }
  useEffect(() => {
    if (loggeduser != null && loggeduser.length != 0) {
      if (query == '')
        fetchDoctorData();
      else
        searchdoctor();
    }
  }, [refreshData, selectedpage, query]);

  const handleChangeQuery = (event) => {

    const querys = event.target.value;
    if (querys.trim() != '')
      setQuery(querys.trim());
    else
      setQuery('');


  }
  useEffect(() => {
    if (query == '') {
      settotalpages(0);
      setselectedpage(1)
      setHasMore(true)
    }
  }, [query])
  const openPopup = () => {
    setShowPopup(true);
    setSelectedDoctor(null);
  };

  useEffect(() => {
    if (showPopup) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [showPopup]);

  const closePopup = () => {
    setShowPopup(false);
  };
  const toggleMenu = (index) => {
    setOpenMenuIndex(openMenuIndex === index ? null : index);
  };
  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setOpenMenuIndex(null);
    }
  };


  // const handleExport = () => {
  //   const worksheet = XLSX.utils.json_to_sheet(doctors);
  //   const workbook = XLSX.utils.book_new();
  //   XLSX.utils.book_append_sheet(workbook, worksheet, 'doctor');
  //   XLSX.writeFile(workbook, 'doctor.xlsx');
  // };

  const pageselected = () => {

    totalpages >= (selectedpage + 1) ? setHasMore(true) : setHasMore(false)
    totalpages > selectedpage && setselectedpage(prevPage => prevPage + 1)
    /* setselectedpage(page); */
  }

  const handleEditClick = (doctor) => {
    setShowPopup(true);
    setSelectedDoctor(doctor);

  };

  const handleRowClick = (doctor) => {
    setSelectedDoctor(doctor);
  };

  const deleteDoctor = (doctor_id) => {
    setDeleteId(doctor_id)
    setConfirmBox(true);
  }
  const openDoctorDetails = (doctor) => {
    let data = btoa(JSON.stringify({ doctor_id: doctor.doctor_id }));
    window.location.href = `/DoctorProfile?profile=${data}`
    // window.open(`/DoctorProfile?profile=${data}`, '_blank');
  };
  const handleDeleteSubmit = () => {
    setDisableButton(true);
    store.deleteDoctor(loggedusertoken, DataDecode.encryptPayload({ 'clinic_guid': loggeduser.facilityID, 'doctor_id': deleteId }))
      .then((result) => {
        if (result.status == 204) {
          setRefreshData(r => !r);
          dispatch(setNotification({ message: 'Doctor Deleted Successfully!', status: 'success', action: true }));
        }
        else if (result.status == 409) {
          dispatch(setNotification({ message: 'Data available for the selected doctor, hence the doctor cannot be deleted!', status: 'info', action: true }));
        }
        else {
          dispatch(setNotification({ message: 'Error Occurred!', status: 'error', action: true }));
        }
        setDisableButton(false);
        setConfirmBox(false);
      })
      .catch(err => {
        dispatch(setNotification({ message: 'Error Occurred!', status: 'error', action: true }));

      })
  }


  return (
    <>
      {confirmBox &&
        <div id="DoctorsDivconfirmBoxAppointment" tabIndex="-1" aria-hidden="true" className="fixed left-1/2 top-1/2 w-full max-w-sm transform  -translate-x-1/2 -translate-y-1/2 sm:w-80 sm:left-[40%]  sm:top-[3rem] sm:translate-x-[50%] sm:translate-y-[50%] ">
          <div id="DoctorsDivconfirmBoxAppointmentContentDialog" className="relative p-4 w-full max-w-md h-full md:h-auto">
            <div id="DoctorsDivconfirmBoxContentDialogBox" className="relative p-4 text-center bg-white border-gray-400 border-[1px] rounded-lg shadow-lg dark:bg-gray-800 sm:p-5">
              <svg id="DoctorsSvgconfirmBoxIcon" className="text-red-800 dark:text-gray-500 w-11 h-11 mb-3.5 mx-auto" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clipRule="evenodd"></path></svg>
              <p id="DoctorsPconfirmBoxMessage" className={`mb-4 `} style={{ fontFamily: previewTheme.fontFamily }}>Are you sure you want to delete this doctor?</p>
              <div id="DoctorsDivconfirmBoxButtons" className="flex justify-center items-center space-x-4">
                <button
                  id="DoctorsButtonconfirmBoxcancel"
                  onClick={() => setConfirmBox(false)}
                  disabled={disableButton}
                  type="button"
                  className={`py-2 px-3 text-sm font-medium rounded-lg border focus:ring-4 focus:outline-none focus:ring-primary-300 focus:z-10  ease-in-out
                  ${previewTheme.cancelBtnColor} 
                  ${previewTheme.cancelBtnBgColor} 
                  ${disableButton ? "opacity-50 cursor-not-allowed" : `hover:${previewTheme.cancelBtnHoverColor}`}
                  `}
                >
                  No, cancel
                </button>



                <button id="DoctorsButtonconfirmBoxconfirm" type="button" onClick={handleDeleteSubmit} disabled={disableButton} className={`py-2 px-3 text-sm font-medium rounded-lg border focus:ring-4 focus:outline-none focus:ring-primary-300 focus:z-10  ease-in-out
                      ${previewTheme.saveBtnColor} 
                      ${previewTheme.saveBtnBgColor} 
                      ${disableButton ? "opacity-50 cursor-not-allowed" : `hover:${previewTheme.saveBtnHoverColor}`}`}
                >
                  Yes, I'm sure {disableButton &&
                    <svg id="DoctorsSvgconfirmBoxIcon" aria-hidden="true" role="status" className="inline w-4 h-4 ml-2 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB" />
                      <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor" />
                    </svg>
                  }
                </button>
              </div>
            </div>
          </div>
        </div>
      }

      <div id="DoctorsDivmainContainer" className=" mx-auto px-4 py-2 my-20 w-100%">
        <header id="DoctorsHeadercontainer" className="flex items-center justify-between">
          <header id="DoctorsHeadertitle" className={` flex font-bold mb-2 text-2xl lg:text-2xl lg:hidden sm:text-lg pl-2.5 pt-1 ${previewTheme.headingTitleColor}`} style={{ fontFamily: previewTheme.fontFamily }}>Doctor Data</header>
          <div id="DoctorsDivheaderButtons" className="lg:hidden flex items-center ml-auto">
            <button id="DoctorsBtnopenPopup" className="sm:flex md:flex sm:top-section " onClick={openPopup}>
              <svg id="DoctorsSvgopenPopupIcon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-10 h-10 fill-red-800">
                <path fillRule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25ZM12.75 9a.75.75 0 0 0-1.5 0v2.25H9a.75.75 0 0 0 0 1.5h2.25V15a.75.75 0 0 0 1.5 0v-2.25H15a.75.75 0 0 0 0-1.5h-2.25V9Z" clipRule="evenodd" />
              </svg>
            </button>
            <button id="DoctorsBtntoggleSearch" className="sm:flex md:flex sm:top-section" onClick={toggleSearchBar}>
              <svg id="DoctorsSvgtoggleSearchIcon" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-8 h-8 text-red-800">
                <path strokeLinecap="round" strokeLinejoin="round" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" />
              </svg>
            </button>
          </div>

        </header>
        <div>
          {showSearchBar && (
            <div id="DoctorsDivsearchBarContainer" className="lg:hidden right-0 w-full flex items-center">
              <div id="DoctorsDivsearchBar" className="relative w-full flex items-center">
                <input id="DoctorsInputsearchBarMobile" type="text" className="w-full pl-2 pr-10 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none" placeholder="Search Id,Name,Phone" onChange={handleChangeQuery} />
                <button id="DoctorsBtncloseSearch" onClick={closeSearch} className="absolute right-2">
                  <svg id="DoctorsSvgcloseSearchIcon" xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-gray-500" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M6.293 6.293a1 1 0 0 1 1.414 0L10 8.586l2.293-2.293a1 1 0 1 1 1.414 1.414L11.414 10l2.293 2.293a1 1 0 0 1-1.414 1.414L10 11.414l-2.293 2.293a1 1 0 0 1-1.414-1.414L8.586 10 6.293 7.707a1 1 0 0 1 0-1.414z" clipRule="evenodd" />
                  </svg>
                </button>
              </div>
            </div>
          )}
        </div>
        <h1 id="DoctorsH1doctorDataHeader" className=" mb-4 px-2 lg:mt-0">

          <div id="DoctorsDivdoctorDataContainer" className="flex justify-between items-center">
            <header id="DoctorsHeaderdoctorDataTitle" className={`hidden lg:flex font-bold mb-2 text-2xl lg:text-2xl  sm:text-lg pl-2.5 pt-1 ${previewTheme.headingTitleColor}`} style={{ fontFamily: previewTheme.fontFamily }}>Doctor Data</header>
            <div id="DoctorsDivheaderActionButtons" className=' flex justify-between items-center flex-row'>
              <div id="DoctorsDivsearchInputContainer" className="hidden lg:block relative items-center gap-2 mx-4">
                <input id="DoctorsInputsearchDesktop" type="text" className="block pl-10 pr-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none" placeholder="Search Id,Name,Phone" onChange={handleChangeQuery} />
                <svg id="DoctorsInputsearchIconDesktop" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 absolute left-3 top-1/2 transform -translate-y-1/2 text-red-800">
                  <path strokeLinecap="round" strokeLinejoin="round" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" />
                </svg>
              </div>
              <div id="DoctorsDivaddDoctorButtonContainer" className="hidden items-center lg:flex flex-row mx-4">

                <button id="DoctorsBtnaddDoctor" className={`py-2 px-4 rounded-md flex items-center ${previewTheme.addBtnColor} ${previewTheme.addBtnBgColor} transition-colors duration-300 ease-in-out hover:${previewTheme.addBtnHoverColor}`} style={{ fontFamily: previewTheme.fontFamily }} onClick={openPopup}>
                  <svg id="DoctorsSvgaddDoctorIcon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6 mr-2">
                    <path d="M5.25 6.375a4.125 4.125 0 1 1 8.25 0 4.125 4.125 0 0 1-8.25 0ZM2.25 19.125a7.125 7.125 0 0 1 14.25 0v.003l-.001.119a.75.75 0 0 1-.363.63 13.067 13.067 0 0 1-6.761 1.873c-2.472 0-4.786-.684-6.76-1.873a.75.75 0 0 1-.364-.63l-.001-.122ZM18.75 7.5a.75.75 0 0 0-1.5 0v2.25H15a.75.75 0 0 0 0 1.5h2.25v2.25a.75.75 0 0 0 1.5 0v-2.25H21a.75.75 0 0 0 0-1.5h-2.25V7.5Z" />
                  </svg>
                  Add New Doctor
                </button>

              </div>
              <button id="DoctorsBtnreload" className="hidden lg:block items-center lg:ml-6 sm:ml-4 mx-4" onClick={() => window.location.reload()}>
                <svg id="DoctorsSvgreloadIcon" xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-reload  h-8 w-8 mr-2 text-red-800 hover:text-red-800" width="44" height="44" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M19.933 13.041a8 8 0 1 1 -9.925 -8.788c3.899 -1 7.935 1.007 9.425 4.747" />
                  <path d="M20 4v5h-5" />
                </svg>
              </button>
            </div>
          </div>
        </h1>


        {showPopup && <AddDoctor isOpen={showPopup} onClose={closePopup} setRefreshData={setRefreshData} doctor={selectedDoctor} />}
        <InfiniteScroll
          dataLength={doctors.length} // This is important field to render the next data
          next={pageselected}
          hasMore={hasMore}
          loader={totalpages != selectedpage && <Spinner />}>
          <table id="DoctorsTabledoctor" className="hidden lg:inline-table w-[100%] lg:border rounded-md focus:outline-none  border-color:rgb(2 6 23);">
            <thead id="DoctorsTableheader">
              <tr id="DoctorsTableheaderRow" className=' px-5 py-2 text-left border-b'>
                <th id="DoctorsHeaderdoctorId" className="px-2 py-2 text-left">Doctor id</th>
                <th id="DoctorsHeaderdoctorName" className="px-2 py-2 text-left">Name</th>
                <th id="DoctorsHeaderdepartment" className="px-2 py-2 text-left">Department</th>
                <th id="DoctorsHeaderdoctorPhone" className="px-2 py-2 text-left">Mobile Number</th>
                <th id="DoctorsHeaderdate" className="px-2 py-2 text-left">Joining Date</th>
                {/* <th className="px-2 py-2 text-left">Status</th>  */}
                <th id="DoctorsHeaderEdit" className="px-2 py-2  ">Edit</th>
                <th id="DoctorsHeaderDelete" className="px-2 py-2  ">Delete</th>
              </tr>
            </thead>
            <tbody>
              {doctors.map((doctor, index) => (
                <tr id="DoctorsTablerow" className='odd:bg-white even:bg-gray-100' key={index} onClick={() => handleRowClick(doctor)}>
                  <td id="DoctorsTDdoctorId" className="px-2 py-2 text-left cursor-pointer" onClick={() => openDoctorDetails(doctor)}>{doctor.doctor_code}</td>
                  <td id="DoctorsTDdoctorName" className="px-2 py-2 text-left cursor-pointer" onClick={() => openDoctorDetails(doctor)}>{doctor.title}{" "}{`${doctor.FirstName+" "+doctor.MiddleName?.charAt(0)+" "+doctor.LastName}` || '-'}</td>
                  <td id="DoctorsTDdoctorDepartment" className="px-2 py-2 text-left cursor-pointer" onClick={() => openDoctorDetails(doctor)}>{departments.find(dep => dep.value === doctor.department_id)?.label || '-'}</td>
                  <td id="DoctorsTDdoctorPhone" className="px-2 py-2 text-left cursor-pointer" onClick={() => openDoctorDetails(doctor)}>{doctor.phone_number || '-'}</td>
                  <td id="DoctorsTDdoctorDate" className="px-2 py-2 text-left cursor-pointer" onClick={() => openDoctorDetails(doctor)}>
                    {doctor.joiningDate ? DateFunction.GetDate(doctor.joiningDate) : '-'}
                  </td>

                  {/* <td className="px-2 py-2 text-left cursor-pointer">{doctor.status || '-'}</td> */}
                  <td id="DoctorsTDactionButtonsContainer" className="px-2 py-2 text-left">
                    <button id="DoctorsBtndoctorEdit" onClick={() => handleEditClick(doctor)}>
                      <svg id="DoctorsSvgdoctorEditIcon" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                        <path d="M2.25 12.9375V15.75H5.0625L13.3575 7.455L10.545 4.6425L2.25 12.9375ZM15.5325 5.28C15.602 5.21062 15.6572 5.1282 15.6948 5.03747C15.7325 4.94674 15.7518 4.84948 15.7518 4.75125C15.7518 4.65303 15.7325 4.55576 15.6948 4.46503C15.6572 4.3743 15.602 4.29189 15.5325 4.2225L13.7775 2.4675C13.7081 2.39797 13.6257 2.34281 13.535 2.30518C13.4442 2.26754 13.347 2.24817 13.2488 2.24817C13.1505 2.24817 13.0533 2.26754 12.9625 2.30518C12.8718 2.34281 12.7894 2.39797 12.72 2.4675L11.3475 3.84L14.16 6.6525L15.5325 5.28Z" fill="black" />
                      </svg>
                    </button>
                  </td>
                  <td className="px-2 py-2 text-left">
                    <button id="DoctorsBtndoctordelete" onClick={() => deleteDoctor(doctor.doctor_id)} className='disabled:cursor-not-allowed text-red-800 disabled:opacity-50'>
                      <svg id="DoctorsSvgdoctorDeleteIcon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-5 h-5 ">
                        <path fillRule="evenodd" d="M16.5 4.478v.227a48.816 48.816 0 0 1 3.878.512.75.75 0 1 1-.256 1.478l-.209-.035-1.005 13.07a3 3 0 0 1-2.991 2.77H8.084a3 3 0 0 1-2.991-2.77L4.087 6.66l-.209.035a.75.75 0 0 1-.256-1.478A48.567 48.567 0 0 1 7.5 4.705v-.227c0-1.564 1.213-2.9 2.816-2.951a52.662 52.662 0 0 1 3.369 0c1.603.051 2.815 1.387 2.815 2.951Zm-6.136-1.452a51.196 51.196 0 0 1 3.273 0C14.39 3.05 15 3.684 15 4.478v.113a49.488 49.488 0 0 0-6 0v-.113c0-.794.609-1.428 1.364-1.452Zm-.355 5.945a.75.75 0 1 0-1.5.058l.347 9a.75.75 0 1 0 1.499-.058l-.346-9Zm5.48.058a.75.75 0 1 0-1.498-.058l-.347 9a.75.75 0 0 0 1.5.058l.345-9Z" clipRule="evenodd" />
                      </svg>
                    </button>
                  </td>
               
                </tr>
              ))}
            </tbody>
          </table>


          <div id="DoctorsDivmobileTableContainer" className="block lg:hidden">
            <table id="DoctorsDivmobileDoctorTable" className="min-w-full bg-white border border-gray-200">
              <thead id="DoctorsTHmobileTableHeader" className="bg-red-800 text-white">
                <tr id="DoctorsTRmobileTableHeaderRow">
                  <th id="DoctorsTHmobileHeaderDoctorName" className="px-1 py-2">Doctor Name</th>
                  <th id="DoctorsTHmobileHeaderDepartment" className="px-1 py-2">Department</th>

                  <th className='px-1 py-2'></th>
                </tr>
              </thead>
              <tbody id="DoctorsTBmobileTableBody">
                {doctors.map((doctor, index) => (
                  <tr id="DoctorsTRmobileTableRow" className="odd:bg-white even:bg-gray-100" key={index}>
                    <td id="DoctorsTRmobileDoctorName" className="px-1 py-2 text-left cursor-pointer" onClick={() => openDoctorDetails(doctor)}>
                      <div id="DoctorsDivmobileDoctorNameContent"
                        style={{
                          maxWidth: '150px',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                        }}
                      // data-tooltip-id="Doctorstooltip"
                      // onClick={(e) => e.stopPropagation()}
                      // data-tooltip-content={doctor.doctor_name || '-'}
                      >
                        {doctor.title}{" "}{`${doctor.FirstName+" "+doctor.MiddleName?.charAt(0)+" "+doctor.LastName}`|| '-'}
                      </div>
                      <Tooltip id="DoctorsmobileTooltip" place="top" effect="solid" />
                    </td>
                    <td id="DoctorsTDmobileDepartment" className="px-1 py-2 text-left cursor-pointer" onClick={() => openDoctorDetails(doctor)}>{departments.find(dep => dep.value === doctor.department_id)?.label || '-'}

                    </td>

                    <td id="DoctorsTDmobileActions" className="px-1 py-2 text-center cursor-pointer">
                      <button id="DoctorsBtnmobileToggleMenuButton" onClick={() => toggleMenu(index)}>
                        <svg id="DoctorsSvgmobileToggleMenuIcon" xmlns="http://www.w3.org/2000/svg" width="6" height="19" viewBox="0 0 6 19" fill="none">
                          <g opacity="0.5">
                            <path d="M3 3.86C3.55228 3.86 4 3.44362 4 2.93C4 2.41638 3.55228 2 3 2C2.44772 2 2 2.41638 2 2.93C2 3.44362 2.44772 3.86 3 3.86Z" stroke="#A31B1B" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M3 10.68C3.55228 10.68 4 10.2636 4 9.75001C4 9.23638 3.55228 8.82001 3 8.82001C2.44772 8.82001 2 9.23638 2 9.75001C2 10.2636 2.44772 10.68 3 10.68Z" stroke="#A31B1B" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M3 17.5C3.55228 17.5 4 17.0836 4 16.57C4 16.0564 3.55228 15.64 3 15.64C2.44772 15.64 2 16.0564 2 16.57C2 17.0836 2.44772 17.5 3 17.5Z" stroke="#A31B1B" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
                          </g>
                        </svg>
                      </button>
                      {openMenuIndex === index && (
                        <div id="DoctorsDivmobileActionMenu" ref={menuRef} className="absolute mt-[-30px] right-6 w-30 bg-white border border-gray-300 rounded-md">
                          <ul>
                            <li id="DoctorsLImobileEditAction" className="px-4 py-2 hover:bg-gray-100 cursor-pointer flex items-center gap-2" onClick={() => { handleEditClick(doctor); toggleMenu(); }}>
                              <svg id="DoctorsSvgmobileEditActionIcon" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                <path d="M2.25 12.9375V15.75H5.0625L13.3575 7.455L10.545 4.6425L2.25 12.9375ZM15.5325 5.28C15.602 5.21062 15.6572 5.1282 15.6948 5.03747C15.7325 4.94674 15.7518 4.84948 15.7518 4.75125C15.7518 4.65303 15.7325 4.55576 15.6948 4.46503C15.6572 4.3743 15.602 4.29189 15.5325 4.2225L13.7775 2.4675C13.7081 2.39797 13.6257 2.34281 13.535 2.30518C13.4442 2.26754 13.347 2.24817 13.2488 2.24817C13.1505 2.24817 13.0533 2.26754 12.9625 2.30518C12.8718 2.34281 12.7894 2.39797 12.72 2.4675L11.3475 3.84L14.16 6.6525L15.5325 5.28Z" fill="black" />
                              </svg>Edit</li>
                            <li id="DoctorsLImobileDeleteAction" className="px-4 py-2 hover:bg-gray-100 cursor-pointer flex items-center gap-2 text-red-800 " onClick={() => { deleteDoctor(doctor.doctor_id); toggleMenu(); }}>
                              <svg id="DoctorsSvgmobileDeleteActionIcon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-5 h-5 ">
                                <path fillRule="evenodd" d="M16.5 4.478v.227a48.816 48.816 0 0 1 3.878.512.75.75 0 1 1-.256 1.478l-.209-.035-1.005 13.07a3 3 0 0 1-2.991 2.77H8.084a3 3 0 0 1-2.991-2.77L4.087 6.66l-.209.035a.75.75 0 0 1-.256-1.478A48.567 48.567 0 0 1 7.5 4.705v-.227c0-1.564 1.213-2.9 2.816-2.951a52.662 52.662 0 0 1 3.369 0c1.603.051 2.815 1.387 2.815 2.951Zm-6.136-1.452a51.196 51.196 0 0 1 3.273 0C14.39 3.05 15 3.684 15 4.478v.113a49.488 49.488 0 0 0-6 0v-.113c0-.794.609-1.428 1.364-1.452Zm-.355 5.945a.75.75 0 1 0-1.5.058l.347 9a.75.75 0 1 0 1.499-.058l-.346-9Zm5.48.058a.75.75 0 1 0-1.498-.058l-.347 9a.75.75 0 0 0 1.5.058l.345-9Z" clipRule="evenodd" />
                              </svg>Delete</li>
                          </ul>
                        </div>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

        </InfiniteScroll>

        {/* {showPopup && <AddDoctor isOpen={showPopup} onClose={closePopup} setRefreshData={setRefreshData} doctor={selectedDoctor} />} */}
        {/* {
        selectedDoctor && (
          <DoctorDetails
            isOpen={true}
            doctor={selectedDoctor}
            // onSave={handleSaveDoctor}
            onClose={() => setSelectedDoctor(null)}
          />
        )
      } */}
        {/* {totalpages > 1 && doctors.length != 0 &&
          <Pagination totalpages={totalpages} pageselected={pageselected} selectedpage={selectedpage} />} */}

      </div >
    </>
  );
};
export default Doctors;