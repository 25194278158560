
import { Navigate, useLocation } from 'react-router-dom'
/* export const App = () => <RouterProvider router={router} /> */
import { useEffect } from 'react';
import { Routes, Route } from "react-router-dom";
import { MsalProvider, useMsal } from '@azure/msal-react';
import { EventType } from '@azure/msal-browser';
import { PageLayouts } from './components/PageLayouts'
import { b2cPolicies } from './authConfig';
import { compareIssuingPolicy } from './components/claimUtils';
import Patient from './Pages/Patients/Patient'
import Doctors from './Pages/Doctor/Doctors'
import { useSelector } from 'react-redux';
import UserDashboard from './Pages/Dashboard/UserDashboard';
import './App.css'
import Facility from './Pages/Facility/Facility';
import Users from './Pages/Usermangement/Users';
import Subscription from './Pages/Subscription/Subscriptions';
import UserRole from './Pages/UserRole/Userroles';
import Features from './Pages/Feature/Features';
import AdminFeature from './Pages/AdminFeature/AdminFeature';
import DoctorProfile from './Pages/Doctor/DoctorProfile';
import Invoice from './Pages/Invoice';
import AdminUser from './Pages/AdminUserManagement/AdminUser';
import UserProfile from './Pages/UserProfile';
import Settings from './Pages/Settings';
import SuperAdminDashboard from './Pages/Dashboard/SuperAdminDashboard';
import MasterTariff from './Pages/Invoice/MasterTariff';
import AdminInvoices from './Pages/Invoice/AdminInvoices';
import AccountOwner from './Pages/AccountOwner';
import ClearSessionStorageListener from './components/ClearSessionStorageListener';
import PrivacyPolicy from './Pages/static/PrivacyPolicy';
import PrivacyOpen from './Pages/static/PrivacyOpen';
import TermsOfService from './Pages/static/TermsOfService';
import PastHistories from './Pages/Patients/PastHistories';
import ProfileViewPort from './Pages/Patients/ProfileViewPort';
import RecycleBinViewPort from './Pages/RecycleBin/RecycleBinViewPort';
import CacheBuster from 'react-cache-buster';
import packageInfo from '../package.json';
import { Helmet } from 'react-helmet';
import AppointmentViewPort from './Pages/Appointments/AppointmentViewPort';
import GenerateQRCode from './Pages/PatientIntake/GenerateQRCode';
import PatientViewPort from './Pages/Patients/PatientViewPort';
import IntakeForm from './Pages/PatientIntake/IntakeForm';
import ScanQRCodeLink from './Pages/PatientIntake/ScanQRCodeLink';

const Pages = () => {
    /**
     * useMsal is hook that returns the PublicClientApplication instance,
     * an array of all accounts currently signed in and an inProgress value
     * that tells you what msal is currently doing. For more, visit:
     * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/hooks.md
     */
    var loggeduser = useSelector(state => state.login.loginuserData);
    const { instance } = useMsal();
    useEffect(() => {

        const callbackId = instance.addEventCallback((event) => {
            if (
                (event.eventType === EventType.LOGIN_SUCCESS || event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) &&
                event.payload.account
            ) {
                /**
                 * For the purpose of setting an active account for UI update, we want to consider only the auth
                 * response resulting from SUSI flow. "tfp" claim in the id token tells us the policy (NOTE: legacy
                 * policies may use "acr" instead of "tfp"). To learn more about B2C tokens, visit:
                 * https://docs.microsoft.com/en-us/azure/active-directory-b2c/tokens-overview
                 */
                if (compareIssuingPolicy(event.payload.idTokenClaims, b2cPolicies.names.editProfile)) {
                    // retrieve the account from initial sing-in to the app
                    const originalSignInAccount = instance
                        .getAllAccounts()
                        .find(
                            (account) =>
                                account.idTokenClaims.oid === event.payload.idTokenClaims.oid &&
                                account.idTokenClaims.sub === event.payload.idTokenClaims.sub &&
                                compareIssuingPolicy(account.idTokenClaims, b2cPolicies.names.signUpSignIn)
                        );

                    let signUpSignInFlowRequest = {
                        authority: b2cPolicies.authorities.signUpSignIn.authority,
                        account: originalSignInAccount,
                    };

                    // silently login again with the signUpSignIn policy
                    instance.ssoSilent(signUpSignInFlowRequest);
                }

                /**
                 * Below we are checking if the user is returning from the reset password flow.
                 * If so, we will ask the user to reauthenticate with their new password.
                 * If you do not want this behavior and prefer your users to stay signed in instead,
                 * you can replace the code below with the same pattern used for handling the return from
                 * profile edit flow
                 */
                if (compareIssuingPolicy(event.payload.idTokenClaims, b2cPolicies.names.forgotPassword)) {
                    let signUpSignInFlowRequest = {
                        authority: b2cPolicies.authorities.signUpSignIn.authority,
                        scopes: [],

                    };
                    instance.loginRedirect(signUpSignInFlowRequest);
                }
            }

            if (event.eventType === EventType.LOGIN_FAILURE) {
                // Check for forgot password error
                // Learn more about AAD error codes at https://docs.microsoft.com/en-us/azure/active-directory/develop/reference-aadsts-error-codes
                if (event.error && event.error.errorMessage.includes('AADB2C90118')) {
                    const resetPasswordRequest = {
                        authority: b2cPolicies.authorities.forgotPassword.authority,
                        scopes: [],
                    };
                    instance.loginRedirect(resetPasswordRequest);
                }
            }
        });

        return () => {
            if (callbackId) {
                instance.removeEventCallback(callbackId);
            }
        };
        // eslint-disable-next-line
    }, [instance]);

    return (
        <Routes>
            <Route path='/features' element={<Features />} />
            <Route path='/subscriptions' element={<Subscription />} />
            <Route path='/user-management' element={<Users />} />
            <Route path='/user-role' element={<UserRole />} />
            <Route path='/facility' element={<Facility />} />
            <Route path="/doctors" element={<Doctors />} />
            <Route path="/patients" element={<PatientViewPort />} />
            <Route path="/doctors" element={<Doctors />} />
            <Route path="/patient-profile" element={<ProfileViewPort />} />
            <Route path="/past-history-details" element={<PastHistories />} />
            <Route path="/dashboard" element={<UserDashboard />} />
            <Route path="/doctorprofile" element={<DoctorProfile />} />
            <Route path="/feature" element={<AdminFeature />} />
            <Route path="/invoice" element={<Invoice />} />
            <Route path="/admin-user-management" element={<AdminUser />} />
            <Route path="/appointments" element={<AppointmentViewPort />} />
            <Route path="/userprofile" element={<UserProfile />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="/recyclebin" element={<RecycleBinViewPort />} />
            <Route path="/master-tariff" element={<MasterTariff />} />
            <Route path="/invoices" element={<AdminInvoices />} />
            <Route path="/accountowner" element={<AccountOwner />} />
            <Route path="/privacy" element={<PrivacyPolicy />} />
            <Route path="/privacy.html" element={<PrivacyOpen />} />
            <Route path="/terms-of-service" element={<TermsOfService />} />
            <Route path="/GenerateQRCode" element={<GenerateQRCode />} />
            <Route path="/patient-intake-form" element={<IntakeForm />} />

            {(loggeduser.user_role === 'Admin' || loggeduser.user_role === 'Users' || loggeduser.user_role === 'Doctor') && (
                <Route path="/" element={<Navigate to="/appointments" replace={true} />} />
            )}
            {loggeduser.user_role === 'SuperAdmin' && (
                <Route path="/" element={<SuperAdminDashboard />} />
            )}
            <Route path="*" element={<Navigate to="/" replace={true} />} />
        </Routes>
    );
};


/**
 * msal-react is built on the React context API and all parts of your app that require authentication must be
 * wrapped in the MsalProvider component. You will first need to initialize an instance of PublicClientApplication
 * then pass this to MsalProvider as a prop. All components underneath MsalProvider will have access to the
 * PublicClientApplication instance via context as well as all hooks and components provided by msal-react. For more, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
const App = ({ instance }) => {
    const canonicalUrl = process.env.REACT_APP_CANONICAL_URL;
    const robotsMeta = process.env.REACT_APP_ROBOTS_CONTENT || "noindex,nofollow";
    const location = useLocation();
    const pathName = location?.pathname?.substring(1);
    return (
        <>
            <Helmet>
                <link rel="canonical" href={canonicalUrl} />
                <meta name="robots" content={robotsMeta} />
            </Helmet>

            <CacheBuster
                currentVersion={packageInfo.version}
                isEnabled={true} //If false, the library is disabled.
                isVerboseMode={false} //If true, the library writes verbose logs to console.
                metaFileDirectory={'.'} //If public assets are hosted somewhere other than root on your server.
            >
                <MsalProvider instance={instance}>
                    <ClearSessionStorageListener />
                    {(pathName === "patient-intake-form" || pathName === "patient-intake-qrlink") ?
                        <>
                            {pathName === "patient-intake-form" ?
                                <IntakeForm /> :
                                <ScanQRCodeLink />}
                        </>
                        :
                        <PageLayouts>
                            <Pages />
                        </PageLayouts>}
                </MsalProvider>
            </CacheBuster>
        </>
    );
};

export default App;

