"use client";
import { useEffect, useState } from 'react';

function IdleTimer({timeoutHandler}) {
  const [idleTimeout, setIdleTimeout] = useState(null);

  useEffect(() => {
    const setIdleTimer = () => {
      const timeout = setTimeout(() => {
        timeoutHandler()
        }, 10 * 60 * 1000);
      setIdleTimeout(timeout);
    };

    
    const resetIdleTimer = () => {
      // Clear the previous timeout if the user interacts before it triggers
      if (idleTimeout) {
        clearTimeout(idleTimeout);
      }
      setIdleTimer();
    };

    const events = ['mousemove', 'mousedown', 'keydown', 'touchstart']; // Events that reset the timer

    const attachEvents = () => {
      events.forEach(event => {
        document.addEventListener(event, resetIdleTimer);
      });
    };

    attachEvents();
    setIdleTimer(); 

    return () => {
      events.forEach(event => {
        window.removeEventListener(event, resetIdleTimer);
      });
    };
  }, []);

  return null;
}

export default IdleTimer;