import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { setNotification } from '../../../../Redux/features/toast/toastSlice';
import store from '../../../../API/store';
import DataDecode from '../../../../utils/DataDecode';
import EMRAudioRecorder from '../Audio/EMRAudioRecorder';
import { setShowRecoringPopup, setStartRecording, setRecConfirmBox } from '../../../../Redux/features/recording/recordingSlice';
import LoadingSpinner from '../../../../components/LoadingSpinner';
import EMRAudioPlayer from '../Audio/EMRAudioPlayer';
import { setDocumentType, setIsCameraUpload, setRefreshNotes, setShowUploadPopup } from '../../../../Redux/features/uploadDoc/uploadDocSlice';
import FileIcon from '../FileIcon';
import DateFunction from '../../../../utils/DateFunctions';
import { setIsGlobalSaveRequired, setNewAppointmentId } from '../../../../Redux/features/profileDrawer/profileSideNavbarSlice';
import { theme } from '../../../../EmraxisTheme/Theme';
import { addLoginDetails } from "../../../../Redux/features/login/loginSlice";

const DoctorNotes = ({ appointment, clinicGuid, appointment_id, patient_id, page_src, setIsMessagePopupOpen }) => {
    const dispatch = useDispatch();
    const recNotesEndRef = useRef(null);
    var isAiscriptEnable=useSelector(state=>state.feature.isStandardAiActive);
    var loggeduser = useSelector(state => state.login.loginuserData);
    var loggedusertoken = useSelector(state => state.login.userBarerToken);
    var showRecoringPopup = useSelector(state => state.recording.showRecoringPopup);
    var startRecording = useSelector(state => state.recording.startRecording);
    var confirmBox = useSelector(state => state.recording.confirmBox);
    var disableButtons = useSelector(state => state.recording.disableButtons);
    var isVoice2TextActive = useSelector(state => state.feature.isVoice2TextActive);
    var isRecLoading = useSelector(state => state.recording.isLoading);
    var refreshNotes = useSelector(state => state.document_upload.refreshNotes);
    var autoSaveAll = useSelector(state => state.profileSideNavbar.autoSaveAll);
    var documentType = useSelector(state => state.document_upload.documentType);
    var isDoctorNotesDataAvailable = useSelector(state => state.profileSideNavbar.isDoctorNotesDataAvailable);
    const previewTheme = useSelector(state => state.theme.colors) || theme.filter(a => a.name === "defaultTheme")[0];
    const textareaRef = useRef(null);

    const [drNotes, setDrNotes] = useState('');
    const [loading, setLoading] = useState(false);
    const [dataLoading, setDataLoading] = useState(true);
    const [refreshData, setRefreshData] = useState(false);
    const [notesFileDeleteID, setNotesFileDeleteID] = useState(0);
    const [notesFileDeleteCnfBox, setNotesFileDeleteCnfBox] = useState(false);
    const [clinicalNoteData, setClinicalNoteData] = useState([]);
    const [allClinicalData, setAllClinicalData] = useState([]);
    const [files, setFiles] = useState([]);
    const [loadingDeleteImage, setLoadingDeleteImage] = useState(false);
    const [isAnyChanges, setIsAnyChanges] = useState(false);
    const [viewMore, setViewMore] = useState(false);
    const [clinicalDocumentation, setClinicalDocumentation] = useState(false);
    const [showClinicalNotes, setShowClinicalNotes] = useState(false);
    const [showAudioFiles, setShowAudioFiles] = useState(false);
    const [transcriptJson, setTranscriptJson] = useState([{}]);
    const [audioFiles, setAudioFiles] = useState([]);
    const [currentPlaying, setCurrentPlaying] = useState(null); // Track currently playing audio
    const [audioConfirmBox, setAudioConfirmBox] = useState(false);
    const [showRecordDialog, setShowRecordDialog] = useState(false);

   
    const scrollToBottom = () => {
        if (recNotesEndRef.current) {
            recNotesEndRef.current.scrollTop = recNotesEndRef.current.scrollHeight;
        }
    };

  const hanldeCloseClinicalNotes = () => {
    setShowAudioFiles(true);
  };
  const hanldeEnableRecordIcon = () => {
    if (loggeduser?.clinicalNoteModelType === "AiScript") {
      dispatch(setShowRecoringPopup(true));
      setRecConfirmBox(true);
    } else {
      setAudioConfirmBox(true);
    }
  };
  useEffect(() => {
    if (clinicalDocumentation) {
      handleFetchTranscriptionFiles().then((res) => {
        setClinicalDocumentation(false);
      });
    }  
  }, [clinicalDocumentation]);
  useEffect(() => {
    if (isRecLoading) scrollToBottom();
  }, [isRecLoading]);
  const handlePlay = (file) => {
    if (currentPlaying === file) {
      setCurrentPlaying(null);
    } else {
      setCurrentPlaying(file);
    }
  };
 
  const GetClinicalFiles = () => {
    try {
      let patientId = patient_id;
      store
        .GetClinicalNotes(DataDecode.encryptPayload(patientId))
        .then((result) => {
          if (result.status === 200) {
            result.json().then((res) => {
              let response = DataDecode.decryptResponse(res);
              let data = JSON.parse(response);
              setClinicalNoteData(data);
            });
          } else {
            setClinicalNoteData([]);
          }
        });
    } catch (err) {
      setClinicalNoteData([]);
      dispatch(
        setNotification({
          message: "Error occurred",
          status: "error",
          action: true,
        })
      );
    }
  };
  const handleUpdateAudioRecordOption = async () => {
    let enableAudioRecording = {
      userId: loggeduser?.userId,
      clinicalNoteModelType: "AiScript",
    };
    await store
      .EnableAudioRecording(DataDecode.encryptPayload(enableAudioRecording))
      .then((result) => {
        if (result.status === 200) {
          setAudioConfirmBox(false);
          dispatch(
            addLoginDetails({
              ...loggeduser,
              clinicalNoteModelType: enableAudioRecording.clinicalNoteModelType,
            })
          );
            dispatch(
            setNotification({
              message: "Enabled Clinical DOcuments",
              status: "success",
              action: true,
            })
          );
         dispatch(setShowRecoringPopup(true));
        }
        if (result.status === 400) {
          dispatch(
            setNotification({
              message:
                "Error while enabling the  Standard_Ai feature",
              status: "error",
              action: true,
            })
          );
        } else if(result.status !== 200) {
          dispatch(
            setNotification({
              message: "Error while enabling the ClinicalDocumentation",
              status: "error",
              action: true,
            })
          );
        }
      });
  };
 
  const GetClinicalNotes = () => {
    try {
      store
        .GetClinicalNotes(
          loggedusertoken,
          DataDecode.encryptPayload({
            clinic_guid: clinicGuid,
            appointment_id: appointment_id,
            patient_id: patient_id,
          })
        )
        .then((result) => {
          if (result.status === 200) {
            result.json().then((res) => {
              let response = DataDecode.decryptResponse(res);
              let data = JSON.parse(response);
              setClinicalNoteData(data);
            });
          } else {
            setClinicalNoteData([]);
          }
        });
    } catch (err) {
      setClinicalNoteData([]);
      dispatch(
        setNotification({
          message: "Error occurred",
          status: "error",
          action: true,
        })
      );
    }
  };

  const GetPatientNotesFile = () => {
    store
      .GetPatientFiles(
        loggedusertoken,
        DataDecode.encryptPayload({
          clinic_guid: clinicGuid,
          patient_id: patient_id,
          appointment_id: appointment_id,
        })
      )
      .then((result) => {
        if (result.status == 200) {
          result.json().then((res) => {
            let response = DataDecode.decryptResponse(res);
            setFiles(JSON.parse(response));
          });
        } else {
          setFiles([]);
        }
      })
      .catch((err) => {
        setFiles([]);
        dispatch(
          setNotification({
            message: "Error occurred",
            status: "error",
            action: true,
          })
        );
      });
  };

  const handleFetchTranscriptionFiles = async () => {
    let data = { PatientId: patient_id, Id: appointment_id };
    await store
      .GetTranscriptionFiles(DataDecode.encryptPayload(data))
      .then((result) => {
        if (result.status == 200) {
          result.json().then((res) => {
            let audioData = res;
            setAudioFiles(audioData);
          }, {});
        } else {
          dispatch(
            setNotification({
              message: "Error while fetching Audio Files",
              status: "error",
              action: true,
            })
          );
          setAudioFiles([]);
        }
      })
      .catch((err) => {
        setDataLoading(false);
        setAllClinicalData([]);
        dispatch(
          setNotification({
            message: "Error occurred",
            status: "error",
            action: true,
          })
        );
      });
  };

  const GetAllPatientDrNotes = () => {
    if (allClinicalData.length == 0) {
      setDataLoading(true);
    }
    store
      .GetAllPatientDrNotes(
        loggedusertoken,
        DataDecode.encryptPayload({
          clinic_guid: clinicGuid,
          patient_id: patient_id,
        })
      )
      .then((result) => {
        setDataLoading(false);
        if (result.status == 200) {
          result.json().then((res) => {
            let response = DataDecode.decryptResponse(res);
            setAllClinicalData(JSON.parse(response));
          });
        } else {
          setAllClinicalData([]);
        }
      })
      .catch((err) => {
        setDataLoading(false);
        setAllClinicalData([]);
        dispatch(
          setNotification({
            message: "Error occurred",
            status: "error",
            action: true,
          })
        );
      });
  };

  useEffect(() => {
    const fetchData = async () => {
      if (page_src === "appointment" || isDoctorNotesDataAvailable) {
        const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

        await delay(1000);
        await handleFetchTranscriptionFiles().then((result) => {});
        await delay(1000);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (page_src === "patient") {
      GetAllPatientDrNotes();
    }
  }, [refreshNotes]);

  useEffect(() => {
    GetClinicalFiles();
    GetPatientNotesFile();
  }, [refreshNotes]);

  useEffect(() => {
    GetClinicalNotes();
  }, [refreshData]);

  const handleStartRecording = () => {
    dispatch(setShowRecoringPopup(true));
  };

  const handleCloseRecording = () => {
    dispatch(setStartRecording(false));
    dispatch(setRecConfirmBox(true));
  };

  const handleClinicalNotes = (div) => {
    scrollToDiv(div);
    setShowClinicalNotes(true);
  };
  const handleAudioFIles = (div) => {
    scrollToDiv(div);
    setShowClinicalNotes(false);
  };
  const scrollToDiv = (divId) => {
    const targetDiv = document.getElementById(divId);
    if (targetDiv) {
      targetDiv.scrollIntoView({ behavior: "smooth" });
    }
  };
  useEffect(() => {
    setDrNotes(appointment && appointment?.notes ? appointment?.notes : "");
  }, [appointment]);

  const saveNotes = () => {
    if (drNotes.trim() === "") {
      dispatch(
        setNotification({
          message: "Please enter Notes!",
          status: "error",
          action: true,
        })
      );
    } else {
      setLoading(true);
      store
        .updateDoctorNotes(
          loggedusertoken,
          DataDecode.encryptPayload({
            appointment_id: appointment.appointment_id,
            clinic_guid: clinicGuid,
            notes: drNotes,
            type: "notes",
          })
        )
        .then((result) => {
          setLoading(false);
          setIsAnyChanges(false);
          if (result.status == 204) {
            dispatch(
              setNotification({
                message: "Notes Updated!",
                status: "success",
                action: true,
              })
            );
          } else {
            dispatch(
              setNotification({
                message: "Error Occured!",
                status: "error",
                action: true,
              })
            );
          }
        })
        .catch((err) => {
          dispatch(
            setNotification({
              message: "Error occurred",
              status: "error",
              action: true,
            })
          );
        });
    }
  };
  useEffect(() => {
    if (isAnyChanges && drNotes.trim() !== "") {
      saveNotes();
    }
  }, [autoSaveAll]);

  const deleteNotesFile = () => {
    setLoadingDeleteImage(true);
    store
      .DeletePatientFile(
        loggedusertoken,
        DataDecode.encryptPayload({
          clinic_guid: clinicGuid,
          patient_id: patient_id,
          files: notesFileDeleteID,
        })
      )
      .then((result) => {
        setLoadingDeleteImage(false);
        setNotesFileDeleteCnfBox(false);
        if (result.status == 204) {
          dispatch(setRefreshNotes(!refreshNotes));
          dispatch(
            setNotification({
              message: "Deleted Successfully!",
              status: "success",
              action: true,
            })
          );
        } else {
          dispatch(
            setNotification({
              message: "Error Occurred!",
              status: "error",
              action: true,
            })
          );
        }
      })
      .catch((error) => {
        dispatch(
          setNotification({
            message: "Error occurred",
            status: "error",
            action: true,
          })
        );
      });
  };

    const handleUploadButton = (type) => {
        dispatch(setNewAppointmentId(appointment_id))
        dispatch(setDocumentType({ ...documentType, doctor_note: true }))
        dispatch(setIsCameraUpload(type === "capture" ? true : false));
        if (appointment_id == 0 || (appointment && (appointment.status == 'Completed'))) {
            setIsMessagePopupOpen(p => !p);
        } else {
            dispatch(setShowUploadPopup(true))
        }
    }

    useEffect(() => {
        const textarea = textareaRef.current;
        if(textarea){
            textarea.style.height = 'auto';
            textarea.style.height = textarea.scrollHeight + 'px';
        }
    }, [drNotes]);

  return (
    <>
      {confirmBox && (
        <div className="fixed inset-0 flex justify-center items-center p-8 z-[999999] rounded-xl">
          <div className="absolute inset-0 bg-black opacity-25 rounded-xl"></div>
          <div
            className="relative bg-white shadow-xl rounded-xl"
            style={{ width: "400px" }}
          >
            <div className="pl-8 pt-4 pr-8">
              <p className="pt-4 text-lg font-semibold text-center">
                Your recording will be deleted, are you sure you want to close?
              </p>
              <div className="my-10 flex justify-center items-center">
                <button
                  id="btnCancelCfmRecordingPopup"
                  className={`mr-2 px-4 py-1 font-semibold rounded-md shadow-md ${previewTheme.cancelBtnColor} ${previewTheme.cancelBtnBgColor} hover:${previewTheme.cancelBtnHoverColor}`}
                  onClick={() => {
                    dispatch(setRecConfirmBox(false));
                  }}
                >
                  Cancel
                </button>
                <button
                  id="btnCancelCfmProccedPopup"
                  className={`mr-2 py-1 px-4 font-semibold rounded-md shadow-md ${previewTheme.saveBtnColor} ${previewTheme.saveBtnBgColor} hover:${previewTheme.saveBtnHoverColor}`}
                  onClick={() => {
                    dispatch(setRecConfirmBox(false));
                    dispatch(setShowRecoringPopup(false));
                    dispatch(setStartRecording(false));
                  }}
                >
                  Proceed
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {notesFileDeleteCnfBox && (
        <div
          id="delete"
          tabIndex="-1"
          aria-hidden="true"
          className="fixed z-[9999] lg:left-[40%] lg:w-80 top-[10rem] lg:top-[3rem] lg:translate-x-[50%] translate-y-[50%]"
        >
          <div className="relative p-4 w-full max-w-md h-full md:h-auto">
            <div className="relative p-4 text-center bg-white border-gray-400 border-[1px] rounded-lg shadow-lg dark:bg-gray-800 sm:p-5">
              <svg
                className="text-red-600 dark:text-gray-500 w-11 h-11 mb-3.5 mx-auto"
                aria-hidden="true"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <p className="mb-4 text-gray-500 dark:text-gray-300">
                Are you sure you want to delete this file?
              </p>
              <div className=" flex justify-center items-center">
                <button
                  id="btnCancelCfmDeletePopup"
                  disabled={loadingDeleteImage}
                  className={`mr-2 py-2 px-3 text-sm font-medium rounded-lg border focus:ring-4 focus:outline-none focus:ring-primary-300 focus:z-10 dark:focus:ring-gray-600 ${previewTheme.cancelBtnColor} ${previewTheme.cancelBtnBgColor} hover:${previewTheme.cancelBtnHoverColor}`}
                  onClick={() => {
                    setNotesFileDeleteCnfBox(false);
                  }}
                >
                  No, cancel
                </button>
                <button
                  id="btnYesCfmDeletePopup"
                  type="button"
                  onClick={deleteNotesFile}
                  disabled={loadingDeleteImage}
                  className={`py-2 px-3 text-sm font-medium text-center rounded-lg focus:ring-4 focus:outline-none disabled:opacity-50 disabled:cursor-not-allowed ${previewTheme.saveBtnColor} ${previewTheme.saveBtnBgColor} hover:${previewTheme.saveBtnHoverColor}`}
                >
                  Yes, I'm sure{" "}
                  {loadingDeleteImage && (
                    <svg
                      aria-hidden="true"
                      role="status"
                      className="inline w-4 h-4 ml-2 text-white animate-spin"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="#E5E7EB"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentColor"
                      />
                    </svg>
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      <>
        {/* {loggeduser?.clinicalNoteModelType !== "Llama"? */}
        <>
          {audioConfirmBox && (
            <div className="fixed inset-0 flex justify-center items-center p-8 z-[999999]">
              <div className="absolute inset-0 bg-black opacity-25 "></div>
              <div
                className="relative bg-white shadow-xl rounded-xl"
                style={{ width: "400px" }}
              >
                <div className="pl-8 pt-4 pr-8 rounded-xl">
                  <p className="pt-2 text-lg font-semibold text-center">
                    Do You Want to Enable the AI Generated Clinical Notes?
                  </p>
                  <div className="my-10 flex justify-center items-center">
                    <button
                      id="btnCancelCfmRecordingPopup"
                      className={`mr-2 px-3 py-1 font-semibold rounded-md shadow-md ${previewTheme.cancelBtnColor} ${previewTheme.cancelBtnBgColor} hover:${previewTheme.cancelBtnHoverColor}`}
                      onClick={() => {
                        setAudioConfirmBox(false);
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      id="btnCancelCfmProccedPopup"
                      className={`mr-2  py-1 px-3 font-semibold rounded-md shadow-md ${previewTheme.saveBtnColor} ${previewTheme.saveBtnBgColor} hover:${previewTheme.saveBtnHoverColor}`}
                      onClick={() => {
                        handleUpdateAudioRecordOption();
                      }}
                    >
                      Enable
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
        <>
          {showRecoringPopup && (
            <div className="fixed inset-0 flex justify-center items-center p-8 z-[999998]">
              <div className="absolute inset-0 bg-black opacity-25 "></div>
              <div
                className="relative bg-white shadow-xl rounded-sm"
                style={{ width: "600px" }}
              >
                <div className="flex justify-between">
                  <div className="m-2"></div>
                  <div className="m-2">
                    <h2 className="text-center text-lg font-semibold">
                      {startRecording ? "Recording" : "Record"}
                    </h2>
                  </div>
                  <button
                    id="btnCloseRecordingPopup"
                    className="text-red-700 sm:justify-left disabled:cursor-not-allowed disabled:opacity-50"
                    disabled={disableButtons}
                    onClick={handleCloseRecording}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className="h-7 w-7 mr-2"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                      />
                    </svg>
                  </button>
                </div>

                <div className="min-h-[200px] pl-8 pr-8 ">
                  <EMRAudioRecorder
                    patient_id={patient_id}
                    appointment_id={appointment_id}
                    setRefreshData={setRefreshData}
                    setClinicalDocumentation={setClinicalDocumentation}
                  />
                </div>
              </div>
            </div>
          )}
        </>
      </>

      <div className="shadow-lg rounded-md ">
        <div className="flex justify-between bg-[#e2e8f0] text-[#a31b1b] rounded-t-lg text-lg font-semibold py-1 px-2">
          <div>
            <p id="DoctorNameNotesId" className="font-semibold text-lg">
              Doctor Notes
            </p>
          </div>
          <div>
            {/* {
                        <span title='record'>
                        <img
                        id='AudioRecordIcon'
                        className={`size-7 mb-1 mr-2 inline cursor-pointer text-red-700`}
                           src ={AudioRecordIcon}/>

                        </span>} */}
            {isAiscriptEnable &&
              appointment?.status !== "Completed" &&
              appointment_id > 0 && (
                <button
                  title="Record"
                  onClick={hanldeEnableRecordIcon}
                  id="btnStartRecording"
                  type="button"
                  className="  border-2 rounded-full p-1  "
                >
                  <img
                    id="AudioRecordIcon"
                    className={`size-7 mb-1 mr-2 inline cursor-pointer text-red-700`}
                    src={"image/AudioRecordIcon.png"}
                  />
                  {/* <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" className="size-5">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 18.75a6 6 0 0 0 6-6v-1.5m-6 7.5a6 6 0 0 1-6-6v-1.5m6 7.5v3.75m-3.75 0h7.5M12 15.75a3 3 0 0 1-3-3V4.5a3 3 0 1 1 6 0v8.25a3 3 0 0 1-3 3Z" />
                                </svg> */}
                </button>
              )}
            <span title="Upload">
              <svg
                id="DesktopBtnUploadDrNotes"
                xmlns="http://www.w3.org/2000/svg"
                onClick={() => handleUploadButton("upload")}
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="2"
                stroke="currentColor"
                className={`size-7 mb-1 mr-2 inline cursor-pointer text-red-700`}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5m-13.5-9L12 3m0 0 4.5 4.5M12 3v13.5"
                />
              </svg>
            </span>
            <span title="Capture">
              <svg
                id="DesktopBtnCaptureDrNotes"
                xmlns="http://www.w3.org/2000/svg"
                onClick={() => handleUploadButton("capture")}
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="2"
                stroke="currentColor"
                className={`inline mb-1 size-7 cursor-pointer text-red-700`}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6.827 6.175A2.31 2.31 0 0 1 5.186 7.23c-.38.054-.757.112-1.134.175C2.999 7.58 2.25 8.507 2.25 9.574V18a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 18V9.574c0-1.067-.75-1.994-1.802-2.169a47.865 47.865 0 0 0-1.134-.175 2.31 2.31 0 0 1-1.64-1.055l-.822-1.316a2.192 2.192 0 0 0-1.736-1.039 48.774 48.774 0 0 0-5.232 0 2.192 2.192 0 0 0-1.736 1.039l-.821 1.316Z"
                />
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M16.5 12.75a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0ZM18.75 10.5h.008v.008h-.008V10.5Z"
                />
              </svg>
            </span>
          </div>
        </div>
        {isDoctorNotesDataAvailable && (
          <>
            {page_src === "appointment" ? (
              <div className="w-full rounded-lg bg-gray-50">
                {appointment_id > 0 ? (
                  <>
                  <div>
                    {isAiscriptEnable &&loggeduser?.clinicalNoteModelType === "AiScript" && (
                      <div
                        ref={recNotesEndRef}
                        className="px-2 py-2 bg-white rounded-t-lg dark:bg-gray-800 min-h-36"
                      >
                        {isVoice2TextActive && (
                          <>
                            {clinicalNoteData.filter(
                              (a) => a.notes !== "N/A" && a.notes !== ""
                            ).length > 0 ? (
                              <>
                                {clinicalNoteData
                                  .filter(
                                    (a) => a.notes !== "N/A" && a.notes !== ""
                                  )
                                  .map((cn, ind) => (
                                    <div
                                      key={"cn-" + ind}
                                      className="flex justify-between my-1 "
                                    >
                                      <div>
                                        <p className="break-words">
                                          <span className="font-semibold">
                                            Note {ind + 1}:{" "}
                                          </span>{" "}
                                          {cn.notes}
                                        </p>
                                      </div>
                                      <div>
                                        {cn.file_path && (
                                          <EMRAudioPlayer
                                            source={
                                              process.env
                                                .REACT_APP_EMRAXIS_S3_URL +
                                              "/" +
                                              cn.file_path
                                            }
                                          />
                                        )}
                                      </div>
                                    </div>
                                  ))}
                              </>
                            ) : (
                              <p></p>
                            )}
                            {isRecLoading && (
                              <LoadingSpinner text="Generating Notes" />
                            )}
                          </>
                        )}
                        {!showAudioFiles &&
                          loggeduser?.clinicalNoteModelType === "AiScript" && (
                            <>
                              {showClinicalNotes && (
                                <div
                                  id={"audioFiles_Id"}
                                  className="grid grid-cols-10 gap-4 flex  justify-between  bg-red-50  text-md font-semibold py-1 px-2"
                                >
                                  <div className="col-span-2 px-2 py-2 bg-Red rounded-t-lg dark:bg-gray-800 min-h-12">
                                    <p
                                      id={"textAudfiles"}
                                      className="font-semibold text-md"
                                    >
                                      {" "}
                                      Audio Files
                                    </p>
                                  </div>
                                  <div className="col-span-2 px-2 py-2 bg-Red rounded-t-lg dark:bg-gray-800 min-h-12">
                                    <p
                                      id={"textDoctorNameId"}
                                      className="font-semibold text-md"
                                    >
                                      {" "}
                                      {appointment.doctor_name}
                                    </p>
                                  </div>
                                  <div className="col-span-4"></div>

                                  <div className="col-span-2 px-2 py-2 bg-Red flex justify-end rounded-t-lg dark:bg-gray-800 min-h-12">
                                    {/* <button onClick={handleStartRecording}>
                                      <img
                                        id="PlusIconId"
                                        className={`size-7 mb-1 mr-2 inline cursor-pointer text-red-700`}
                                        src={
                                          "AudioFilesIcons/carbon_add-filled.png"
                                        }
                                      />
                                    </button> */}
                                    <img
                                      id="CopyIconId"
                                      className={`size-7 mb-1 mr-2 inline cursor-pointer text-red-700`}
                                      src={"AudioFilesIcons/tabler_copy.png"}
                                    />

                                    <img
                                      id="EditIconId"
                                      className={`size-7 mb-1 mr-2 inline cursor-pointer text-red-700`}
                                      src={
                                        "AudioFilesIcons/material-symbols_edit.png"
                                      }
                                    />
                                    {/* <button onClick={hanldeCloseClinicalNotes}>
                                      <img
                                        id="CrossIconId"
                                        className={`size-7 mb-1 mr-2 inline cursor-pointer text-red-700`}
                                        src={"AudioFilesIcons/CrossIcon.png"}
                                      />
                                    </button> */}
                                  </div>
                                </div>
                              )}
                              {isAiscriptEnable && !showClinicalNotes && (
                                <div
                                  id={"clinicalNotes_Id"}
                                  className="grid grid-cols-10 gap-4 flex  justify-between  bg-red-50  text-md font-semibold py-1 px-2"
                                >
                                  <div className="col-span-2 px-2 py-2 bg-Red rounded-t-lg dark:bg-gray-800 min-h-12">
                                    <p className="font-semibold text-md">
                                      {" "}
                                      Clinical Notes
                                    </p>
                                  </div>
                                  <div className="col-span-2 px-2 py-2 bg-Red rounded-t-lg dark:bg-gray-800 min-h-12">
                                    <p className="font-semibold text-md">
                                      {" "}
                                      {appointment.doctor_name}
                                    </p>
                                  </div>
                                  <div className="col-span-4"></div>
                                  <div className="col-span-2 px-2 py-2 bg-red flex justify-end rounded-t-lg dark:bg-gray-800 min-h-12">
                                    {/* <button onClick={handleStartRecording}>
                                      <img
                                        id="PlusIconId"
                                        className={`size-7 mb-1 mr-2 inline cursor-pointer text-red-700`}
                                        src={
                                          "AudioFilesIcons/carbon_add-filled.png"
                                        }
                                      />
                                    </button> */}
                                    <img
                                      id="CopyIconId"
                                      className={`size-7 mb-1 mr-2 inline cursor-pointer text-red-700`}
                                      src={"AudioFilesIcons/tabler_copy.png"}
                                    />

                                    <img
                                      id="EditIconId"
                                      className={`size-7 mb-1 mr-2 inline cursor-pointer text-red-700`}
                                      src={
                                        "AudioFilesIcons/material-symbols_edit.png"
                                      }
                                    />
                                    {/* <button onClick={hanldeCloseClinicalNotes}>
                                      <img
                                        id="CrossIconId"
                                        className={`size-7 mb-1 mr-2 inline cursor-pointer text-red-700`}
                                        src={"AudioFilesIcons/CrossIcon.png"}
                                      />
                                    </button> */}
                                  </div>
                                </div>
                              )}

                              <div className="grid grid-cols-10 gap-4">
                                <div className="col-span-9">
                                  {isAiscriptEnable && !showClinicalNotes && (
                                    <div
                                      id={"showClinicalNotes_Id"}
                                      className=" flex justify-between hidden sm:inline grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-2"
                                    >
                                      <div className="col-span-9 justify-self-start ">
                                        {audioFiles && audioFiles.length > 0 ? (
                                          <>
                                            {audioFiles?.map((file, index) => {
                                              if (
                                                file?.listOfClinicalNote != "Empty"
                                              ) {
                                                const clinicalDocumentString =
                                                  file?.listOfClinicalNote;
                                                let clinicalDocumentParsed = [];
                                                let ClnicalDocuments;

                                                try {
                                                  if (
                                                    clinicalDocumentString !=
                                                    "Empty"
                                                  ) {
                                                    clinicalDocumentParsed =
                                                      JSON.parse(
                                                        clinicalDocumentString
                                                      );
                                                    let data =
                                                      clinicalDocumentParsed.reduce(
                                                        (acc, item) => {
                                                          acc[item?.Version] = {
                                                            Version:
                                                              item?.Version,
                                                            EditedBy:
                                                              item?.EditedBy,
                                                            Content:
                                                              item?.Content
                                                                ? JSON?.parse(
                                                                    JSON.parse(
                                                                      item?.Content
                                                                    )?.[0]
                                                                      ?.Content
                                                                  )
                                                                : {},
                                                          };

                                                          return acc;
                                                        },
                                                        {}
                                                      );
                                                    let dataObj =
                                                      data?.Original;

                                                    ClnicalDocuments =
                                                      dataObj.Content
                                                        .ClinicalDocumentation;
                                                  } else {
                                                    ClnicalDocuments = [];
                                                  }
                                                } catch (error) {
                                                  console.error(
                                                    "Error parsing clinical document:",
                                                    error
                                                  );
                                                }

                                                const date = new Date(
                                                  file?.creationTime
                                                );

                                                const day = String(
                                                  date.getDate()
                                                ).padStart(2, "0");
                                                const month = String(
                                                  date.getMonth() + 1
                                                ).padStart(2, "0");
                                                const year = String(
                                                  date.getFullYear()
                                                ).slice(-2);
                                                return (
                                                  <div className="content-container pt-2 pb-2 pl-4 pr-4">
                                                    <ul>
                                                      <li key={index}>
                                                        <div className="grid grid-row-10 gap-4">
                                                          <p className="font-bold">
                                                            {`Clinical Notes.${
                                                              index + 1
                                                            }`}{" "}
                                                          </p>

                                                          {ClnicalDocuments?.Sections?.map(
                                                            (
                                                              section,
                                                              sectionIndex
                                                            ) => ( 
                                                              <div
                                                                className="p-2"
                                                                key={`section-${sectionIndex}`}
                                                              >
                                                                <span
                                                                  className="!font-semibold"
                                                                  onInput={(
                                                                    e
                                                                  ) => {
                                                                    e.stopPropagation();
                                                                    e.preventDefault();
                                                                  }}
                                                                  suppressContentEditableWarning={
                                                                    true
                                                                  }
                                                                >
                                                                  {section?.SectionName.replaceAll(
                                                                    "_",
                                                                    " "
                                                                  )}
                                                                </span>
                                                                <ul
                                                                  className="!list-disc ml-8"
                                                                  id="clinical_note_unordered_list"
                                                                >
                                                                  {section?.Summary?.filter(
                                                                    (item) =>
                                                                      item?.SummarizedSegment
                                                                  )?.map(
                                                                    (
                                                                      item,
                                                                      summaryIndex
                                                                    ) => (
                                                                      <p
                                                                        key={`${section.SectionName}-${summaryIndex}`}
                                                                        onInput={(
                                                                          e
                                                                        ) => {
                                                                          const liElement =
                                                                            e.currentTarget;
                                                                          if (
                                                                            !liElement?.textContent
                                                                          ) {
                                                                            liElement?.remove();
                                                                          }
                                                                        }}
                                                                        onKeyDown={(
                                                                          event
                                                                        ) => {
                                                                          if (
                                                                            event.key ===
                                                                              "Enter" &&
                                                                            !event.shiftKey
                                                                          ) {
                                                                            event.preventDefault();
                                                                          }
                                                                        }}
                                                                        suppressContentEditableWarning={
                                                                          true
                                                                        }
                                                                        className="focus"
                                                                      >
                                                                        {`${
                                                                          summaryIndex +
                                                                          1
                                                                        }. ${
                                                                          item?.SummarizedSegment
                                                                        }`}
                                                                      </p>
                                                                    )
                                                                  )}
                                                                </ul>
                                                              </div>
                                                                )
                                                          )}
                                                        </div>
                                                      </li>
                                                    </ul>
                                                  </div>
                                                );
                                              } else {
                                                return (
                                                  <ul>
                                                  <li
                                                    key={index}
                                                    className="mb-4 flex  text-center"
                                                  >
                                                     <p className="font-bold">
                                                            {`Clinical Notes.${
                                                              index + 1
                                                            }`}{" "}
                                                          </p>
                                                    
                                                  </li>
                                                  <li> <p
                                                  id={"emptyErrormsgId"}
                                                  className="mb-4 flex justify-center items-center text-center"
                                                >
                                                  No Clinical Notes are
                                                  available.
                                                </p></li>
                                                </ul>
                                                );
                                              }
                                            })}
                                          </>
                                        ) : (
                                          <p className='mb-4 flex justify-center border items-center text-center  px-4 py-2"'>
                                            No clinical Notes are available.
                                          </p>
                                        )}
                                      </div>
                                      <br />
                                    </div>
                                  )}
                                  {showClinicalNotes && (
                                    <div className=" flex justify-between hidden sm:inline grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-2">
                                      <div className="col-span-9 justify-self-start ">
                                        {audioFiles && audioFiles.length > 0 ? (
                                          <>
                                            {audioFiles?.map((file, index) => {
                                              let ClnicalDocuments;
                                              const fileExtension =
                                                file?.documentUrl
                                                  .split(".")
                                                  .pop()
                                                  .toLowerCase();
                                              const isAudio = [
                                                "mp3",
                                                "wav",
                                                "ogg",
                                                "m4a",
                                              ].includes(fileExtension);
                                              const mimeTypes = {
                                                mp3: "audio/mpeg",
                                                wav: "audio/wav",
                                                ogg: "audio/ogg",
                                                m4a: "audio/mp4",
                                              };
                                               if (file?.transcript) {
                                                const date = new Date(
                                                  file?.creationTime
                                                );
                                                const day = String(
                                                  date.getDate()
                                                ).padStart(2, "0");
                                                const month = String(
                                                  date.getMonth() + 1
                                                ).padStart(2, "0");
                                                const year = String(
                                                  date.getFullYear()
                                                ).slice(-2);

                                                const formattedDate = `${day}-${month}-${year}`;
                                                return (
                                                  <div className="content-container pt-2 pb-2 pl-4 pr-4">
                                                    <ul>
                                                      <li key={index}>
                                                        <div className="grid grid-row-10 gap-4">
                                                          <div className="row-span-2 flex items-center justify-between ">
                                                            <p className="text-xl">{`Voice Note ${
                                                              index +
                                                              1 +
                                                              "   (" +
                                                              formattedDate +
                                                              ")"
                                                            } `}</p>
                                                            {file?.transcript!="Empty" ? (
                                                              <audio
                                                                controls
                                                                id="AudioTagId"
                                                                key={`${index}-wav`}
                                                                onPlay={() =>
                                                                  handlePlay(
                                                                    file
                                                                  )
                                                                }
                                                                onPause={() =>
                                                                  setCurrentPlaying(
                                                                    null
                                                                  )
                                                                }
                                                                onEnded={() =>
                                                                  setCurrentPlaying(
                                                                    null
                                                                  )
                                                                }
                                                              >
                                                                <source
                                                                  src={`${process.env.REACT_APP_EMRAXIS_S3_URL}/${file?.documentUrl}`}
                                                                  type={
                                                                    mimeTypes[
                                                                      fileExtension
                                                                    ]
                                                                  }
                                                                />
                                                              </audio>
                                                            ) : (
                                                              <p>
                                                              </p>
                                                            )}
                                                          </div>
                                                        </div>
                                                      </li>
                                                      <li
                                                        key={index}
                                                        className="mb-4 pt-4 pb-2 pl-2 pr-2"
                                                      >
                                                        {file?.transcript!="Empty" ? (
                                                          <p className="text-base">
                                                            {file?.transcript}
                                                          </p>
                                                        ) : (
                                                          <p className="text-center  px-4 py-2">
                                                            Voice Notes are not
                                                            present.
                                                          </p>
                                                        )}
                                                      </li>
                                                    </ul>
                                                  </div>
                                                );
                                              }
                                            })}
                                          </>
                                        ) : (
                                          <p className='mb-4 className="text-center border px-4 py-2"'>
                                            No audio files available.
                                          </p>
                                        )}
                                      </div>
                                      <div className="col-span-9 justify-self-start"></div>
                                    </div>
                                  )}
                                </div>
                                {isAiscriptEnable && showClinicalNotes && (
                                  <div className="col-span-1 md:col-span-1 lg:col-span-1 self-center text-right rounded-t-lg dark:bg-gray-800 min-h-auto">
                                    <button
                                      id={"ClinicalNotesBtn"}
                                      onClick={() => {
                                        handleAudioFIles("showVoiceNotes_Id");
                                      }}
                                    >
                                      <img
                                        id="EditIcon"
                                        className={`size-auto mb-1 mr-2 inline cursor-pointer text-red-700`}
                                        src={"image/ClinicalNotesPic.png"}
                                      />
                                    </button>
                                  </div>
                                )}
                                {!showClinicalNotes && (
                                  <div className="col-span-1 md:col-span-1 lg:col-span-1 self-center text-right rounded-t-lg dark:bg-gray-800 min-h-auto">
                                    <button
                                      id="AudioFilesBtn"
                                      onClick={() => {
                                        handleClinicalNotes(
                                          "showClinicalNotes_Id"
                                        );
                                      }}
                                    >
                                      <img
                                        id="AudioEditIcon"
                                        className={`size-auto mb-1 mr-2 inline cursor-pointer text-red-700`}
                                        src={"image/AudioFilesPic.png"}
                                      />
                                    </button>
                                  </div>
                                )}
                              </div>
                            </>
                          )}
                      </div>
                    )}
                    {(loggeduser?.clinicalNoteModelType === " " ||
                          loggeduser?.clinicalNoteModelType === "") && 
                        <textarea
                          ref={textareaRef}
                          id="doctorNotes"
                          readOnly={appointment?.status === "Completed"}
                          rows={
                            isVoice2TextActive &&
                            clinicalNoteData.filter(
                              (a) => a.notes !== "N/A" && a.notes !== ""
                            ).length > 0
                              ? 2
                              : 5
                          }
                          onChange={(evt) => {
                            setDrNotes(evt.target.value);
                            setIsAnyChanges(true);
                            dispatch(setIsGlobalSaveRequired(true));
                          }}
                          className="w-full mt-2 px-2 rounded-md text-sm border border-gray-300 focus:border-gray-500 text-gray-900 bg-white dark:bg-gray-800 focus:ring-0 dark:text-white dark:placeholder-gray-400 h-auto overflow-hidden resize-none"
                          value={drNotes}
                          placeholder="Doctor Notes"
                        ></textarea>
                      }</div>
                    <div>
                      <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-2">
                        {files
                          .filter((a) => a.file_type === "Notes")
                          .map((file, index) => {
                            const fileExtension = file.file_path
                              .split(".")
                              .pop()
                              .toLowerCase();
                            const isImage = [
                              "jpg",
                              "jpeg",
                              "png",
                              "gif",
                              "bmp",
                              "webp",
                            ].includes(fileExtension);
                            const isAudio = [
                              "mp3",
                              "wav",
                              "ogg",
                              "m4a",
                            ].includes(fileExtension);
                            const isVideo = [
                              "mp4",
                              "webm",
                              "ogg",
                              "mov",
                            ].includes(fileExtension);

                            return (
                              <div
                                className="relative border rounded-sm"
                                key={"image-notes-" + index}
                              >
                                <a
                                  rel="noopener"
                                  target="_blank"
                                  href={
                                    process.env.REACT_APP_EMRAXIS_S3_URL +
                                    "/" +
                                    file.file_path
                                  }
                                  className=""
                                >
                                  {isImage ? (
                                    <img
                                      className="max-h-[180px] w-auto h-auto object-contain p-5"
                                      src={
                                        process.env.REACT_APP_EMRAXIS_S3_URL +
                                        "/" +
                                        file.file_path
                                      }
                                      alt="Prescription"
                                    />
                                  ) : isAudio ? (
                                    <div className="flex justify-center items-center h-full">
                                      {" "}
                                      <audio
                                        className="w-full max-w-[400px]"
                                        controls
                                      >
                                        <source
                                          src={
                                            process.env
                                              .REACT_APP_EMRAXIS_S3_URL +
                                            "/" +
                                            file.file_path
                                          }
                                          type={`audio/${fileExtension}`}
                                        />
                                        Your browser does not support the audio
                                        element.
                                      </audio>
                                    </div>
                                  ) : isVideo ? (
                                    <video
                                      className="max-h-[180px] w-auto h-auto object-contain p-5"
                                      controls
                                    >
                                      <source
                                        src={
                                          process.env.REACT_APP_EMRAXIS_S3_URL +
                                          "/" +
                                          file.file_path
                                        }
                                        type={`video/${fileExtension}`}
                                      />
                                      Your browser does not support the video
                                      element.
                                    </video>
                                  ) : (
                                    <div className="flex justify-center items-center h-full">
                                      <FileIcon
                                        ext={"." + fileExtension}
                                        className="max-h-[180px] w-auto h-auto object-contain p-5  "
                                      />
                                    </div>
                                  )}
                                </a>
                                <button
                                  id="btnDeleteDrNoteFile"
                                  className="absolute top-2 left-2 bg-gray-100 rounded-full p-1 shadow"
                                  onClick={() => {
                                    setNotesFileDeleteCnfBox(true);
                                    setNotesFileDeleteID([
                                      { ...file, is_active: false },
                                    ]);
                                  }}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="size-6 text-red-600"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="2"
                                      d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                                    />
                                  </svg>
                                </button>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  </>
                ) : (
                  <p className="text-center text-red-600 p-5">
                    Notes not available!
                  </p>
                )}
              </div>
            ) : (
              <>
                {allClinicalData.length > 0 ? (
                  <div className="w-full rounded-lg bg-gray-50">
                    {allClinicalData.map((data, index) => (
                      <div
                        className={
                          "border border-gray-200" +
                          (index > 2 && !viewMore ? " hidden" : "")
                        }
                        key={"all-notes-" + index}
                      >
                        <div className="bg-red-300 h-6">
                          <p className="text-center font-semibold">
                            <span className="px-1">
                              {DateFunction.GetDate(data.date)}
                            </span>
                          </p>
                        </div>
                        <div className="px-2 pt-2">{data.notes}</div>
                        {data?.audio_notes?.filter(
                          (a) => a.audio_notes !== "N/A" && a.audio_notes !== ""
                        ).length > 0 && (
                          <div className="p-2">
                            {data?.audio_notes
                              ?.filter(
                                (a) =>
                                  a.audio_notes !== "N/A" &&
                                  a.audio_notes !== ""
                              )
                              .map((cn, ind) => (
                                <div
                                  key={"cn-" + ind}
                                  className="flex justify-between my-1 "
                                >
                                  <div>
                                    <p className="break-words">
                                      <span className="font-semibold">
                                        Note {ind + 1}:{" "}
                                      </span>{" "}
                                      {cn.audio_notes}
                                    </p>
                                  </div>
                                  <div>
                                    {cn.audio_file && (
                                      <EMRAudioPlayer
                                        source={
                                          process.env.REACT_APP_EMRAXIS_S3_URL +
                                          "/" +
                                          cn.audio_file
                                        }
                                      />
                                    )}
                                  </div>
                                </div>
                              ))}
                          </div>
                        )}
                        <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-4 gap-2 p-2">
                          {data?.files?.map((file, index) => {
                            const fileExtension = file.upload_file
                              .split(".")
                              .pop()
                              .toLowerCase();
                            const isImage = [
                              "jpg",
                              "jpeg",
                              "png",
                              "gif",
                              "bmp",
                              "webp",
                            ].includes(fileExtension);
                            const isAudio = [
                              "mp3",
                              "wav",
                              "ogg",
                              "m4a",
                            ].includes(fileExtension);
                            const isVideo = [
                              "mp4",
                              "webm",
                              "ogg",
                              "mov",
                            ].includes(fileExtension);

                            return (
                              <div
                                className="relative border rounded-sm"
                                key={"image-notes-" + index}
                              >
                                <a
                                  rel="noopener"
                                  target="_blank"
                                  href={
                                    process.env.REACT_APP_EMRAXIS_S3_URL +
                                    "/" +
                                    file.upload_file
                                  }
                                  className=""
                                >
                                  {isImage ? (
                                    <img
                                      id={"imgDoctorNotesFile" + index}
                                      className="max-h-[180px] w-auto h-auto object-contain p-5"
                                      src={
                                        process.env.REACT_APP_EMRAXIS_S3_URL +
                                        "/" +
                                        file.upload_file
                                      }
                                      alt="Doctor Notes"
                                    />
                                  ) : isAudio ? (
                                    <div className="flex justify-center items-center h-full">
                                      {" "}
                                      <audio
                                        id={"audioDoctorNotesFile" + index}
                                        className="w-full max-w-[400px]"
                                        controls
                                      >
                                        <source
                                          src={
                                            process.env
                                              .REACT_APP_EMRAXIS_S3_URL +
                                            "/" +
                                            file.upload_file
                                          }
                                          type={`audio/${fileExtension}`}
                                        />
                                        Your browser does not support the audio
                                        element.
                                      </audio>
                                    </div>
                                  ) : isVideo ? (
                                    <video
                                      id={"videogDoctorNotesFile" + index}
                                      className="max-h-[180px] w-auto h-auto object-contain p-5"
                                      controls
                                    >
                                      <source
                                        src={
                                          process.env.REACT_APP_EMRAXIS_S3_URL +
                                          "/" +
                                          file.upload_file
                                        }
                                        type={`video/${fileExtension}`}
                                      />
                                      Your browser does not support the video
                                      element.
                                    </video>
                                  ) : (
                                    <div className="flex justify-center items-center h-full">
                                      <FileIcon
                                        id={"fileDoctorNotesFile" + index}
                                        ext={"." + fileExtension}
                                        className="max-h-[180px] w-auto h-auto object-contain p-5  "
                                      />
                                    </div>
                                  )}
                                </a>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    ))}
                    <div className="flex justify-end">
                      {allClinicalData.length > 3 && (
                        <button
                          id="btnViewMoreDrNotes"
                          className="text-red-600 p-2"
                          onClick={() => {
                            setViewMore((vm) => !vm);
                          }}
                        >
                          {viewMore ? "View Less" : "View More"}
                        </button>
                      )}
                    </div>
                  </div>
                ) : (
                  <>
                    {dataLoading && (
                      <p className="text-center p-5">
                        <svg
                          id="imgDrNotesDataLoading"
                          aria-hidden="true"
                          role="status"
                          className="size-6 ml-2 text-red-600 animate-spin"
                          viewBox="0 0 100 101"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                            fill="#E5E7EB"
                          />
                          <path
                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                            fill="currentColor"
                          />
                        </svg>
                      </p>
                    )}
                  </>
                )}
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default DoctorNotes;
