"use client";
import React, { useState, useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import DashboardIcon from './Assets/Dashboard.png';
import DoctorIcon from './Assets/Doctor.png';
import PatientIcon from './Assets/Patient.png';
import AppointmentIcon from './Assets/Appointment.png';
import FeatureIcon from './Assets/Feature.png';
import UsersIcon from './Assets/users.png';
import RolesIcon from './Assets/roles.png';
import InvoiceIcon from './Assets/invoice_icon.png';
import FeedbackIcon from './Assets/ri_feedback-fill.png';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Drawer, Sidebar, TextInput } from "flowbite-react";
import { setIsInvoiceEnabled } from '../Redux/features/invoice/invoiceSlice';
import { setActivateIsStandardAiStatus, setActivateVoice2TextStatus } from '../Redux/features/feature/featureSlice';
import { theme } from '../EmraxisTheme/Theme';
import { setTheme } from '../Redux/features/colors/themeSlice';
import { setIsMobileSidebarOpen } from '../Redux/features/drawer/drawerSlice';
import { addFeedback } from '../Redux/features/feedback/feedbackSlice';
import RecycleBinIcon from "./Assets/recycleBin.svg";

function MobileSideNavBar(props) {
   var loggeduser = useSelector(state => state.login.loginuserData);
   const previewTheme = useSelector(state => state.theme.colors) || theme.defaultTheme;
   var isMobileSidebarOpen = useSelector(state => state.drawer.isMobileSidebarOpen);

   const dispatch = useDispatch();
   const location = useLocation();
   const pathName = location?.pathname?.substring(1);
 
   useEffect(() => {
      if (loggeduser) {
         dispatch(setIsInvoiceEnabled(loggeduser?.addon_features?.filter(a => a.feature === "Invoice" && a.is_active).length > 0));
         dispatch(setActivateVoice2TextStatus(loggeduser?.addon_features?.filter(a => a.feature === "Voice_To_Text" && a.is_active).length > 0))
         dispatch(setActivateIsStandardAiStatus(loggeduser?.addon_features?.filter(a => a.feature === "Standard_AI" && a.is_active).length > 0))
         if (loggeduser.length != 0 && loggeduser.user_role == "SuperAdmin") {
            const themeToPreview = theme.filter(t => t.name == "defaultTheme")[0] || {};
            dispatch(setTheme(themeToPreview))
         }
         else {
            if (loggeduser.length != 0) {
               const themeToPreview = theme.filter(t => t.name == loggeduser?.theme_management?.theme)[0] || {};
               dispatch(setTheme(themeToPreview))
            }
         }
      }

   }, [loggeduser]);




   const [isOpen, setIsOpen] = useState(false);
   const [openMenu, setOpenMenu] = useState(null);

   useEffect(() => {
      if (pathName === "master-tariff" || pathName === "invoices") {
         setOpenMenu('invoice');
      } else {
         setOpenMenu(null);
      }
   }, [pathName]);

   const handleMenuClick = (menu) => {
      setOpenMenu(openMenu === menu ? null : menu);
   };

   const handleFeedback = () => {
      dispatch(addFeedback(true));
  }

   const handleClose = () => setIsOpen(false);
   var isInvoiceEnabled = useSelector(state => state.invoice.isInvoiceEnabled);

   return (
      <>

         <div
            className="flex items-center justify-center text-black">
            <Button id="MobSideBarButtonsideBarOpen"
               className="text-black-500"
               onClick={() => dispatch(setIsMobileSidebarOpen(true))}><svg id="MobSideBarSvgsideBarIcon" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-4 h-4">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
               </svg>
            </Button>
         </div>
         <Drawer id="MobSideBarDrawersideBarClose" open={isMobileSidebarOpen} onClose={() => dispatch(setIsMobileSidebarOpen(false))}>
            <div ><h5 className=" inline-flex items-center text-base font-semibold text-gray-500 dark:text-gray-400" id="MobSideBar:r1:">MENU</h5><button data-testid="MobSideBarclose-drawer" onClick={() => dispatch(setIsMobileSidebarOpen(false))} className="absolute end-2.5 top-2.5 flex h-8 w-8 items-center justify-center rounded-lg bg-transparent text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900 dark:hover:bg-gray-600 dark:hover:text-white"><svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" aria-hidden="true" className="h-6 w-6" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0z"></path><path d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path></svg></button><span className="hidden" id="MobSideBarflowbite-drawer-header-:r3:"></span></div>
            <Drawer.Items >
               <Sidebar
                  aria-label="Sidebar with multi-level dropdown example"
                  className="[&>div]:bg-transparent [&>div]:p-0"
               >
                  <div className="flex h-full flex-col justify-between py-2">
                     <div>
                        {/* <form className="pb-3 md:hidden">
                  <TextInput icon={HiSearch} type="search" placeholder="Search" required size={32} />
                </form> */}
                        {loggeduser != null && loggeduser.length != 0 && loggeduser.user_role != "SuperAdmin" ?
                           <Sidebar.Items>
                              <Sidebar.ItemGroup>
                                 <NavLink id="MobSideBarnotsuperAdminmobLinkdashboard" to="/dashboard" className={`flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:${previewTheme?.navbarBgHoverColor} hover:${previewTheme?.navbarTextColor} group`} onClick={() => dispatch(setIsMobileSidebarOpen(false))}>
                                 <img id="MobSideBarImgdashboardIcon" src={DashboardIcon} alt="Dashboard" className="inline-block w-6 h-6" /><span id="MobSideBarSpandashboardText" className={`ms-3`}>Dashboard</span>
                                 </NavLink>
                                 <NavLink id="MobSideBarnotsuperAdminmobLinkappointments" to="/appointments" className={`flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:${previewTheme?.navbarBgHoverColor} hover:${previewTheme?.navbarTextColor} group`} onClick={() => dispatch(setIsMobileSidebarOpen(false))}>
                                 <img id="MobSideBarImgappointementIcon" src={AppointmentIcon} alt="Appointment"className="inline-block w-6 h-6" /><span id="MobSideBarSpanappointementText" className={`ms-3`}>Appointments</span>
                                 </NavLink>
                                 <NavLink id="MobSideBarnotsuperAdminmobLinkpatients" to="/patients" className={`flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:${previewTheme?.navbarBgHoverColor} hover:${previewTheme?.navbarTextColor} group`} onClick={() => dispatch(setIsMobileSidebarOpen(false))}>
                                 <img id="MobSideBarImgpatientIcon" src={PatientIcon} alt="Patient" className="inline-block w-6 h-6" /><span id="MobSideBarSpanpatientText" className={`ms-3`}>Patients</span>
                                 </NavLink>
                                 <NavLink id="MobSideBarnotsuperAdminLinkdoctor" to="/doctors"  className={`flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:${previewTheme?.navbarBgHoverColor} hover:${previewTheme?.navbarTextColor} group`} onClick={() => dispatch(setIsMobileSidebarOpen(false))}>
                                 <img id="MobSideBarImgdoctorIcon" src={DoctorIcon} alt="Doctor" className="inline-block w-6 h-6" /><span id="MobSideBarSpandoctorText" className={`ms-3`}>Doctors</span>
                                 </NavLink>

                                 {
                                    loggeduser.user_role == "Admin" &&
                                    <>
                                 <NavLink id="MobSideBarmobAdminLinkuserManagement" to="/admin-user-management" className={`flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:${previewTheme?.navbarBgHoverColor} hover:${previewTheme?.navbarTextColor} group`} onClick={() => dispatch(setIsMobileSidebarOpen(false))}>
                                 <img id="MobSideBarImguserManagementIcon"  src={UsersIcon} alt="User Management" className="inline-block w-6 h-6" /><span id="MobSideBarSpanuserManagementText" className={`ms-3`}>Users</span>
                                 </NavLink>
                                 <NavLink id="MobSideBarmobRecycleBin" to="/recyclebin" className={`flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:${previewTheme?.navbarBgHoverColor} hover:${previewTheme?.navbarTextColor} group`} onClick={() => dispatch(setIsMobileSidebarOpen(false))}>
                                 <img id="MobSideBarmobRecycleBinIcon"  src={RecycleBinIcon} alt="RecycleBin" className="inline-block w-6 h-6" /><span id="MobSideBarmobRecycleBinIText" className={`ms-3`}>Recycle Bin</span>
                                 </NavLink>
                                 {/* <NavLink to="/feature" className={`flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:${previewTheme?.navbarBgHoverColor} hover:${previewTheme?.navbarTextColor} group`} onClick={() => dispatch(setIsMobileSidebarOpen(false))}>
                                 <img src={FeatureIcon} alt="Feature Icon" className="inline-block w-6 h-6" /><span className={`ms-3`}>Features</span>
                                 </NavLink> */}
                                      
                                       {isInvoiceEnabled &&
                                          <div className="relative">
                                             <button id="MobSideBarButtonmenuClick" onClick={() => handleMenuClick('invoice')} className={`p-2 text-gray-600 hover:${previewTheme?.navbarBgHoverColor} hover:${previewTheme?.navbarTextColor} w-full flex justify-between items-center  transition duration-200`}>
                                                <span><img id="MobSideBarmobImginvoiceIcon" src={InvoiceIcon} alt="Doctor" className="inline-block w-6 h-6" /> <span id="MobSideBarspaninvoiceText" className={`lg:ml-2 xl:ml-4 lg:text-[14px] xl:text-[16px]`}>Invoice</span></span>
                                                <svg id="MobSideBarSvginvoiceIcon" className={`w-4 h-4 transform transition-transform duration-200 ${openMenu === 'invoice' ? 'rotate-180' : ''}`} fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                   <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
                                                </svg>
                                             </button>
                                             <div className={`mt-2 pl-7 ${openMenu === 'invoice' ? 'block' : 'hidden'}`}>
                                                <NavLink id="MobSideBarmobLinkmasterTariff" to="/master-tariff" className={`flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:${previewTheme?.navbarBgHoverColor} hover:${previewTheme?.navbarTextColor} group`} onClick={() => dispatch(setIsMobileSidebarOpen(false))}>
                                                   <span id="MobSideBarSpanmasterTariffText" className={`ms-3`}>Master Tarrif</span>
                                                </NavLink>
                                                <NavLink id="MobSideBarmobLinkinvoices" to="/invoices" className={`flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:${previewTheme?.navbarBgHoverColor} hover:${previewTheme?.navbarTextColor} group`} onClick={() => dispatch(setIsMobileSidebarOpen(false))}>
                                                   <span id="MobSideBarSpaninvoicesText" className={`ms-3`}>Invoices</span>
                                                </NavLink>
                                             </div>
                                          </div>
                                       }
                                    </>
                                 }
                                 <div id="MobSideBarDivmobSideBarClose" className={`flex items-center p-2 text-gray-900 rounded-lg dark:text-white cursor-pointer hover:${previewTheme?.navbarBgHoverColor} hover:${previewTheme?.navbarTextColor} group`} onClick={() => {dispatch(setIsMobileSidebarOpen(false)); handleFeedback();}}>
                                    <img id="MobSideBarImgfeedbackIcon" src={FeedbackIcon} alt="Feature Icon" className="inline-block w-6 h-6" /><span id="MobSideBarSpanfeedbackText" className={`ms-3`}>Feedback</span>
                                 </div>


                              </Sidebar.ItemGroup>

                           </Sidebar.Items> :
                           loggeduser != null && loggeduser.length != 0 && loggeduser.user_role == "SuperAdmin" ?
                              <Sidebar.Items>
                                 <Sidebar.ItemGroup>
                                    <NavLink id="MobSideBarLinkmobsuperAdminDashboard" to="/" className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-red-200 hover:text-red-400 group" onClick={() => dispatch(setIsMobileSidebarOpen(false))}>
                                       <img id="MobSideBarImgmobsuperAdminDashboardIcon" src={DashboardIcon} alt="Dashboard" className="inline-block w-6 h-6" />
                                       <span id="MobSideBarSpansuperAdminDashboardText" className="ms-3">Dashboard</span>
                                    </NavLink>
                                    <NavLink id="MobSideBarLinkmobsuperAdminFacility" to="/facility" className='flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-red-200 hover:text-red-400 group' onClick={() => dispatch(setIsMobileSidebarOpen(false))}>
                                       <img id="MobSideBarImgmobsuperAdminPatientIcon" src={PatientIcon} alt="Patient" className="inline-block w-6 h-6" />
                                       <span id="MobSideBarSpanmobsuperAdminFaciliyText" className="ms-3">Facility</span>
                                    </NavLink>
                                    <NavLink id="MobSideBarLinkmobsuperAdminUserManagement" to="/user-management" className='flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-red-200 hover:text-red-400 group' onClick={() => dispatch(setIsMobileSidebarOpen(false))}>
                                       <img id="MobSideBarImgmobsuperAdminUserIcon" src={UsersIcon} alt="Doctor" className="inline-block w-6 h-6" />
                                       <span id="MobSideBarSpanmobsuperAdminUserText" className="ms-3">Users</span>
                                    </NavLink>
                                    <NavLink id="MobSideBarLinkmobsuperAdminUseroles" to="/user-roles" className='flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-red-200 hover:text-red-400 group' onClick={() => dispatch(setIsMobileSidebarOpen(false))}>
                                       <img id="MobSideBarImgmobsuperAdminRolesIcon" src={RolesIcon} alt="Doctor" className="inline-block w-6 h-6" />
                                       <span id="MobSideBarSpanmobsuperAdminUserolesText" className="ms-3">User Roles</span>
                                    </NavLink>
                                    <NavLink id="MobSideBarLinkmobsuperAdminReports" to="/excel-reports" className='flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-red-200 hover:text-red-400 group' onClick={() => dispatch(setIsMobileSidebarOpen(false))}>
                                       <img id="MobSideBarImgmobsuperAdminAppointmentIcon" src={AppointmentIcon} alt="Appointment" className="inline-block w-6 h-6" />
                                       <span id="MobSideBarSpanmobsuperAdminReportsText" className="ms-3">Excel Reports</span>
                                    </NavLink>
                                    {/* <NavLink to="/subscriptions" className='flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-red-200 hover:text-red-400 group' onClick={() => dispatch(setIsMobileSidebarOpen(false))}>
                              <img src={AppointmentIcon} alt="Patient" className="inline-block w-6 h-6" />
                              <span className="ms-3">Subscription</span>
                           </NavLink> */}
                                    {/* <NavLink to="/feature" className='flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-red-200 hover:text-red-400 group' onClick={() => dispatch(setIsMobileSidebarOpen(false))}>
                              <img src={FeatureIcon} alt="Feature" className="inline-block w-6 h-6" />
                              <span className="ms-3">Feature</span>
                           </NavLink> */}


                                 </Sidebar.ItemGroup>

                              </Sidebar.Items> : ''}
                     </div>
                  </div>
               </Sidebar>
            </Drawer.Items>
         </Drawer>
      </>
   );
}

export default MobileSideNavBar;
