import React, { useState } from 'react';
import store from '../../API/store';
import { useSelector } from 'react-redux';


const AddFeatures = ({ isOpen, onClose, features }) => {

    var loggedusertoken = useSelector(state => state.login.userBarerToken);
    const [form, setState] = useState({
        feature_id: 0,
        feature_name: "",
        feature_monthly: "",
        feature_yearly: "",
        isperuser:true

    })

    if (!isOpen) return null;

    const clicksave = async (event) => {
        event.preventDefault();
        //check for duplication in the user roles
        var testfound = features.find((x) => x.feature_name.toLowerCase() === form.feature_name.toLowerCase());
        if (testfound == undefined) {
            await store.SaveFeature(loggedusertoken, form)
                .then((result) => {
                    result.json().then((res) => {
                        if (res == true) {
                            alert("added")
                            onClose();
                        }
                        else {
                            alert("failed")
                        }
                    })
                })
                .catch(err => {
                    console.log("Error occured while saving UserRoles " + err)
                })

        }
    }


    const handlerchange = (e) => {
        setState({
            ...form,
            [e.target.name]: e.target.value
        });
    }

    function clickcancel(event) {
        onClose();
    }


    return (
        <div id="AddFeaturesDivaddFeatureContainer"  className="fixed inset-0 flex justify-center items-center p-8">
            <div id="AddFeaturesDivoverlay" className="absolute inset-0 bg-black opacity-25"></div>
            <div id="AddFeaturesDivmodalContent"className="relative bg-white shadow-xl p-8 " style={{ width: "700px" }}>
                <div id="AddFeaturesDivheader" className="flex justify-between items-center mb-6">
                    <div id="AddFeaturesDivtitle" className="text-black font-bold text-2xl">Add feature</div>
                    <button id="AddFeaturesBtnclose" className="text-red-700" onClick={onClose}>
                        <svg id="AddFeaturesSvgcloseIcon" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="h-10 w-10">
                            <path strokeLinecap="round" strokeLinejoin="round" d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                        </svg>
                    </button>
                </div>
                <form>
                    <div className="flex items-center">
                        <div id="AddFeaturesDivnameField" className="mb-4 p-2">

                            <label id="AddFeaturesLabelnameField" className="block text-gray-700 mb-2">Name*</label>
                            <div className="relative">
                                <input type="text" id="AddFeaturesInputfeatureName" name="feature_name" onChange={(event) => { handlerchange(event) }} className="block w-full  border rounded-md focus:outline-none focus:border-gray-500 py-2 pr-10" />
                            </div>

                        </div>
                        <div id="AddFeaturesDivisperUserField" className="mb-4 p-2">
                            <label id="AddFeaturesLabelisperUserField" className="block text-gray-700 mb-2">Feature/User</label>
                            <div className="relative">
                                <input type="text" id="AddFeaturesisperuser" name="Inputisperuser" value={form.feature_isperuser} onChange={(event) => { handlerchange(event) }} className="block w-full border rounded-md focus:outline-none focus:border-gray-500 py-2  pr-10" />
                            </div>

                        </div>
                    </div>
                    <div className="flex items-center">
                    </div>

                    <div className="flex items-center">
                        <div id="AddFeaturesDivmonthlyField" className="mb-4 p-2">
                            <label id="AddFeaturesLabelmonthlyField" className="block text-gray-700 mb-2">feature/Month</label>
                            <div className="relative">
                                <input type="text" id="AddFeaturesfeaturemonth" name="InputfeatureMonthly" onChange={(event) => { handlerchange(event) }} className="block w-full border rounded-md focus:outline-none focus:border-gray-500 pr-10 py-2" />
                            </div>
                        </div>
                        <div  id="AddFeaturesDivyearlyField" className="mb-4 p-2">
                            <label id="AddFeaturesLabelyearlyField" className="block text-gray-700 mb-2">feature/Yearly</label>
                            <div className="relative">
                                <input type="text" id="AddFeaturesfeature_yearly" name="InputfeatureYearly" onChange={(event) => { handlerchange(event) }} className="block w-full border rounded-md focus:outline-none focus:border-gray-500 pr-10 py-2" />
                            </div>
                        </div>
                    </div>


                </form>
                <div id="AddFeaturesDivactions" className="flex space-x-4">
                    <button id="AddFeaturesBtnsave" className="px-4 py-2 border bg-red-700 text-white rounded-md" onClick={(event) => clicksave(event)}>Save</button>
                    <button id="AddFeaturesBtncancel" className="px-4 py-2 border bg-gray-500 text-white rounded-md" onClick={(event) => clickcancel(event)}>Cancel</button>


                </div>
            </div>

        </div>
    );
};

export default AddFeatures;