import React, { useState, useEffect, useRef } from 'react';
import DateFunction from '../../utils/DateFunctions';
import store from '../../API/store';
import { useSelector, useDispatch } from 'react-redux';
import DataDecode from '../../utils/DataDecode';
import { setNotification } from '../../Redux/features/toast/toastSlice';
import { setAddPatientPopup, setPatientAppointment } from '../../Redux/features/popup/popupSlice';
import AddPatient from './AddPatient';
import { theme } from '../../EmraxisTheme/Theme';
import { useLocation } from 'react-router-dom';

const PatientCardDetails = ({ setShowPatient }) => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const queryParamValue = queryParams.get('profile');

    const { patient_id } = JSON.parse(atob(queryParamValue));
    var loggeduser = useSelector(state => state.login.loginuserData);
    var loggedusertoken = useSelector(state => state.login.userBarerToken);
    var showAddPatientpopup = useSelector(state => state.popup.showAddPatient);
    var patientAddAppointment = useSelector(state => state.popup.setPatientAddAppointment);
    const previewTheme = useSelector(state => state.theme.colors) || theme.defaultTheme;
    const [patient, setPatient] = useState({});
    const [appointmentId, setAppointmentId] = useState(null);
    const [appointment, setAppointment] = useState({});
    const [selectedPatient, setSelectedPatient] = useState(null);
    const [openMenuIndex, setOpenMenuIndex] = useState(null);
    const [refreshData, setRefreshData] = useState(false);
    const [openMenu, setOpenMenu] = useState(false);
    const [disableButton, setDisableButton] = useState(false);
    const [deleteId, setDeleteId] = useState(0);
    const [confirmBox, setConfirmBox] = useState(false);
    const [doctors, setDoctors] = useState([]);

    const menuRef = useRef();
    const dispatch = useDispatch();
    const toggleMenu = () => {
        setOpenMenu(prevState => !prevState);
    };
    const imageUrl = `${process.env.REACT_APP_EMRAXIS_S3_URL}/${patient.patient_pic}`;
    useEffect(() => {
        if (showAddPatientpopup) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'unset';
        }
    }, [showAddPatientpopup]);
    const openPopup = () => {
        setSelectedPatient(null);
        dispatch(setAddPatientPopup(true));
        dispatch(setPatientAppointment({ ...patientAddAppointment, path: 'patient' }));
    };

    const handleEditClick = (patient) => {
        dispatch(setAddPatientPopup(true));
        dispatch(setPatientAppointment({ ...patientAddAppointment, path: 'patient' }));
        setSelectedPatient(patient);
        setOpenMenuIndex(null);
        setOpenMenu(null);
    };

    const GetPatient = (guid) => {
        store.GetPatient(loggedusertoken, DataDecode.encryptPayload({ 'clinic_guid': guid, 'patient_id': patient_id }))
            .then((result) => {
                if (result.status == 200) {
                    result.json().then((res) => {
                        let response = DataDecode.decryptResponse(res);

                        setPatient(JSON.parse(response));
                    })
                } else {
                    setPatient({})
                }
            })
            .catch(err => {
                dispatch(setNotification({ message: 'Error Occurred!', status: 'error', action: true }));
            })
    }
    useEffect(() => {
        GetPatient(loggeduser.facilityID)
    }, [loggeduser, refreshData]);
    const GetAppointmnet = (guid) => {
        store.GetAppointmnet(loggedusertoken, DataDecode.encryptPayload({ 'clinic_guid': guid, 'appointment_id': appointmentId }))
            .then((result) => {
                if (result.status == 200) {
                    result.json().then((res) => {
                        let response = DataDecode.decryptResponse(res);

                        setAppointment(JSON.parse(response));
                    })
                } else {
                    // setAppointment({})
                }
            })
            .catch(err => {
                dispatch(setNotification({ message: 'Error Occurred!', status: 'error', action: true }));
            })
    }
    useEffect(() => {
        GetAppointmnet(loggeduser.facilityID)
    }, [loggeduser, refreshData, patient]);

    // useEffect(() => {

    // }, [patient]);

    const handleDeleteSubmit = () => {
        setOpenMenuIndex(null);
        setDisableButton(true);
        store.deletePatient(loggedusertoken, DataDecode.encryptPayload({ 'clinic_guid': loggeduser.facilityID, 'patient_id': deleteId }))
            .then((result) => {
                if (result.status == 204) {
                    setRefreshData(r => !r);
                    dispatch(setNotification({ message: 'Patient Deleted Successfully!', status: 'success', action: true }));
                }
                else if (result.status == 409) {
                    dispatch(setNotification({ message: 'Data available for the selected patient, hence the patient cannot be deleted!', status: 'error', action: true }));
                }
                else {
                    dispatch(setNotification({ message: 'Error Occured!', status: 'error', action: true }));
                }
                setDisableButton(false);
                setConfirmBox(false);
            })
            .catch(err => {
                dispatch(setNotification({ message: 'Error Occurred!', status: 'error', action: true }));
            })
    }
    const deletePatient = (patient_id) => {
        setDeleteId(patient_id)
        setConfirmBox(true);
    }
 
    return (
        <>
            {confirmBox &&
                <div id="PatientsdeletePatient" tabIndex="-1" aria-hidden="true" className="fixed left-1/2 top-1/2 w-full max-w-sm transform  -translate-x-1/2 -translate-y-1/2 sm:w-80 sm:left-[40%]  sm:top-[3rem] sm:translate-x-[50%] sm:translate-y-[50%] z-[50] ">
                    <div className="relative p-4 w-full max-w-md h-full md:h-auto">
                        <div className="relative p-4 text-center bg-white border-gray-400 border-[1px] rounded-lg shadow-lg dark:bg-gray-800 sm:p-5">
                            <svg id="MobPatientDetailsSvgmobConfirmIcon"  className="text-red-800 dark:text-gray-500 w-11 h-11 mb-3.5 mx-auto" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clipRule="evenodd"></path></svg>
                            <p className="mb-4 text-gray-500 dark:text-gray-300">Are you sure you want to delete this patient?</p>
                            <div className="flex justify-center items-center space-x-4">
                                <button id="MobPatientDetailsSvgmobCancelIcon"  onClick={() => setConfirmBox(false)} disabled={disableButton} type="button" className={`py-2 px-3 text-sm font-medium rounded-lg border focus:ring-4 focus:outline-none focus:ring-primary-300 focus:z-10  ease-in-out
                  ${previewTheme.cancelBtnColor}
                  ${previewTheme.cancelBtnBgColor}
                  ${disableButton ? "opacity-50 cursor-not-allowed" : `hover:${previewTheme.cancelBtnHoverColor}`}
                  `}>
                                    No, cancel
                                </button>
                                <button id="MobPatientDetailsSvgmobSureIcon" type="button" onClick={handleDeleteSubmit} disabled={disableButton} className={`py-2 px-3 text-sm font-medium rounded-lg border focus:ring-4 focus:outline-none focus:ring-primary-300 focus:z-10  ease-in-out
                      ${previewTheme.saveBtnColor}
                      ${previewTheme.saveBtnBgColor}
                      ${disableButton ? "opacity-50 cursor-not-allowed" : `hover:${previewTheme.saveBtnHoverColor}`}`}
                                >
                                    Yes, I'm sure {disableButton &&
                                        <svg id="MobPatientDetailsSvgmobConfirmIcon" aria-hidden="true" role="status" className="inline w-4 h-4 ml-2 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB" />
                                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor" />
                                        </svg>
                                    }
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            }

            <div className="mx-auto px-4 lg:py-8 my-20 ">
                <div className='lg:hidden xs:block'>

                    <div className="flex flex-col justify-around">
                        <div className="mt-2 origin-top-left flex font-bold mb-2 lg:text-black text-red-700 lg:text-xl sm:text-xl justify-between items-center">
                            <svg
                                id="MobPatientDetailsSvgmobBackIcon"
                                onClick={() => { setShowPatient(false) }}
                                xmlns="http://www.w3.org/2000/svg"
                                width="18"
                                height="20"
                                viewBox="0 0 8 12"
                                fill="none"
                            >
                                <path d="M7.70492 1.41L6.29492 0L0.294922 6L6.29492 12L7.70492 10.59L3.12492 6L7.70492 1.41Z" className="fill-red-800" />
                            </svg>


                            <td className="px-1 py-1 text-center cursor-pointer">
                                <button id="PatientsBtnmenu" onClick={toggleMenu}>
                                    <svg id="MobPatientsBtnmenuIcon" xmlns="http://www.w3.org/2000/svg" width="6" height="19" viewBox="0 0 6 19" fill="none">
                                        <g opacity="0.5">
                                            <path d="M3 3.86C3.55228 3.86 4 3.44362 4 2.93C4 2.41638 3.55228 2 3 2C2.44772 2 2 2.41638 2 2.93C2 3.44362 2.44772 3.86 3 3.86Z" stroke="#A31B1B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M3 10.68C3.55228 10.68 4 10.2636 4 9.75001C4 9.23638 3.55228 8.82001 3 8.82001C2.44772 8.82001 2 9.23638 2 9.75001C2 10.2636 2.44772 10.68 3 10.68Z" stroke="#A31B1B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M3 17.5C3.55228 17.5 4 17.0836 4 16.57C4 16.0564 3.55228 15.64 3 15.64C2.44772 15.64 2 16.0564 2 16.57C2 17.0836 2.44772 17.5 3 17.5Z" stroke="#A31B1B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        </g>
                                    </svg>
                                </button>
                                {openMenu && (
                                    <div id="MobPatientsDivmobileActionMenu" ref={menuRef} className="absolute mt-[-30px] right-6 w-30 bg-white border border-gray-300 rounded-md z-20">
                                        <ul>

                                            <li id="PatientsLImobileDeleteAction" ref={menuRef}
                                                className="px-3 py-1 hover:bg-gray-100 cursor-pointer flex items-center gap-2 text-gray-800"
                                                onClick={() => handleEditClick(patient)}>
                                                <svg id="PatientDetailsSvgmobileEditActionIcon" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                                    <path d="M2.25 12.9375V15.75H5.0625L13.3575 7.455L10.545 4.6425L2.25 12.9375ZM15.5325 5.28C15.602 5.21062 15.6572 5.1282 15.6948 5.03747C15.7325 4.94674 15.7518 4.84948 15.7518 4.75125C15.7518 4.65303 15.7325 4.55576 15.6948 4.46503C15.6572 4.3743 15.602 4.29189 15.5325 4.2225L13.7775 2.4675C13.7081 2.39797 13.6257 2.34281 13.535 2.30518C13.4442 2.26754 13.347 2.24817 13.2488 2.24817C13.1505 2.24817 13.0533 2.26754 12.9625 2.30518C12.8718 2.34281 12.7894 2.39797 12.72 2.4675L11.3475 3.84L14.16 6.6525L15.5325 5.28Z" fill="black" />
                                                </svg>
                                                Edit
                                            </li>

                                        </ul>
                                    </div>
                                )}
                            </td>
                        </div>
                    </div>

                    <div className="flex flex-col items-center justify-center w-full text-center">
                        <div className="w-24 h-24 flex items-center justify-center mb-2">
                            {patient.patient_pic ? (
                                <img
                                    id="PatientProfileIMGuserImage"
                                    className="w-full h-full object-cover bg-slate-300 rounded-2xl"
                                    src={`${process.env.REACT_APP_EMRAXIS_S3_URL}/${patient.patient_pic}`}
                                    alt="patient"
                                />
                            ) : (
                                <img
                                    id="PatientProfileIMGdefaultImage"
                                    className="w-full h-full object-cover bg-slate-300 rounded-2xl"
                                    src="/image/user.png"
                                    alt="default user"
                                />
                            )}
                        </div>
                        <span id="MobPatientDetailmobName" className="font-semibold text-center">
                            {`${patient.FirstName} ${patient.MiddleName?.charAt(0)} ${patient.LastName}`}
                        </span>
                    </div>


                    {/* Details List */}
                    <div className='space-y-2'>
                        <div className="bg-gray-100 rounded-lg shadow-sm p-4">
                            <div className='rounded-lg relative text-left'>
                                <div className='flex mb-2'>
                                    <p className='text-gray-700  w-[97px]' id='Age'>Age :</p>
                                    <p className='break-all ml-[5px]'>{patient && patient?.dob && DateFunction.GetAge(patient?.dob)}</p>
                                </div>


                                <div className='flex mb-2'>
                                    <p className='text-gray-700 w-[97px]' id='PId'>Patient ID :</p>
                                    <p className='text-black break-all ml-[5px]'>{patient?.patient_op_number}</p>
                                </div>
                                <div className='flex mb-2'>
                                    <p className='text-gray-700 w-[97px]' id='BLDGRP'>Blood Group:</p>
                                    <p className=' break-all ml-[5px]'>{patient?.blood_group}</p>
                                </div>
                                <div className='flex mb-2'>
                                    <p className='text-gray-700 w-[97px]' id='Email'>Email :</p>
                                    <p className=' break-all ml-[5px]'>{patient?.email_id}</p>
                                </div>
                                <div className='flex mb-2'>
                                    <p className='text-gray-700 w-[97px]' id='Mobile'>Mobile :</p>
                                    <p className='ml-[5px]'>{patient?.mobile_number}</p>
                                </div>
                                <div className='flex mb-2'>
                                    <p className='text-gray-700 w-[97px]' id='Dob'>DOB :</p>
                                    <p className=' ml-[5px]'>{patient && patient?.dob && DateFunction.GetDate(patient?.dob)}</p>
                                </div>
                            </div>
                        </div>




                        <div className="bg-gray-100 rounded-lg shadow-sm p-2">
                            <div className='rounded-lg relative '>
                                <button  id="MobPatientDetailsSvgmobDetailsIcon" className="w-full flex items-center  gap-4 p-2 text-gray-700 hover:bg-gray-50 rounded-md" onTouchStart={() => { deletePatient(patient.patient_id); setTimeout(() => { toggleMenu(false); }, 200) }} onClick={() => { deletePatient(patient.patient_id); toggleMenu(false); }}>

                                    <svg
                                        id="PatientDetailsSvggetDeleteIcon"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 24 24"
                                        fill="currentColor"
                                        className="w-6 h-6 text-red-800 justify-start"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            d="M16.5 4.478v.227a48.816 48.816 0 0 1 3.878.512.75.75 0 1 1-.256 1.478l-.209-.035-1.005 13.07a3 3 0 0 1-2.991 2.77H8.084a3 3 0 0 1-2.991-2.77L4.087 6.66l-.209.035a.75.75 0 0 1-.256-1.478A48.567 48.567 0 0 1 7.5 4.705v-.227c0-1.564 1.213-2.9 2.816-2.951a52.662 52.662 0 0 1 3.369 0c1.603.051 2.815 1.387 2.815 2.951Zm-6.136-1.452a51.196 51.196 0 0 1 3.273 0C14.39 3.05 15 3.684 15 4.478v.113a49.488 49.488 0 0 0-6 0v-.113c0-.794.609-1.428 1.364-1.452Zm-.355 5.945a.75.75 0 1 0-1.5.058l.347 9a.75.75 0 1 0 1.499-.058l-.346-9Zm5.48.058a.75.75 0 1 0-1.498-.058l-.347 9a.75.75 0 0 0 1.5.058l.345-9Z"
                                            clipRule="evenodd"
                                        />
                                    </svg>
                                    Delete Profile
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {showAddPatientpopup && <AddPatient doctors={doctors} setRefreshData={setRefreshData} patient={selectedPatient} />}
        </>
    );
};

export default PatientCardDetails;