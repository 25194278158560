import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setNotification } from '../../../../Redux/features/toast/toastSlice';
import store from '../../../../API/store';
import DataDecode from '../../../../utils/DataDecode';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import '../../../overrides.css';
import PrescriptionPrintPreview from '../PrescriptionPrintPreview';
import UploadPrescription from '../UploadPrescription';
import CreatableSelect from 'react-select/creatable';
import { theme } from '../../../../EmraxisTheme/Theme';
import { HiMiniPlusCircle } from "react-icons/hi2";
import { setAddAppointmentPopup, setPatientAppointment } from '../../../../Redux/features/popup/popupSlice';
import FileIcon from '../FileIcon';
import { IoMdSave } from 'react-icons/io';
import DateFunction from '../../../../utils/DateFunctions';
import { setIsGlobalSaveRequired } from '../../../../Redux/features/profileDrawer/profileSideNavbarSlice';
import { setDocumentType, setIsCameraUpload, setIsWithoutAppointment, setShowUploadPopup } from '../../../../Redux/features/uploadDoc/uploadDocSlice';

const Prescription = ({ appointment, clinicGuid, patient, doctor_id, appointment_id, row, page_src, patient_id}) => {
    const dispatch = useDispatch();
    const [file, setFile] = useState(null);
    const [fileList, setFileList] = useState([]);
    const [submitDisable, setSubmitDisable] = useState(false);
    const [loadingSave, setLoadingSave] = useState(false);
    const [loadingSavePrint, setLoadingSavePrint] = useState(false);
    const [nextReview, setNextReview] = useState(null);
    const [expandRow, setExpandRow] = useState(true);
    const [uploadPrescription, setUploadPrescription] = useState(false);
    const [refreshImage, setRefreshImage] = useState(false);
    const [typeScript, setTypeScript] = useState(false);
    const [confirmBox, setConfirmBox] = useState(false);
    const [confirmBoxDeleteAll, setConfirmBoxDeleteAll] = useState(false);
    const [loadingDeleteImage, setLoadingDeleteImage] = useState(false);
    const [deleteFile, setDeleteFile] = useState([]);
    const [printPreview, setPrintPreview] = useState(false);
    const [medicineOptions, setMedicineOptions] = useState([]);
    const [medicineDetails, setMedicineDetails] = useState("");
    const [inputValue, setInputValue] = useState('');
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [isPrescriptionOpen, setIsPrescriptionOpen] = useState(false);
    const [formType, setFormType] = useState('');
    const [selectedRow, setSelectedRow] = useState(null);
    const [editingIndex, setEditingIndex] = useState(null);
    const [isMessagePopupOpen, setIsMessagePopupOpen] = useState(null);
    const [doctors, setDoctors] = useState([]);
    const [refreshData, setRefreshData] = useState(false);
    const [medicineSelectedValues, setMedicineSelectedValues] = useState({});
    const [allPrescriptions, setAllPrescriptions] = useState([]);
    const [dataLoading, setDataLoading] = useState(true);
    const [showAll, setShowAll] = useState(false);
    const [isAnyChanges, setIsAnyChanges] = useState(false);
    const [loadInitialMedidcineStatus, setLoadInitialMedidcineStatus] = useState({ medicine: false, existingRows: false });

    const [checkNewPrescription, setCheckNewPrescription] = useState({
        file: 'initial',
        entry: 'initial'
    });

    var loggedusertoken = useSelector(state => state.login.userBarerToken);
    var loggeduser = useSelector(state => state.login.loginuserData);
    const previewTheme = useSelector(state => state.theme.colors) || theme.filter(a => a.name === "defaultTheme")[0];
    const [newMedicine, setNewMedicine] = useState({ order_id: 1, medicine: '', medicine_type: 'Tablet', when_medidcine: false, morning: '', noon: '', evening: '', night: '', duration: '', total: 'N/A', other: '' });
    const [rows, setRows] = useState([]);
    const [enterPrescriptionExist, setEnterPrescriptionExist] = useState(false);
    var refreshPrescription = useSelector(state => state.document_upload.refreshPrescription);
    var patientAddAppointment = useSelector(state => state.popup.setPatientAddAppointment);
    var autoSaveAll = useSelector(state => state.profileSideNavbar.autoSaveAll);
    var documentType = useSelector(state => state.document_upload.documentType);

    const handleMessagePopupOpen = () => {
        setIsMessagePopupOpen(!isMessagePopupOpen);
    }
    const openAppointmentPopup = () => {
        dispatch(setAddAppointmentPopup(true));
        dispatch(setPatientAppointment({ isOpenAppointment: true, patient: patient, path: 'appointment' }))
    };
    const openPopup = () => {
        if (appointment_id == 0 || (appointment && (appointment.status == 'Completed'))) {
            handleMessagePopupOpen(true);
        } else {
            addRow();
            setIsPrescriptionOpen(true);
            setTypeScript(true);
        }
    }
    useEffect(() => {
        if (patientAddAppointment?.isOpenAppointment) {
            dispatch(setAddAppointmentPopup(true));
        }
    }, [patientAddAppointment])
    const closePopup = () => {
        setIsPrescriptionOpen(false);
        addRow();
    };
    const handleFormTypeChange = (e) => {
        setFormType(e.target.value);
    };

    useEffect(() => {
        if (patient?.patient_id > 0 && appointment?.appointment_id > 0) {
            GetPrescription();
            GetPatientFiles();
            setNextReview(appointment.nextReview ? appointment.nextReview : null)
        }
    }, [appointment, patient])

    const GetPrescription = () => {
        if (appointment && appointment?.appointment_id) {
            store.GetPrescription(loggedusertoken, DataDecode.encryptPayload({ appointment_id: appointment.appointment_id, 'clinic_guid': clinicGuid }))
                .then((result) => {
                    if (result.status == 200) {
                        result.json().then((res) => {
                            let response = DataDecode.decryptResponse(res);
                            let val = JSON.parse(response);
                            setRows(val);
                            if (val.length > 0) {
                                setLoadInitialMedidcineStatus({ ...loadInitialMedidcineStatus, existingRows: true })
                                setTimeout(() => {
                                    setCheckNewPrescription({
                                        ...checkNewPrescription,
                                        entry: 'exist'
                                    })
                                    setExpandRow(true);
                                }, 100)
                                // setIsPrescriptionOpen(true);
                                setEnterPrescriptionExist(true)
                                setTypeScript(true);
                            } else {
                                setTimeout(() => {
                                    setCheckNewPrescription({
                                        ...checkNewPrescription,
                                        entry: 'na'
                                    })
                                }, 100)
                            }
                        })
                    } else {
                        setCheckNewPrescription({
                            ...checkNewPrescription,
                            entry: 'na'
                        })
                        setRows([{ order_id: 1, medicine: '', medicine_type: 'Tablet', when_medidcine: false, morning: '', noon: '', evening: '', night: '', duration: '', total: 'N/A', other: '' }])
                    }
                })
                .catch(err => {
                    dispatch(setNotification({ message: 'Error occurred', status: 'error', action: true }));
                })
        }
    }

    const GetPatientFiles = () => {
        store.GetPatientFiles(loggedusertoken, DataDecode.encryptPayload({ 'clinic_guid': clinicGuid, 'patient_id': patient.patient_id, 'appointment_id': appointment.appointment_id }))
            .then((result) => {
                if (result.status == 200) {
                    result.json().then((res) => {
                        let response = DataDecode.decryptResponse(res);
                        let data = JSON.parse(response);
                        let pres = data.filter(a => a.file_type === 'Prescription');
                        setFile(pres.length > 0 ? pres[0] : null);
                        setFileList(pres.length > 0 ? pres : []);
                        setExpandRow(pres.length > 0 ? true : false);
                        setCheckNewPrescription({
                            ...checkNewPrescription,
                            file: (pres.length > 0 ? 'exist' : 'na')
                        })
                    })
                }
                else if (result.status == 404) {
                    setFile(null)
                    setFileList([])
                    setCheckNewPrescription({
                        ...checkNewPrescription,
                        file: 'na'
                    })
                }
            })
            .catch(err => {
                dispatch(setNotification({ message: 'Error occurred', status: 'error', action: true }));
            })
    }

    const GetPastPrescription = () => {
        store.GetPastPrescription(loggedusertoken, DataDecode.encryptPayload({ 'clinic_guid': clinicGuid, 'patient_id': patient.patient_id, 'appointment_id': appointment.appointment_id }))
            .then((result) => {
                if (result.status == 200) {
                    result.json().then((res) => {
                        let response = DataDecode.decryptResponse(res);
                        if (response === 'file') {
                            setTimeout(() => {
                                GetPatientFiles();
                            }, 500)
                        }
                        else if (response === 'entry') {
                            setTimeout(() => {
                                GetPrescription();
                            }, 500)
                        }
                    })
                }
            })
            .catch(err => {
                dispatch(setNotification({ message: 'Error occurred', status: 'error', action: true }));
            })
    }

    useEffect(() => {
        if (checkNewPrescription.entry === 'na' && checkNewPrescription.file === 'na' && patient?.patient_id > 0 && appointment?.appointment_id > 0) {
            GetPastPrescription();
        }
    }, [checkNewPrescription])

    useEffect(() => {
        if (clinicGuid && patient?.patient_id > 0 && appointment?.appointment_id > 0) {
            GetPatientFiles();
        }
    }, [refreshImage, clinicGuid, patient, appointment, refreshPrescription])

    const addRow = () => {
        setNewMedicine({
            order_id: 1,
            medicine: '',
            medicine_type: 'Tablet',
            when_medidcine: false,
            morning: '',
            noon: '',
            evening: '',
            night: '',
            duration: '',
            total: 'N/A',
            other: ''
        });
    };
    const addRowMedicine = () => {

        if (newMedicine.medicine.trim() === "" || newMedicine.duration.trim() === "") {
            dispatch(setNotification({ message: '* Fields are required!', status: 'error', action: true }));
            return;
        }
        if (editingIndex !== null) {
            const updatedRows = rows.map((row, index) =>
                index === editingIndex ? { ...newMedicine } : row
            );
            setRows(updatedRows);
            setEditingIndex(null);
        } else {
            const maxValue = Math.max(...rows.filter(f => f.medicine !== "").map(obj => obj.order_id));
            const newRow = {
                order_id: maxValue < rows.length + 1 ? rows.length + 1 : maxValue + 1,
                medicine: newMedicine.medicine,
                medicine_type: newMedicine.medicine_type,
                when_medidcine: newMedicine.when_medidcine,
                morning: newMedicine.morning,
                noon: newMedicine.noon,
                evening: newMedicine.evening,
                night: newMedicine.night,
                duration: newMedicine.duration,
                total: newMedicine.total,
                other: newMedicine.other
            };
            if (rows.length == 1 && rows[0].medicine === "") {
                setRows([newRow]);
            } else {
                setRows([...rows, newRow]);
            }
        }
        setTimeout(() => {
            addRow();
        }, 100);
    };



    const deleteRow = (id) => {
        const updatedRows = rows.filter(row => row.order_id !== id);
        if (updatedRows.length > 0) {
            setRows(updatedRows);
            setIsAnyChanges(true);
            dispatch(setIsGlobalSaveRequired(true));
            setMedicineSelectedValues(prevValues => {
                const newValues = { ...prevValues };
                delete newValues[id];
                return newValues;
            });
        } else {
            dispatch(setNotification({ message: 'Minimum one row required!', status: 'error', action: true }));
        }
    };
    const fetchMedicineData = () => {
        try {
            store.GetAllMedicine(loggedusertoken, DataDecode.encryptPayload({ 'clinic_guid': clinicGuid }))
                .then(response => {
                    if (response.status == 200) {
                        response.json().then(res => {
                            const decryptedResponse = DataDecode.decryptResponse(res);
                            const data = JSON.parse(decryptedResponse);
                            const options = data.map(val => ({
                                value: val.medicine_id,
                                label: val.medicine_name,

                            }));
                            setMedicineOptions(options);
                            setLoadInitialMedidcineStatus({ ...loadInitialMedidcineStatus, medicine: true })
                        })
                    } else {
                        setMedicineOptions([]);
                    }
                })
        } catch (err) {
            dispatch(setNotification({ message: 'Error occurred', status: 'error', action: true }));
            setMedicineOptions([]);
        }
    };

    async function validateMedicine(inputValue) {
        const apiUrl = process.env.REACT_APP_EMRAXIS_MEDICINEVALIDATION + encodeURIComponent(inputValue);
        const response = await fetch(apiUrl);
        if (response.status == 200) {
            return true;
        }else {
            return false;
        }
    }

    useEffect(() => {
        setTimeout(() => {
            if (loadInitialMedidcineStatus.existingRows && loadInitialMedidcineStatus.medicine) {
                let updatedMedicine = {};
                rows.map((r) => {
                    let seletedMedicine = medicineOptions.filter(a => a.label?.trim()?.toLowerCase() === r.medicine?.trim()?.toLowerCase())
                    if (seletedMedicine.length > 0) {
                        updatedMedicine[r.order_id] = seletedMedicine[0];
                    }
                });
                setTimeout(() => {
                    setMedicineSelectedValues({
                        ...medicineSelectedValues,
                        ...updatedMedicine
                    });
                }, 100)
            }
        }, 100)
    }, [loadInitialMedidcineStatus])

    useEffect(() => {
        fetchMedicineData()
    }, [clinicGuid, loggedusertoken]);

    const handleChangeMedicine = (id, event, name = 'medicine') => {
        setIsAnyChanges(true);
        dispatch(setIsGlobalSaveRequired(true));
        if (event && event.target) {
            const { value, checked, type } = event.target;
            if (type === 'checkbox') {
                setNewMedicine(prev => ({
                    ...prev,
                    [name]: name === "when_medidcine" ? checked : checked ? "1" : "0"
                }));
            } else if (name === 'duration') {
                const numericValue = value.replace(/[^0-9]/g, '');
                setNewMedicine(prev => ({
                    ...prev,
                    [name]: numericValue
                }));
            } else {
                setNewMedicine(prev => ({
                    ...prev,
                    [name]: value
                }));
            }
        } else if (event.label || event) {
            const value = event.label || event;
            setNewMedicine(prev => ({
                ...prev,
                [name]: value
            }));
        }
        if (name === 'medicine') {
            setMedicineSelectedValues({
                ...medicineSelectedValues,
                [id]: event
            });
        }
    };


    const handleEditClick = (row, index) => {
        setNewMedicine(row);
        setEditingIndex(index);
        setIsPrescriptionOpen(true);
    };

    const handleCreateChangeMedicine = async (id, inputValue) => {
        try {
            let checkExixtingRow = rows.filter(a=>a.medicine?.toLowerCase() === inputValue?.toLowerCase())
            if(checkExixtingRow?.length > 0){
                dispatch(setNotification({ message: 'Medicine already added in the list!', status: 'info', action: true }));
            }else{
            const isValidMedicine = await validateMedicine(inputValue);
            if (isValidMedicine) {
                const updatedRows = rows.map(row =>
                    row.order_id === id ? { ...row, medicine: inputValue } : row
                );
                setRows(updatedRows);
            } else {
                dispatch(setNotification({ message: 'Please enter valid medicine name!', status: 'error', action: true }));
            }}
        } catch (error) {
            dispatch(setNotification({ message: 'Error occurred', status: 'error', action: true }));
        }
    };

    const savePrescription = (save_type) => {
        let exist = rows.filter(a => a.medicine.trim() === "" || a.duration == 0 || a.duration == "")
        if (exist.length > 0 && typeScript) {
            dispatch(setNotification({ message: '* Fields are required!', status: 'error', action: true }));
        }
        else if (!file && !enterPrescriptionExist && exist > 0) {
            dispatch(setNotification({ message: 'Please add prescription!', status: 'error', action: true }));
        }
        else {
            setSubmitDisable(true);
            if (save_type === 'save') {
                setLoadingSave(true);
            } else {
                setLoadingSavePrint(true);
            }
            let payload = { appointment_id: appointment.appointment_id, 'clinic_guid': clinicGuid, 'prescriptions': rows, patient_id: patient.patient_id, doctor_id: doctor_id, nextReview: nextReview, type_script: typeScript, medicineDetails: medicineDetails, inputValue: inputValue }
            store.AddPrescription(loggedusertoken, DataDecode.encryptPayload(payload))
                .then((result) => {
                    setSubmitDisable(false);
                    setIsAnyChanges(false);
                    if (result.status == 201) {
                        if (save_type === 'save') {
                            setLoadingSave(false);
                        } else {
                            setPrintPreview(true);
                            setLoadingSavePrint(false);
                        }
                        dispatch(setNotification({ message: "Prescription Added", status: 'success', action: true }));
                    }
                    else {
                        setLoadingSave(false);
                        setLoadingSavePrint(false);
                        dispatch(setNotification({ message: 'Error Occured!', status: 'error', action: true }));
                    }
                })
                .catch(err => {
                    dispatch(setNotification({ message: 'Error Occurred!', status: 'error', action: true }));
                });
        }
    };

    useEffect(() => {
        let exist = rows.filter(a => a.medicine.trim() !== "" || a.duration != 0 || a.duration !== "")
        if (isAnyChanges && exist.length > 0) {
            savePrescription('save')
        }
    }, [autoSaveAll])
    const addMonths = (date, months) => {
        var newDate = new Date(date);
        newDate.setMonth(newDate.getMonth() + months);
        return newDate;
    }
    const handlePrescriptionType = (type) => {
        if (appointment_id == 0 || (appointment && (appointment.status == 'Completed'))) {
            handleMessagePopupOpen(true);
        } else {
            if (type === "image") {
                if (enterPrescriptionExist) {
                    dispatch(setNotification({ message: 'Please delete the entered prescriptions!', status: 'error', action: true }));
                } else {
                    setTypeScript(false);
                    setUploadPrescription(true)
                }
            } else {
                if (file) {
                    dispatch(setNotification({ message: 'Please delete the file prescription!', status: 'error', action: true }));
                } else {
                    setExpandRow(true);
                    setTypeScript(true);
                }

            }
        }
    }
    const handlePrescriptionSvgClick = () => {
        setExpandRow(!expandRow)
    }

    const deletePrescriptionFile = () => {
        setLoadingDeleteImage(true);
        store.DeletePatientFile(loggedusertoken, DataDecode.encryptPayload({ 'clinic_guid': clinicGuid, 'patient_id': patient.patient_id, files: deleteFile }))
            .then((result) => {
                setLoadingDeleteImage(false)
                setConfirmBox(false)
                if (result.status == 204) {
                    setRefreshImage(o => !o);
                    dispatch(setNotification({ message: 'Deleted Successfully!', status: 'success', action: true }));
                }
                else {
                    dispatch(setNotification({ message: 'Error Occurred!', status: 'error', action: true }));
                }

            })
            .catch((error) => {
                console.error("Error occurred while saving patient details:", error);
            });
    }

    const deleteEnteredPrescriptions = () => {
        setLoadingDeleteImage(true);
        store.DeleteEnteredPrescriptions(loggedusertoken, DataDecode.encryptPayload({ 'clinic_guid': clinicGuid, 'patient_id': patient.patient_id, appointment_id: appointment.appointment_id }))
            .then((result) => {
                setLoadingDeleteImage(false)
                setConfirmBoxDeleteAll(false);
                if (result.status == 204) {
                    GetPrescription();
                    setEnterPrescriptionExist(false);
                    dispatch(setNotification({ message: 'Deleted Successfully!', status: 'success', action: true }));
                }
                else {
                    dispatch(setNotification({ message: 'Error Occurred!', status: 'error', action: true }));
                }
            })
            .catch((error) => {
                console.error("Error occurred while saving patient details:", error);
            });
    }

    const getAvailableOptions = () => {
        const selectedOptions = Object.values(medicineSelectedValues).map(option => option?.value);
        return medicineOptions.filter(option => !selectedOptions.includes(option.value));
    };

    const GetAllPatientPrescriptions = () => {
        if (allPrescriptions.length == 0) {
            setDataLoading(true);
        }
        store.GetAllPatientPrescriptions(loggedusertoken, DataDecode.encryptPayload({ 'clinic_guid': clinicGuid, 'patient_id': patient_id }))
            .then((result) => {
                setDataLoading(false);
                if (result.status == 200) {
                    result.json().then((res) => {
                        let response = DataDecode.decryptResponse(res);
                        setAllPrescriptions(JSON.parse(response));
                    })
                } else {
                    setAllPrescriptions([])
                }
            })
            .catch(err => {
                setDataLoading(false);
                setAllPrescriptions([])
                dispatch(setNotification({ message: 'Error occurred', status: 'error', action: true }));
            })
    }

    useEffect(() => {
        if (page_src === "patient") {
            GetAllPatientPrescriptions();
        }
    }, [refreshPrescription])

    const handleUploadButton = (type) => {
        dispatch(setDocumentType({ ...documentType, prescription: true }))
        dispatch(setIsCameraUpload(type === "capture" ? true : false));
        if (appointment_id == 0 || (appointment && (appointment.status == 'Completed'))) {
            setIsMessagePopupOpen(p => !p);
        } else {
            dispatch(setShowUploadPopup(true))
        }
    }

    const handleUploadWithoutAppointment = () => {
        setIsMessagePopupOpen(false);
        dispatch(setShowUploadPopup(true));
        dispatch(setIsWithoutAppointment(true));
    }

    return (
        <>
            {printPreview && <PrescriptionPrintPreview setPrintPreview={setPrintPreview} patient={patient} medicines={rows} nextReview={nextReview} appointment={appointment} file={file} />}
            {confirmBox &&
                <div id="delete" tabIndex="-1" aria-hidden="true" className="fixed z-[9999] lg:left-[40%] lg:w-80 top-[10rem] lg:top-[3rem] lg:translate-x-[50%] translate-y-[50%]">

                    <div className="relative p-4 w-full max-w-md h-full md:h-auto">
                        <div className="relative p-4 text-center bg-white border-gray-400 border-[1px] rounded-lg shadow-lg dark:bg-gray-800 sm:p-5">
                            <svg className="text-red-600 dark:text-gray-500 w-11 h-11 mb-3.5 mx-auto" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clipRule="evenodd"></path></svg>
                            <p className="mr-2 py-2 px-3 text-sm font-medium text-gray-500 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-primary-300 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600">Are you sure you want to delete this file?</p>
                            <div className="flex justify-center items-center space-x-4">
                                <button id='BtnDelprescfileCancelButton' onClick={() => setConfirmBox(false)} disabled={loadingDeleteImage} type="button" className={`mr-2 py-2 px-3 text-sm font-medium rounded-lg border border-gray-200 focus:ring-4 focus:outline-none focus:ring-primary-300 focus:z-10 ${previewTheme.cancelBtnColor} ${previewTheme.cancelBtnBgColor} hover:${previewTheme.cancelBtnHoverColor}`}>
                                    No, cancel
                                </button>
                                <button id='BtnDelprescfileYesButton' type="button" onClick={deletePrescriptionFile} disabled={loadingDeleteImage} className="py-2 px-3 text-sm font-medium text-center text-white bg-red-600 rounded-lg hover:bg-red-700 focus:ring-4 focus:outline-none focus:ring-red-300 dark:bg-red-500 dark:hover:bg-red-600 dark:focus:ring-red-900 disabled:opacity-50 disabled:cursor-not-allowed">
                                    Yes, I'm sure {loadingDeleteImage &&
                                        <svg aria-hidden="true" role="status" className="inline w-4 h-4 ml-2 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB" />
                                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor" />
                                        </svg>
                                    }
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {confirmBoxDeleteAll &&
                <div id="delete" tabIndex="-1" aria-hidden="true" className="fixed z-[9999] lg:left-[40%] lg:w-80 top-[10rem] lg:top-[3rem] lg:translate-x-[50%] translate-y-[50%]">
                    <div className="relative p-4 w-full max-w-md h-full md:h-auto">
                        <div className="relative p-4 text-center bg-white border-gray-400 border-[1px] rounded-lg shadow-lg dark:bg-gray-800 sm:p-5">
                            <svg className="text-red-600 dark:text-gray-500 w-11 h-11 mb-3.5 mx-auto" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clipRule="evenodd"></path></svg>
                            <p className="mb-4 text-gray-500 dark:text-gray-300">Are you sure you want to delete all prescriptions?</p>
                            <div className="flex justify-center items-center space-x-4">
                                <button id='BtnDelPrescCancelButton' onClick={() => setConfirmBoxDeleteAll(false)} disabled={loadingDeleteImage} type="button" className={`py-2 px-3 text-sm font-medium rounded-lg border border-gray-200 hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-primary-300  focus:z-10 ${previewTheme.cancelBtnColor} ${previewTheme.cancelBtnBgColor} hover:${previewTheme.cancelBtnHoverColor}`}>
                                    No, cancel
                                </button>
                                <button id='BtnDelPrescYesButton' type="button" onClick={deleteEnteredPrescriptions} disabled={loadingDeleteImage} className={`py-2 px-3 text-sm font-medium text-center rounded-lg focus:ring-4 focus:outline-none disabled:opacity-50 disabled:cursor-not-allowed ${previewTheme.saveBtnColor} ${previewTheme.saveBtnBgColor} hover:${previewTheme.saveBtnHoverColor}`}>
                                    Yes, I'm sure {loadingDeleteImage &&
                                        <svg aria-hidden="true" role="status" className="inline w-4 h-4 ml-2 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB" />
                                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor" />
                                        </svg>
                                    }
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {isMessagePopupOpen &&
                <div className="fixed lg:left-[40%] w-80 left-[10%] top-[30%] z-[50]">
                    <div className="relative p-4 w-full max-w-md h-full md:h-auto">
                        <div className="relative p-4 text-center bg-white border-gray-400 border-[1px] rounded-lg shadow-lg dark:bg-gray-800 sm:p-5">
                            <div className="mb-4 text-gray-500 dark:text-gray-300">
                                <p>No Appointments scheduled.</p>
                                <p>Do you want to schedule an appointment?</p>
                            </div>
                            <div className="flex justify-center items-center space-x-4">
                                <button id='BtnCancelButton' onClick={handleUploadWithoutAppointment} className={`py-2 px-3 text-sm font-medium rounded-lg border border-gray-200 focus:ring-4 focus:outline-none focus:ring-primary-300 focus:z-10 ${previewTheme.cancelBtnColor} ${previewTheme.cancelBtnBgColor} hover:${previewTheme.cancelBtnHoverColor}`}>
                                    No, cancel
                                </button>
                                <button id='BtnYesButton' type="button" onClick={() => { handleMessagePopupOpen(false); openAppointmentPopup(); }} className="py-2 px-3 text-sm font-medium text-center text-white bg-red-600 rounded-lg hover:bg-red-700 focus:ring-4 focus:outline-none focus:ring-red-300 dark:bg-red-500 dark:hover:bg-red-600 dark:focus:ring-red-900 disabled:opacity-50 disabled:cursor-not-allowed">
                                    Yes, Proceed</button>
                            </div>
                        </div>
                    </div>
                </div>
            }
            <div className='shadow-md rounded-lg '>
                <div className='flex justify-between bg-slate-200 py-1 px-1 border-2 rounded-t-lg border-b-zinc-300'>
                    <p className='flex cursor-pointer text-[#a31b1b] text-lg font-semibold' onClick={handlePrescriptionSvgClick}>
                        Prescription
                        <span className='mt-1 cursor-pointer'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                <path d="M5.8335 8.33289L10.0002 12.4996L14.1668 8.33289H5.8335Z" fill="#a31b1b" />
                            </svg>
                        </span>
                    </p>
                    <div className='flex items-center justify-end'>

                        {/* <button id='BtnUploadPrescButton' className='pl-1 underline cursor-pointer'>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                onClick={() => handlePrescriptionType('image')}
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth="2"
                                stroke="currentColor"
                                className={`lg:size-5 size-5 inline cursor-pointer text-[#a31b1b]`}
                            >
                                <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5m-13.5-9L12 3m0 0 4.5 4.5M12 3v13.5" />
                            </svg>
                        </button> */}

                        <svg xmlns="http://www.w3.org/2000/svg" id='MobBtnUploadPrescriptionData' onClick={() => handleUploadButton('upload')} fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" className={`size-5 mr-2 inline cursor-pointer text-red-700`}>
                            <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5m-13.5-9L12 3m0 0 4.5 4.5M12 3v13.5" />
                        </svg>
                        <svg xmlns="http://www.w3.org/2000/svg" id='MobBtnCapturePrescriptionData' onClick={() => handleUploadButton('capture')} fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" className={`inline size-5 cursor-pointer text-red-700`}>
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6.827 6.175A2.31 2.31 0 0 1 5.186 7.23c-.38.054-.757.112-1.134.175C2.999 7.58 2.25 8.507 2.25 9.574V18a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 18V9.574c0-1.067-.75-1.994-1.802-2.169a47.865 47.865 0 0 0-1.134-.175 2.31 2.31 0 0 1-1.64-1.055l-.822-1.316a2.192 2.192 0 0 0-1.736-1.039 48.774 48.774 0 0 0-5.232 0 2.192 2.192 0 0 0-1.736 1.039l-.821 1.316Z" />
                            <path strokeLinecap="round" strokeLinejoin="round" d="M16.5 12.75a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0ZM18.75 10.5h.008v.008h-.008V10.5Z" />
                        </svg>

                        {page_src === "appointment" &&
                        <button id='BtnPrescPopupButton' className="sm:flex md:flex sm:top-section sm:border" onClick={openPopup}>
                            <p className='lg:hidden flex text-[#a31b1b] cursor-pointer'>
                                <HiMiniPlusCircle className='size-6 ml-2' />
                            </p>
                        </button>}
                    </div>
                </div>
                {expandRow && (
                    <>
                        {page_src === "appointment" ?
                            <>
                                {/* appointments to patient profile */}
                                {appointment_id > 0 ?
                                    <div className={'py-2 px-4 min-w-full lg:w-full  '}>
                                        {appointment.status !== "Completed" ?
                                            <>
                                                <div className='flex mt-2 flex-col'>
                                                    <div className="">
                                                        {rows.filter(a => a.medicine !== "").length > 0 && (
                                                            <div>
                                                                {rows.filter(a => a.medicine !== "").map((row, index) => (
                                                                    <div key={index} className="border rounded-md shadow-md px-4 py-2 mb-2">
                                                                        <div className="flex justify-between items-center">
                                                                            <div>
                                                                                <p><span className="font-medium text-black"></span> {row.medicine}</p>
                                                                                <p><span className="font-semibold"></span> {row.duration} days</p>
                                                                                <p>
                                                                                    {row.morning ? '1' : '0'} -
                                                                                    {row.noon ? '1' : '0'} -
                                                                                    {row.night ? '1' : '0'} {row.when_medidcine ? '(Before Food)' : ''}
                                                                                </p>
                                                                            </div>
                                                                            <div className="flex space-x-2">
                                                                                <button id='BtnEditrowButton' onClick={() => handleEditClick(row, index)}>
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                                                                        <path d="M2.25 12.9375V15.75H5.0625L13.3575 7.455L10.545 4.6425L2.25 12.9375ZM15.5325 5.28C15.602 5.21062 15.6572 5.1282 15.6948 5.03747C15.7325 4.94674 15.7518 4.84948 15.7518 4.75125C15.7518 4.65303 15.7325 4.55576 15.6948 4.46503C15.6572 4.3743 15.602 4.29189 15.5325 4.2225L13.7775 2.4675C13.7081 2.39797 13.6257 2.34281 13.535 2.30518C13.4442 2.26754 13.347 2.24817 13.2488 2.24817C13.1505 2.24817 13.0533 2.26754 12.9625 2.30518C12.8718 2.34281 12.7894 2.39797 12.72 2.4675L11.3475 3.84L14.16 6.6525L15.5325 5.28Z" fill="black" />
                                                                                    </svg>
                                                                                </button>
                                                                                <button id='BtnDeleteRowButton' onClick={() => deleteRow(row.order_id)}>
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                                        <path d="M9.808 17H10.808V7.99998H9.808V17ZM13.192 17H14.192V7.99998H13.192V17ZM6 20V5.99998H5V4.99998H9V4.22998H15V4.99998H19V5.99998H18V20H6Z" fill="#A31B1B" />
                                                                                    </svg>
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        )}
                                                    </div>

                                                    {/* {uploadPrescription && (
                                                <UploadPrescription
                                                    setUploadPrescription={setUploadPrescription}
                                                    patient_id={patient.patient_id}
                                                    appointment_id={appointment.appointment_id}
                                                    setExpandRow={setExpandRow}
                                                    setRefreshImage={setRefreshImage}
                                                />
                                            )} */}
                                                    <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-4 gap-2 p-2">
                                                        {fileList.map((file, index) => {
                                                            const fileExtension = file.file_path.split('.').pop().toLowerCase();
                                                            const isImage = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp'].includes(fileExtension);
                                                            const isAudio = ['mp3', 'wav', 'ogg', 'm4a'].includes(fileExtension);
                                                            const isVideo = ['mp4', 'webm', 'ogg', 'mov'].includes(fileExtension);

                                                            return (
                                                                <div key={index} className="relative p-2 border rounded-sm">
                                                                    <a rel="noopener" target="_blank" href={process.env.REACT_APP_EMRAXIS_S3_URL + "/" + file.file_path} className="block h-full">
                                                                        <div className="flex justify-center items-center h-full">
                                                                            {isImage ? (
                                                                                <img
                                                                                    className='max-h-[180px] max-w-full w-auto h-auto object-contain'
                                                                                    src={process.env.REACT_APP_EMRAXIS_S3_URL + "/" + file.file_path}
                                                                                    alt={file.file_name || "Image"}
                                                                                />
                                                                            ) : isAudio ? (
                                                                                <audio className="w-full max-w-[400px]" controls>
                                                                                    <source src={process.env.REACT_APP_EMRAXIS_S3_URL + "/" + file.file_path} type={`audio/${fileExtension}`} />
                                                                                </audio>
                                                                            ) : isVideo ? (
                                                                                <video className="max-h-[180px] max-w-full w-auto h-auto object-contain" controls>
                                                                                    <source src={process.env.REACT_APP_EMRAXIS_S3_URL + "/" + file.file_path} type={`video/${fileExtension}`} />
                                                                                </video>
                                                                            ) : (
                                                                                <FileIcon
                                                                                    ext={"." + fileExtension}
                                                                                    className='max-h-[180px] w-auto h-auto object-contain'
                                                                                />
                                                                            )}
                                                                        </div>
                                                                    </a>
                                                                    <button id='BtnDeleteFileButton'
                                                                        className="absolute top-2 left-2 bg-gray-100 rounded-full p-1 shadow"
                                                                        onClick={() => { setConfirmBox(true); setDeleteFile([file]); }}
                                                                    >
                                                                        <svg xmlns="http://www.w3.org/2000/svg" className="size-6 text-red-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                                                                        </svg>
                                                                    </button>
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                    
                                                    <div className="flex justify-end items-center">
                                                        {rows.filter(a => a.medicine !== "").length > 0 && (
                                                            <div className="flex items-center space-x-2">
                                                                {rows.length > 0 &&
                                                                    <button id={"btnDeleteAllMedicineRowMobile"} title='Delete All' disabled={submitDisable} onClick={() => setConfirmBoxDeleteAll(true)}>
                                                                        <span className='flex justify-end text-red-600 font-semibold hover:underline pr-2'>Delete All
                                                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6 text-red-500 hover:text-red-700">
                                                                                <path fillRule="evenodd" d="M16.5 4.478v.227a48.816 48.816 0 0 1 3.878.512.75.75 0 1 1-.256 1.478l-.209-.035-1.005 13.07a3 3 0 0 1-2.991 2.77H8.084a3 3 0 0 1-2.991-2.77L4.087 6.66l-.209.035a.75.75 0 0 1-.256-1.478A48.567 48.567 0 0 1 7.5 4.705v-.227c0-1.564 1.213-2.9 2.816-2.951a52.662 52.662 0 0 1 3.369 0c1.603.051 2.815 1.387 2.815 2.951Zm-6.136-1.452a51.196 51.196 0 0 1 3.273 0C14.39 3.05 15 3.684 15 4.478v.113a49.488 49.488 0 0 0-6 0v-.113c0-.794.609-1.428 1.364-1.452Zm-.355 5.945a.75.75 0 1 0-1.5.058l.347 9a.75.75 0 1 0 1.499-.058l-.346-9Zm5.48.058a.75.75 0 1 0-1.498-.058l-.347 9a.75.75 0 0 0 1.5.058l.345-9Z" clipRule="evenodd" />
                                                                            </svg>
                                                                        </span>
                                                                    </button>
                                                                }

                                                                <button
                                                                    disabled={submitDisable}
                                                                    onClick={() => {
                                                                        savePrescription("save-print");
                                                                    }}
                                                                    id='BtnSavePrintPrescButton'

                                                                >
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" className=''>
                                                                        <path d="M6 6.94777V2.97754H18V6.94777H6ZM18 12.4068C18.2833 12.4068 18.521 12.3116 18.713 12.121C18.905 11.9304 19.0007 11.6948 19 11.4143C18.9993 11.1337 18.9033 10.8981 18.712 10.7076C18.5207 10.517 18.2833 10.4217 18 10.4217C17.7167 10.4217 17.4793 10.517 17.288 10.7076C17.0967 10.8981 17.0007 11.1337 17 11.4143C16.9993 11.6948 17.0953 11.9307 17.288 12.122C17.4807 12.3132 17.718 12.4082 18 12.4068ZM8 18.8585H16V14.8882H8V18.8585ZM6 20.8436V16.8733H2V7.94033H22V16.8733H18V20.8436H6Z" fill="#176CBB" />
                                                                    </svg>
                                                                    {loadingSavePrint && (
                                                                        <svg aria-hidden="true" role="status" className="inline  ml-2 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB" />
                                                                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor" />
                                                                        </svg>
                                                                    )}
                                                                </button>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>

                                            </>
                                            :
                                            <p className='text-green-500 text-center font-semibold'>Appointment already Signed off!</p>
                                        }
                                    </div> :
                                    <p className='text-center text-red-600 p-5'>Prescription not available!</p>
                                }


                            </>
                            :
                            <>
                                {allPrescriptions.length > 0 ?
                                    // patients to patient profile
                                    <div className="w-full border border-gray-200 rounded-lg bg-gray-50 dark:bg-gray-700 dark:border-gray-600 my-2">
                                        {allPrescriptions.slice(0, showAll ? undefined : 3).map((data, index) => (
                                            <div className='border border-gray-200 p-1' key={'all-prescriptions-' + index}>
                                                <div className='bg-red-300 h-6 ring-2 ring-red-400 rounded-sm'>
                                                    <p className='text-center font-semibold text-blue-900'><span className='px-1'>{DateFunction.GetDate(data.date)}</span></p>
                                                </div>
                                                <div className='p-2'>
                                                    {data.prescriptions && data.prescriptions.filter(a => a.medicine !== "").length > 0 && (
                                                        <div>
                                                            {data.prescriptions.filter(a => a.medicine !== "").map((row, rowIndex) => (
                                                                <div key={rowIndex} className="border rounded-md shadow-md px-4 py-2 mb-2">
                                                                    <div className="flex justify-between items-center">
                                                                        <div>
                                                                            <p><span className="font-medium text-black"></span> {row.medicine}</p>
                                                                            <p><span className="font-semibold"></span> {row.duration} days</p>
                                                                            <p>
                                                                                {row.morning ? '1' : '0'} -
                                                                                {row.noon ? '1' : '0'} -
                                                                                {row.night ? '1' : '0'} {row.when_medidcine ? '(Before Food)' : ''}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    )}
                                                </div>

                                                <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-4 gap-2 p-2">
                                                    {
                                                        data?.files?.map((file, index) => {
                                                            const fileExtension = file.file_path.split('.').pop().toLowerCase();
                                                            const isImage = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp'].includes(fileExtension);
                                                            const isAudio = ['mp3', 'wav', 'ogg', 'm4a'].includes(fileExtension);
                                                            const isVideo = ['mp4', 'webm', 'ogg', 'mov'].includes(fileExtension);

                                                            return (
                                                                <div className="relative p-2 border rounded-sm" key={'image-pres-' + index}>
                                                                    <a rel="noopener" target="_blank" href={process.env.REACT_APP_EMRAXIS_S3_URL + "/" + file.file_path} className=''>
                                                                        {isImage ? (
                                                                            <img
                                                                                className='max-h-[180px] w-auto h-auto object-contain p-5'
                                                                                src={process.env.REACT_APP_EMRAXIS_S3_URL + "/" + file.file_path}
                                                                                alt="Prescription"
                                                                            />
                                                                        ) : isAudio ? (
                                                                            <div className="flex justify-center items-center h-full">  <audio className="w-full max-w-[400px]" controls>
                                                                                <source src={process.env.REACT_APP_EMRAXIS_S3_URL + "/" + file.file_path} type={`audio/${fileExtension}`} />
                                                                                Your browser does not support the audio element.
                                                                            </audio></div>
                                                                        ) : isVideo ? (
                                                                            <video className="max-h-[180px] w-auto h-auto object-contain p-5" controls>
                                                                                <source src={process.env.REACT_APP_EMRAXIS_S3_URL + "/" + file.file_path} type={`video/${fileExtension}`} />
                                                                                Your browser does not support the video element.
                                                                            </video>
                                                                        ) : (
                                                                            <div className="flex justify-center items-center h-full">
                                                                                <FileIcon
                                                                                    ext={"." + fileExtension}
                                                                                    className='max-h-[180px] w-auto h-auto object-contain p-5  '
                                                                                />
                                                                            </div>
                                                                        )}
                                                                    </a>
                                                                </div>
                                                            );
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        ))}
                                        <div className="flex justify-end">
                                            {allPrescriptions.length > 3 && (
                                                <button id='BtnShowMorePrescription'
                                                    className="text-red-600 p-2 "
                                                    onClick={() => setShowAll(!showAll)}
                                                >
                                                    {showAll ? "View Less" : "View More"}
                                                </button>
                                            )}
                                        </div>
                                    </div> :
                                    <>
                                        {dataLoading &&
                                            <p className='text-center p-5'>
                                                <svg id="imgDataLoading" aria-hidden="true" role="status" className="size-6 ml-2 text-red-600 animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB" />
                                                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor" />
                                                </svg>
                                            </p>
                                        }
                                    </>
                                }
                            </>
                        }
                    </>

                )}
                {uploadPrescription && <UploadPrescription setUploadPrescription={setUploadPrescription} patient_id={patient.patient_id} appointment_id={appointment.appointment_id} setExpandRow={setExpandRow} setRefreshImage={setRefreshImage} />}

                {/* {uploadPrescription &&
                    <UploadPrescription
                        setUploadPrescription={setUploadPrescription}
                        appointment_id={appointment_id}
                        setExpandRow={setExpandRow}
                        patient_id={patient.patient_id}
                        setRefreshImage={setRefreshImage}
                    />
                } */}
                {/* {showAddAppointmentpopup && <AddAppointment setRefreshData={setRefreshData} doctors={doctors} />}
                {showAddPatientpopup && <AddPatient doctors={doctors} setRefreshData={setRefreshData} patient={{}} />} */}
                {isPrescriptionOpen && (
                    <div className='lg:hidden'>
                        <div className="fixed inset-0 overflow-hidden flex justify-center items-center z-50">
                            <div className="absolute inset-0 bg-black opacity-25"></div>
                            <div className="relative bg-white shadow-xl p-8 max-h-[70vh] overflow-y-auto" style={{ width: "350px" }}>
                                <div className="flex items-center justify-between">
                                    <header className={`origin-top-left flex font-bold mb-2 text-2xl lg:text-xl sm:text-lg pl-2.5 pt-1 ${previewTheme.headingTitleColor}`} style={{ fontFamily: previewTheme.fontFamily }}>Add Prescription</header>
                                    <button id='btnClosePrescriptionPopup' className={`text-[#a31b1b] sm:justify-left`} onClick={closePopup}>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className={`h-10 w-10 ${previewTheme.popupCloseBtnColor} transition-colors duration-300 ease-in-out hover:${previewTheme.popupCloseBtnHoverColor}`}>
                                            <path strokeLinecap="round" strokeLinejoin="round" d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                                        </svg>
                                    </button>
                                </div>
                                {appointment_id > 0 && (
                                    <div>
                                        {appointment.status !== "Completed" && (
                                            <>
                                                <div className='mb-4 mt-4'>
                                                    <div className="mb-4">
                                                        <div className="relative rounded-md border border-gray-600">
                                                            <CreatableSelect
                                                                name="medicine"
                                                                styles={{
                                                                    input: base => ({
                                                                        ...base,
                                                                        'input:focus': {
                                                                            boxShadow: 'none',
                                                                        },
                                                                    }),
                                                                    control: (provided, state) => ({
                                                                        ...provided,
                                                                        height: '45px',
                                                                        minHeight: '45px',
                                                                        border: 'none',
                                                                        boxShadow: state.isFocused ? '0 0 0 1px blue' : 'none',
                                                                        '&:hover': {
                                                                            boxShadow: state.isFocused ? '0 0 0 1px blue' : '0 0 0 1px gray',
                                                                        },
                                                                    }),
                                                                }}
                                                                value={{ value: newMedicine.medicine, label: newMedicine.medicine }}
                                                                onChange={selectedOption => handleChangeMedicine(newMedicine.order_id, selectedOption)}
                                                                options={getAvailableOptions()}
                                                                onCreateOption={inputValue => handleCreateChangeMedicine(newMedicine.order_id, inputValue)}
                                                                getOptionLabel={option => option.label}
                                                                placeholder="Type"
                                                            />
                                                            <label htmlFor="medicine" className="absolute top-0 left-4 -mt-2 px-1 bg-white text-sm font-medium text-gray-700">
                                                                Medicine*
                                                            </label>

                                                        </div>

                                                    </div>
                                                    <div className="mb-4">
                                                        <div className="relative rounded-md border border-gray-600 w-full">
                                                            <select
                                                                name="medicine_type"
                                                                value={newMedicine.medicine_type}
                                                                onChange={(event) => handleChangeMedicine(newMedicine.order_id, event, "medicine_type")}
                                                                className="block w-full border-0 focus:ring-0 p-3 sm:p-2 sm:text-base text-sm rounded-md"

                                                            >
                                                                <option value="Tablet">Tablet</option>
                                                                <option value="Syrup">Syrup</option>
                                                                <option value="Capsule">Capsule</option>
                                                                <option value="Ointment">Ointment</option>
                                                            </select>
                                                            <label htmlFor="formType" className="absolute top-0 left-4 -mt-2 px-1 bg-white text-sm font-medium text-gray-700">
                                                                Tablet
                                                            </label>
                                                        </div>
                                                    </div>


                                                    <div className='mb-4'>
                                                        <div className="flex items-center mb-4">
                                                            <span className="ml-2 mr-4 text-gray-700">Before Food:</span>
                                                            <label htmlFor="beforeFood" className="inline-flex items-center">
                                                                <input
                                                                    type="checkbox"
                                                                    className="ml-2 form-checkbox h-5 w-5 text-blue-600"
                                                                    name="when_medidcine"
                                                                    checked={newMedicine.when_medidcine}
                                                                    onChange={(event) => handleChangeMedicine(newMedicine.order_id, event, "when_medidcine")}
                                                                />
                                                            </label>
                                                        </div>


                                                        <div className='mb-4'>
                                                            <div className="relative border border-gray-500 px-4 py-4 rounded">
                                                                <label className="absolute top-0 left-4 -mt-2 px-1 bg-white text-sm font-medium text-gray-700">
                                                                    What time do you take this medicine?
                                                                </label>
                                                                <div className="mt-6">
                                                                    <div className="mb-4 flex justify-between items-center">
                                                                        <span className="ml-2 text-gray-700">Morning</span>
                                                                        <label htmlFor="morning" className="inline-flex items-center">
                                                                            <input
                                                                                type='checkbox'
                                                                                className='h-8 w-8 rounded-md'
                                                                                name="morning"
                                                                                checked={newMedicine.morning === "1"}
                                                                                onChange={(event) => handleChangeMedicine(newMedicine.order_id, event, "morning")}
                                                                            />
                                                                        </label>
                                                                    </div>
                                                                    <div className="mb-4 flex justify-between items-center">
                                                                        <span className="ml-2 text-gray-700">Afternoon</span>
                                                                        <label htmlFor="afternoon" className="inline-flex items-center">
                                                                            <input
                                                                                type='checkbox'
                                                                                className='h-8 w-8 rounded-md'
                                                                                name="noon"
                                                                                checked={newMedicine.noon === "1"}
                                                                                onChange={(event) => handleChangeMedicine(newMedicine.order_id, event, "noon")}
                                                                            />
                                                                        </label>
                                                                    </div>
                                                                    <div className="mb-4 flex justify-between items-center">
                                                                        <span className="ml-2 text-gray-700">Night</span>
                                                                        <label htmlFor="night" className="inline-flex items-center">
                                                                            <input
                                                                                type='checkbox'
                                                                                className='h-8 w-8 rounded-md'
                                                                                name="night"
                                                                                checked={newMedicine.night === "1"}
                                                                                onChange={(event) => handleChangeMedicine(newMedicine.order_id, event, "night")}
                                                                            />
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>


                                                        <div className='mb-4'>
                                                            <div className="relative rounded-md border border-gray-600">
                                                                <input
                                                                    type="text"
                                                                    name="duration"
                                                                    value={newMedicine.duration}
                                                                    onChange={(event) => handleChangeMedicine(newMedicine.order_id, event, "duration")}
                                                                    className="appearance-none border-none bg-transparent w-full p-3 leading-tight focus:outline-none"
                                                                    placeholder="Duration"
                                                                />
                                                                <label htmlFor="duration" className="absolute top-0 left-4 -mt-2 px-1 bg-white text-sm font-medium text-gray-700">
                                                                    Duration (Days)*
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="mt-8 flex justify-center">
                                                        <button id='BtnAddRowButton'
                                                            className={`px-4 disabled:cursor-not-allowed disabled:opacity-50 py-2 border rounded-md text-white transition-colors duration-300 ease-in-out ${previewTheme.saveBtnColor} ${previewTheme.saveBtnBgColor} hover:${previewTheme.saveBtnHoverColor}`}
                                                            onClick={addRowMedicine}
                                                        >
                                                            Add
                                                        </button>
                                                    </div>
                                                </div>

                                            </>
                                        )}
                                    </div>
                                )}
                            </div>

                        </div>
                    </div>
                )}
            </div >
        </>
    )
}

export default Prescription