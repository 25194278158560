import React, { useState, useEffect } from 'react';
import store from '../API/store';
import { useDispatch, useSelector } from 'react-redux';
import DataDecode from '../utils/DataDecode';
import AddDepartment from '../Pages/AddDepartment';
import { setNotification } from '../Redux/features/toast/toastSlice';
import { setFont, setTheme } from '../Redux/features/colors/themeSlice';
import ToastMessage from '../components/ToastMessage';
import { font_family, theme } from '../EmraxisTheme/Theme';
import { addLoginDetails } from '../Redux/features/login/loginSlice';
import UploadLogo from './Facility/UploadLogo';

const Settings = () => {
  const dispatch = useDispatch();
  const [showPopup, setShowPopup] = useState(false);
  const [department, setDepartment] = useState([]);
  const [selectedDepartment, setselectedDepartment] = useState(null);
  const [refreshData, setRefreshData] = useState(false);
  const [confirmBox, setConfirmBox] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [updatedTheme, setUpdatedTheme] = useState({});
  const [disableThemeButton, setDisableThemeButton] = useState(false);
  const [deleteId, setDeleteId] = useState(0);
  const [disableButton, setDisableButton] = useState(false);
  const [activeTab, setActiveTab] = useState('Department');
  const previewTheme = useSelector(state => state.theme.colors);
  const previewFont = useSelector(state => state.theme.fontFamily);
  const [selectedTheme, setSelectedTheme] = useState();
  const [currentTheme, setCurrentTheme] = useState(previewTheme);
  const [fontFamily, setFontFamily] = useState(previewFont?.fontFamily || '');
  const [savedTheme, setSavedTheme] = useState({
    theme: '',
    font_family: ''

  });

  const [isUploadLogo, setIsUploadLogo] = useState(false);
  const [file, setFile] = useState(null);
  const [fileRefresh, setFileRefresh] = useState(false);
  const [facilityData, setFacilityData] = useState({});

  useEffect(() => {
    const themeToPreview = theme.filter(t => t.name === selectedTheme)[0] || previewTheme;
    const themeToFont = font_family.filter(t => t.name === selectedTheme) || previewFont;
    setCurrentTheme(themeToFont);
    setCurrentTheme(themeToPreview);
  }, [selectedTheme]);
  const handleSave = () => {
    setDisableThemeButton(true);
    UpdateTheme(savedTheme);
    setDisableThemeButton(false);
    dispatch(setNotification({ message: 'Theme changed Successfully!', status: 'success', action: true }));
    setShowConfirmation(false);
  };
  const handleChange = (e) => {
    setSelectedTheme(e.target.value);
    setSavedTheme({
      ...savedTheme,
      theme: e.target.value
    });
  };
  const handleFontChange = (e) => {
    setFontFamily(e.target.value);
    setSavedTheme({
      ...savedTheme,
      font_family: e.target.value
    });
  };
  const UpdateTheme = () => {
    store.UpdateTheme(loggedusertoken, DataDecode.encryptPayload({ clinic_guid: loggeduser.facilityID, user_id: loggeduser.user_details_id, theme_management: savedTheme }))
      .then((result) => {
        if (result.status === 200) {
          dispatch(addLoginDetails({ ...loggeduser, theme_management: savedTheme }));
          const themeToPreview = theme.filter(t => t.name === savedTheme.theme)[0] || {};
          dispatch(setTheme(themeToPreview));
          dispatch(setFont(savedTheme.font_family));
          setSavedTheme(savedTheme);
        }
      })
      .catch(error => {
        console.error('Error fetching data', error.message);
      });
  };

  const handleConfirmationClose = () => {
    setShowConfirmation(false);
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  var loggeduser = useSelector(state => state.login.loginuserData);
  var loggedusertoken = useSelector(state => state.login.userBarerToken);

  useEffect(() => {
    setTimeout(() => {
      if (loggeduser)
        fetchFacilityData();
    }, 100)

  }, [])

  useEffect(() => {
    fetchFacilityData();
  }, [fileRefresh])

  const fetchFacilityData = async () => {
    await store.GetFacilityinfo(loggedusertoken, DataDecode.encryptPayload(loggeduser.facilityID))
      .then((result) => {

        if (result.status == 201) {
          result.json().then((res) => {
            let response = DataDecode.decryptResponse(res);
            let data = JSON.parse(response)
            setFacilityData(data);
            dispatch(addLoginDetails({ ...loggeduser, facility_logo: data.facility_logo }));
          })
        }
      })
      .catch(err => {
        console.log("Error occured while saving UserRoles " + err)
      })
  }

  useEffect(() => {
    if (loggeduser) {
      const colorTheme = loggeduser?.theme_management?.theme;
      const FontFamily = loggeduser?.theme_management?.font_family;
      setSelectedTheme(colorTheme || 'defaultTheme');
      setFontFamily(FontFamily || 'Arial, sans-serif');
      setSavedTheme({
        theme: colorTheme || 'defaultTheme',
        font_family: FontFamily || 'Arial, sans-serif'
      });
    }
  }, [loggeduser]);

  const fetchDepartmentData = () => {
    try {
      store.GetAllDepartments(loggedusertoken, DataDecode.encryptPayload({ 'clinic_guid': loggeduser.facilityID }))
        .then((result) => {
          if (result.status === 200) {
            result.json().then((res) => {
              let response = DataDecode.decryptResponse(res);
              setDepartment(JSON.parse(response));
            });
          } else {
            setDepartment([]);
          }
        });
    } catch (err) {
      console.log("Error occurred", err);
    }
  };
  useEffect(() => {
    if (loggeduser != null && loggeduser.length != 0) {
      fetchDepartmentData();
    }
  }, [refreshData]);

  const openPopup = () => {
    setShowPopup(true);
    setselectedDepartment(null);
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  // const handleRowClick = (department) => {
  //   setselectedDepartment(department);
  // };

  const handleEditClick = (department) => {
    setShowPopup(true);
    setselectedDepartment(department);
  };
  const deleteDepartment = (department_id) => {
    setDeleteId(department_id)
    setConfirmBox(true);
  }
  const handleDeleteSubmit = () => {
    setDisableButton(true);
    store.deleteDepartment(loggedusertoken, DataDecode.encryptPayload({ 'clinic_guid': loggeduser.facilityID, 'department_id': deleteId }))
      .then((result) => {
        if (result.status == 204) {
          setRefreshData(r => !r);
          dispatch(setNotification({ message: 'Department Deleted Successfully!', status: 'success', action: true }));
        }
        else if (result.status == 409) {
          dispatch(setNotification({ message: 'Department is assigned to Doctor!', status: 'Conflict', action: true }));
        }
        else {
          dispatch(setNotification({ message: 'Error Occured!', status: 'error', action: true }));
        }
        setDisableButton(false);
        setConfirmBox(false);
      })
      .catch(err => {
        dispatch(setNotification({ message: 'Error Occured!', status: 'error', action: true }));
      })
  }

  const deleteLogo = () => {
    setConfirmBox(true);
  }

  const handleDeleteLogoSubmit = () => {
    setDisableButton(true);
    store.deleteLogo(loggedusertoken, DataDecode.encryptPayload({ 'facility_id': facilityData?.facility_id }))
      .then((result) => {
        if (result.status == 204) {
          setRefreshData(r => !r);
          setDisableButton(false);
          setConfirmBox(false);
          setFileRefresh(o => !o);
          dispatch(setNotification({ message: 'Logo Deleted Successfully!', status: 'success', action: true }));
        } else {
          dispatch(setNotification({ message: 'Error Occurred', status: 'error', action: true }));
        }
      })
      .catch(err => {
        console.log("Error occured")
      })
  }

  return (
    <>
      <div className="bg-white mx-auto px-4 py-2 my-20 w-100%">
        <header id="SettingsHeadersettingsTitle"
          className={`flex font-bold mb-2 text-2xl lg:text-xl sm:text-lg pl-2.5 pt-1 ${previewTheme.headingTitleColor}`}
          style={{ fontFamily: previewFont.fontFamily }}
        >
          Settings
        </header>

        <div className='flex justify-between mx-auto mt-5 gap-4'>
          <div className="flex justify-center w-full">
            <h1 id="SettingsH1departmentTitle"
              className={`flex items-center justify-center font-medium text-sm sm:text-lg lg:text-xl w-full sm:w-1/2 rounded-md shadow-lg cursor-pointer m-1 p-2
        ${activeTab === 'Department' ? 'bg-gray-200 border-gray-200' : 'bg-gray-50 border-gray-50'} `}
              onClick={() => handleTabClick('Department')}>
              Department
            </h1>
            <h1 id="SettingsH1themeManagementTitle"
              className={`flex items-center justify-center font-medium text-sm sm:text-lg lg:text-xl w-full sm:w-1/2 rounded-md shadow-lg cursor-pointer m-1 p-2
        ${activeTab === 'Theme Management' ? 'bg-gray-200 border-gray-200' : 'bg-gray-50 border-gray-50'}`}
              onClick={() => handleTabClick('Theme Management')}>
              Theme Management
            </h1>
            {loggeduser?.user_role === "Admin" &&
              <h1 id="SettingsH1updatefacilityLogo"
                className={`flex items-center justify-center font-medium text-sm sm:text-lg lg:text-xl w-full sm:w-1/2 rounded-md shadow-lg cursor-pointer m-1 p-2
                ${activeTab === 'Update Facility Logo' ? 'bg-gray-200 border-gray-200' : 'bg-gray-50 border-gray-50'}`}
                onClick={() => handleTabClick('Update Facility Logo')}>
                Update Facility Logo
              </h1>
            }
          </div>
        </div>
        {/* DEPARTMENT */}
        {
          activeTab === 'Department' && (
            <>
              {confirmBox &&
                <div id="SettingsDivdeleteDepartment" tabIndex="-1" aria-hidden="true" className="fixed left-1/2 top-1/2 w-full max-w-sm transform  -translate-x-1/2 -translate-y-1/2 sm:w-80 sm:left-[40%]  sm:top-[3rem] sm:translate-x-[50%] sm:translate-y-[50%] ">
                  <div id="SettingsDivdeleteDepartmentContainer" className="relative p-4 w-full max-w-md h-full md:h-auto">
                    <div id="SettingsDivdeleteDepartmentContent" className="relative p-4 text-center bg-white border-gray-400 border-[1px] rounded-lg shadow-lg dark:bg-gray-800 sm:p-5">
                      <svg id="SettingsSvgdeleteDepartmentIcon" className="text-red-800 dark:text-gray-500 w-11 h-11 mb-3.5 mx-auto" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clipRule="evenodd"></path></svg>
                      <p id="SettingsPdeleteDepartmentText" className="mb-4 text-gray-500 dark:text-gray-300">Are you sure you want to delete this Department?</p>
                      <div id="SettingsDivdeleteDepartment" className="flex justify-center items-center space-x-4">
                        <button id="SettingsBtndeleteDepartmentCancel" onClick={() => setConfirmBox(false)} disabled={disableButton} type="button" className="py-2 px-3 text-sm font-medium text-gray-500 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-primary-300 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600">
                          No, cancel
                        </button>
                        <button id="SettingsBtndeleteDepartmentConfirm" type="button" onClick={handleDeleteSubmit} disabled={disableButton} className="py-2 px-3 text-sm font-medium text-center text-white bg-red-800 rounded-lg hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:bg-red-500 dark:hover:bg-red-600 dark:focus:ring-red-800 disabled:opacity-50 disabled:cursor-not-allowed">
                          Yes, I'm sure {disableButton &&
                            <svg id="SettingsSvgdeleteDepartmentConfirmIcon" aria-hidden="true" role="status" className="inline w-4 h-4 ml-2 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB" />
                              <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor" />
                            </svg>
                          }
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              }
              <div id="SettingsDivdepartmentsContainer" className="mx-auto px-4 py-2  w-[100%]">
                <header id="SettingsHeaderdepartments" className="flex items-center">
                  <div id="SettingsDivdepartmentsTitle" className={`origin-top-left flex font-bold mb-2 mt-2 text-2xl lg:text-xl sm:text-lg pl-2.5 pt-1 ${previewTheme.headingTitleColor}`} style={{ fontFamily: previewFont.fontFamily }}>Departments</div>
                </header>
                <div id="SettingsDivdepartmentsActions" className="flex justify-between items-center mb-4 px-2">
                  <div id="SettingsDivaddDepartmentContainer" className="hidden items-center lg:flex flex-row">
                    <button  id="SettingsBtnaddDepartmentButton" className={`py-2 px-4 rounded-md flex items-center ${previewTheme.addBtnColor} ${previewTheme.addBtnBgColor} transition-colors duration-300 ease-in-out hover:${previewTheme.addBtnHoverColor}`} style={{ fontFamily: previewFont.fontFamily }} onClick={openPopup}>
                      <svg id="SettingsSvgaddDepartmentIcon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6 mr-2">
                        <path d="M5.25 6.375a4.125 4.125 0 1 1 8.25 0 4.125 4.125 0 0 1-8.25 0ZM2.25 19.125a7.125 7.125 0 0 1 14.25 0v.003l-.001.119a.75.75 0 0 1-.363.63 13.067 13.067 0 0 1-6.761 1.873c-2.472 0-4.786-.684-6.76-1.873a.75.75 0 0 1-.364-.63l-.001-.122ZM18.75 7.5a.75.75 0 0 0-1.5 0v2.25H15a.75.75 0 0 0 0 1.5h2.25v2.25a.75.75 0 0 0 1.5 0v-2.25H21a.75.75 0 0 0 0-1.5h-2.25V7.5Z" />
                      </svg>
                      Add New Department
                    </button>
                  </div>
                  <div id="SettingsDivsearchDepartmentContainer" className="flex sm:flex-row items-center">
                    <div id="SettingsDivsearchInputContainer" className="relative items-center gap-2">
                      <input type="text" id="SettingsInputsearchDepartment" className="block pl-10 pr-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none" placeholder="Search here" />
                      <svg id="SettingsSvgsearchIcon" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 absolute left-3 top-1/2 transform -translate-y-1/2 text-red-700">
                        <path strokeLinecap="round" strokeLinejoin="round" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" />
                      </svg>
                    </div>
                    <button id="SettingsBtnmobileAddDepartment" className="sm:flex md:flex lg:hidden sm:top-section " onClick={openPopup}>
                      <svg id="SettingsSvgmobileAddDepartmentIcon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-10 h-10 fill-red-700">
                        <path fillRule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25ZM12.75 9a.75.75 0 0 0-1.5 0v2.25H9a.75.75 0 0 0 0 1.5h2.25V15a.75.75 0 0 0 1.5 0v-2.25H15a.75.75 0 0 0 0-1.5h-2.25V9Z" clipRule="evenodd" />
                      </svg>
                    </button>
                    <button id="SettingsBtnreload" className="items-center " onClick={() => window.location.reload()}>
                      <svg id="SettingsSvgreloadIcon" xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-reload  h-8 w-8 mr-2 text-red-700 hover:text-red-800" width="44" height="44" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path d="M19.933 13.041a8 8 0 1 1 -9.925 -8.788c3.899 -1 7.935 1.007 9.425 4.747" />
                        <path d="M20 4v5h-5" />
                      </svg>
                    </button>
                  </div>
                </div>
                {showPopup && <AddDepartment id="SettingsAddDepartmentpopup" isOpen={showPopup} onClose={closePopup} setRefreshData={setRefreshData} department={selectedDepartment} />}
                <table className="hidden lg:inline-table w-[100%] lg:border rounded-md focus:outline-none  border-color:rgb(2 6 23);">
                  <thead id="SettingsTHeaddepartment">
                    <tr  id="SettingsTrdepartmentsHeaderRow" className="px-5 py-2 text-left border-b">
                      {/* <th className="px-2 py-2 text-left ">Department id</th> */}
                      <th id="SettingsTHdepartmentName" className="px-2 py-2 text-left">Department Name</th>
                      <th id="SettingsTHdepartmentAction" className="px-4 py-2 flex items-center">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {department.map((department, index) => (
                      <tr id={`department-row-${index}`} className='odd:bg-white even:bg-gray-100' key={index} >
                        {/* <td className="px-2 py-2 text-left cursor-pointer">{department.department_id}</td> */}
                        <td id={`department-name-${index}`} className="px-2 py-2 text-left cursor-pointer">{department.department_name || '-'}</td>
                        <td  id={`department-actions-${index}`} className="px-2 py-2 ">
                          <button id={`button-edit-${index}`} onClick={() => handleEditClick(department)}>
                            <svg  id="SettingsSvgdeptEditIcon" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                              <path d="M2.25 12.9375V15.75H5.0625L13.3575 7.455L10.545 4.6425L2.25 12.9375ZM15.5325 5.28C15.602 5.21062 15.6572 5.1282 15.6948 5.03747C15.7325 4.94674 15.7518 4.84948 15.7518 4.75125C15.7518 4.65303 15.7325 4.55576 15.6948 4.46503C15.6572 4.3743 15.602 4.29189 15.5325 4.2225L13.7775 2.4675C13.7081 2.39797 13.6257 2.34281 13.535 2.30518C13.4442 2.26754 13.347 2.24817 13.2488 2.24817C13.1505 2.24817 13.0533 2.26754 12.9625 2.30518C12.8718 2.34281 12.7894 2.39797 12.72 2.4675L11.3475 3.84L14.16 6.6525L15.5325 5.28Z" fill="black" />
                            </svg>
                          </button>
                        </td>
                        <td className="px-10 py-2 text-left ">
                          <button id={`button-delete-${index}`} onClick={() => deleteDepartment(department.department_id)}>
                            <svg id="SettingsSvgdeptDeleteIcon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                              <path d="M9.808 17H10.808V7.99998H9.808V17ZM13.192 17H14.192V7.99998H13.192V17ZM6 20V5.99998H5V4.99998H9V4.22998H15V4.99998H19V5.99998H18V20H6Z" fill="#A31B1B" />
                            </svg>
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div id="SettingsDivmobileDepartmentsContainer" className="lg:hidden">
                  <div id="SettingsDivmobileDepartmentsList" className="">
                    {department.map((department, index) => (
                      <div id={`mobile-department-item-${index}`} className='grid grid-cols-1 gap-5' key={index} >
                        <div id={`mobile-department-card-${index}`} className='px-2 py-2 w-full bg-white mb-2 border-2 border-gray-200 p-4 rounded-lg shadow-lg items-center'>
                          <div id={`mobile-department-header-${index}`} className="flex items-center justify-between">
                            <div id={`mobile-department-info-${index}`} className='flex items-center space-x-4'>
                              <div id={`mobile-department-name-container-${index}`} className="flex items-center mb-4">
                                <span id={`mobile-department-label-${index}`} className="font-semibold text-xl mr-2">Department Name:</span>
                                <span id={`mobile-department-name-${index}`} className='text-gray-500 font-semibold text-xl'>{department.department_name || '-'}</span>
                              </div>
                              <button  id={`mobile-edit-button-${index}`} onClick={() => handleEditClick(department)}>
                                <svg id="SettingsSvgmobEditIcon" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                  <path d="M2.25 12.9375V15.75H5.0625L13.3575 7.455L10.545 4.6425L2.25 12.9375ZM15.5325 5.28C15.602 5.21062 15.6572 5.1282 15.6948 5.03747C15.7325 4.94674 15.7518 4.84948 15.7518 4.75125C15.7518 4.65303 15.7325 4.55576 15.6948 4.46503C15.6572 4.3743 15.602 4.29189 15.5325 4.2225L13.7775 2.4675C13.7081 2.39797 13.6257 2.34281 13.535 2.30518C13.4442 2.26754 13.347 2.24817 13.2488 2.24817C13.1505 2.24817 13.0533 2.26754 12.9625 2.30518C12.8718 2.34281 12.7894 2.39797 12.72 2.4675L11.3475 3.84L14.16 6.6525L15.5325 5.28Z" fill="black" />
                                </svg>
                              </button>
                              <button id={`mobile-delete-button-${index}`} onClick={() => deleteDepartment(department.department_id)}>
                                <svg id="SettingsSvgmobDeleteIcon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                  <path d="M9.808 17H10.808V7.99998H9.808V17ZM13.192 17H14.192V7.99998H13.192V17ZM6 20V5.99998H5V4.99998H9V4.22998H15V4.99998H19V5.99998H18V20H6Z" fill="#A31B1B" />
                                </svg>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </>
          )
        }
        {/* THEME MANAGEMENT */}
        {
          activeTab === 'Theme Management' && (
            <>

              {showConfirmation && (
                <div className="fixed inset-0 z-10 flex items-center justify-center  bg-gray-500 bg-opacity-50">

                  <div className="relative p-4 w-full max-w-md h-full md:h-auto">
                    <div className="relative p-4 text-center bg-white border-gray-400 border-[1px] rounded-lg shadow-lg dark:bg-gray-800 sm:p-5">
                      <svg className="text-red-800 dark:text-gray-500 w-8 h-8 mb-3.5 mx-auto fill-red-800" xmlns="http://www.w3.org/2000/svg" shape-rendering="geometricPrecision" textRendering="geometricPrecision" imageRendering="optimizeQuality" fillRule="evenodd" clipRule="evenodd" viewBox="0 0 512 511.52">
                        <path fillRule="nonzero" d="M36.75 0h438.5C495.55 0 512 16.82 512 37.03v437.46c0 20.19-16.47 37.03-36.75 37.03H98.28c-2.89 0-5.5-1.17-7.39-3.06L3.06 420.62A10.387 10.387 0 0 1 0 413.24V37.03C0 16.81 16.45 0 36.75 0zM174.5 447.79c-13.75 0-13.75-20.9 0-20.9h153.97c13.74 0 13.74 20.9 0 20.9H174.5zm0-64.38c-13.75 0-13.75-20.9 0-20.9h153.97c13.74 0 13.74 20.9 0 20.9H174.5zm209.51 106.91V350.25c0-16.78-13.87-30.64-30.65-30.64H149.6c-16.78 0-30.64 13.86-30.64 30.64v140.07h265.05zm20.89-140.07v140.37h70.35c8.85 0 15.85-7.37 15.85-16.13V37.03c0-8.78-6.99-16.13-15.85-16.13H404.9v170.17c0 28.31-23.23 51.55-51.54 51.55H149.6c-28.34 0-51.54-23.21-51.54-51.55V20.9H36.75c-8.87 0-15.85 7.34-15.85 16.13v371.88l77.16 77.16V350.25c0-28.32 23.22-51.54 51.54-51.54h203.76c28.22 0 51.54 23.32 51.54 51.54zm-20.89-159.18V20.9H118.96v170.17c0 16.8 13.85 30.65 30.64 30.65h203.76c16.77 0 30.65-13.88 30.65-30.65z" />
                      </svg>

                      <p className="mb-4 text-gray-700 dark:text-gray-700">Are you sure you want to save?</p>
                      <div className="flex justify-center items-center space-x-4">
                        <button onClick={() => setShowConfirmation(false)} disabled={disableThemeButton} type="button" className="py-2 px-3 text-sm font-medium text-gray-500 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-primary-300 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600">
                          No, cancel
                        </button>
                        <button type="button" onClick={handleSave} disabled={disableThemeButton} className="py-2 px-3 text-sm font-medium text-center text-white bg-red-800 rounded-lg hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:bg-red-500 dark:hover:bg-red-600 dark:focus:ring-red-800 disabled:opacity-50 disabled:cursor-not-allowed">
                          Yes, I'm sure {disableThemeButton &&
                            <svg aria-hidden="true" role="status" className="inline w-4 h-4 ml-2 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB" />
                              <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor" />
                            </svg>
                          }
                        </button>
                      </div>
                    </div>
                  </div>

                </div>
              )}
              <div className='text-black'>
                <div className='border border-gray-200 rounded-md shadow-md mt-2'>
                  <header className={`origin-top-left flex font-bold mb-2 text-2xl mt-2 lg:text-xl sm:text-lg pl-2.5 pt-1 ${previewTheme.headingTitleColor}`} style={{ fontFamily: previewFont.fontFamily }}>Theme Management</header>
                  <div className="min-w-8 border border-gray-100 bg-white rounded-md p-6 shadow-md mb-8 ml-8 mr-8">
                    <div className='block text-center'>
                      <div className='ml-2 p-1'>
                        <label htmlFor="theme" className="font-bold text-xl mb-2 block">Theme</label>
                        <select
                          id="Settingstheme"
                          name="theme"
                          className="block bg-white border border-gray-300 rounded-md shadow-sm p-2 focus:ring-gray-100 focus:border-gray-100 sm:text-sm w-full  lg:w-[25%] mx-auto"
                          onChange={handleChange}
                          value={selectedTheme}
                        >
                          <option value="defaultTheme">DefaultTheme</option>
                          <option value="theme1">Theme1</option>
                          <option value="theme2">Theme2</option>
                          <option value="theme3">Theme3</option>
                        </select>
                      </div>
                      <div className="preview-section flex flex-col ml-4 mt-2 text-bold">
                        <div className='font-bold text-xl'>Preview</div>
                        <div className='mt-4'></div>
                        <div className='flex flex-col space-y-4 items-center justify-center'>
                          <div className='flex flex-wrap space-y-4 sm:space-y-0 sm:space-x-4'>
                            <button className={`w-full sm:w-auto px-2 py-1 font-bold border rounded-md ${currentTheme.headingTitleColor}`}>Heading Title</button>
                            <button className={`w-full sm:w-auto px-2 py-1 font-bold border rounded-md ${currentTheme.popupCloseBtnHoverColor} ${currentTheme.popupCloseBtnColor}`}>PopUp Close</button>
                            <button className={`w-full sm:w-auto px-2 py-1 border rounded-md ${currentTheme.addBtnColor} ${currentTheme.addBtnBgColor} transition-colors duration-300 ease-in-out hover:${currentTheme.addBtnHoverColor}`}>Add Button</button>
                            <button className={`w-full sm:w-auto px-2 py-1 border rounded-md ${currentTheme.saveBtnColor} ${currentTheme.saveBtnBgColor} transition-colors duration-300 ease-in-out hover:${currentTheme.saveBtnHoverColor}`}>Save Button</button>
                            <button className={`w-full sm:w-auto px-2 py-1 border rounded-md ${currentTheme.cancelBtnColor} ${currentTheme.cancelBtnBgColor} transition-colors duration-300 ease-in-out hover:${currentTheme.cancelBtnHoverColor}`}>Cancel Button</button>
                          </div>
                          <div className='flex flex-wrap space-y-4 sm:space-y-0 sm:space-x-4'>
                            <button className={`w-full sm:w-auto px-2 py-1 border rounded-md ${currentTheme.editBtnColor} ${currentTheme.editBtnBgColor} transition-colors duration-300 ease-in-out hover:${currentTheme.editBtnHoverColor}`}>Edit Button</button>
                            <button className={`w-full sm:w-auto px-2 py-1 border rounded-md ${currentTheme.otherBtnColor} ${currentTheme.otherBtnBgColor} transition-colors duration-300 ease-in-out hover:${currentTheme.otherBtnHoverColor}`}>Other Button</button>
                            <button className={`w-full sm:w-auto px-2 py-1 font-bold border rounded-md hover:${currentTheme.navbarBgHoverColor} hover:${currentTheme.navbarTextColor}`}>Navbar Text</button>
                            <button className={`w-full sm:w-auto px-2 py-1 border rounded-md ${currentTheme.navbarActiveColor} ${currentTheme.navbarBgColor}`}>Navbar Active Text</button>
                          </div>

                        </div>
                      </div>

                      <div className='block text-center mt-2 p-2'>
                        <div>
                          <label htmlFor="fontfamily" className="font-bold text-xl mb-2 block">Font Family</label>
                          <select
                            id="fontfamily"
                            name="fontfamily"
                            className="block bg-white border border-gray-300 rounded-md shadow-sm focus:ring-gray-100 focus:border-gray-100 sm:text-sm w-full  lg:w-[25%] mx-auto"
                            onChange={handleFontChange}
                            value={fontFamily}
                          >
                            <option value="Arial, sans-serif">Arial</option>
                            <option value="Verdana, sans-serif">Verdana</option>
                            <option value="Georgia, serif">Georgia</option>
                            <option value="Times New Roman, serif">Times New Roman</option>
                            <option value="Nunito, sans-serif">Nunito</option>
                          </select>
                        </div>
                        <div className="text-center mt-2">
                          <div
                            className={`px-4 py-2 font-medium ${currentTheme.fontColor}`}
                            style={{ fontFamily: fontFamily }}
                          >
                            Font Preview
                          </div>
                        </div>
                      </div>
                      <div className="flex space-x-4 justify-end mt-2 mb-2 mr-2">
                        <button className="px-4 py-2 border bg-red-800 text-white rounded-md" onClick={() => setShowConfirmation(true)} disabled={disableThemeButton}>Save</button>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )
        }
        {
          activeTab === 'Update Facility Logo' && (
            <>
              {confirmBox && (
                <div id="DivthemeConfirmation-Modal" className="fixed inset-0 z-10 flex items-center justify-center  bg-gray-500 bg-opacity-50">
                  <div id="DivthemeConfirmationContainer"  className="relative p-4 w-full max-w-sm h-full md:h-auto">
                    <div  id="DivthemeConfirmationContent" className="relative p-4 text-center bg-white border-gray-400 border-[1px] rounded-lg shadow-lg dark:bg-gray-800 sm:p-5">
                      <svg id="SvgthemeConfirmationIcon"  className="text-red-600 dark:text-gray-500 w-11 h-11 mb-3.5 mx-auto" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clipRule="evenodd"></path></svg>
                      <p  id="PthemeConfirmationText" className="mb-4 text-gray-500 dark:text-gray-300">Are you sure you want to delete this Logo?</p>
                      <div id="DivthemeConfirmationButtons" className="flex justify-center items-center space-x-4">
                        <button  id="BtnthemeCancel" onClick={() => setConfirmBox(false)} disabled={disableButton} type="button" className="py-2 px-3 text-sm font-medium text-gray-500 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-primary-300 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600">
                          No, cancel
                        </button>
                        <button id="BtnthemeConfirm" type="button" onClick={handleDeleteLogoSubmit} disabled={disableButton} className="py-2 px-3 text-sm font-medium text-center text-white bg-red-800 rounded-lg hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:bg-red-500 dark:hover:bg-red-600 dark:focus:ring-red-800 disabled:opacity-50 disabled:cursor-not-allowed">
                          Yes, I'm sure {disableButton &&
                            <svg aria-hidden="true" role="status" className="inline w-4 h-4 ml-2 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB" />
                              <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor" />
                            </svg>
                          }
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div id="DivthemeManagement" className='text-black'>
                <div id="DivthemeManagementContainer" className='border border-gray-200 rounded-md shadow-md mt-2'>
                  <header id="HeaderthemeManagement" className={`origin-top-left flex font-bold mb-2 text-2xl mt-2 lg:text-xl sm:text-lg pl-2.5 pt-1 ${previewTheme.headingTitleColor}`} style={{ fontFamily: previewFont.fontFamily }}>Update Facility Logo</header>
                  <div id="DivthemeManagementContent" className="min-w-8  bg-white p-2 mb-2 ml-2 mr-2">
                    <div id="DivthemeManagementSelect" className='block text-center'>

                      <div id="DivthemeSelectContainer" className="flex mt-1 sm:mb-0 w-full px-[14px] py-[7px] bg-white border border-[#CACACA] rounded shadow-sm placeholder-[#B7B7B7] focus:outline-none focus:border-[#1976d2] focus:ring-1 focus:ring-[#1976d2]">
                        {file ?
                          <div className='w-36 h-36 pr-2 pt-2'>
                            <img id="ImgthemeSelectImageUrl" src={URL.createObjectURL(file)} alt={file.name} className='h-auto max-w-full' />
                          </div> :
                          facilityData.facility_logo ?
                            <div className='w-36 h-36 pr-2 pt-2 relative'>
                              <img id="ImgthemeFacilityName" src={process.env.REACT_APP_EMRAXIS_S3_URL + "/" + facilityData.facility_logo} alt={facilityData.facility_name} className='object-cover absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 max-w-full h-auto' />
                              <button id="BtnthemeDeleteLogo" className="absolute top-2 right-2 bg-gray-100 rounded-full p-1 shadow" type='button' onClick={deleteLogo}>
                                <svg id="SvgthemeDeleteLogoIcon" xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-red-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                                </svg>
                              </button>
                            </div>
                            :
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-36 h-36 mr-2 bg-gray-300 rounded-sm p-4">
                              <path strokeLinecap="round" strokeLinejoin="round" d="m2.25 15.75 5.159-5.159a2.25 2.25 0 0 1 3.182 0l5.159 5.159m-1.5-1.5 1.409-1.409a2.25 2.25 0 0 1 3.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 0 0 1.5-1.5V6a1.5 1.5 0 0 0-1.5-1.5H3.75A1.5 1.5 0 0 0 2.25 6v12a1.5 1.5 0 0 0 1.5 1.5Zm10.5-11.25h.008v.008h-.008V8.25Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z" />
                            </svg>
                        }
                        <div>
                          <label id="LabeluploadLogo" className="block text-gray-700 mb-2 pl-2 italic text-base">
                            Please upload logo
                          </label>
                          <div className="relative pl-2">
                            <button id="BtnchooseImage" type="button" onClick={() => { setIsUploadLogo(true) }} className="text-lg p-2 bg-red-700 text-white transition rounded-md cursor-pointer">
                              Choose Image
                            </button>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
              {isUploadLogo && <UploadLogo setIsUploadLogo={setIsUploadLogo} file={file} setFile={setFile} existingLogo={facilityData.facility_logo} saveDirectly={true} setFileRefresh={setFileRefresh} facilityData={facilityData} />}
            </>
          )
        }
      </div>

    </>
  );
};

export default Settings;