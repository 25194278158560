import React, { useEffect, useState, useRef } from 'react';
import { IoMdSave } from 'react-icons/io';
import { useSelector, useDispatch } from 'react-redux';
import { theme } from '../../../../EmraxisTheme/Theme';
import DataDecode from '../../../../utils/DataDecode';
import store from '../../../../API/store';
import { setNotification } from '../../../../Redux/features/toast/toastSlice';
import { setDocumentType, setIsCameraUpload, setRefreshNotes, setRefreshPrescription, setRefreshUploadedFiles, setShowDocumentUploadPopup, setShowUploadPopup } from '../../../../Redux/features/uploadDoc/uploadDocSlice';
import FileIcon from '../FileIcon';
import { setActiveNavvisitbar, setAutoSaveAll, setSelectedAppointmentId, setpastSelected, setDoctorNotes, setPrescriptions, setUploadedData, setLabNotes, setRadiologyNotes } from '../../../../Redux/features/visitDrawer/visitSideNavbarSlice';
const LabContent = ({ appointment_id, history }) => {
    var loggeduser = useSelector(state => state.login.loginuserData);
    var loggedusertoken = useSelector(state => state.login.userBarerToken);
    var refreshNotes = useSelector(state => state.document_upload.refreshNotes);
    var refreshUploadedFiles = useSelector(state => state.document_upload.refreshUploadedFiles);
    const islabNotes = useSelector(state => state.visitSideNavbar.islabNotes);
    const previewTheme = useSelector(state => state.theme.colors) || theme.filter(a => a.name === "defaultTheme")[0];
    const textareaRef = useRef(null);
    var autoSaveAll = useSelector(state => state.profileSideNavbar.autoSaveAll);
    const [files, setFiles] = useState([]);
    const [originalFiles, setOriginalFiles] = useState([]);
    const [refreshImage, setRefreshImage] = useState(false);
    const [drLabNotes, setDrLabNotes] = useState('');
    const [notesFileDeleteCnfBox, setNotesFileDeleteCnfBox] = useState(false);
    var showDocumentUploadPopup = useSelector(state => state.document_upload.showDocumentUploadPopup);
    const [loadingDeleteImage, setLoadingDeleteImage] = useState(false);
    const [notesFileDeleteID, setNotesFileDeleteID] = useState(0);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [isAnyChanges, setIsAnyChanges] = useState(false);
    const [editNotesLab, setEditNotesLab] = useState(false);
    const [dataLoading, setDataLoading] = useState(true);
    const handleEditClickLabNotes = () => {
        setEditNotesLab(!editNotesLab);
        adjustTextareaHeight();
    }
    const handleLabDoneEditing = () => {
        setEditNotesLab(false);
    };
    useEffect(() => {
        adjustTextareaHeight();
    }, [drLabNotes]);
    const adjustTextareaHeight = () => {
        const textarea = textareaRef.current;
        if (textarea) {
            textarea.style.height = 'auto';
            textarea.style.height = Math.min(textarea.scrollHeight, 500) + 'px';
        }
    };
   
    const GetAllPatientLabNotes = () => {
      
            setDataLoading(true);
        
        store.GetAllPatientLabNotes(loggedusertoken, DataDecode.encryptPayload({ 'clinic_guid': loggeduser.facilityID, 'patient_id': history.patient_id,
                 'appointment_id': history.appointment_id  }))
            .then((result) => {
                setDataLoading(false);
                if (result.status == 200) {
                    result.json().then((res) => {
                        let response = DataDecode.decryptResponse(res);
                        dispatch(setLabNotes(JSON.parse(response)));
                    })
                } else {
                    dispatch(setLabNotes([]))
                }
            })
            .catch(err => {
                setDataLoading(false);
                dispatch(setLabNotes([]))
                dispatch(setNotification({ message: 'Error occurred', status: 'error', action: true }));
            })
    }

    useEffect(() => {
        GetAllPatientLabNotes();
    }, []);
    const saveNotesLab = () => {
        if (drLabNotes.trim() === "") {
            dispatch(setNotification({ message: "Please enter Notes!", status: 'error', action: true }));
        } else {
            setLoading(true)
            store.updateDoctorNotes(loggedusertoken, DataDecode.encryptPayload({ appointment_id: history.appointment_id, 'clinic_guid': loggeduser.facilityID, 'notes': drLabNotes, type: "lab", history: history }))
                .then((result) => {
                    setLoading(false)
                    setIsAnyChanges(false)
                    dispatch(setLabNotes(false))
                    if (result.status == 204) {
                        setDrLabNotes(drLabNotes);
                        handleLabDoneEditing();
                        dispatch(setLabNotes(true))
                        dispatch(setNotification({ message: "Notes Updated!", status: 'success', action: true }));
                    }
                    else {
                        dispatch(setNotification({ message: 'Error Occured!', status: 'error', action: true }));
                    }
                })
                .catch(err => {
                    dispatch(setNotification({ message: 'Error occurred', status: 'error', action: true }));
                })
        }
    }
    useEffect(() => {
        if (isAnyChanges && drLabNotes.trim() !== "") {
            saveNotesLab();
        }
    }, [autoSaveAll, drLabNotes]);

    useEffect(() => {
        setDrLabNotes(history?.lab_notes || '');
    }, [history]);
    useEffect(() => {
        adjustTextareaHeight();
    }, [drLabNotes]);
    const deleteNotesFile = () => {
        setLoadingDeleteImage(true);
        store.DeletePatientFile(loggedusertoken, DataDecode.encryptPayload({ 'clinic_guid': loggeduser.facilityID, 'patient_id': history.patient_id, files: notesFileDeleteID }))
            .then((result) => {
                setLoadingDeleteImage(false)
                setNotesFileDeleteCnfBox(false)
                if (result.status == 204) {
                    dispatch(setRefreshNotes(!refreshNotes))
                    dispatch(setNotification({ message: 'Deleted Successfully!', status: 'success', action: true }));
                }
                else {
                    dispatch(setNotification({ message: 'Error Occurred!', status: 'error', action: true }));
                }

            })
            .catch((error) => {
                dispatch(setNotification({ message: 'Error occurred', status: 'error', action: true }));
            });
    }
    const GetPatientFiles = () => {
        store.GetPatientFiles(loggedusertoken, DataDecode.encryptPayload({
            'clinic_guid': loggeduser.facilityID,
            'patient_id': history.patient_id,
            'appointment_id': history.appointment_id
        }))
            .then((result) => {
                if (result.status === 200) {
                    result.json().then((res) => {
                        let response = DataDecode.decryptResponse(res);
                        let data = JSON.parse(response);

                        setFiles([...data, ...(history.PatientFiles || [])]);
                        setOriginalFiles([...data, ...(history.PatientFiles || [])]);
                    });
                } else {
                    setFiles(history.PatientFiles || []);
                    setOriginalFiles(history.PatientFiles || []);
                }
            })
            .catch(err => {
                setFiles(history.PatientFiles || []);
                setOriginalFiles(history.PatientFiles || []);
                dispatch(setNotification({ message: 'Error Occurred', status: 'error', action: true }));
            });
    };
    const GetPatientNotesFile = () => {
        store.GetPatientFiles(loggedusertoken, DataDecode.encryptPayload({ 'clinic_guid': loggeduser.facilityID, 'patient_id': history.patient_id, 'appointment_id': history.appointment_id }))
            .then((result) => {
                if (result.status == 200) {
                    result.json().then((res) => {
                        let response = DataDecode.decryptResponse(res);
                        setFiles(JSON.parse(response));
                    })
                } else {
                    setFiles([])
                }
            })
            .catch(err => {
                setFiles([])
                dispatch(setNotification({ message: 'Error occurred', status: 'error', action: true }));
            })
    }
    useEffect(() => {
        GetPatientNotesFile();
    }, [refreshNotes])

    useEffect(() => {
        if (loggeduser.facilityID && appointment_id) {
            GetPatientFiles(loggeduser.facilityID);
        }
    }, [loggeduser, appointment_id, refreshImage, refreshNotes, refreshUploadedFiles]);
    const handleUploadButton = (type) => {
        dispatch(setShowDocumentUploadPopup(true))
        dispatch(setIsCameraUpload(type === "capture" ? true : false));
    }

    useEffect(() => {
        if (showDocumentUploadPopup) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'unset';
        }
    }, [showDocumentUploadPopup]);
    useEffect(() => {
        const textarea = textareaRef.current;
        if (textarea) {
            textarea.style.height = 'auto';
            textarea.style.height = textarea.scrollHeight + 'px';
        }
    }, [drLabNotes]);
    return (
        <>
            {notesFileDeleteCnfBox &&

                <div id="delete" tabIndex="-1" aria-hidden="true" className="fixed z-[9999] lg:left-[40%] lg:w-80 top-[10rem] lg:top-[3rem] lg:translate-x-[50%] translate-y-[50%]">

                    <div className="relative p-4 w-full max-w-md h-full md:h-auto">
                        <div className="relative p-4 text-center bg-white border-gray-400 border-[1px] rounded-lg shadow-lg dark:bg-gray-800 sm:p-5">
                            <svg id="LabNotesSvgconfirmBoxIcon" className="text-red-600 dark:text-gray-500 w-11 h-11 mb-3.5 mx-auto" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clipRule="evenodd"></path></svg>

                            <p id="LabNotesconfirmBoxMessage" className={`mb-4 `} style={{ fontFamily: previewTheme.fontFamily }}>Are you sure you want to delete this file?</p>
                            <div className=" flex justify-center items-center space-x-4">

                                <button id="btnLabNotesCancelCfmdDeleteFile" onClick={() => setNotesFileDeleteCnfBox(false)} disabled={loadingDeleteImage} type="button" className={`py-2 px-3 text-sm font-medium rounded-lg border focus:ring-4 focus:outline-none focus:ring-primary-300 focus:z-10  ease-in-out
                  ${previewTheme.cancelBtnColor} 
                  ${previewTheme.cancelBtnBgColor} 
                  ${loadingDeleteImage ? "opacity-50 cursor-not-allowed" : `hover:${previewTheme.cancelBtnHoverColor}`}
                  `}>
                                    No, cancel
                                </button>
                                <button id="btnLabNotesYesCfmdDeleteFile" type="button" onClick={deleteNotesFile} disabled={loadingDeleteImage} className={`py-2 px-3 text-sm font-medium rounded-lg border focus:ring-4 focus:outline-none focus:ring-primary-300 focus:z-10  ease-in-out
                      ${previewTheme.saveBtnColor} 
                      ${previewTheme.saveBtnBgColor} 
                      ${loadingDeleteImage ? "opacity-50 cursor-not-allowed" : `hover:${previewTheme.saveBtnHoverColor}`}`}
                                >
                                    Yes, I'm sure {loadingDeleteImage &&
                                        <svg id="btnDelteYesLab" aria-hidden="true" role="status" className="inline w-4 h-4 ml-2 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB" />
                                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor" />
                                        </svg>
                                    }
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            }
            <div className=''>
                <div className="bg-gray-100 font-semibold justify-center flex items-center py-1 mt-4">
                    <div className={`font-bold text-xl text-center ${previewTheme.headingTitleColor}`} style={{ fontFamily: previewTheme.fontFamily }}>
                        Lab Notes
                    </div>
                    <div className='flex items-center absolute right-8'>
                        {/* <svg id="btnUploadLab" xmlns="http://www.w3.org/2000/svg" onClick={() => handleUploadButton('upload')} fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" className={`lg:size-7 size-5 lg:mr-8 mr-0 inline cursor-pointer text-red-700`}>
                            <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5m-13.5-9L12 3m0 0 4.5 4.5M12 3v13.5" />
                        </svg>
                        <svg id="btnCaptureLab" xmlns="http://www.w3.org/2000/svg" onClick={() => handleUploadButton('capture')} fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" className={`hidden lg:inline size-7 cursor-pointer text-red-700`}>
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6.827 6.175A2.31 2.31 0 0 1 5.186 7.23c-.38.054-.757.112-1.134.175C2.999 7.58 2.25 8.507 2.25 9.574V18a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 18V9.574c0-1.067-.75-1.994-1.802-2.169a47.865 47.865 0 0 0-1.134-.175 2.31 2.31 0 0 1-1.64-1.055l-.822-1.316a2.192 2.192 0 0 0-1.736-1.039 48.774 48.774 0 0 0-5.232 0 2.192 2.192 0 0 0-1.736 1.039l-.821 1.316Z" />
                            <path strokeLinecap="round" strokeLinejoin="round" d="M16.5 12.75a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0ZM18.75 10.5h.008v.008h-.008V10.5Z" />
                        </svg> */}

                    </div>
                </div>



                <textarea ref={textareaRef} id="txtlabNotes" style={{ resize: 'none' }} rows={5} onChange={(evt) => {
                    setDrLabNotes(evt.target.value);
                    setIsAnyChanges(true)
                }}
                    className="w-full mt-2 px-2 rounded-md text-sm border border-gray-300 focus:border-gray-500 text-gray-900 bg-white dark:bg-gray-800 focus:ring-0 dark:text-white dark:placeholder-gray-400 h-auto overflow-hidden resize-none"
                    value={drLabNotes} placeholder="Lab Notes"></textarea>
                <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2 mb-4">
                    {
                        files?.filter(a => a.file_type === "Lab Reports").map((file, index) => {
                            const fileExtension = file.file_path.split('.').pop().toLowerCase();
                            const isImage = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp'].includes(fileExtension);
                            const isAudio = ['mp3', 'wav', 'ogg', 'm4a'].includes(fileExtension);
                            const isVideo = ['mp4', 'webm', 'ogg', 'mov'].includes(fileExtension);

                            return (
                                <div className="relative p-2 border rounded-sm" key={'image-notes-' + index}>
                                    <a rel="noopener" target="_blank" href={process.env.REACT_APP_EMRAXIS_S3_URL + "/" + file.file_path} className=''>
                                        {isImage ? (
                                            <img id="imgLab"
                                                className='max-h-[180px] w-auto h-auto object-contain p-5'
                                                src={process.env.REACT_APP_EMRAXIS_S3_URL + "/" + file.file_path}
                                                alt="Prescription"
                                            />
                                        ) : isAudio ? (
                                            <div className="flex justify-center items-center h-full">  <audio className="w-full max-w-[400px]" controls>
                                                <source src={process.env.REACT_APP_EMRAXIS_S3_URL + "/" + file.file_path} type={`audio/${fileExtension}`} />
                                                Your browser does not support the audio element.
                                            </audio></div>
                                        ) : isVideo ? (
                                            <video className="max-h-[180px] w-auto h-auto object-contain p-5" controls>
                                                <source src={process.env.REACT_APP_EMRAXIS_S3_URL + "/" + file.file_path} type={`video/${fileExtension}`} />
                                                Your browser does not support the video element.
                                            </video>
                                        ) : (
                                            <div className="flex justify-center items-center h-full">
                                                <FileIcon
                                                    ext={"." + fileExtension}
                                                    className='max-h-[180px] w-auto h-auto object-contain p-5  '
                                                />
                                            </div>
                                        )}
                                    </a>
                                    <button id='btnNotesFile'
                                        className="absolute top-2 left-2 bg-gray-100 rounded-full p-1 shadow"
                                        onClick={() => { setNotesFileDeleteCnfBox(true); setNotesFileDeleteID([{ ...file, is_active: false }]); }}
                                    >
                                        <svg id="btnDeleteFileLab" xmlns="http://www.w3.org/2000/svg" className="size-6 text-red-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                                        </svg>
                                    </button>
                                </div>
                            );
                        })
                    }
                </div>
            </div>
        </>
    );
};

export default LabContent;
