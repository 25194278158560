import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import AddMasterTariff from './AddMasterTariff';
import { setAddMasterTariff, setUpdateInvoiceTerms } from '../../Redux/features/popup/popupSlice';
import DataDecode from '../../utils/DataDecode';
import store from '../../API/store';
import { setNotification } from '../../Redux/features/toast/toastSlice';
import DoctorFeesEdit from './DoctorFeesEdit';
import { theme } from '../../EmraxisTheme/Theme';
import InvoiceTerms from './InvoiceTerms';

const MasterTariff = () => {

    const dispatch = useDispatch();

    var loggeduser = useSelector(state => state.login.loginuserData);
    var loggedusertoken = useSelector(state => state.login.userBarerToken);
    var showAddMasterTariff = useSelector(state => state.popup.showAddMasterTariff);
    var showUpdateInvoiceTerms = useSelector(state => state.popup.showUpdateInvoiceTerms);
    var isInvoiceEnabled = useSelector(state => state.invoice.isInvoiceEnabled);
    const previewTheme = useSelector(state => state.theme.colors) || theme.defaultTheme;

    const [allTariffs, setAllTariffs] = useState([]);
    const [tariff, setTariff] = useState({
        master_tariff_id: 0,
        tariff_name: "",
        amount: 0,
        gst: 0
    });
    const [materId, setMasterId] = useState(0);
    const [refreshData, setRefreshData] = useState(true);
    const [confirmBox, setConfirmBox] = useState(false);
    const [disableButton, setDisableButton] = useState(false);
    const [showDoctorFees, setShowDoctorFees] = useState(false);
    const [doctors, setDoctors] = useState([]);
    const [refreshDoctor, setRefreshDoctor] = useState(false);
    const [invoiceTerms, setInvoiceTerms] = useState({ 'clinic_guid': "", terms: '', terms_id: 0 });
    const [refreshTerms, setRefreshTerms] = useState(false);

    const GetMasterTariff = () => {
        store.GetAllMasterTariff(loggedusertoken, DataDecode.encryptPayload({ 'clinic_guid': loggeduser.facilityID }))
            .then((result) => {
                if (result.status == 200) {
                    result.json().then((res) => {
                        let response = DataDecode.decryptResponse(res);
                        var myObject = JSON.parse(response);
                        setAllTariffs(myObject);
                    })
                } else {
                    setAllTariffs([]);
                }
            })
            .catch(err => {
                setAllTariffs([]);
                dispatch(setNotification({ message: 'Error Occurred!', status: 'error', action: true }));
            })
    }

    const GetDoctors = () => {
        try {
            store.GetAllDoctors(loggedusertoken, DataDecode.encryptPayload({ 'clinic_guid': loggeduser.facilityID, page: 0 }))
                .then((result) => {
                    if (result.status === 200) {
                        result.json().then((res) => {
                            let response = DataDecode.decryptResponse(res);
                            var myObject = JSON.parse(response);
                            setDoctors(myObject.doctors);
                        });
                    } else {
                        setDoctors([]);
                    }
                });
        } catch (err) {
            setDoctors([]);
            dispatch(setNotification({ message: 'Error Occurred!', status: 'error', action: true }));
        }
    };

    const GetInvoiceTerms = () => {
        try {
            store.GetInvoiceTerms(loggedusertoken, DataDecode.encryptPayload({ 'clinic_guid': loggeduser.facilityID }))
                .then((result) => {
                    if (result.status === 200) {
                        result.json().then((res) => {
                            let response = DataDecode.decryptResponse(res);
                            var data = JSON.parse(response);
                            setInvoiceTerms(data);
                        });
                    } else {
                        setInvoiceTerms({ 'clinic_guid': loggeduser.facilityID, terms: '', terms_id: 0 });
                    }
                });
        } catch (err) {
            dispatch(setNotification({ message: 'Error Occurred!', status: 'error', action: true }));
        }
    };

    useEffect(() => {
        if (loggeduser) {
            GetInvoiceTerms();
        }
    }, [loggeduser, refreshTerms])

    useEffect(() => {
        if (loggeduser) {
            GetDoctors();
        }
    }, [loggeduser, refreshDoctor])

    useEffect(() => {
        if (loggeduser) {
            GetMasterTariff();
        }
    }, [loggeduser, refreshData])

    const openAddTariff = () => {
        setTariff({
            master_tariff_id: 0,
            tariff_name: "",
            amount: 0,
            gst: 0
        })
        dispatch(setAddMasterTariff(true));
    }

    const openUpdateTariff = () => {
        dispatch(setUpdateInvoiceTerms(true));
    }

    const editMasterTariff = tar => {
        setTariff(tar);
        dispatch(setAddMasterTariff(true));
    }

    const editDoctorMasterTariff = (tar) => {
        setTariff(tar);
        setShowDoctorFees(true);
    }

    const deleteMasterTariff = master_tariff_id => {
        setMasterId(master_tariff_id);
        setConfirmBox(true);
    }

    const handleDeleteSubmit = () => {
        setDisableButton(true);
        store.DeleteMasterTariff(loggedusertoken, DataDecode.encryptPayload({ 'clinic_guid': loggeduser.facilityID, 'master_tariff_id': materId }))
            .then((result) => {
                setDisableButton(false);
                if (result.status == 204) {
                    setConfirmBox(false);
                    dispatch(setNotification({ message: 'Tariff Deleted Successfully!', status: 'success', action: true }));
                    setRefreshData(o => !o);
                } else {
                    setConfirmBox(false);
                    dispatch(setNotification({ message: 'Error Occurred!', status: 'error', action: true }));
                }
            })
            .catch(err => {
                setDisableButton(false);
                setConfirmBox(false);
                dispatch(setNotification({ message: 'Error Occurred!', status: 'error', action: true }));
            })
    }

    return (
        <>
            {isInvoiceEnabled ?
                <>
                    {confirmBox &&
                        <div id="MasterTariffDivconfirmBoxInvoiceEnabled" tabIndex="-1" aria-hidden="true" className="fixed left-1/2 top-1/2 w-full max-w-sm transform  -translate-x-1/2 -translate-y-1/2 sm:w-80 sm:left-[40%]  sm:top-[3rem] sm:translate-x-[50%] sm:translate-y-[50%] ">
                            <div id="MasterTariffDivconfirmBoxInvoiceEnabledDialog" className="relative p-4 w-full max-w-md h-full md:h-auto">
                                <div id="MasterTariffDivconfirmBoxInvoiceEnabledDialogBox" className="relative p-4 text-center bg-white border-gray-400 border-[1px] rounded-lg shadow-lg dark:bg-gray-800 sm:p-5">
                                    <svg id="MasterTariffSvgconfirmBoxInvoiceEnabledDialogIcon" className="text-red-600 dark:text-gray-500 w-11 h-11 mb-3.5 mx-auto" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clipRule="evenodd"></path></svg>
                                    <p id="MasterTariffPconfirmBoxInvoiceEnabledMessage" className="mb-4 text-gray-500 dark:text-gray-300">Are you sure you want to delete this tariff?</p>
                                    <div id="MasterTariffDivconfirmBoxInvoiceEnabledButtons" className="flex justify-center items-center space-x-4">
                                        <button id="MasterTariffButtonconfirmBoxInvoiceEnabledcancel" onClick={() => setConfirmBox(false)} disabled={disableButton} type="button" className="py-2 px-3 text-sm font-medium text-gray-500 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-primary-300 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600 disabled:opacity-50 disabled:cursor-not-allowed">
                                            No, cancel
                                        </button>
                                        <button id="MasterTariffButtonconfirmBoxInvoiceEnabledconfirm" type="button" onClick={handleDeleteSubmit} disabled={disableButton} className="py-2 px-3 text-sm font-medium text-center text-white bg-red-600 rounded-lg hover:bg-red-700 focus:ring-4 focus:outline-none focus:ring-red-300 dark:bg-red-500 dark:hover:bg-red-600 dark:focus:ring-red-900 disabled:opacity-50 disabled:cursor-not-allowed">
                                            Yes, I'm sure {disableButton &&
                                                <svg aria-hidden="true" role="status" className="inline w-4 h-4 ml-2 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB" />
                                                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor" />
                                                </svg>
                                            }
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    <div className=" mx-auto px-4 py-2 my-20 w-100% ">
                        <div className="flex items-center  ">

                        </div>
                        <div className="flex justify-between items-center mb-4 px-2">
                            <header id="MasterTariffHeaderTitle" className={`origin-top-left flex font-bold mb-2 text-2xl lg:text-2xl sm:text-lg pl-2.5 pt-1 ${previewTheme.headingTitleColor}`} style={{ fontFamily: previewTheme.fontFamily }}>
                                Master Tariff
                            </header>

                            <div className='flex items-center'>
                                <div className="hidden items-center lg:flex flex-row mx-4">
                                    <button id="MasterTariffBtnaddTariff" className={`py-2 px-4 rounded-md flex items-center ${previewTheme.addBtnColor} ${previewTheme.addBtnBgColor} transition-colors duration-300 ease-in-out hover:${previewTheme.addBtnHoverColor}`} style={{ fontFamily: previewTheme.fontFamily }} onClick={openAddTariff}>
                                        <svg id="MasterTariffSvgaddTariff" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6 mr-2">
                                            <path d="M5.25 6.375a4.125 4.125 0 1 1 8.25 0 4.125 4.125 0 0 1-8.25 0ZM2.25 19.125a7.125 7.125 0 0 1 14.25 0v.003l-.001.119a.75.75 0 0 1-.363.63 13.067 13.067 0 0 1-6.761 1.873c-2.472 0-4.786-.684-6.76-1.873a.75.75 0 0 1-.364-.63l-.001-.122ZM18.75 7.5a.75.75 0 0 0-1.5 0v2.25H15a.75.75 0 0 0 0 1.5h2.25v2.25a.75.75 0 0 0 1.5 0v-2.25H21a.75.75 0 0 0 0-1.5h-2.25V7.5Z" />
                                        </svg>
                                        Add Tariff
                                    </button>
                                    <button id="MasterTariffBtnupdateInvoice" className="bg-blue-700 hover:bg-blue-800 text-white py-2 px-4 rounded-md flex items-center ml-4" onClick={openUpdateTariff}>
                                        <svg id="MasterTariffSvgupdateInvoiceIcon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-6 mr-2">
                                            <path fillRule="evenodd" d="M5.625 1.5c-1.036 0-1.875.84-1.875 1.875v17.25c0 1.035.84 1.875 1.875 1.875h12.75c1.035 0 1.875-.84 1.875-1.875V12.75A3.75 3.75 0 0 0 16.5 9h-1.875a1.875 1.875 0 0 1-1.875-1.875V5.25A3.75 3.75 0 0 0 9 1.5H5.625ZM7.5 15a.75.75 0 0 1 .75-.75h7.5a.75.75 0 0 1 0 1.5h-7.5A.75.75 0 0 1 7.5 15Zm.75 2.25a.75.75 0 0 0 0 1.5H12a.75.75 0 0 0 0-1.5H8.25Z" clipRule="evenodd" />
                                            <path d="M12.971 1.816A5.23 5.23 0 0 1 14.25 5.25v1.875c0 .207.168.375.375.375H16.5a5.23 5.23 0 0 1 3.434 1.279 9.768 9.768 0 0 0-6.963-6.963Z" />
                                        </svg>
                                        Update Invoice Terms
                                    </button>
                                </div>
                                <div className=' flex flex-row gap-0 '>
                                    <button id="MasterTariffBtnchangeQuery" className="sm:flex md:flex lg:hidden sm:top-section " >
                                        <svg id="MasterTariffBtnchangeQueryIcon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-10 h-10 fill-red-700">
                                            <path fillRule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25ZM12.75 9a.75.75 0 0 0-1.5 0v2.25H9a.75.75 0 0 0 0 1.5h2.25V15a.75.75 0 0 0 1.5 0v-2.25H15a.75.75 0 0 0 0-1.5h-2.25V9Z" clipRule="evenodd" />
                                        </svg>
                                    </button>
                                    <div className="relative items-center gap-2">
                                        <input type="text" id="MasterTariffInputsearchQuery" className="block pl-10 pr-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none" placeholder="Start with"
                                        // onChange={handleChangeQuery} 
                                        // onChange={(e) => searchProducts(e.target.value)}
                                        />
                                        <svg id="MasterTariffSvgsearchQueryIcon" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 absolute left-3 top-1/2 transform -translate-y-1/2 text-red-700">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" />
                                        </svg>
                                    </div>
                                    <button id="MasterTariffBtnreload" className="items-center lg:ml-6" onClick={() => window.location.reload()}>
                                        <svg id="MasterTariffBtnreloadIcon" xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-reload  h-8 w-8 mr-2 text-red-700 hover:text-red-800" width="44" height="44" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                            <path d="M19.933 13.041a8 8 0 1 1 -9.925 -8.788c3.899 -1 7.935 1.007 9.425 4.747" />
                                            <path d="M20 4v5h-5" />
                                        </svg>
                                    </button>
                                </div>

                            </div>

                        </div>
                        <div className='hidden lg:block '>
                            <table className=" hidden lg:inline-table w-[100%]">
                                <thead>
                                    <tr className="px-5 py-2 text-left border-b">
                                        <th id="MasterTariffThindex" className="px-2 py-2 text-left">#</th>
                                        <th id="MasterTariffThtariff" className="px-2 py-2 text-left">Tariff</th>
                                        <th id="MasterTariffThcost" className="px-2 py-2 text-left">Cost</th>
                                        <th id="MasterTariffThgst" className="px-2 py-2 text-left">GST</th>
                                        <th id="MasterTariffThedit" className="px-4 py-2 text-center">Edit</th>
                                        <th id="MasterTariffThdelete" className="px-4 py-2 text-center">Delete</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {allTariffs.map((tar, ind) => (
                                        <tr className='odd:bg-white even:bg-gray-100' key={'tar' + ind}>
                                            <td id={"MasterTariffTdtariffName" + ind} className="px-2 py-2 text-left">{tar.tariff_name || '-'}</td>
                                            <td id={"MasterTariffTdindex" + ind} className="px-2 py-2 text-left">{ind + 1}</td>
                                            <td id={"MasterTariffTdamount" + ind} className="px-2 py-2 text-left">{tar.amount || '-'}</td>
                                            <td id={"MasterTariffTdgst" + ind} className="px-2 py-2 text-left">{tar.gst || 0}%</td>
                                            <td id={"MasterTariffEdit" + ind} className="px-2 py-1 text-center">
                                                <button id={"MasterTariffBtnEditDocTariff" + ind} onClick={() => { tar.is_doctor_consultancy ? editDoctorMasterTariff(tar) : editMasterTariff(tar) }}>
                                                    <svg id={"MasterTariffSvgEditDocTariff" + ind} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-5 h-5">
                                                        <path d="M21.731 2.269a2.625 2.625 0 0 0-3.712 0l-1.157 1.157 3.712 3.712 1.157-1.157a2.625 2.625 0 0 0 0-3.712ZM19.513 8.199l-3.712-3.712-12.15 12.15a5.25 5.25 0 0 0-1.32 2.214l-.8 2.685a.75.75 0 0 0 .933.933l2.685-.8a5.25 5.25 0 0 0 2.214-1.32L19.513 8.2Z" />
                                                    </svg>
                                                </button>
                                            </td>
                                            <td id={"MasterTariffDelete" + ind} className="px-2  py-1 text-center">
                                                <button id={"MasterTariffBtnDeleteDocTariff" + ind} disabled={tar.is_doctor_consultancy} onClick={() => { deleteMasterTariff(tar.master_tariff_id) }} className="disabled:cursor-not-allowed text-[#9B1C1C] disabled:text-[#D88C8C]">
                                                    <svg id={"MasterTariffBtnDeleteDocTariff" + ind} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                                                        <path fillRule="evenodd" d="M16.5 4.478v.227a48.816 48.816 0 0 1 3.878.512.75.75 0 1 1-.256 1.478l-.209-.035-1.005 13.07a3 3 0 0 1-2.991 2.77H8.084a3 3 0 0 1-2.991-2.77L4.087 6.66l-.209.035a.75.75 0 0 1-.256-1.478A48.567 48.567 0 0 1 7.5 4.705v-.227c0-1.564 1.213-2.9 2.816-2.951a52.662 52.662 0 0 1 3.369 0c1.603.051 2.815 1.387 2.815 2.951Zm-6.136-1.452a51.196 51.196 0 0 1 3.273 0C14.39 3.05 15 3.684 15 4.478v.113a49.488 49.488 0 0 0-6 0v-.113c0-.794.609-1.428 1.364-1.452Zm-.355 5.945a.75.75 0 1 0-1.5.058l.347 9a.75.75 0 1 0 1.499-.058l-.346-9Zm5.48.058a.75.75 0 1 0-1.498-.058l-.347 9a.75.75 0 0 0 1.5.058l.345-9Z" clipRule="evenodd" />
                                                    </svg>
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    {showAddMasterTariff && <AddMasterTariff setRefreshData={setRefreshData} masterTariff={tariff} />}
                    {showDoctorFees && <DoctorFeesEdit setShowDoctorFees={setShowDoctorFees} doctors={doctors} setRefreshDoctor={setRefreshDoctor} setRefreshData={setRefreshData} masterTariff={tariff} />}
                    {showUpdateInvoiceTerms && <InvoiceTerms setRefreshTerms={setRefreshTerms} terms={invoiceTerms} />}
                </>
                :
                <p id="MasterTariffPnotAuthorizedText" className='text-red-600 font-semibold text-lg p-10 text-center my-20'>You are not authorized to perform this action!</p>}
        </>
    )
}

export default MasterTariff