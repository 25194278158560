import React, { useEffect, useState } from "react";
import DashboardIcon from "./Assets/Dashboard.png";
import UserIcon from "./Assets/User.png";
import FeaturesIcon from "./Assets/features.png";
import SubscriptionIcon from "./Assets/subscription.png";
import UserRoleIcon from "./Assets/userRole.png";
import HomeIcon from "./Assets/Home.png";
import DoctorIcon from "./Assets/Doctor.png";
import PatientIcon from "./Assets/Patient.png";
import AppointmentIcon from "./Assets/Appointment.png";
import FeatureIcon from "./Assets/Feature.png";
import InvoiceIcon from "./Assets/invoice_icon.png";
import UsersIcon from "./Assets/users.png";
import { useDispatch, useSelector } from "react-redux";
import { theme } from "../EmraxisTheme/Theme";
import { Link } from "react-router-dom";
import { setTheme } from "../Redux/features/colors/themeSlice";
import { NavLink, useLocation } from "react-router-dom";
import { setIsInvoiceEnabled } from "../Redux/features/invoice/invoiceSlice";
import FeedbackIcon from "./Assets/ri_feedback-fill.png";
import { useNavigate } from "react-router-dom";
import {
  setActivateIsStandardAiStatus,
  setActivateVoice2TextStatus,
} from "../Redux/features/feature/featureSlice";
import { addFeedback } from "../Redux/features/feedback/feedbackSlice";
import { BiChevronUp } from "react-icons/bi";
import RecycleBinIcon from "./Assets/recycleBin.svg";
import { setIsSuperAdminDropdownOpen, setIsDesktopUserScreenOpen } from "../Redux/features/drawer/drawerSlice";
import { setIsSideNavbarClicked, setPatientProfileMenuSelector } from "../Redux/features/profileDrawer/profileSideNavbarSlice";
const Sidebar = ({ isSidebarOpen }) => {
  var loggeduser = useSelector((state) => state.login.loginuserData);
  var facilityName = useSelector((state) => state.facility.facility_name);
  var patientProfileMenuSelector = useSelector((state) => state.profileSideNavbar.patientProfileMenuSelector);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  var isSuperAdminDropdownOpen = useSelector(
    (state) => state.drawer.isSuperAdminDropdownOpen
  );
  var isDesktopUserScreenOpen = useSelector(
    (state) => state.drawer.isDesktopUserScreenOpen
  );
  useEffect(() => {
    if (loggeduser) {
      dispatch(
        setIsInvoiceEnabled(
          loggeduser?.addon_features?.filter(
            (a) => a.feature === "Invoice" && a.is_active
          ).length > 0
        )
      );
      dispatch(
        setActivateVoice2TextStatus(
          loggeduser?.addon_features?.filter(
            (a) => a.feature === "Voice_To_Text" && a.is_active
          ).length > 0
        )
      );
      dispatch(
        setActivateIsStandardAiStatus(
          loggeduser?.addon_features?.filter(
            (a) => a.feature === "Standard_AI" && a.is_active
          ).length > 0
        )
      );
      if (loggeduser.length != 0 && loggeduser.user_role == "SuperAdmin") {
        const themeToPreview =
          theme.filter((t) => t.name == "defaultTheme")[0] || {};
        dispatch(setTheme(themeToPreview));
      } else {
        if (loggeduser.length != 0) {
          const themeToPreview =
            theme.filter(
              (t) => t.name == loggeduser?.theme_management?.theme
            )[0] || {};
          dispatch(setTheme(themeToPreview));
          /*   navigate('/appointments')  */
        }
      }
    }
  }, [loggeduser, dispatch]);

  const location = useLocation();
  const pathName = location?.pathname?.substring(1);
  var feedback = useSelector(state => state.feedback.feedback_status);
  const previewTheme =
    useSelector((state) => state.theme.colors) || theme.defaultTheme;
  var isInvoiceEnabled = useSelector((state) => state.invoice.isInvoiceEnabled);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [activeLink, setActiveLink] = useState("");
  const [normalLink, setNormalLink] = useState("");
  const [activeSuperAdminLink, setActiveSuperAdminLink] = useState("");
  const [normalSuperAdminLink, setNormalSuperAdminLink] = useState("");
  var isSideNavbarClicked = useSelector(state => state.profileSideNavbar.isSideNavbarClicked);

  const toggleDropdown = () => {
    dispatch(setIsSuperAdminDropdownOpen(!isSuperAdminDropdownOpen));
  };

  const handleDashboard = () => {
    navigate('/')
    dispatch(setIsDesktopUserScreenOpen(false));
    dispatch(setIsSuperAdminDropdownOpen(false));
  }

  useEffect(() => {
    setActiveLink(
      `lg:px-4 xl:px-6 py-4  ${previewTheme?.navbarActiveColor} ${previewTheme?.navbarBgColor}`
    );
    setNormalLink(
      `lg:px-4 xl:px-6 px-6 py-4 hover:${previewTheme?.navbarBgHoverColor} hover:${previewTheme?.navbarTextColor}`
    );
  }, [previewTheme]);

  useEffect(() => {
    setActiveSuperAdminLink(
      `lg:px-4 xl:px-6 py-4 ${previewTheme?.navbarActiveColorSuperAdmin} ${previewTheme?.navbarBgColor}`
    );
    setNormalSuperAdminLink(
      `lg:px-4 xl:px-6 px-6 py-4 hover:${previewTheme?.navbarBgColor} hover:${previewTheme?.navbarTextColor}`
    );
  }, [previewTheme]);

  const labelClass = isSidebarOpen ? "visible" : "hidden";
  const [openMenu, setOpenMenu] = useState(null);

  useEffect(() => {
    if (pathName === "master-tariff" || pathName === "invoices") {
      setOpenMenu("invoice");
    } else {
      setOpenMenu(null);
    }
  }, [pathName]);

  const handleMenuClick = (menu) => {
    dispatch(setIsSideNavbarClicked(!isSideNavbarClicked));
    setOpenMenu(openMenu === menu ? null : menu);
  };

  const handleFeedback = () => {
    dispatch(setIsSideNavbarClicked(!isSideNavbarClicked));
    dispatch(addFeedback(true));
  };

  useEffect(() => {
    if (feedback) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [feedback]);

  useEffect(() => {
    const handleUrlChange = () => {
      const queryParams = new URLSearchParams(window.location.search);
      const profileValue = queryParams.get('profile');
      if (profileValue) {
        const { page_src } = JSON.parse(atob(profileValue));
        dispatch(setPatientProfileMenuSelector(page_src))
      }
    };
    window.addEventListener('urlChange', handleUrlChange);
    handleUrlChange();
    return () => {
      window.removeEventListener('urlChange', handleUrlChange);
    };
  }, [location.search]);

  useEffect(()=>{
    if(pathName !== "patient-profile"){
      dispatch(setPatientProfileMenuSelector(""))
    }
  },[pathName])

  const handleNavChange = (event) => {
    event.preventDefault();
    dispatch(setIsSideNavbarClicked(!isSideNavbarClicked));
    let to = event.currentTarget.getAttribute('href');
    if(to){
      setTimeout(()=>{
        navigate(to)
      },100)      
    }
  }

  return (
    <div className=" h-full bg-white">
      <div className=" bg-white overflow-hidden">
        {loggeduser && loggeduser?.user_role &&
        <div className="w-[100%] bg-white mt-20">
          {loggeduser &&
          loggeduser.user_role != "SuperAdmin" &&
          !isDesktopUserScreenOpen &&
          loggeduser.user_isactive == true ? (
            <nav
              className={
                isSidebarOpen
                  ? "flex flex-col"
                  : "flex flex-col justify-center items-center"
              }
            >
              <NavLink
               id="DesktopLinkdashboard"
                to="/dashboard"
                onClick={handleNavChange}
                className={({ isActive }) =>
                  isActive ? activeLink : normalLink
                }
                style={
                  isSidebarOpen
                    ? {}
                    : {
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                      }
                }
              >
                <img
                 id="DesktopImgdashboardIcon"
                  src={DashboardIcon}
                  alt="Dashboard"
                  className="inline-block w-6 h-6"
                />{" "}
                <span
                  id="DesktopSpandashboardText"
                  className={`lg:ml-2 xl:ml-4 lg:text-[14px] xl:text-[16px] ${labelClass}`}
                  style={{ fontFamily: previewTheme.fontFamily }}
                >
                  Dashboard
                </span>
              </NavLink>
              <NavLink
               id="DesktopLinkappointments"
                to="/appointments"
                onClick={handleNavChange}
                className={({ isActive }) =>
                    (isActive || (pathName === "patient-profile" && patientProfileMenuSelector === "appointment"))  ? activeLink : normalLink
                  }
                style={
                  isSidebarOpen
                    ? {}
                    : {
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                      }
                }
              >
                <img
                  id="DesktopImgappointmentIcon"
                  src={AppointmentIcon}
                  alt="Appointment"
                  className="inline-block w-5 h-4"
                />{" "}
                <span
                  id="DesktopSpanappointmentsText"
                  className={`lg:ml-2 xl:ml-4 lg:text-[14px] xl:text-[16px] ${labelClass}`}
                  style={{ fontFamily: previewTheme.fontFamily }}
                >
                  Appointments
                </span>
              </NavLink>
              <NavLink
                id="DesktopLinkpatients"
                to="/patients"
                onClick={handleNavChange}
                className={({ isActive }) =>
                    (isActive  || (pathName === "patient-profile" && patientProfileMenuSelector === "patient")) ? activeLink : normalLink
                  }
                style={
                  isSidebarOpen
                    ? {}
                    : {
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                      }
                }
              >
                <img
                id="DesktopImgpatientIcon"
                  src={PatientIcon}
                  alt="Patient"
                  className="inline-block w-6 h-6"
                />{" "}
                <span
                id="DesktopSpanpatientText"
                  className={`lg:ml-2 xl:ml-4 lg:text-[14px] xl:text-[16px] ${labelClass}`}
                  style={{ fontFamily: previewTheme.fontFamily }}
                >
                  Patients
                </span>
              </NavLink>
              <NavLink
                id="DesktopLinkdoctors"
                to="/doctors"
                onClick={handleNavChange}
                className={({ isActive }) =>
                  isActive ? activeLink : normalLink
                }
                style={
                  isSidebarOpen
                    ? {}
                    : {
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                      }
                }
              >
                <img
                id="DesktopImgdoctorIcon"
                  src={DoctorIcon}
                  alt="Doctor"
                  className="inline-block w-6 h-6"
                />{" "}
                <span
                  id="DesktopSpandoctorsText"
                  className={`lg:ml-2 xl:ml-4 lg:text-[14px] xl:text-[16px] ${labelClass}`}
                  style={{ fontFamily: previewTheme.fontFamily }}
                >
                  Doctors
                </span>
              </NavLink>
              {loggeduser.user_role == "Admin" && (
                <>
                  <NavLink
                     id="DesktopLinkAdminuserManagement"
                    to="/admin-user-management"
                    onClick={handleNavChange}
                    className={({ isActive }) =>
                      isActive ? activeLink : normalLink
                    }
                    style={
                      isSidebarOpen
                        ? {}
                        : {
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "100%",
                          }
                    }
                  >
                    <img
                      id="DesktopImguserIcon"
                      src={UsersIcon}
                      alt="Doctor"
                      className="inline-block w-6 h-6"
                    />{" "}
                    <span
                      id="DesktopSpanusersText"
                      className={`lg:ml-2 xl:ml-4 lg:text-[14px] xl:text-[16px] ${labelClass}`}
                      style={{ fontFamily: previewTheme.fontFamily }}
                    >
                      Users
                    </span>
                  </NavLink>
                  <NavLink
                     id="DesktopLinkRecycleBin"
                    to="/recyclebin"
                    onClick={handleNavChange}
                    className={({ isActive }) =>
                      isActive ? activeLink : normalLink
                    }
                    style={
                      isSidebarOpen
                        ? {}
                        : {
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "100%",
                          }
                    }
                  >
                    <img
                      id="DesktopImgLinkRecycleBinIcon"
                      src={RecycleBinIcon}
                      alt="Recycle Bin"
                      className="inline-block w-6 h-6"
                    />{" "}
                    <span
                      id="DesktopSpanLinkRecycleBinText"
                      className={`lg:ml-2 xl:ml-4 lg:text-[14px] xl:text-[16px] ${labelClass}`}
                      style={{ fontFamily: previewTheme.fontFamily }}
                    >
                      Recycle Bin
                    </span>
                  </NavLink>
                  {isInvoiceEnabled && (
                    <div className="relative">
                      <button 
                       id="DesktopButtonmenuClick"
                        onClick={() => handleMenuClick("invoice")}
                        className={`lg:px-4 xl:px-6 px-6 py-4 hover:${previewTheme?.navbarBgHoverColor} hover:${previewTheme?.navbarTextColor} w-full flex justify-between items-center  transition duration-200`}
                      >
                        <span>
                          <img
                            id="DesktopImginvoiceIcon"
                            src={InvoiceIcon}
                            alt="Invoice"
                            className="inline-block w-6 h-6"
                          />{" "}
                          <span 
                          id="DesktopSpaninvoiceText"
                            className={`lg:ml-2 xl:ml-4 lg:text-[14px] xl:text-[16px] ${labelClass}`}
                          >
                            Invoice
                          </span>
                        </span>
                        <svg id="DesktopSvginvoiceIcon"
                          className={`w-4 h-4 transform transition-transform duration-200 ${
                            openMenu === "invoice" ? "rotate-180" : ""
                          }`}
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M19 9l-7 7-7-7"
                          ></path>
                        </svg>
                      </button>
                      <div
                        className={`mt-2 pl-7 ${
                          openMenu === "invoice" ? "block" : "hidden"
                        }`}
                      >
                        <NavLink id="DesktopLinkmasterTariff"
                          to="/master-tariff"
                          className={({ isActive }) =>
                            isActive
                              ? " block py-2.5 px-4 rounded transition duration-200 " +
                                activeLink
                              : " block py-2.5 px-4 rounded transition duration-200 " +
                                normalLink
                          }
                        >
                          <span id="DesktopSpanmasterTariffText"
                            className={`lg:ml-2 xl:ml-4 lg:text-[14px] xl:text-[16px] ${labelClass}`}
                          >
                            Master Tarrif
                          </span>
                        </NavLink>
                        <NavLink id="DesktopLinkinvoices"
                          to="/invoices"
                          className={({ isActive }) =>
                            isActive
                              ? " block py-2.5 px-4 rounded transition duration-200 " +
                                activeLink
                              : " block py-2.5 px-4 rounded transition duration-200 " +
                                normalLink
                          }
                        >
                          <span id="DesktopSpaninvoices"
                            className={`lg:ml-2 xl:ml-4 lg:text-[14px] xl:text-[16px] ${labelClass}`}
                          >
                            Invoices
                          </span>
                        </NavLink>
                      </div>
                    </div>
                  )}
                </>
              )}
              <div id="DesktopDivfeedBackOpen"
                onClick={handleFeedback}
                className={normalLink + " cursor-pointer"}
                style={
                  isSidebarOpen
                    ? {}
                    : {
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                      }
                }
              >
                <img
                  id="DesktopImgfeedBackIcon"
                  src={FeedbackIcon}
                  alt="Doctor"
                  className="inline-block w-6 h-6"
                />{" "}
                <span id="DesktopSpanfeedbackText"
                  className={`lg:ml-2 xl:ml-4 lg:text-[14px] xl:text-[16px] ${labelClass}`}
                >
                  Feedback
                </span>
              </div>
            </nav>
          ) : loggeduser != null &&
            loggeduser.length != 0 &&
            loggeduser.user_role == "SuperAdmin" &&
            !isDesktopUserScreenOpen ? (
            <nav className="flex flex-col">
              <NavLink
                to="/"
                id="DesktopLinksuperAdminDashboard"
                className={({ isActive }) =>
                  isActive ? activeLink : normalLink
                }
                style={
                  isSidebarOpen
                    ? {}
                    : {
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                      }
                }
              >
                <img
                 id="DesktopImgsuperAdminDashboardIcon"
                  src={DashboardIcon}
                  alt="Dashboard"
                  className="inline-block w-6 h-6"
                />{" "}
                <span id="DesktopSpansuperAdmindashboardText"
                  className={`lg:ml-2 xl:ml-4 lg:text-[14px] xl:text-[16px] ${labelClass}`}
                >
                  Dashboard
                </span>
              </NavLink>
              <NavLink 
              id="DesktopLinksuperAdminReports"
                to="/excel-reports"
                className={({ isActive }) =>
                  isActive ? activeLink : normalLink
                }
                style={
                  isSidebarOpen
                    ? {}
                    : {
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                      }
                }
              >
                <img
                id="DesktopImgsuperAdminAppointmentIcon"
                  src={AppointmentIcon}
                  alt="Appointment"
                  className="inline-block w-5 h-4"
                />{" "}
                <span id="DesktopSpansuperAdminReportsText"
                  className={`lg:ml-2 xl:ml-4 lg:text-[14px] xl:text-[16px] ${labelClass}`}
                >
                  Excel Reports
                </span>
              </NavLink>
            </nav>
          ) : (
            <nav className="">
              <div className="flex flex-col  justify-center   text-[#444444] font-medium">
                {isSidebarOpen ? (
                  <div className="flex p-[20px] !pl-[0px]">
                    <Link to="/" id="DesktopNavLinkdashboard">
                      <svg id="DesktopSvgdesktopDashboardIcon"
                        width="25"
                        height="25"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        onClick={handleDashboard}
                      >
                        <path
                          d="M12.4999 6.42708L9.26657 9.66042L12.4999 12.8937C12.8249 13.2188 12.8249 13.7438 12.4999 14.0688C12.1749 14.3938 11.6499 14.3938 11.3249 14.0688L7.4999 10.2437C7.1749 9.91875 7.1749 9.39375 7.4999 9.06875L11.3249 5.24375C11.6499 4.91875 12.1749 4.91875 12.4999 5.24375C12.8166 5.56875 12.8249 6.10208 12.4999 6.42708Z"
                          fill="#666666"
                        />
                      </svg>
                    </Link>
                    <img 
                      id="DesktopImgHomeIcon"
                      src={HomeIcon}
                      alt="Dashboard"
                      className="inline-block w-6 h-6"
                    />{" "}
                    <div id="DesktopDivfacilityName" className="flex cursor-pointer !font-semibold ml-[11px]">
                      {facilityName}
                      <BiChevronUp
                      id="DesktopBiChevronUpIcon"
                        size={26}
                        onClick={toggleDropdown}
                        className={`ml-[15px] -mt-[3px] ${
                          isSuperAdminDropdownOpen && "rotate-180"
                        }`}
                      />
                    </div>
                  </div>
                ) : (
                  <>
                    <div className="flex mt-[20px]">
                      <Link to="/" id="DesktopLinkdesktopSuperAdminDashboardIcon">
                        <svg id="DesktopSvgdesktopSuperAdminDashboardIcon"
                          width="25"
                          height="25"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          onClick={handleDashboard}
                        >
                          <path
                            d="M12.4999 6.42708L9.26657 9.66042L12.4999 12.8937C12.8249 13.2188 12.8249 13.7438 12.4999 14.0688C12.1749 14.3938 11.6499 14.3938 11.3249 14.0688L7.4999 10.2437C7.1749 9.91875 7.1749 9.39375 7.4999 9.06875L11.3249 5.24375C11.6499 4.91875 12.1749 4.91875 12.4999 5.24375C12.8166 5.56875 12.8249 6.10208 12.4999 6.42708Z"
                            fill="#666666"
                          />
                        </svg>
                      </Link>
                      <img
                        id="DesktopIMGHomeDashboardIcon"
                        src={HomeIcon}
                        alt="Dashboard"
                        className="inline-block w-7 h-7 ml-[8px]"
                      />
                       <BiChevronUp
                      id="DesktopBiChevronUpIcon"
                        size={26}
                        onClick={toggleDropdown}
                        className={`${
                          isSuperAdminDropdownOpen && "rotate-180"
                        }`}
                      />
                     
                    </div>
                  </>
                )}

                {isSuperAdminDropdownOpen && (
                  <div className="flex flex-col">
                    <NavLink id="DesktopLinkdropDownManagement"
                      to="/user-management"
                  
                      className={({ isActive }) =>
                        isActive ? activeSuperAdminLink : normalSuperAdminLink
                      }
                      style={
                        isSidebarOpen
                          ? {}
                          : {
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              width: "100%",
                            }
                      }
                    >
                      <img
                       id="DesktopIMGUserIcon"
                        src={UserIcon}
                        className="inline-block w-6 h-6"
                      />{" "}
                   
                      <span id="DesktopSpandropDownUsersText"
                        className={`lg:ml-2 xl:ml-4 lg:text-[14px] xl:text-[16px] ${labelClass}`}
                        style={{ fontFamily: previewTheme.fontFamily }}
                      >
                        Users
                      </span>
                    </NavLink>
                    <NavLink id="DesktopLinkdropDownUserRole"
                      to="/user-role"
                      className={({ isActive }) =>
                        isActive ? activeSuperAdminLink : normalSuperAdminLink
                      }
                      style={
                        isSidebarOpen
                          ? {}
                          : {
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              width: "100%",
                            }
                      }
                    >
                      <img
                        id="DesktopIMGUserRoleIcon"
                        src={UserRoleIcon}
                        className="inline-block w-6 h-6"
                      />{" "}
                     
                      <span id="DesktopSpandropDownUserRoleText"
                        className={`lg:ml-2 xl:ml-4 lg:text-[14px] xl:text-[16px] ${labelClass}`}
                        style={{ fontFamily: previewTheme.fontFamily }}
                      >
                        User Role
                      </span>
                    </NavLink>
                    <NavLink id="DesktopLinkdropDownsubscription"
                      to="/subscriptions"
                   
                      className={({ isActive }) =>
                        isActive ? activeSuperAdminLink : normalSuperAdminLink
                      }
                      style={
                        isSidebarOpen
                          ? {}
                          : {
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              width: "100%",
                            }
                      }
                    >
                      <img
                        id="DesktopIMGSubscriptionIcon"
                        src={SubscriptionIcon}
                        className="inline-block w-6 h-6"
                      />{" "}
                 
                      <span id="DesktopSpandropDownSubscriptionText"
                        className={`lg:ml-2 xl:ml-4 lg:text-[14px] xl:text-[16px] ${labelClass}`}
                        style={{ fontFamily: previewTheme.fontFamily }}
                      >
                        Subscription
                      </span>
                    </NavLink>

                    <NavLink id="DesktopLinkdropDownFeatures"
                      to="/feature"
                    
                      className={({ isActive }) =>
                        isActive ? activeSuperAdminLink : normalSuperAdminLink
                      }
                      style={
                        isSidebarOpen
                          ? {}
                          : {
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              width: "100%",
                            }
                      }
                    >
                      <img 
                        id="DesktopIMGFeatureIcon"
                        src={FeaturesIcon}
                        className="inline-block w-6 h-6"
                        alt="Feature"
                      />{" "}
                     
                      <span id="DesktopSpandropDownFeaturesText"
                        className={`lg:ml-2 xl:ml-4 lg:text-[14px] xl:text-[16px] ${labelClass}`}
                        style={{ fontFamily: previewTheme.fontFamily }}
                      >
                        Features
                      </span>
                    </NavLink>
                  </div>
                )}
              </div>
            </nav>
          )}
        </div>}
      </div>
    </div>
  );
};

export default Sidebar;
