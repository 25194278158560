import React, { useEffect, useState, useRef, useMemo } from 'react';
import store from '../../API/store';
import { useDispatch, useSelector } from 'react-redux';
import DataDecode from '../../utils/DataDecode';
import { useLocation } from 'react-router-dom';
import { IoMdSave } from "react-icons/io";
import { setNotification } from '../../Redux/features/toast/toastSlice';
import { theme } from '../../EmraxisTheme/Theme';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import SnackBarComponent from "../../components/SnackBarComponent/SnackBarComponent";
import dayjs from 'dayjs';
import Select from "react-select";
import { PiCurrencyInrLight } from "react-icons/pi";
import AddDoctor from './AddDoctor';
import AddAppointment from '../Appointments/AddAppointment';
import { setAddAppointmentPopup } from "../../Redux/features/popup/popupSlice";
import AddPatient from '../Patients/AddPatient';

const DoctorProfile = ({ setRefreshData }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const previewTheme = useSelector(state => state.theme.colors) || theme.defaultTheme;
  const queryParams = new URLSearchParams(location.search);
  var refreshDataAvailable = useSelector(state => state.document_upload.refreshDataAvailable);
  const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
  const queryParamValue = queryParams.get('profile');
  const { doctor_id } = JSON.parse(atob(queryParamValue));
  const [toast, setToast] = useState({ show: false, message: '', type: '' });
  const [loading, setLoading] = useState(false);
  const [selectDay, setSelectDay] = useState('');
  const [doctorinfo, setDoctorinfo] = useState([]);
  const [isPopup, setIsPopup] = useState();
  const [isEditing, setIsEditing] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [isTimeInOpen, setIsTimeInOpen] = useState(false);
  const [isTimeOutOpen, setIsTimeOutOpen] = useState(false);
  const timeInRef = useRef(null);
  const timeOutRef = useRef(null);
  const [editableAbout, setEditableAbout] = useState(false);
  const [educationContent, setEducationContent] = useState("M.B.B.S., Gujarat University, Ahmedabad, India. M.S., Gujarat University, Ahmedabad, India. SPINAL FELLOWSHIP Dr. John Adam, Allegimeines Krakenhaus, Schwerin, Germany.Fellowship in Endoscopic Spine Surgery Phoenix, USA.");
  const [editableEducation, setEditableEducation] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [totalPatients, setTotalPatients] = useState(null);
  const [selectedDoctor, setSelectedDoctor] = useState(null);
  const [slot, setSlot] = useState(null);
  const [doctorsTimeslot, setDoctorsTimeSlot] = useState([]);
  const [doctors, setDoctors] = useState([]);
  const [saveChange, setSaveChange] = useState(false);
  const [docPic, setDocPic] = useState(false);
  const [docFile, setDocFile] = useState(null);

  const [editedDoctorInfo, setEditedDoctorInfo] = useState({
    doctor_id: 0,
    FirstName: '',
    MiddleName: '',
    LastName: '',
    title: '',
    department_id: 0,
    gender: 'Male',
    specialist_id: 0,
    doctor_degree: '',
    phone_number: '',
    email_id: '',
    joiningDate: null,
    about: '',
    specialization: '',
    title: ''
  });
  var showAddAppointmentpopup = useSelector((state) => state.popup.showAddAppointment);
  var showAddPatientpopup = useSelector((state) => state.popup.showAddPatient);
  var refreshAppointment = useSelector((state) => state.popup.refreshAppointment);
  
  useEffect(() => {
    const today = new Date();
    const currentDay = today.toLocaleDateString('en-US', { weekday: 'long' });
    setSelectDay(currentDay);
  }, []);

  useEffect(() => {
    if (isPopup) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [isPopup]);

  useEffect(() => {
    if (showPopup) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [showPopup]);

  const closePopup = () => {
    setShowPopup(false);
  };

  const showToast = () => {
    setTimeout(() => {
      setToast({ show: false, message: '', type: '' });
    }, 3000);
  };

  useEffect(() => {
    if (doctorinfo.length > 0) {
      const existingDoctorInfo = doctorinfo[0];
      setEditedDoctorInfo({
        doctor_id: existingDoctorInfo.doctor_id || editedDoctorInfo.doctor_id,
        title: existingDoctorInfo.title || editedDoctorInfo.title,
        FirstName: existingDoctorInfo.FirstName || editedDoctorInfo.FirstName,
        MiddleName: existingDoctorInfo.MiddleName || editedDoctorInfo.MiddleName,
        LastName: existingDoctorInfo.LastName || editedDoctorInfo.LastName,
        department_id: existingDoctorInfo.department_id || editedDoctorInfo.department_id,
        gender: existingDoctorInfo.gender || editedDoctorInfo.gender,
        specialist_id: existingDoctorInfo.specialist_id || editedDoctorInfo.specialist_id,
        doctor_degree: existingDoctorInfo.doctor_degree || editedDoctorInfo.doctor_degree,
        phone_number: existingDoctorInfo.phone_number || editedDoctorInfo.phone_number,
        email_id: existingDoctorInfo.email_id || editedDoctorInfo.email_id,
        JoiningDate: existingDoctorInfo.created_on || editedDoctorInfo.joiningDate,
        about: existingDoctorInfo.about || editedDoctorInfo.about,
        specialization: existingDoctorInfo.specialization || editedDoctorInfo.specialization,
        consulting_fees: existingDoctorInfo.consulting_fees || editedDoctorInfo.consulting_fees,
        status: 'Added',
        clinic_guid: loggeduser.facilityID,

      });

    }
  }, [doctorinfo]);


  var loggeduser = useSelector(state => state.login.loginuserData);
  var loggedusertoken = useSelector(state => state.login.userBarerToken);
  const fetchDoctorData = () => {
    try {
      store.GetDoctorInfo(loggedusertoken, DataDecode.encryptPayload({ 'clinic_guid': loggeduser.facilityID, 'doctor_id': doctor_id }))
        .then((result) => {
          if (result.status === 200) {
            result.json().then((res) => {
              let response = DataDecode.decryptResponse(res);
              let docInfo = JSON.parse(response)
              if(docInfo.length > 0 && docInfo[0].profile_pic ){              
              setDocFile(`${process.env.REACT_APP_EMRAXIS_S3_URL}/${docInfo[0].profile_pic}`)}
              setDoctorinfo(JSON.parse(response));

            });
          } else {
            setDoctorinfo([]);
          }
        });
    } catch (err) {
      setToast({ show: true, message: "Error occurred", type: "error" });
      showToast();
      setLoading(false);

    }
  };
  useEffect(() => {
    if (loggeduser != null && loggeduser.length != 0) {
      fetchDoctorData();
      GetPatient();
      GetDoctors(loggeduser.facilityID);
    }
  }, [refreshDataAvailable,refreshAppointment]);

  const GetPatient = () => {
    let myPayloads = DataDecode.encryptPayload({
      clinic_guid: loggeduser.facilityID,
      doctor_id: doctor_id,
    });
    store
      .GetNoOfPatientsForDoctor(loggedusertoken, myPayloads)
      .then(result => {
        if (result.status == 200) {
          result.json().then(res => {
            let response = DataDecode.decryptResponse(res);
            let data = JSON.parse(response);
            setTotalPatients(data);
          });
        } else {
          setTotalPatients(null);
        }
      })
      .catch(err => {
        dispatch(setNotification({ message: 'Error Occured!', status: 'error', action: true }));
      });
  };
  const validateForm = () => {
    let valid = true;
    if (!editedDoctorInfo.FirstName) {
      valid = false;
    }
    if (!/^[a-zA-Z\s]+$/.test(editedDoctorInfo.FirstName)) {
      valid = false;
    }
    if (!/^[a-zA-Z\s]+$/.test(editedDoctorInfo.MiddleName)) {
      valid = false;
    }
    if (!editedDoctorInfo.LastName) {
      valid = false;
    }
    if (!/^[a-zA-Z\s]+$/.test(editedDoctorInfo.LastName)) {
      valid = false;
    }
    if (!editedDoctorInfo.email_id) {
      valid = false;
    } else if (editedDoctorInfo.email_id && !/\S+@\S+\.\S+/.test(editedDoctorInfo.email_id)) {
      valid = false;
    }
    return valid;
  };

  const handleEditClick = () => {
    setIsEditing(true);
  }
  const handleSaveClick = () => {
    setIsEditing(false);
    updateDoctorInfo();
    setSaveChange(false);
  }

  const handleChange = (e) => {
    setEditedDoctorInfo({
      ...editedDoctorInfo,
      clinic_guid: loggeduser.facilityID,
      [e.target.name]: e.target.value
    });
    setSaveChange(true);
  }
  const titles = [
    { value: 1, label: "Dr." },
    { value: 2, label: "Mr." },
    { value: 3, label: "Ms." },
    { value: 4, label: "Mrs." },
    { value: 5, label: "Md." },
  ];

  const handleSelectTitles = (selectedOption) => {
    setEditedDoctorInfo({
      ...editedDoctorInfo, title: selectedOption ? selectedOption.label : "",
      clinic_guid: loggeduser.facilityID
    })

  };

  const handleAboutEditClick = () => {
    setEditableAbout(!editableAbout);
  };
  const handleAboutSave = () => {
    setEditableAbout(false);
    updateDoctorInfo();
  };
  const handleEducationChange = (event) => {
    setEducationContent(event.target.value);
  };
  const handleEducationEditClick = () => {
    setEditableEducation(!editableEducation);
  };
  const handleEducationSave = () => {
    setEditableEducation(false);
  };

  const isMobileBrowser = /Mobi|Android/i.test(navigator.userAgent);
  
  function updateDoctorInfo() {
    if (!validateForm() && !isMobileBrowser) {
      dispatch(setNotification({ message: 'Name & Email can not be empty!', status: 'error', action: true }));
      fetchDoctorData();
    } else {
      store.UpdateDoctorInfo(loggedusertoken, DataDecode.encryptPayload(editedDoctorInfo))
        .then((result) => {
          if (result.status == 204) {
            fetchDoctorData();
            dispatch(setNotification({ message: 'Doctor Information Updated Successfully!', status: 'success', action: true }));
            setRefreshData(r => !r);
          }
          else {
            dispatch(setNotification({ message: 'Error Occured!', status: 'error', action: true }));
          }
        })
        .catch((error) => {
          console.error("Error occurred while saving doctor details:", error);
        });
    }
  }

  const handleEditDoc = (doctor) => {
    setShowPopup(true);
    setSelectedDoctor(doctor);
    setDocPic(true)
  };

  const handlePopup = () => {
    resetForm();
    setIsPopup(true);
  }

  const handleCancel = () => {
    setIsPopup(false);
    resetForm();
  }

  const initialDays = {
    selectAll: false,
    Monday: false,
    Tuesday: false,
    Wednesday: false,
    Thursday: false,
    Friday: false,
    Saturday: false,
    Sunday: false
  };
  const [selectedDays, setSelectedDays] = useState(initialDays);
  const [timings, setTimings] = useState([]);
  const [schedule, setSchedule] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [timeIn, setTimeIn] = React.useState(null);
  const [timeOut, setTimeOut] = React.useState(null);
  const [expandedDays, setExpandedDays] = useState([])
  const [confirmBox, setConfirmBox] = useState(false);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [disableButton, setDisableButton] = useState(false);
  const [dayError, setDayError] = useState("");
  const [timeError, setTimeError] = useState("");
  const dropdownRef = useRef(null);
  const toggleDropdown = () => setIsOpen(!isOpen);
  const orderedSchedule = useMemo(() => {
    // Initialize an array with all days of the week
    const allDays = [
      { days: 'Monday', TimeSlots: [] },
      { days: 'Tuesday', TimeSlots: [] },
      { days: 'Wednesday', TimeSlots: [] },
      { days: 'Thursday', TimeSlots: [] },
      { days: 'Friday', TimeSlots: [] },
      { days: 'Saturday', TimeSlots: [] },
      { days: 'Sunday', TimeSlots: [] },
    ];

    // Merge and sort the schedule with the initialized array
    return allDays.map(day => {
      const apiDay = schedule.find(item => item.days === day.days);
      return apiDay || day; // Use API data if available, otherwise use the initialized day
    });
  }, [schedule]);
  const handleDeleteSlot = (days, timing) => {
    setSelectedSlot({ days, timing });
    setConfirmBox(true);
  };

  const handleDeleteSubmit = () => {
    setDisableButton(true);
    const { days, timing } = selectedSlot;
    const payload = {
      doctor_id: doctor_id,
      day: days,
      time_in: timing.time_in,
      time_out: timing.time_out
    };
    store.deleteDoctorSlot(loggedusertoken, DataDecode.encryptPayload(payload))
      .then(response => {
        if (response.ok) {
          dispatch(setNotification({ message: 'Time Slot Deleted Successfully!', status: 'success', action: true }));
          fetchDoctorSchedule();
        } else {
          dispatch(setNotification({ message: 'Error Occurred!', status: 'error', action: true }));
        }
        setDisableButton(false);
        setConfirmBox(false);
      })
      .catch(err => {
        dispatch(setNotification({ message: 'Error Occurred!', status: 'error', action: true }));
        setDisableButton(false);
        setConfirmBox(false);
      });
  };

  const handleSaveTimings = () => {
    const formattedTimeIn = formatTime(timeIn);
    const formattedTimeOut = formatTime(timeOut);

    const newTimings = [...timings];
    const existingTimeSlot = newTimings.some(timing => timing.timeIn === formattedTimeIn && timing.timeOut === formattedTimeOut);

    if (!existingTimeSlot) {
      newTimings.push({ time_in: formattedTimeIn, time_out: formattedTimeOut });
      setTimings(newTimings);
    }
    return newTimings;
  };
  const handleSelectedDaysChange = (selectedDays) => {
    const selectedDaysList = Object.keys(selectedDays).filter(day => selectedDays[day] && day !== "selectAll");
    return selectedDaysList;
  };


  const handlePopupSave = () => {
    let isValid = true;
    const hasSelectedDay = Object.values(selectedDays).some(day => day === true);
    if (!hasSelectedDay) {
      isValid = false;
      setDayError("Days are required");
    } else {
      setDayError("");
    }
    if (!timeIn && !timeOut) {
      isValid = false;
      setTimeError("Time in and Time out are required");
    } else if (!timeIn) {
      isValid = false;
      setTimeError("Time in is required");
    } else if (!timeOut) {
      isValid = false;
      setTimeError("Time out is required");
    } else if (!timeOut || timeOut <= timeIn) {
      isValid = false;
      setTimeError("Time out must be greater than Time in.");
    } else {
      setTimeError("");
    }
    if (!isValid) {
      dispatch(setNotification({ message: 'Fields are required!!', status: 'error', action: true }));
      return;
    }
    const updatedTimings = handleSaveTimings();
    const updatedDays = handleSelectedDaysChange(selectedDays);
    addSchedule(updatedTimings, updatedDays);

    setIsPopup(false);
    resetForm();
  };
  const handleCheckboxChange = (day) => {
    setSelectedDays(prevState => {
      let newState;
      if (day === "selectAll") {
        const newValue = !prevState.selectAll;
        newState = Object.fromEntries(
          Object.keys(prevState).map(key => [key, newValue])
        );
      } else {
        newState = {
          ...prevState,
          [day]: !prevState[day]
        };
        newState.selectAll = Object.keys(newState)
          .filter(key => key !== "selectAll")
          .every(key => newState[key]);
      }
      if (Object.values(newState).some(day => day === true)) {
        setDayError('');
      }
      return newState;
    });
  };

  const getSelectedDaysText = () => {
    const selected = Object.entries(selectedDays)
      .filter(([day, isChecked]) => day !== "selectAll" && isChecked)
      .map(([day]) => day);

    if (selected.length === 0) return "Select";
    if (selected.length === 7) return "All days selected";
    return selected.join(", ");
  };

  const handleAddMore = () => {
    setSelectedDays(initialDays);
    setTimeIn('');
    setTimeOut('');
  };
  const toggleExpandDay = (day) => {
    setExpandedDays(prevState =>
      prevState.includes(day) ? prevState.filter(d => d !== day) : [...prevState, day]
    );
  };
  const resetForm = () => {
    setSelectedDays(initialDays);
    setTimings([]);
    setTimeError('');
    setDayError('')
    setTimeIn('');
    setTimeOut('');
    setIsOpen(false);


  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);
  const handleTimeChangeIn = (date) => {
    if (date instanceof Date && !isNaN(date.getTime())) {
      if (timeOut && date >= timeOut) {
        setTimeError("Time In must be less than Time Out.");
        setTimeIn(null);
      } else {
        setTimeIn(date);
        setTimeError('');
      }
    }
    setIsTimeInOpen(false)
  };
  const handleTimeChangeOut = (date) => {
    if (date instanceof Date && !isNaN(date.getTime())) {
      if (timeIn && date <= timeIn) {
        setTimeError("Time Out must be greater than Time In.");
        setTimeOut(null);
      } else {
        setTimeOut(date);
        setTimeError('');
      }
    }
    setIsTimeOutOpen(false);
  };

  const handleSvgClickIn = () => {
    if (isTimeInOpen) {
      timeInRef.current.setBlur();
    } else {
      timeInRef.current.setFocus();
    }
    setIsTimeInOpen(!isTimeInOpen);
  };

  const handleSvgClickOut = () => {
    if (isTimeOutOpen) {
      timeOutRef.current.setBlur();
    } else {
      timeOutRef.current.setFocus();
    }
    setIsTimeOutOpen(!isTimeOutOpen);
  };

  const formatTime = (date) => {
    return dayjs(date).format('h:mm A');
  };

  const GetDoctors = (guid) => {
    store
      .getDoctorForAppointment(
        loggedusertoken,
        DataDecode.encryptPayload({ clinic_guid: guid })
      )
      .then((result) => {
        if (result.status == 200) {
          result.json().then((res) => {
            let response = DataDecode.decryptResponse(res);
            let data = JSON.parse(response);
            setDoctors(data.drDetails);
            setDoctorsTimeSlot(data.drDetailstimeSlots);
          });
        } else {
          setDoctors([]);
          setDoctorsTimeSlot([]);
        }
      })
      .catch((err) => {
        dispatch(
          setNotification({
            message: "Error Occurred!",
            status: "error",
            action: true,
          })
        );
      });
  };

  const addSchedule = async (timings, days) => {
    const payload = {
      doctor_id: doctor_id,
      days: days,
      timings: timings,
      clinic_guid: loggeduser.facilityID
    };
    await store.AddSchedule(loggedusertoken, DataDecode.encryptPayload(payload))
      .then((result) => {
        if (result.status === 201) {
          dispatch(setNotification({ message: 'Doctor schedule added successfully!', status: 'success', action: true }));
          fetchDoctorSchedule();
        } else if (result.status === 204) {
          dispatch(setNotification({ message: 'Time slots added successfully!', status: 'success', action: true }));
          fetchDoctorSchedule();
        }
        else {
          dispatch(setNotification({ message: 'Error occurred!', status: 'error', action: true }));
        }
      })
      .catch((error) => {
        dispatch(setNotification({ message: 'Error occurred!', status: 'error', action: true }));
      });
  };

  const fetchDoctorSchedule = async () => {
    try {
      const result = await store.GetDoctorSchedules(loggedusertoken, DataDecode.encryptPayload({ clinic_guid: loggeduser.facilityID, doctor_id: doctor_id }));
      if (result.status === 200) {
        const res = await result.json();
        const response = DataDecode.decryptResponse(res);
        setSchedule(JSON.parse(response));
      } else {
        setSchedule([]);
      }
    } catch (err) {
      dispatch(setNotification({ message: 'Error occurred!', status: 'error', action: true }));
    }
  };

  const handleKeyDown = (e, currentTime, handleTimeChange) => {
    if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
      e.preventDefault();

      const minutesToAdd = e.key === 'ArrowUp' ? -1 : 1;
      const newTime = new Date(currentTime.getTime() + minutesToAdd * 600000);

      handleTimeChange(newTime);

      setTimeout(() => {
        const container = e.target.closest('.react-datepicker-popper');
        if (container) {
          const timeList = container.querySelector('.react-datepicker__time-list');
          const selectedItem = container.querySelector('.react-datepicker__time-list-item--selected');
          const header = container.querySelector('.react-datepicker-time__header')
          if (header) {
            header.style.display = 'none';
          }
          if (timeList && selectedItem) {
            const itemTop = selectedItem.offsetTop;
            const listHeight = timeList.clientHeight;
            const scrollPosition = itemTop - listHeight / 2 + selectedItem.offsetHeight / 2;
            timeList.scrollTop = scrollPosition;
          }
        }
      }, 0);
    }
  };
  useEffect(() => {
    if (loggeduser) {
      fetchDoctorSchedule();
    }
  }, [loggeduser]);

  return (
    <>
      <div>
        <div className="lg:block hidden">
          <div
            id="DoctorProfileDivmainDiv"
            className="mx-auto p-2 my-20 max-w-full lg:border border-gray-400 rounded-lg"
          >
            <div id="DoctorProfileDivdoctorDetails" className="flex items-center">
              <svg
                id="DoctorProfileSvgback"
                onClick={() => {
                  window.history.back();
                }}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="size-6 block lg:hidden text-red-700"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m11.25 9-3 3m0 0 3 3m-3-3h7.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                />
              </svg>
              <header
                id="DoctorProfileHeaderdoctorDetails"
                className={`ml-[2rem] mt-[1rem] font-bold text-2xl ${previewTheme.headingTitleColor}`}
                style={{ fontFamily: previewTheme.fontFamily }}
              >
                Doctor Details
              </header>
              <svg
                id="DoctorProfileSvgEdit"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                className="ml-4 cursor-pointer"
                onClick={handleEditClick}
              >
                <path
                  d="M7.243 17.997H3V13.754L14.435 2.31903C14.6225 2.13156 14.8768 2.02625 15.142 2.02625C15.4072 2.02625 15.6615 2.13156 15.849 2.31903L18.678 5.14703C18.771 5.2399 18.8447 5.35019 18.8951 5.47159C18.9454 5.59299 18.9713 5.72312 18.9713 5.85453C18.9713 5.98595 18.9454 6.11607 18.8951 6.23747C18.8447 6.35887 18.771 6.46916 18.678 6.56203L7.243 17.997ZM3 19.997H21V21.997H3V19.997Z"
                  fill="#A31B1B"
                />
              </svg>
            </div>

            {doctorinfo.map((doctor, index) => (
              <div id="DoctorProfileDivdoctorInfo" className="" key={index}>
                <div
                  id="DoctorProfileDivuserImage"
                  className="p-4 flex flex-col sm:flex-row lg:mt-2"
                >
                  {doctor.profile_pic ? (
                    <img
                      id="DoctorProfileIMGuserImage"
                      className="bg-slate-300 rounded-full lg:mt-4 mb-4 w-20 h-20 lg:w-[200px] lg:h-[200px] object-cover"
                      src={`${process.env.REACT_APP_EMRAXIS_S3_URL}/${doctor.profile_pic}`}
                      alt="user"
                    />
                  ) : (
                    <img
                      id="DoctorProfileIMGdefaultImage"
                      className="bg-slate-300 rounded-full lg:mt-4 mb-4 w-20 h-20 lg:w-[200px] lg:h-[200px] object-cover"
                      src="./image/user.png"
                      alt="user"
                    />
                  )}
                  <div
                    id="DoctorProfileDivdoctorInformationMainDiv"
                    className="lg:mt-4 sm:mt-0 sm:ml-4"
                  >
                    <div
                      id="DoctorProfileDivdoctorInformation"
                      className="flex items-center"
                    >
                      <div
                        id="DoctorProfileDivdoctorName"
                        className="flex items-center text-2xl font-medium text-black"
                      >
                        {isEditing ? (
                          <>
                            <Select
                              id="AddDoctorProSelecttitle"
                              options={titles}
                              onChange={handleSelectTitles}
                              isSearchable={false}
                              isClearable
                              className="ml-2 mr-2 border border-gray-300 rounded-lg"
                              styles={{
                                control: (provided) => ({
                                  ...provided,
                                  minHeight: "28px",
                                  fontSize: "16px",
                                }),
                                menu: (provided) => ({
                                  ...provided,
                                  fontSize: "16px",
                                }),
                              }}
                              value={
                                editedDoctorInfo.title
                                  ? titles.find(
                                    (option) => option.label === editedDoctorInfo.title
                                  )
                                  : null
                              }
                            />
                            <input
                              id="DoctorProfileFirstName"
                              placeholder="First Name"
                              className="ml-2 mr-2 border border-gray-300 rounded-lg"
                              type="text"
                              name="FirstName"
                              value={editedDoctorInfo.FirstName}
                              onChange={handleChange}
                            />
                            <input
                              id="DoctorProfileMiddleName"
                              placeholder="Middle Name"
                              className="ml-2 mr-2 border border-gray-300 rounded-lg"
                              type="text"
                              name="MiddleName"
                              value={editedDoctorInfo.MiddleName}
                              onChange={handleChange}
                            />
                            <input
                              id="DoctorProfileLastName"
                              placeholder="Last Name"
                              className="ml-2 mr-2 border border-gray-300 rounded-lg"
                              type="text"
                              name="LastName"
                              value={editedDoctorInfo.LastName}
                              onChange={handleChange}
                            />
                          </>
                        ) : (
                          `${doctor.title ? doctor.title : ""} ${doctor.FirstName+" "+doctor.MiddleName?.charAt(0)+" "+doctor.LastName}`
                        )}
                      </div>
                      {isEditing ? (
                        <IoMdSave
                          id="DoctorProfileIoMdSave"
                          className="text-green-600 size-6 cursor-pointer ml-4"
                          onClick={handleSaveClick}
                        >
                          Save
                        </IoMdSave>
                      ) : (
                        <svg
                          id="DoctorProfileSvgEdit"
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          className="ml-4 cursor-pointer"
                          onClick={handleEditClick}
                        >
                          <path
                            d="M7.243 17.997H3V13.754L14.435 2.31903C14.6225 2.13156 14.8768 2.02625 15.142 2.02625C15.4072 2.02625 15.6615 2.13156 15.849 2.31903L18.678 5.14703C18.771 5.2399 18.8447 5.35019 18.8951 5.47159C18.9454 5.59299 18.9713 5.72312 18.9713 5.85453C18.9713 5.98595 18.9454 6.11607 18.8951 6.23747C18.8447 6.35887 18.771 6.46916 18.678 6.56203L7.243 17.997ZM3 19.997H21V21.997H3V19.997Z"
                            fill="#A31B1B"
                          />
                        </svg>
                      )}
                    </div>
                    <p className="text-base text-neutral-700 mt-2 font-medium">
                      {isEditing ? (
                        <input
                          id="DoctorProfileInputdegree"
                          placeholder="Degree"
                          className="ml-2 mr-2 border border-gray-300 rounded-lg"
                          type="text"
                          name="doctor_degree"
                          value={
                            editedDoctorInfo.doctor_degree
                          }
                          onChange={handleChange}
                        />
                      ) : (
                        doctor.doctor_degree
                      )}
                    </p>
                    <p className="mr-2 text-base text-zinc-800 mt-2 font-medium flex items-center">
                      Specialist :
                      <span id="DoctorProfileSpandoctorSpecialization" className="ml-2">
                        {isEditing ? (
                          <input
                            id="DoctorProfileInputspecialization"
                            placeholder="Specialization"
                            className="ml-2 mr-2 border border-gray-300 rounded-lg"
                            type="text"
                            name="specialization"
                            value={
                              editedDoctorInfo.specialization
                            }
                            onChange={handleChange}
                          />
                        ) : (
                          doctor.specialization
                        )}
                      </span>
                      <svg
                        id="DoctorProfileSvgMobile"
                        xmlns="http://www.w3.org/2000/svg"
                        width="21"
                        height="21"
                        viewBox="0 0 21 21"
                        fill="none"
                      >
                        <path
                          d="M3 10.5H5L7.5 4.5L9.5 16.5L12.5 7.5L14.595 13.5L16 10.5H18"
                          stroke="#A31B1B"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </p>
                    <p className="text-zinc-800 font-medium mt-2">
                      Mobile :
                      <span
                        id="DoctorProfileSpandoctorPhoneNumber"
                        className="text-blue-500 text-sm ml-2"
                      >
                        {isEditing ? (
                          <input
                            id="DoctorProfileInputmobile"
                            placeholder="Mobile"
                            className="mr-2 border border-gray-300 rounded-lg"
                            type="text"
                            name="phone_number"
                            value={
                              editedDoctorInfo.phone_number
                            }
                            onChange={handleChange}
                          />
                        ) : (
                          doctor.phone_number
                        )}
                      </span>
                    </p>
                    <p className="text-zinc-800 font-medium mt-2">
                      Email*:
                      <span
                        id="DoctorProfileSpandoctorEmail"
                        className="text-blue-500 text-sm ml-2"
                      >
                        {isEditing ? (
                          <input
                            id="DoctorProfileInputemail"
                            placeholder="Email"
                            className="mr-2 border border-gray-300 rounded-lg"
                            type="text"
                            name="email_id"
                            value={editedDoctorInfo.email_id}
                            onChange={handleChange}
                          />
                        ) : (
                          doctor.email_id
                        )}
                      </span>
                    </p>
                    <p className="text-zinc-800 font-medium mt-2">
                      Consulting Fees :
                      <span
                        id="DoctorProfileSpandoctorConsultingFees"
                        className="text-blue-500 text-sm ml-2"
                      >
                        {isEditing ? (
                          <input
                            id="DoctorProfileInputconsultingFees"
                            placeholder="Consulting Fees"
                            className="mr-2 border border-gray-300 rounded-lg"
                            type="text"
                            name="consulting_fees"
                            value={
                              editedDoctorInfo.consulting_fees
                            }
                            onChange={handleChange}
                          />
                        ) : (
                          <span
                            id="DoctorProfileSpanconsultingFees"
                            className="text-red-700 font-semibold text-xl"
                          >
                            {doctor.consulting_fees}/-
                          </span>
                        )}
                      </span>
                    </p>
                  </div>
                </div>
                <>
                  <div className="border border-gray-200 rounded-sm shadow mt-2 w-full py-4 px-3">
                    <div
                      id="DoctorProfileDivavailableTimings"
                      className="flex justify-between items-center"
                    >
                      <h3
                        id="DoctorProfileH3availableTimings"
                        className="text-lg font-semibold text-[#A31B1B] flex gap-[1rem] ml-[22px]"
                      >
                        <svg
                          id="DoctorProfileSvgavailableTimings"
                          width="24"
                          height="25"
                          viewBox="0 0 24 25"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            opacity="0.5"
                            d="M22 10.2849H2V19.5414C2 20.3597 2.31607 21.1446 2.87868 21.7232C3.44129 22.3018 4.20435 22.6269 5 22.6269H19C19.7956 22.6269 20.5587 22.3018 21.1213 21.7232C21.6839 21.1446 22 20.3597 22 19.5414V10.2849ZM7 8.2279C6.73478 8.2279 6.48043 8.11954 6.29289 7.92666C6.10536 7.73377 6 7.47217 6 7.1994V3.08539C6 2.81261 6.10536 2.55101 6.29289 2.35813C6.48043 2.16524 6.73478 2.05688 7 2.05688C7.26522 2.05688 7.51957 2.16524 7.70711 2.35813C7.89464 2.55101 8 2.81261 8 3.08539V7.1994C8 7.47217 7.89464 7.73377 7.70711 7.92666C7.51957 8.11954 7.26522 8.2279 7 8.2279ZM17 8.2279C16.7348 8.2279 16.4804 8.11954 16.2929 7.92666C16.1054 7.73377 16 7.47217 16 7.1994V3.08539C16 2.81261 16.1054 2.55101 16.2929 2.35813C16.4804 2.16524 16.7348 2.05688 17 2.05688C17.2652 2.05688 17.5196 2.16524 17.7071 2.35813C17.8946 2.55101 18 2.81261 18 3.08539V7.1994C18 7.47217 17.8946 7.73377 17.7071 7.92666C17.5196 8.11954 17.2652 8.2279 17 8.2279Z"
                            fill="#A31B1B"
                          />
                          <path
                            d="M19 4.11401H18V7.19952C18 7.4723 17.8946 7.7339 17.7071 7.92678C17.5196 8.11966 17.2652 8.22802 17 8.22802C16.7348 8.22802 16.4804 8.11966 16.2929 7.92678C16.1054 7.7339 16 7.4723 16 7.19952V4.11401H8V7.19952C8 7.4723 7.89464 7.7339 7.70711 7.92678C7.51957 8.11966 7.26522 8.22802 7 8.22802C6.73478 8.22802 6.48043 8.11966 6.29289 7.92678C6.10536 7.7339 6 7.4723 6 7.19952V4.11401H5C4.20435 4.11401 3.44129 4.43909 2.87868 5.01774C2.31607 5.59638 2 6.38119 2 7.19952V10.285H22V7.19952C22 6.38119 21.6839 5.59638 21.1213 5.01774C20.5587 4.43909 19.7956 4.11401 19 4.11401Z"
                            fill="#A31B1B"
                          />
                        </svg>
                        Available Timings
                      </h3>
                      <button
                        id="DoctorProfileButtonaddTiming"
                        className="bg-[#A31B1B] text-white py-1 px-4 rounded-lg hover:bg-red-700 mb-[10px] h-[36px] flex mr-[22px] h-[36px] pt-[0.35rem] pb-[0.5rem] pl-[0.5rem] pr-[0.65rem]"
                        onClick={handlePopup}
                      >
                        <svg
                          id="DoctorProfileSvgaddTimingDays"
                          className="mr-[5px] mt-[1px]"
                          width="20"
                          height="20"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M11 13H5V11H11V5H13V11H19V13H13V19H11V13Z"
                            fill="white"
                          />
                        </svg>
                        Add Timing
                      </button>
                    </div>
                    <div id="DoctorProfileDivdayDataMain" className="border rounded-lg">
                      <div id="DoctorProfileDivdayData" className="border rounded-lg">
                        {orderedSchedule.map((dayData, index) => (
                          <div
                            id="DoctorProfileDivdays"
                            key={index}
                            className={`grid grid-cols-12 gap-4 items-center ${index < days.length - 1 ? "border-b" : ""
                              }`}
                          >
                            <div
                              id="DoctorProfileDivdaysAvailable"
                              className="col-span-2 text-[#666666] font-normal border-r border-gray-300 pt-[1em] pb-[1em] pr-[1em] pl-[1em] h-full self-stretch flex justify-center pt-[25px]"
                            >
                              {dayData.days}
                            </div>
                            <div
                              id="DoctorProfileDivTimeSlots"
                              className="col-span-10 flex flex-wrap gap-2 h-full pt-[1em] pb-[1em] pr-[1em] pl-[1em]"
                            >
                              {dayData.TimeSlots.map((timing, slotIndex) => (
                                <div
                                  id="DoctorProfileDivslot"
                                  key={slotIndex}
                                  className=" p-2 rounded  bg-indigo-600 bg-opacity-[6%]"
                                >
                                  <span
                                    id="DoctorProfileSpandelete"
                                    key={index}
                                    className="flex"
                                  >
                                    <p className="text-[#000088]">
                                      {timing.time_in} - {timing.time_out}
                                    </p>
                                    <svg
                                      id="DoctorProfileSvgdeleteDays"
                                      width="20"
                                      height="24"
                                      viewBox="0 0 20 24"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                      className="size-4 text-red-700 ml-2 cursor-pointer mt-[3px]"
                                      onClick={() =>
                                        handleDeleteSlot(dayData.days, timing)
                                      }
                                    >
                                      <path
                                        d="M9.45801 4.5C5.31051 4.5 1.95801 7.8525 1.95801 12C1.95801 16.1475 5.31051 19.5 9.45801 19.5C13.6055 19.5 16.958 16.1475 16.958 12C16.958 7.8525 13.6055 4.5 9.45801 4.5ZM13.208 14.6925L12.1505 15.75L9.45801 13.0575L6.76551 15.75L5.70801 14.6925L8.40051 12L5.70801 9.3075L6.76551 8.25L9.45801 10.9425L12.1505 8.25L13.208 9.3075L10.5155 12L13.208 14.6925Z"
                                        fill="#A31B1B"
                                      />
                                    </svg>
                                  </span>
                                </div>
                              ))}

                              {confirmBox && (
                                <div
                                  id="DoctorProfileDivdeleteDoctor"
                                  tabIndex="-1"
                                  aria-hidden="true"
                                  className="fixed left-1/2 top-1/2 w-full max-w-sm transform -translate-x-1/2 -translate-y-1/2 sm:w-80 sm:left-[40%] sm:top-[3rem] sm:translate-x-[50%] sm:translate-y-[50%]"
                                >
                                  <div
                                    id="DoctorProfileDivdeleteSlotMain"
                                    className="relative p-4 w-full max-w-md h-full md:h-auto"
                                  >
                                    <div
                                      id="DoctorProfileDivdeleteSlot"
                                      className="relative p-4 text-center bg-white border-gray-400 border-[1px] rounded-lg shadow-lg dark:bg-gray-800 sm:p-5"
                                    >
                                      <svg
                                        id="DoctorProfileSvgdelete"
                                        className="text-red-800 dark:text-gray-500 w-11 h-11 mb-3.5 mx-auto"
                                        aria-hidden="true"
                                        fill="currentColor"
                                        viewBox="0 0 20 20"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          fillRule="evenodd"
                                          d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                                          clipRule="evenodd"
                                        ></path>
                                      </svg>
                                      <p
                                        id="DoctorProfilePdeleteSlot"
                                        className="mb-4 text-gray-500 dark:text-gray-300"
                                      >
                                        Are you sure you want to delete this
                                        slot?
                                      </p>
                                      <div
                                        id="DoctorProfileDivcancel"
                                        className="flex justify-center items-center space-x-4"
                                      >
                                        <button
                                          id="DoctorProfileButtoncancel"
                                          onClick={() => setConfirmBox(false)}
                                          type="button"
                                          className="py-2 px-3 text-sm font-medium text-gray-500 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-primary-300 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
                                        >
                                          No, cancel
                                        </button>
                                        <button
                                          id="DoctorProfileButtonsave"
                                          type="button"
                                          onClick={handleDeleteSubmit}
                                          className="py-2 px-3 text-sm font-medium text-center text-white bg-red-800 rounded-lg hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:bg-red-500 dark:hover:bg-red-600 dark:focus:ring-red-800 disabled:opacity-50 disabled:cursor-not-allowed"
                                          disabled={disableButton}
                                        >
                                          Yes, I'm sure
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>

                  <div className="">
                    {/* <div className="p-4 border border-gray-300 ">
                                <div className="text-red-700 font-bold mb-4">Also Practices at</div>
                                <div className="text-sm">
                                    <div className="text-blue-500 flex items-center cursor-pointer">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" className='mr-2'>
                                            <path opacity="0.2" d="M10 3V13.5H8V10H5V13.5H3V3C3 2.86739 3.05268 2.74021 3.14645 2.64645C3.24021 2.55268 3.36739 2.5 3.5 2.5H9.5C9.63261 2.5 9.75979 2.55268 9.85355 2.64645C9.94732 2.74021 10 2.86739 10 3Z" fill="#008767" />
                                            <path d="M15.5 13H15V8C15 7.73478 14.8946 7.48043 14.7071 7.29289C14.5196 7.10536 14.2652 7 14 7H10.5V3C10.5 2.73478 10.3946 2.48043 10.2071 2.29289C10.0196 2.10536 9.76522 2 9.5 2H3.5C3.23478 2 2.98043 2.10536 2.79289 2.29289C2.60536 2.48043 2.5 2.73478 2.5 3V13H2C1.86739 13 1.74021 13.0527 1.64645 13.1464C1.55268 13.2402 1.5 13.3674 1.5 13.5C1.5 13.6326 1.55268 13.7598 1.64645 13.8536C1.74021 13.9473 1.86739 14 2 14H15.5C15.6326 14 15.7598 13.9473 15.8536 13.8536C15.9473 13.7598 16 13.6326 16 13.5C16 13.3674 15.9473 13.2402 15.8536 13.1464C15.7598 13.0527 15.6326 13 15.5 13ZM14 8V13H10.5V8H14ZM3.5 3H9.5V13H8.5V10C8.5 9.86739 8.44732 9.74021 8.35355 9.64645C8.25979 9.55268 8.13261 9.5 8 9.5H5C4.86739 9.5 4.74021 9.55268 4.64645 9.64645C4.55268 9.74021 4.5 9.86739 4.5 10V13H3.5V3ZM7.5 13H5.5V10.5H7.5V13ZM4.5 6C4.5 5.86739 4.55268 5.74021 4.64645 5.64645C4.74021 5.55268 4.86739 5.5 5 5.5H6V4.5C6 4.36739 6.05268 4.24021 6.14645 4.14645C6.24021 4.05268 6.36739 4 6.5 4C6.63261 4 6.75979 4.05268 6.85355 4.14645C6.94732 4.24021 7 4.36739 7 4.5V5.5H8C8.13261 5.5 8.25979 5.55268 8.35355 5.64645C8.44732 5.74021 8.5 5.86739 8.5 6C8.5 6.13261 8.44732 6.25979 8.35355 6.35355C8.25979 6.44732 8.13261 6.5 8 6.5H7V7.5C7 7.63261 6.94732 7.75979 6.85355 7.85355C6.75979 7.94732 6.63261 8 6.5 8C6.36739 8 6.24021 7.94732 6.14645 7.85355C6.05268 7.75979 6 7.63261 6 7.5V6.5H5C4.86739 6.5 4.74021 6.44732 4.64645 6.35355C4.55268 6.25979 4.5 6.13261 4.5 6Z" fill="#008767" />
                                        </svg>ABC Hospitals</div>
                                    <div className="text-gray-600">5428 N, Hillsman NJ 10055</div>
                                    <div className="text-blue-500 mt-2 flex items-center cursor-pointer">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" className='mr-2'>
                                            <path opacity="0.2" d="M10 3V13.5H8V10H5V13.5H3V3C3 2.86739 3.05268 2.74021 3.14645 2.64645C3.24021 2.55268 3.36739 2.5 3.5 2.5H9.5C9.63261 2.5 9.75979 2.55268 9.85355 2.64645C9.94732 2.74021 10 2.86739 10 3Z" fill="#008767" />
                                            <path d="M15.5 13H15V8C15 7.73478 14.8946 7.48043 14.7071 7.29289C14.5196 7.10536 14.2652 7 14 7H10.5V3C10.5 2.73478 10.3946 2.48043 10.2071 2.29289C10.0196 2.10536 9.76522 2 9.5 2H3.5C3.23478 2 2.98043 2.10536 2.79289 2.29289C2.60536 2.48043 2.5 2.73478 2.5 3V13H2C1.86739 13 1.74021 13.0527 1.64645 13.1464C1.55268 13.2402 1.5 13.3674 1.5 13.5C1.5 13.6326 1.55268 13.7598 1.64645 13.8536C1.74021 13.9473 1.86739 14 2 14H15.5C15.6326 14 15.7598 13.9473 15.8536 13.8536C15.9473 13.7598 16 13.6326 16 13.5C16 13.3674 15.9473 13.2402 15.8536 13.1464C15.7598 13.0527 15.6326 13 15.5 13ZM14 8V13H10.5V8H14ZM3.5 3H9.5V13H8.5V10C8.5 9.86739 8.44732 9.74021 8.35355 9.64645C8.25979 9.55268 8.13261 9.5 8 9.5H5C4.86739 9.5 4.74021 9.55268 4.64645 9.64645C4.55268 9.74021 4.5 9.86739 4.5 10V13H3.5V3ZM7.5 13H5.5V10.5H7.5V13ZM4.5 6C4.5 5.86739 4.55268 5.74021 4.64645 5.64645C4.74021 5.55268 4.86739 5.5 5 5.5H6V4.5C6 4.36739 6.05268 4.24021 6.14645 4.14645C6.24021 4.05268 6.36739 4 6.5 4C6.63261 4 6.75979 4.05268 6.85355 4.14645C6.94732 4.24021 7 4.36739 7 4.5V5.5H8C8.13261 5.5 8.25979 5.55268 8.35355 5.64645C8.44732 5.74021 8.5 5.86739 8.5 6C8.5 6.13261 8.44732 6.25979 8.35355 6.35355C8.25979 6.44732 8.13261 6.5 8 6.5H7V7.5C7 7.63261 6.94732 7.75979 6.85355 7.85355C6.75979 7.94732 6.63261 8 6.5 8C6.36739 8 6.24021 7.94732 6.14645 7.85355C6.05268 7.75979 6 7.63261 6 7.5V6.5H5C4.86739 6.5 4.74021 6.44732 4.64645 6.35355C4.55268 6.25979 4.5 6.13261 4.5 6Z" fill="#008767" />
                                        </svg>XYZ Hospitals</div>
                                    <div className="text-gray-600">1020 State Route 36, Hornell NY 14843</div>
                                </div>
                            </div> */}
                    {/* </div> */}

                    <div id="DoctorProfileDivabout" className="pb-1 mt-[16px] w-2/3">
                      <h1 className=" font-medium text-2xl flex items-center">
                        About
                        {editableAbout ? (
                          <IoMdSave
                            className="text-green-600 size-6 cursor-pointer ml-4"
                            onClick={handleAboutSave}
                            id="DoctorProfileeditSave"
                          >
                            Save
                          </IoMdSave>
                        ) : (
                          <svg
                            id="DoctorProfileSvgaboutEdit"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            className="ml-4 cursor-pointer"
                            onClick={handleAboutEditClick}
                          >
                            <path
                              d="M7.243 17.997H3V13.754L14.435 2.31903C14.6225 2.13156 14.8768 2.02625 15.142 2.02625C15.4072 2.02625 15.6615 2.13156 15.849 2.31903L18.678 5.14703C18.771 5.2399 18.8447 5.35019 18.8951 5.47159C18.9454 5.59299 18.9713 5.72312 18.9713 5.85453C18.9713 5.98595 18.9454 6.11607 18.8951 6.23747C18.8447 6.35887 18.771 6.46916 18.678 6.56203L7.243 17.997ZM3 19.997H21V21.997H3V19.997Z"
                              fill="#A31B1B"
                            />
                          </svg>
                        )}
                      </h1>
                    </div>
                    {editableAbout ? (
                      <textarea
                        value={editedDoctorInfo.about}
                        onChange={handleChange}
                        name="about"
                        className="w-full mt-2"
                      />
                    ) : (
                      <div id="DoctorProfileDivaboutDoctor">{doctor.about}</div>
                    )}
                  </div>

                  {/* <div className='border border-gray-200 rounded-sm shadow mt-2 w-full py-4 px-3'>
                      <div className='pb-1 border-b border-gray-400 w-2/3'>
                          <h1 className=" font-medium text-2xl flex items-center">Education
                              {editableEducation && (
                                  <button onClick={handleEducationSave} className="ml-2 text-sm text-blue-500">Save</button>
                              )}
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" className='ml-4' onClick={handleEducationEditClick}>
                                  <path d="M7.243 17.997H3V13.754L14.435 2.31903C14.6225 2.13156 14.8768 2.02625 15.142 2.02625C15.4072 2.02625 15.6615 2.13156 15.849 2.31903L18.678 5.14703C18.771 5.2399 18.8447 5.35019 18.8951 5.47159C18.9454 5.59299 18.9713 5.72312 18.9713 5.85453C18.9713 5.98595 18.9454 6.11607 18.8951 6.23747C18.8447 6.35887 18.771 6.46916 18.678 6.56203L7.243 17.997ZM3 19.997H21V21.997H3V19.997Z" fill="#A31B1B" />
                              </svg>
                          </h1>
                      </div>
                      {editableEducation ? (
                          <textarea
                              value={educationContent}
                              onChange={handleEducationChange}
                              className="w-full mt-2"
                          />
                      ) : (
                          <div>{educationContent}</div>
                      )}
                  </div> */}
                </>
              </div>
            ))}

          </div>
        </div>
        <div className="sm:block lg:hidden h-[80vh]">
          <div className="mx-auto px-2 mt-20 mb-2 max-w-full  h-[65vh] h-670:h-[75vh]">
            <div className="flex items-center justify-between">
              <svg
                id="DoctorProfileSvgmobBack"
                onClick={() => {
                  saveChange && handleSaveClick();
                  window.history.back();
                }}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="size-7 block lg:hidden text-red-700"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m11.25 9-3 3m0 0 3 3m-3-3h7.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                />
              </svg>
              <h2 className="text-lg font-bold text-red-700">Doctor Details</h2>
              <svg
                id="DoctorProfileSvgmobEdit"
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="22"
                viewBox="0 0 24 24"
                fill="none"
                className=" cursor-pointer"
                onClick={() => {
                  handleEditDoc(doctorinfo)
                }}
              >
                <path
                  d="M7.243 17.997H3V13.754L14.435 2.31903C14.6225 2.13156 14.8768 2.02625 15.142 2.02625C15.4072 2.02625 15.6615 2.13156 15.849 2.31903L18.678 5.14703C18.771 5.2399 18.8447 5.35019 18.8951 5.47159C18.9454 5.59299 18.9713 5.72312 18.9713 5.85453C18.9713 5.98595 18.9454 6.11607 18.8951 6.23747C18.8447 6.35887 18.771 6.46916 18.678 6.56203L7.243 17.997ZM3 19.997H21V21.997H3V19.997Z"
                  fill="#A31B1B"
                />
              </svg>
            </div>
            {doctorinfo.map((doctor, index) => (
              <div className=" p-2">
                <div className="flex p-1 w-full">
                  <div className="w-1/3">
                    {docFile !== null ? (
                      <img
                        id="DoctorProfileImgmobUserImage"
                        className="bg-slate-300 rounded-full lg:mt-4 mb-4 w-20 h-20 lg:w-[200px] lg:h-[200px] object-cover"
                        src={docFile}
                        alt="user"
                      />
                    ) : (
                      <img
                        id="DoctorProfileImgmobDefaultImage"
                        className="bg-slate-300 rounded-full lg:mt-4 mb-4 w-20 h-20 lg:w-[200px] lg:h-[200px] object-cover"
                        src="./image/user.png"
                        alt="user"
                      />
                    )}
                  </div>
                  <div className="w-2/3 ml-2">
                    <div className="flex">
                      <h3 className="capitalize text-base text-[#3f3f3f] -mt-1 font-[600]">
                        {doctor?.title && `${doctor.title} `}
                        {editedDoctorInfo.FirstName + " " + editedDoctorInfo.MiddleName + " " + editedDoctorInfo.LastName}
                      </h3>
                    </div>
                    <p className="text-base">
                      {doctor?.doctor_degree ? (
                        doctor?.doctor_degree
                      ) : (
                        <span className="text-red-700 font-bold"></span>
                      )}
                    </p>
                    {doctor?.consulting_fees ? (
                      <div className="flex items-center">
                        <PiCurrencyInrLight className='text-red-700 size-4 stroke-10' />
                        <p className="text-red-700 ">{`${doctor?.consulting_fees}/-`}</p>
                      </div>
                    ) : (
                      <p className="text-red-600 font-bold">--</p>
                    )}
                  </div>
                </div>
                <div className="flex p-2 -mt-5">
                  {doctor?.experience && (
                    <div className="px-2 py-1 border border-gray-300 bg-gray-300 rounded-lg">
                      <p className="text-center text-gray-700 font-bold text-xs">{doctor.experience}</p>
                      <p className="text-center text-gray-700 font-medium text-xs">Experience</p>
                    </div>
                  )}
                  <div className="ml-4 px-2 py-1 border border-gray-300 bg-gray-300 rounded-lg">
                    <p className="text-center text-gray-700 font-extrabold text-xs">{totalPatients?.total_patients}</p>
                    <p className="text-center text-gray-700 font-medium text-xs">
                      {totalPatients?.total_patients > 1 ? 'Patients' : 'Patient'}
                    </p>
                  </div>
                </div>
                {showPopup && <AddDoctor isOpen={showPopup} onClose={closePopup} setRefreshData={setRefreshData} doctor={doctorinfo.length > 0 ? doctorinfo[0] : {}} docPic={docPic} setDocPic={setDocPic} setDocFile={setDocFile} />}
                <div className="flex w-full border-b-4 border-gray-200 ">
                  <button
                    testID="About"
                    aria-label="About"
                    onClick={() => setSelectedIndex(0)}
                    className={`text-base ${selectedIndex === 0 ? 'text-red-600 underline underline-offset-4' : 'text-gray-600'} px-5 mb-1`}
                  >
                    About
                  </button>
                  <button
                    testID="Schedule"
                    aria-label="Schedule"
                    onClick={() => setSelectedIndex(1)}
                    className={`text-base ${selectedIndex === 1 ? 'text-red-600  underline underline-offset-4' : 'text-gray-600'} px-5 mb-1`}
                  >
                    Schedule
                  </button>
                </div>
                {selectedIndex == 0 &&
                  <textarea
                    id="DoctorProfileTextAreamobAbout"
                    value={editedDoctorInfo.about}
                    onChange={handleChange}
                    onBlur={updateDoctorInfo}
                    name="about"
                    className="w-full mt-2 h-40 rounded-lg"
                  />}
                {selectedIndex == 1 &&
                  <div>
                    <div className='flex justify-between mt-1 '>
                      <span className='text-[14px] font-[600] text-red-700'>Date</span>
                      <span
                        id="DoctorProfileSpanmobAddTimings"
                        className="text-red-700 text-[14px] font-[600] cursor-pointer"
                        onClick={handlePopup}
                      >
                        + Add Availability
                      </span>
                    </div>
                    <div className="flex justify-between py-2 px-4">
                      {days.map((item) => (
                        <button
                          key={item}
                          data-testid={`${item.slice(0, 3)}day`}
                          aria-label={`${item.slice(0, 3)}day`}
                          onClick={() => setSelectDay(item)}
                          className={`p-1 uppercase text-xs font-medium rounded-md border 
                             ${selectDay === item
                              ? 'border-red-700 bg-white text-red-700'
                              : 'border-gray-400 bg-gray-200 text-gray-700'}`
                          }
                        >
                          {item.slice(0, 3)}
                        </button>
                      ))}
                    </div>
                    <div className="text-red-700 font-normal text-sm p-2 flex justify-center">
                      <svg
                        id="DoctorProfileSvgmobAvailableTimings"
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        className="mr-2"
                      >
                        <path
                          opacity="0.5"
                          d="M14.6667 6.66665H1.33334V12.6666C1.33334 13.1971 1.54405 13.7058 1.91912 14.0809C2.2942 14.4559 2.8029 14.6666 3.33334 14.6666H12.6667C13.1971 14.6666 13.7058 14.4559 14.0809 14.0809C14.456 13.7058 14.6667 13.1971 14.6667 12.6666V6.66665ZM4.66667 5.33331C4.48986 5.33331 4.32029 5.26307 4.19526 5.13805C4.07024 5.01303 4 4.84346 4 4.66665V1.99998C4 1.82317 4.07024 1.6536 4.19526 1.52858C4.32029 1.40355 4.48986 1.33331 4.66667 1.33331C4.84348 1.33331 5.01305 1.40355 5.13807 1.52858C5.2631 1.6536 5.33334 1.82317 5.33334 1.99998V4.66665C5.33334 4.84346 5.2631 5.01303 5.13807 5.13805C5.01305 5.26307 4.84348 5.33331 4.66667 5.33331ZM11.3333 5.33331C11.1565 5.33331 10.987 5.26307 10.8619 5.13805C10.7369 5.01303 10.6667 4.84346 10.6667 4.66665V1.99998C10.6667 1.82317 10.7369 1.6536 10.8619 1.52858C10.987 1.40355 11.1565 1.33331 11.3333 1.33331C11.5101 1.33331 11.6797 1.40355 11.8047 1.52858C11.9298 1.6536 12 1.82317 12 1.99998V4.66665C12 4.84346 11.9298 5.01303 11.8047 5.13805C11.6797 5.26307 11.5101 5.33331 11.3333 5.33331Z"
                          fill="#A31B1B"
                        />
                        <path
                          d="M12.6667 2.66669H12V4.66669C12 4.8435 11.9298 5.01307 11.8047 5.13809C11.6797 5.26312 11.5101 5.33335 11.3333 5.33335C11.1565 5.33335 10.987 5.26312 10.8619 5.13809C10.7369 5.01307 10.6667 4.8435 10.6667 4.66669V2.66669H5.33334V4.66669C5.33334 4.8435 5.2631 5.01307 5.13807 5.13809C5.01305 5.26312 4.84348 5.33335 4.66667 5.33335C4.48986 5.33335 4.32029 5.26312 4.19526 5.13809C4.07024 5.01307 4 4.8435 4 4.66669V2.66669H3.33334C2.8029 2.66669 2.2942 2.8774 1.91912 3.25247C1.54405 3.62755 1.33334 4.13625 1.33334 4.66669V6.66669H14.6667V4.66669C14.6667 4.13625 14.456 3.62755 14.0809 3.25247C13.7058 2.8774 13.1971 2.66669 12.6667 2.66669Z"
                          fill="#A31B1B"
                        />
                      </svg>
                      Available Timings

                    </div>
                    <div className="overflow-y-auto h-32 xs-px-1 min-[390px]:px-3 mt-1">
                      {orderedSchedule.map((dayData, index) => (
                        <div key={index} className="flex flex-wrap justify-between mx-4">
                          {dayData.days === selectDay &&
                            dayData.TimeSlots?.map((timing, slotIndex) => (
                              <div
                                key={slotIndex}
                                className={`flex items-center rounded-2xl border p-1 mb-2 
                            ${slot === slotIndex && confirmBox
                                    ? 'bg-white border-red-700'
                                    : 'bg-gray-200 border-gray-300'}
                          `}
                              >
                                <span className="text-gray-700 text-xs">
                                  {timing.time_in} - {timing.time_out}
                                </span>
                                <svg
                                  id="DoctorProfileSvgmobDeleteIcon"
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  strokeWidth="1.5"
                                  stroke="currentColor"
                                  className="size-4 text-red-700 ml-1 cursor-pointer"
                                  onClick={() => {
                                    handleDeleteSlot(dayData.days, timing)
                                    setSlot(slotIndex)
                                  }}
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                                  />
                                </svg>
                              </div>
                            ))}
                        </div>
                      ))}
                    </div>
                  </div>}
              </div>
            ))}
          </div>
          <div className=" flex flex-col justify-end items-center">
            {selectedIndex === 1 && (
              <button
                aria-label="Book-Appointment"
                onClick={() => {
                  dispatch(setAddAppointmentPopup(true));
                }}
                className="justify-center bg-red-800 text-white font-semibold text-sm text-center py-2 px-4 rounded-full border border-red-800"
              >
                Book Appointment
              </button>
            )}
          </div>
        </div>
        {showAddAppointmentpopup && (
          <AddAppointment
            setRefreshData={()=>void undefined}
            appointment={{}}
            doctors={doctors}
            doctorsTimeslot={doctorsTimeslot}
            doctorId={doctor_id}
          />
        )}
        {showAddPatientpopup && (
          <AddPatient
            doctors={doctors}
            setRefreshData={setRefreshData}
            patient={{}}
          />
        )}
        {confirmBox && (
          <div
            id="DoctorProfiledeleteDoctor"
            tabIndex="-1"
            aria-hidden="true"
            className="fixed left-1/2 top-1/2 p-1 w-[95%] max-w-sm transform -translate-x-1/2 -translate-y-1/2 sm:w-80 sm:left-[40%] sm:top-[3rem] sm:translate-x-[50%] sm:translate-y-[50%]"
          >
            <div className="relative p-4 w-full max-w-md h-full md:h-auto">
              <div className="relative p-4 text-center bg-white border-gray-400 border-[1px] rounded-lg shadow-lg dark:bg-gray-800 sm:p-5">
                <svg
                  id="DoctorProfileSvgmobDeleteSlot"
                  className="text-red-800 dark:text-gray-500 w-10 h-10 mb-3.5 mx-auto"
                  aria-hidden="true"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                    clipRule="evenodd"
                  ></path>
                </svg>
                <p
                  id="DoctorProfilePmobdeleteSlotText"
                  className=" text-gray-500 text-[15px] mb-8 dark:text-gray-800"
                >
                  Are you sure you want to delete this slot?
                </p>
                <div
                  id="DoctorProfileDivmobCancel"
                  className="flex justify-center items-center space-x-4"
                >
                  <button
                    id="DoctorProfileButtonmobCancel"
                    onClick={() => setConfirmBox(false)}
                    type="button"
                    className="py-1 px-2 text-sm font-medium text-gray-500 bg-white rounded-lg border border-gray-400 hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-primary-300 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
                  >
                    No, cancel
                  </button>
                  <button
                    id="DoctorProfileButtonmobSave"
                    type="button"
                    onClick={handleDeleteSubmit}
                    className="py-1 px-2 text-sm font-medium text-center text-white bg-red-800 rounded-lg hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:bg-red-500 dark:hover:bg-red-600 dark:focus:ring-red-800 disabled:opacity-50 disabled:cursor-not-allowed"
                    disabled={disableButton}
                  >
                    Yes, I'm sure
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
        {isPopup && (
          <div
            id="DoctorProfileDivpopupTimings"
            className="fixed inset-0 flex justify-center items-center p-8 z-50"
          >
            <div
              id="DoctorProfileDivpopupAddTimings"
              className="absolute inset-0 bg-black opacity-25 "
            ></div>
            <div
              className="relative bg-white rounded-xl shadow-xl px-6 py-8 max-h-[100vh] lg:max-h-[70vh] 2xl:max-h-[70vh] "
              style={{ width: "600px" }}
            >
              <div
                id="DoctorProfileDivaddTimings"
                className={`text-center font-semibold text-xl mt-[-10px] mb-4 ${previewTheme.headingTitleColor}`}
                style={{ fontFamily: previewTheme.fontFamily }}
              >
                Add Timing
              </div>
              <div className="mb-4 relative" ref={dropdownRef}>
                <div
                  id="DoctorProfileDivdropdown"
                  onClick={toggleDropdown}
                  className="w-full border border-gray-500 rounded-md px-3 py-2 bg-white cursor-pointer flex justify-between items-center"
                >
                  <span
                    id="DoctorProfileSpangetDays"
                    className=" text-sm text-gray-700 w-full"
                  >
                    {getSelectedDaysText()}
                  </span>
                  <svg
                    id="DoctorProfileSvgdays"
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    className="border-l"
                  >
                    <path
                      d="M5.8335 8.33289L10.0002 12.4996L14.1668 8.33289H5.8335Z"
                      fill="black"
                    ></path>
                  </svg>
                  <label
                    id="DoctorProfileLabeldays"
                    htmlFor="formType"
                    className="absolute top-0 left-4 -mt-2 px-1 bg-white text-sm font-semibold text-gray-700"
                  >
                    Day
                  </label>
                </div>

                {isOpen && (
                  <div
                    id="DoctorProfileDivCheckboxselect"
                    className="absolute z-10 mt-1 w-full bg-white border border-gray-400 max-h-36 overflow-y-auto rounded-md shadow-lg"
                  >
                    <label
                      id="DoctorProfileLabelselect"
                      className="flex items-center px-3 py-1 hover:bg-gray-100"
                    >
                      <input
                        id="DoctorProfileCheckboxselectAll"
                        type="checkbox"
                        checked={selectedDays.selectAll}
                        onChange={() => handleCheckboxChange("selectAll")}
                      />
                      <span
                        id="DoctorProfileSpanselectAll"
                        className="ml-2 text-sm text-gray-700"
                      >
                        Select all
                      </span>
                    </label>
                    {Object.keys(selectedDays)
                      .filter((day) => day !== "selectAll")
                      .map((day) => (
                        <label
                          id="DoctorProfileLabelday"
                          key={day}
                          className="flex items-center px-3 py-1 hover:bg-gray-100"
                        >
                          <input
                            id="DoctorProfileCheckBoxselectDays"
                            type="checkbox"
                            checked={selectedDays[day]}
                            onChange={() => handleCheckboxChange(day)}
                            className="form-checkbox h-4 w-4 text-blue-600"
                          />
                          <span
                            id="DoctorProfileSpandays"
                            className="ml-2 text-sm text-gray-700"
                          >
                            {day}
                          </span>
                        </label>
                      ))}
                  </div>
                )}
                {dayError && (
                  <div
                    id="DoctorProfileDiverrorDays"
                    className="text-red-500 text-sm mt-1"
                  >
                    {dayError}
                  </div>
                )}
              </div>
              <div className="grid grid-cols-2 gap-10 mt-4">
                <div className="w-full ">
                  <div id="DoctorProfileDivDatePicker" className="relative px-2">
                    <DatePicker
                      selected={timeIn}
                      onChange={handleTimeChangeIn}
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={10}
                      dateFormat="h:mm aa"
                      className="border rounded border-gray-500 w-full py-2 px-4 text-sm"
                      onKeyDown={(e) =>
                        handleKeyDown(
                          e,
                          timeIn || new Date(),
                          handleTimeChangeIn
                        )
                      }
                      onFocus={() => setIsTimeInOpen(!isTimeInOpen)}
                      ref={timeInRef}
                    />
                    <svg
                      id="DoctorProfilesvgtimeIn"
                      className="absolute right-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400"
                      viewBox="0 0 20 20"
                      fill="none"
                      stroke="currentColor"
                      onClick={handleSvgClickIn}
                    >
                      <path
                        d="M7 7l3-3 3 3m0 6l-3 3-3-3"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    <label
                      id="DoctorProfileLabeltimeIn"
                      htmlFor="timeIn"
                      className="absolute top-0 left-4 -mt-2 px-1 bg-white text-sm font-semibold text-gray-700"
                    >
                      Time in *
                    </label>
                  </div>
                </div>
                <div className="w-full">
                  <div id="DoctorProfileDivdatePickerTimeout" className="relative px-2">
                    <DatePicker
                      selected={timeOut}
                      onChange={handleTimeChangeOut}
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={10}
                      dateFormat="h:mm aa"
                      className="border border-gray-500 rounded w-full py-2 px-4 text-sm"
                      onKeyDown={(e) =>
                        handleKeyDown(
                          e,
                          timeOut || new Date(),
                          handleTimeChangeOut
                        )
                      }
                      ref={timeOutRef}
                      onFocus={() => setIsTimeOutOpen(!isTimeOutOpen)}
                    />
                    <svg
                      id="DoctorProfileSvgTime"
                      className="absolute right-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400"
                      viewBox="0 0 20 20"
                      fill="none"
                      stroke="currentColor"
                      onClick={handleSvgClickOut}
                    >
                      <path
                        d="M7 7l3-3 3 3m0 6l-3 3-3-3"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    <label
                      id="DoctorProfileLabeltimeOut"
                      htmlFor="timeOut"
                      className="absolute top-0 left-4 -mt-2 px-1 bg-white text-sm font-semibold text-gray-700"
                    >
                      Time out *
                    </label>
                  </div>
                </div>
              </div>
              {timeError && (
                <div
                  id="DoctorProfileDivtimeError"
                  className=" text-red-500 text-sm mt-2 mb-[-14px]"
                >
                  {timeError}
                </div>
              )}
              <div className="text-right mb-4">
                {/* <button className={`font-medium px-4 py-2 mt-2 ${previewTheme.headingTitleColor}`} style={{ fontFamily: previewTheme.fontFamily }} onClick={handleAddMore}>+ Add More Slot</button> */}
              </div>

              <div
                id="DoctorProfileDivButtonCancel"
                className="flex justify-center gap-4 mt-12 "
              >
                <button
                  id="DoctorProfileButtonpopUpCancel"
                  onClick={handleCancel}
                  className={`px-2 py-2 rounded-md font-semibold text-[16px] ${previewTheme.cancelBtnColor} ${previewTheme.cancelBtnBgColor} hover:${previewTheme.cancelBtnHoverColor}`}
                >
                  Cancel
                </button>
                <button
                  id="DoctorProfileButtonpopUpSave"
                  onClick={handlePopupSave}
                  className={`px-3 py-2 rounded-md font-semibold text-[16px] ${previewTheme.saveBtnColor} ${previewTheme.saveBtnBgColor} hover:${previewTheme.saveBtnHoverColor}`}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        )}
        {toast.show && (
          <SnackBarComponent
            message={toast.message}
            type={toast.type}
            onClose={() => setToast({ show: false, message: "", type: "" })}
          />
        )}
      </div>
      <style>
        {`
          .react-datepicker-time__header {
            display: none;
          }.react-datepicker__header{
          padding:0px
          }
          .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
          height:25px
          }
          .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list{
           height: calc(100px + 1.7rem / 2);
          }
        `}

      </style>
    </>
  );
};

export default DoctorProfile;