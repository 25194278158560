import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setActiveNavbar,
  setActiveHistoryNavbar,
  setProfileSideNavbar,
  setVisitHistorySideNavbar,
  setSelectedAppointment,
  setNewAppointmentId,
  setCurrentVisit,
  setActiveSubHistoryNavbar
} from "../../../../Redux/features/profileDrawer/profileSideNavbarSlice";
import store from "../../../../API/store";
import { setNotification } from "../../../../Redux/features/toast/toastSlice";
import SnackBarComponent from "../../../../components/SnackBarComponent/SnackBarComponent";
import DataDecode from "../../../../utils/DataDecode";
import DateFunction from "../../../../utils/DateFunctions";
import { theme } from "../../../../EmraxisTheme/Theme";
import currentVisit from "../../../../components/Assets/currentVisit.png";
import {
  setActiveNavvisitbar,
  setVisitSideNavbar,
  setSelectedAppointmentId,
  setpastSelected,
  setRefreshNavBarScroll,
} from "../../../../Redux/features/visitDrawer/visitSideNavbarSlice";
const ProfileSideNavbar = ({
  patient_id,
  clinic_guid,
  refreshData,
  page_src,
  appointment_id,
  historyRefs,
  refsReady,
  appointment,
}) => {
  useEffect(() => {}, [historyRefs]);

  
  const [showVisitHistory, setShowVisitHistory] = useState(false);
  const [showVisitPastHistory, setShowVisitPastHistory] = useState(false);
  const dispatch = useDispatch();
  const [pastHistory, setPastHistory] = useState([]);
  
  const selectedAppointment = useSelector(
    (state) => state.profileSideNavbar.selectedAppointment
  );

  var selectedAppointmentId = useSelector(
    (state) => state.visitSideNavbar.selectedAppointmentId
  );
  const pastSelected = useSelector(
    (state) => state.visitSideNavbar.pastSelected
  );

  const sectionRefs = useRef([]);
  const previewTheme =
    useSelector((state) => state.theme.colors) ||
    theme.filter((a) => a.name === "defaultTheme")[0];
  const [viewHistoryData, setViewHistoryData] = useState({});
  
  const refreshNavBarScroll = useSelector(
    (state) => state.visitSideNavbar.refreshNavBarScroll
  );
  const [expandedSectionIndex, setExpandedSectionIndex] = useState(-1);
  const [currentExpandedIndex , setCurrentExpandedIndex ] = useState(-1);
  const [activeDropdownIndex, setActiveDropdownIndex] = useState(null);
  const [sideBarOpen, setSideBarOpen] = useState(true);

  const [isDropdownOpen, setDropdownOpen] = useState(true);
  const [isDropdownVisitHistoryOpen, setDropdownVisitHistoryOpen] = useState(true);
  const [selectedHistory, setSelectedHistory] = useState(null);
  var loggeduser = useSelector((state) => state.login.loginuserData);
  const [toast, setToast] = useState({ show: false, message: "", type: "" });
  var loggedusertoken = useSelector((state) => state.login.userBarerToken);
  var isActive = useSelector((state) => state.profileSideNavbar.activeNavbar);
 
  var currentVisitOpen = useSelector((state) => state.profileSideNavbar.currentVisitOpen);
  var isActiveHistory = useSelector(
    (state) => state.profileSideNavbar.activeHistoryNavbar
  );
 
  var isUploadedDataAvailable = useSelector(
    (state) => state.profileSideNavbar.isUploadedDataAvailable
  );
  const [selectedAppointmentData, setSelectedAppointmentData] = useState();

  var isVisitHistoryDataAvailable = useSelector(
    (state) => state.profileSideNavbar.isVisitHistoryDataAvailable
  );
  const toggleDropdown = (index) => {
    setActiveDropdownIndex((prevIndex) => (prevIndex === index ? null : index));
 
  };
  
  var isDoctorNotesDataAvailable = useSelector(
    (state) => state.profileSideNavbar.isDoctorNotesDataAvailable
  );
  var newAppointmentId = useSelector(state => state.profileSideNavbar.newAppointmentId);
  var isPrescriptionDataAvailabale = useSelector(
    (state) => state.profileSideNavbar.isPrescriptionDataAvailabale
  );
  var isLabNotesDataAvailable = useSelector(
    (state) => state.profileSideNavbar.isLabNotesDataAvailable
  );
  var isRadiologyDataAvailable = useSelector(
    (state) => state.profileSideNavbar.isRadiologyDataAvailable
  );
  var patientProfileMenuSelector = useSelector(
    (state) => state.profileSideNavbar.patientProfileMenuSelector
  );
  var selectedAppointmentId = useSelector(state => state.visitSideNavbar.selectedAppointmentId);
  useEffect(() => {
    if (selectedAppointmentId) {
    
      const index = pastHistory.findIndex(history => history.appointment_id === selectedAppointmentId);
  
      if (index !== -1 && index !== expandedSectionIndex) {
        
        setExpandedSectionIndex(index);
          toggleDropdown(index); 
       
      }
    }
  }, [selectedAppointmentId]);
  
  useEffect(() => {
    dispatch(setActiveNavvisitbar("visit-details"));
  }, []);
  const showToast = () => {
    setTimeout(() => {
      setToast({ show: false, message: "", type: "" });
    }, 3000);
  };
 
  const GetPastHistory = () => {
    store
      .GetPastHistory(
        loggedusertoken,
        DataDecode.encryptPayload({
          clinic_guid: clinic_guid,
          patient_id: patient_id,
        })
      )
      .then((result) => {
        if (result.status == 200) {
          result.json().then((res) => {
            let response = DataDecode.decryptResponse(res);
            let data = JSON.parse(response);
            setPastHistory(data);
          });
        } else {
          setPastHistory([]);
        }
      })
      .catch((err) => {
        setPastHistory([]);
        dispatch(
          setNotification({
            message: "Error Occurred!",
            status: "error",
            action: true,
          })
        );
      });
  };

  useEffect(() => {
    GetPastHistory();
  }, [patient_id, clinic_guid, refreshData]);

  const viewDetails = (history) => {
    const data = btoa(
      JSON.stringify({
        appointment_id: history.appointment_id,
        patient_id: history.patient_id,
        doctor_id: history.doctor_id,
        clinic_guid: loggeduser.facilityID,
        viewHistoryData: viewHistoryData,
      })
    );
    setViewHistoryData(history);
    setSelectedHistory(history.appointment_id);

    dispatch(setSelectedAppointment(history));
  };
  const handleAppointmentClick = (appointment) => {
    setSelectedHistory(appointment.appointment_id);
    setSelectedAppointmentData(appointment.appointment_id);
  };

  useEffect(() => {
    dispatch(setActiveNavbar("personal-details"));
  }, []);
const expandVisitHistory = () => {
    if (isDropdownVisitHistoryOpen) handleHistorySectionDetails( `${selectedAppointment.appointment_id}-history-vitals`);
    setTimeout(() => {
      const newState = !showVisitHistory;
      setShowVisitHistory(newState);
      localStorage.setItem("visitHistoryOpen", JSON.stringify(newState));
    }, 100);
  };

  useEffect(() => {
    const savedState = localStorage.getItem("visitHistoryOpen");
    if (savedState !== null) {
      setShowVisitHistory(JSON.parse(savedState));
    }
  }, []);
  const scrollToPosition = (appointment_id, offset = 0) => {
    if (historyRefs) {
      const element = historyRefs.current[appointment_id];
      if (element) {
        const topPosition =
          element.getBoundingClientRect().top + window.scrollY + offset;
        window.scrollTo({
          top: topPosition,
          behavior: "smooth",
        });
      }
    }
  };

  const handleSidebarClick = (appointmentId, selectedHistory) => {
   
    setSideBarOpen(false);
    dispatch(setActiveHistoryNavbar(`${selectedAppointment?.appointment_id}-history-vitals`));
    let index = -1; // Default index if not found

    if (selectedHistory !== -1) {
      dispatch(setRefreshNavBarScroll(!refreshNavBarScroll));
      dispatch(setNewAppointmentId(appointmentId))
      dispatch(setSelectedAppointmentId(appointmentId));
      dispatch(setpastSelected(appointment_id));
     
    }
    
  };
 
  const handlePersonalDetails = (selectedMenu,appointment_id) => {
    let initialState = {
      personalDetails: false,
      vitals: false,
      doctorNotes: false,
      visitHistory: false,
      prescription: false,
      uploadedData: false,
      labNotes: false,
      radiology: false,
       pastHistory:false,
    };
    dispatch(setActiveNavbar(selectedMenu));
    
    if (selectedMenu === "personal-details") {
      dispatch(
        setProfileSideNavbar({ ...initialState, personalDetails: true })
      );
    } else if (selectedMenu === "vitals") {
      dispatch(setProfileSideNavbar({ ...initialState, vitals: true }));
      setTimeout(() => {
        dispatch(setActiveNavbar("personal-details"));
      }, 3000);
    } else if (selectedMenu === "doctor-notes") {
      dispatch(setProfileSideNavbar({ ...initialState, doctorNotes: true }));
    } else if (selectedMenu === "visit-history") {
      dispatch(setProfileSideNavbar({ ...initialState, visitHistory: true }));
    } else if (selectedMenu === "prescription") {
      dispatch(setProfileSideNavbar({ ...initialState, prescription: true }));
    } else if (selectedMenu === "uploaded-data") {
      dispatch(setProfileSideNavbar({ ...initialState, uploadedData: true }));
    } else if (selectedMenu === "lab-notes") {
      dispatch(setProfileSideNavbar({ ...initialState, labNotes: true }));
    } else if (selectedMenu === "radiology") {
      dispatch(setProfileSideNavbar({ ...initialState, radiology: true }));
    } else if (selectedMenu === "pastHistory") {
      dispatch(setProfileSideNavbar({ ...initialState, pastHistory: true }));
    }
    
  };

  const handleHistorySectionDetails = (selectedMenu) => {
   
   
    const appointmentId =  selectedAppointmentId;
    let initialState = {
      historyVitals: false,
      historyDoctorNotes: false,
      historyPrescription: false,
      historyUploadedData: false,
      historyLabNotes: false,
      historyRadiology: false,
    };
   
    dispatch(setActiveHistoryNavbar(selectedMenu));
   
    if (
      selectedMenu === `${appointmentId}-history-vitals`
    ) {
      setSideBarOpen(false)
      dispatch(
        setVisitHistorySideNavbar({ ...initialState, historyVitals: true })
      );
    
    } else if (
      selectedMenu ===
      `${appointmentId}-history-doctor-notes`
    ) {
      setSideBarOpen(false)
      dispatch(
        setVisitHistorySideNavbar({ ...initialState, historyDoctorNotes: true })
      );
     
    } else if (
      selectedMenu ===
      `${appointmentId}-history-prescription`
    ) {
      setSideBarOpen(false)
      dispatch(
        setVisitHistorySideNavbar({
          ...initialState,
          historyPrescription: true,
        })
      );
      
    } else if (
      selectedMenu ===
      `${appointmentId}-history-uploaded-data`
    ) {
      setSideBarOpen(false)
      dispatch(
        setVisitHistorySideNavbar({
          ...initialState,
          historyUploadedData: true,
        })
      );
     
    } else if (
      selectedMenu === `${appointmentId}-history-lab-notes`
    ) {
      setSideBarOpen(false)
      dispatch(
        setVisitHistorySideNavbar({ ...initialState, historyLabNotes: true })
      );
     
    } else if (
      selectedMenu ===  `${appointmentId}-history-radiology`
    ) {
      setSideBarOpen(false)
      dispatch(
        setVisitHistorySideNavbar({ ...initialState, historyRadiology: true })
      );
  
    }
   
    setTimeout(() => setSideBarOpen(false), 0);
    return dispatch(setSelectedAppointment([]))
  };

  return (
    <div>
      <div className="bg-[#f0f5ff] h-screen text-black flex flex-col overflow-y-auto pb-[10rem]">
        <nav className="flex flex-col">
          <button
            id="btnProfileNavPersonalDetails"
            onClick={() => handlePersonalDetails("personal-details")}
            className={` pr-[1rem] justify-start flex items-center p-3 pl-[1.5rem] space-x-3 hover:${
              previewTheme?.navbarBgHoverColor
            } hover:${previewTheme?.navbarTextColor} ${
              isActive === "personal-details"
                ? `${previewTheme?.navbarActiveColor} ${previewTheme?.navbarBgColor} font-bold`
                : ""
            }`}
          >
            <img
              id="imgProfileNavPersonalDetails"
              src="./profile-navbar/clarity_details-solid.png"
              alt="Personal Details"
              className="h-4 w-4"
            />
            <span>Personal Details</span>
          </button>
          {patientProfileMenuSelector === "patient" && (
            <div className="grid  w-full">
              

              {isDoctorNotesDataAvailable && (
                <button
                  id="btnProfileNavDrNotes"
                  onClick={() => handlePersonalDetails("doctor-notes")}
                  className={`pl-[22px] flex items-center p-3 px-4 space-x-3 hover:${
                    previewTheme?.navbarBgHoverColor
                  } hover:${previewTheme?.navbarTextColor} ${
                    isActive === "doctor-notes"
                      ? `${previewTheme?.navbarActiveColor} ${previewTheme?.navbarBgColor} font-bold`
                      : ""
                  }`}
                >
                  <img
                    id="imgProfileNavDrNotes"
                    src="./profile-navbar/solar_notes-bold.png"
                    alt="Doctor Notes"
                    className="h-4 w-4"
                  />
                  <span>Doctor Notes</span>
                </button>
              )}
              {isPrescriptionDataAvailabale && (
                <button
                  id="btnProfileNavPrescription"
                  onClick={() => handlePersonalDetails("prescription")}
                  className={`pl-[22px] flex items-center p-3 px-4 space-x-3 hover:${
                    previewTheme?.navbarBgHoverColor
                  } hover:${previewTheme?.navbarTextColor} ${
                    isActive === "prescription"
                      ? `${previewTheme?.navbarActiveColor} ${previewTheme?.navbarBgColor} font-bold`
                      : ""
                  }`}
                >
                  <img
                    id="imgProfileNavPrescription"
                    src="./profile-navbar/mdi_prescription.png"
                    alt="Prescription"
                    className="h-4 w-4"
                  />
                  <span>Prescription</span>
                </button>
              )}
              {isLabNotesDataAvailable && (
                <button
                  id="btnProfileNavLabNotes"
                  onClick={() => handlePersonalDetails("lab-notes")}
                  className={`pl-[22px] flex items-center p-3 px-4 space-x-3 hover:${
                    previewTheme?.navbarBgHoverColor
                  } hover:${previewTheme?.navbarTextColor} ${
                    isActive === "lab-notes"
                      ? `${previewTheme?.navbarActiveColor} ${previewTheme?.navbarBgColor} font-bold`
                      : ""
                  }`}
                >
                  <img
                    id="imgProfileNavLabNotes"
                    src="./profile-navbar/mdi_notes.png"
                    alt="Lab Notes"
                    className="h-4 w-4"
                  />
                  <span>Lab Notes</span>
                </button>
              )}
              {isRadiologyDataAvailable && (
                <button
                  id="btnProfileNavRadiology"
                  onClick={() => handlePersonalDetails("radiology")}
                  className={`pl-[22px] flex items-center p-3 px-4 space-x-3 hover:${
                    previewTheme?.navbarBgHoverColor
                  } hover:${previewTheme?.navbarTextColor} ${
                    isActive === "radiology"
                      ? `${previewTheme?.navbarActiveColor} ${previewTheme?.navbarBgColor} font-bold`
                      : ""
                  }`}
                >
                  <img
                    id="imgProfileNavRadiology"
                    src="./profile-navbar/mdi_radiology-box.png"
                    alt="Radiology"
                    className="h-4 w-4"
                  />
                  <span>Radiology</span>
                </button>
              )}
              {isUploadedDataAvailable && (
                <button
                  id="btnProfileNavUploadedData"
                  onClick={() => handlePersonalDetails("uploaded-data")}
                  className={`pl-[22px] flex items-center p-3 px-4 space-x-3 hover:${
                    previewTheme?.navbarBgHoverColor
                  } hover:${previewTheme?.navbarTextColor} ${
                    isActive === "uploaded-data"
                      ? `${previewTheme?.navbarActiveColor} ${previewTheme?.navbarBgColor} font-bold`
                      : ""
                  }`}
                >
                  <img
                    id="imgProfileNavUploadedData"
                    src="./profile-navbar/typcn_upload.png"
                    alt="Uploaded Data"
                    className="h-4 w-4"
                  />
                  <span>Uploaded Data</span>
                </button>
              )}
            </div>
          )}
          
          {appointment?.status !== "Completed" &&
            patientProfileMenuSelector === "appointment" && (
              <div>
                <div className="flex justify-start gap-2.5 p-[20px] pl-[1.5rem]">
                  <img
                    id="imgProfileNavVitals"
                    src={currentVisit}
                    alt="Vitals"
                    className="h-4 w-4"
                  />

                  <button
                    id="btnProfileCurrentVisit"
                    className="dropdown-trigger"
                    onClick={() => setDropdownOpen(!isDropdownOpen)} // Toggle dropdown on click
                  >
                    Current Visit
                    {isDropdownOpen ? (
                      <svg
                        id="svgDropdownOpen"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="2"
                        stroke="currentColor"
                        className="size-4 inline ml-[1rem]"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="m4.5 15.75 7.5-7.5 7.5 7.5"
                        />
                      </svg>
                    ) : (
                      <svg
                        id="svgDropdownClose"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="2"
                        stroke="currentColor"
                        className="size-4 inline ml-[1rem]"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="m19.5 8.25-7.5 7.5-7.5-7.5"
                        />
                      </svg>
                    )}
                  </button>
                </div>
                {isDropdownOpen && (
                  <div className="grid pl-[1.75rem] w-full">
                    {patientProfileMenuSelector === "appointment" && (
                      <button
                        id="btnProfileNavVitals"
                        onClick={() => handlePersonalDetails("vitals")}
                        className={`pl-[22px] flex items-center w-full p-3 px-4 space-x-3  hover:${
                          previewTheme?.navbarBgHoverColor
                        } hover:${previewTheme?.navbarTextColor} ${
                          isActive === "vitals"
                            ? `${previewTheme?.navbarActiveColor} ${previewTheme?.navbarBgColor} font-bold`
                            : ""
                        }`}
                      >
                        <img
                          id="imgProfileNavVitals"
                          src="./profile-navbar/mdi_clipboard-vitals.png"
                          alt="Vitals"
                          className="h-4 w-4"
                        />
                        <span>Vitals</span>
                      </button>
                    )}
                    {isDoctorNotesDataAvailable && (
                      <button
                        id="btnProfileNavDrNotes"
                        onClick={() => handlePersonalDetails("doctor-notes")}
                        className={`pl-[22px] flex items-center p-3 px-4 space-x-3 hover:${
                          previewTheme?.navbarBgHoverColor
                        } hover:${previewTheme?.navbarTextColor} ${
                          isActive === "doctor-notes"
                            ? `${previewTheme?.navbarActiveColor} ${previewTheme?.navbarBgColor} font-bold`
                            : ""
                        }`}
                      >
                        <img
                          id="imgProfileNavDrNotes"
                          src="./profile-navbar/solar_notes-bold.png"
                          alt="Doctor Notes"
                          className="h-4 w-4"
                        />
                        <span>Doctor Notes</span>
                      </button>
                    )}

                    {isPrescriptionDataAvailabale && (
                      <button
                        id="btnProfileNavPrescription"
                        onClick={() => handlePersonalDetails("prescription")}
                        className={`pl-[22px] flex items-center p-3 px-4 space-x-3 hover:${
                          previewTheme?.navbarBgHoverColor
                        } hover:${previewTheme?.navbarTextColor} ${
                          isActive === "prescription"
                            ? `${previewTheme?.navbarActiveColor} ${previewTheme?.navbarBgColor} font-bold`
                            : ""
                        }`}
                      >
                        <img
                          id="imgProfileNavPrescription"
                          src="./profile-navbar/mdi_prescription.png"
                          alt="Prescription"
                          className="h-4 w-4"
                        />
                        <span>Prescription</span>
                      </button>
                    )}

                    {isLabNotesDataAvailable && (
                      <button
                        id="btnProfileNavLabNotes"
                        onClick={() => handlePersonalDetails("lab-notes")}
                        className={`pl-[22px] flex items-center p-3 px-4 space-x-3 hover:${
                          previewTheme?.navbarBgHoverColor
                        } hover:${previewTheme?.navbarTextColor} ${
                          isActive === "lab-notes"
                            ? `${previewTheme?.navbarActiveColor} ${previewTheme?.navbarBgColor} font-bold`
                            : ""
                        }`}
                      >
                        <img
                          id="imgProfileNavLabNotes"
                          src="./profile-navbar/mdi_notes.png"
                          alt="Lab Notes"
                          className="h-4 w-4"
                        />
                        <span>Lab Notes</span>
                      </button>
                    )}
                    {isRadiologyDataAvailable && (
                      <button
                        id="btnProfileNavRadiology"
                        onClick={() => handlePersonalDetails("radiology")}
                        className={`pl-[22px] flex items-center p-3 px-4 space-x-3 hover:${
                          previewTheme?.navbarBgHoverColor
                        } hover:${previewTheme?.navbarTextColor} ${
                          isActive === "radiology"
                            ? `${previewTheme?.navbarActiveColor} ${previewTheme?.navbarBgColor} font-bold`
                            : ""
                        }`}
                      >
                        <img
                          id="imgProfileNavRadiology"
                          src="./profile-navbar/mdi_radiology-box.png"
                          alt="Radiology"
                          className="h-4 w-4"
                        />
                        <span>Radiology</span>
                      </button>
                    )}
                    <button
                      id="btnProfileNavUploadedData"
                      onClick={() => handlePersonalDetails("uploaded-data")}
                      className={`pl-[22px] flex items-center p-3 px-4 space-x-3 hover:${
                        previewTheme?.navbarBgHoverColor
                      } hover:${previewTheme?.navbarTextColor} ${
                        isActive === "uploaded-data"
                          ? `${previewTheme?.navbarActiveColor} ${previewTheme?.navbarBgColor} font-bold`
                          : ""
                      }`}
                    >
                      <img
                        id="imgProfileNavUploadedData"
                        src="./profile-navbar/typcn_upload.png"
                        alt="Uploaded Data"
                        className="h-4 w-4"
                      />
                      <span>Uploaded Data</span>
                    </button>
                  </div>
                )}
              </div>
            )}

          {isVisitHistoryDataAvailable && (
            <>
              <div className="flex justify-start gap-2.5 p-[20px] pl-[1.5rem]">
                <img
                  id="imgProfileNavVisitHistoryExpand"
                  src="./profile-navbar/ic_outline-history.png"
                  alt="Visit History"
                  className="h-4 w-4 mt-[4px]"
                />

                <button
                  id="btnProfileNavVisitHistoryExpand"
                  onClick={() => {
                    setDropdownVisitHistoryOpen(!isDropdownVisitHistoryOpen);
                  }}
                >
                  <span>
                    Visit History
                    {pastHistory.length > 0 && (
                      <>
                        {isDropdownVisitHistoryOpen ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="2"
                            stroke="currentColor"
                            className="size-4 inline ml-[1.5rem]"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="m4.5 15.75 7.5-7.5 7.5 7.5"
                            />
                          </svg>
                        ) : (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="2"
                            stroke="currentColor"
                            className="size-4 inline ml-[1.5rem]"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="m19.5 8.25-7.5 7.5-7.5-7.5"
                            />
                          </svg>
                        )}
                      </>
                    )}
                  </span>
                </button>
              </div>
              {isDropdownVisitHistoryOpen && (
                <>
                  {pastHistory.map((history, index) => (
                    <div key={`history-${index}`}>
                      <button
                        id={"btnProfileNavVisitHistory" + index}
                        onClick={() => {
                          toggleDropdown(index);
                          viewDetails(history);
                          handleAppointmentClick(history);
                          handleSidebarClick(
                            history.appointment_id,
                            history.appointment_id
                          );
                        }}
                        key={`history-${index}-btn`}
                        className={`pr-[1rem] w-full justify-center flex items-center p-3 pl-[1.5rem] space-x-3 hover:${
                          previewTheme?.navbarBgHoverColor
                        } hover:${previewTheme?.navbarTextColor} ${
                          selectedAppointmentId === history.appointment_id &&
                          isActive === "pastHistory"
                            ? `${previewTheme?.navbarActiveColor} ${previewTheme?.navbarBgColor} font-bold`
                            : ""
                        }`}
                      >
                        <span>
                          {DateFunction.GetDate(history.appointment_time)}
                        </span>
                        {activeDropdownIndex === index ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="2"
                            stroke="currentColor"
                            className="size-4 inline ml-2"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="m4.5 15.75 7.5-7.5 7.5 7.5"
                            />
                          </svg>
                        ) : (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="2"
                            stroke="currentColor"
                            className="size-4 inline ml-2"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="m19.5 8.25-7.5 7.5-7.5-7.5"
                            />
                          </svg>
                        )}
                      </button>

                      {activeDropdownIndex === index && (
                        <div className="pl-[2rem]">
                          <button
                            id="btnProfileNavDrVitalsHistory"
                            onClick={() => {
                              handleHistorySectionDetails(
                                `${selectedAppointmentId}-history-vitals`
                              );
                              setSideBarOpen(false)
                            
                            }}
                            className={`w-[100%] flex items-center p-3 space-x-3 hover:${
  previewTheme?.navbarBgHoverColor
} hover:${previewTheme?.navbarTextColor} ${
  isActiveHistory === `${selectedAppointment.appointment_id}-history-vitals` &&
  isActive === "pastHistory" &&
  sideBarOpen
    ? `${previewTheme?.navbarActiveColor} ${previewTheme?.navbarBgColor} font-bold`
    : isActiveHistory === `${selectedAppointmentId}-history-vitals` &&
      isActive === "pastHistory" 
    
    ? `${previewTheme?.navbarActiveColor} ${previewTheme?.navbarBgColor} font-bold`
    : ""
}`}

                          >
                            <img
                              id="imgProfileNavVitals"
                              src="./profile-navbar/mdi_clipboard-vitals.png"
                              alt="Vitals"
                              className="h-4 w-4"
                            />
                            <span>Vitals</span>
                          </button>

                          {isDoctorNotesDataAvailable && (
                            <button
                              id="btnProfileNavDrNotesHistory"
                              onClick={() => {
                                handleHistorySectionDetails(
                                  `${selectedAppointmentId}-history-doctor-notes`
                                );
                                setSideBarOpen(false)
                              }}
                              className={`w-[100%] flex items-center p-3 space-x-3 hover:${
  previewTheme?.navbarBgHoverColor
} hover:${previewTheme?.navbarTextColor} ${
  isActiveHistory === `${selectedAppointment.appointment_id}-history-doctor-notes` &&
  isActive === "pastHistory" &&
  sideBarOpen
    ? `${previewTheme?.navbarActiveColor} ${previewTheme?.navbarBgColor} font-bold`
    : isActiveHistory === `${selectedAppointmentId}-history-doctor-notes` &&
      isActive === "pastHistory" 
      
    ? `${previewTheme?.navbarActiveColor} ${previewTheme?.navbarBgColor} font-bold`
    : ""
}`}
  

                             
                            >
                              <img
                                id="imgProfileNavDrNotes"
                                src="./profile-navbar/solar_notes-bold.png"
                                alt="Doctor Notes"
                                className="h-4 w-4"
                              />
                              <span>Doctor Notes</span>
                            </button>
                          )}

                          {isPrescriptionDataAvailabale && (
                            <button
                              id="btnProfileNavDrPrescription"
                              onClick={() => {
                                handleHistorySectionDetails(
                                  `${selectedAppointmentId}-history-prescription`
                                );
                              }}
                              className={`w-[100%] flex items-center p-3 space-x-3 hover:${
  previewTheme?.navbarBgHoverColor
} hover:${previewTheme?.navbarTextColor} ${
  isActiveHistory === `${selectedAppointment.appointment_id}-history-prescription` &&
  isActive === "pastHistory" &&
  sideBarOpen
    ? `${previewTheme?.navbarActiveColor} ${previewTheme?.navbarBgColor} font-bold`
    : isActiveHistory === `${selectedAppointmentId}-history-prescription` &&
      isActive === "pastHistory" 
      
    ? `${previewTheme?.navbarActiveColor} ${previewTheme?.navbarBgColor} font-bold`
    : ""
}`}
  

                             
                            >
                              <img
                                id="imgProfileNavPrescription"
                                src="./profile-navbar/mdi_prescription.png"
                                alt="Prescription"
                                className="h-4 w-4"
                              />
                              <span>Prescription</span>
                            </button>
                          )}

                          {isLabNotesDataAvailable && (
                            <button
                              id="btnProfileNavDrLabNotes"
                              onClick={() => {
                                handleHistorySectionDetails(
                                  `${selectedAppointmentId}-history-lab-notes`
                                );
                              }}
                              className={`w-[100%] flex items-center p-3 space-x-3 hover:${
  previewTheme?.navbarBgHoverColor
} hover:${previewTheme?.navbarTextColor} ${
  isActiveHistory === `${selectedAppointment.appointment_id}-history-lab-notes` &&
  isActive === "pastHistory" &&
  sideBarOpen
    ? `${previewTheme?.navbarActiveColor} ${previewTheme?.navbarBgColor} font-bold`
    : isActiveHistory === `${selectedAppointmentId}-history-lab-notes` &&
      isActive === "pastHistory" 
    
    ? `${previewTheme?.navbarActiveColor} ${previewTheme?.navbarBgColor} font-bold`
    : ""
}`}
                             
                            >
                              <img
                                id="imgProfileNavLabNotes"
                                src="./profile-navbar/mdi_notes.png"
                                alt="Lab Notes"
                                className="h-4 w-4"
                              />
                              <span>Lab Notes</span>
                            </button>
                          )}

                          {isRadiologyDataAvailable && (
                            <button
                              id="btnProfileNavDrRadiology"
                              onClick={() => {
                                handleHistorySectionDetails(
                                  `${selectedAppointmentId}-history-radiology`
                                );
                              }}
                              className={`w-[100%] flex items-center p-3 space-x-3 hover:${
  previewTheme?.navbarBgHoverColor
} hover:${previewTheme?.navbarTextColor} ${
  isActiveHistory === `${selectedAppointment.appointment_id}-history-radiology` &&
  isActive === "pastHistory" &&
  sideBarOpen
    ? `${previewTheme?.navbarActiveColor} ${previewTheme?.navbarBgColor} font-bold`
    : isActiveHistory === `${selectedAppointmentId}-history-radiology` &&
      isActive === "pastHistory" 
     
    ? `${previewTheme?.navbarActiveColor} ${previewTheme?.navbarBgColor} font-bold`
    : ""
}`}
                              
                            >
                              <img
                                id="imgProfileNavRadiology"
                                src="./profile-navbar/mdi_radiology-box.png"
                                alt="Radiology"
                                className="h-4 w-4"
                              />
                              <span>Radiology</span>
                            </button>
                          )}
                          <button
                            id="btnProfileNavDrUploadedData"
                            onClick={() => {
                              handleHistorySectionDetails(
                                `${selectedAppointmentId}-history-uploaded-data`
                              );
                            }}
                            className={`w-[100%] flex items-center p-3 space-x-3 hover:${
  previewTheme?.navbarBgHoverColor
} hover:${previewTheme?.navbarTextColor} ${
  isActiveHistory === `${selectedAppointment.appointment_id}-history-uploaded-data` &&
  isActive === "pastHistory" &&
  sideBarOpen
    ? `${previewTheme?.navbarActiveColor} ${previewTheme?.navbarBgColor} font-bold`
    : isActiveHistory === `${selectedAppointmentId}-history-uploaded-data` &&
      isActive === "pastHistory" 
      
    ? `${previewTheme?.navbarActiveColor} ${previewTheme?.navbarBgColor} font-bold`
    : ""
}`}
                           
                          >
                            <img
                              id="imgProfileNavUploadedData"
                              src="./profile-navbar/typcn_upload.png"
                              alt="Uploaded Data"
                              className="h-4 w-4"
                            />
                            <span>Uploaded Data</span>
                          </button>
                        </div>
                      )}
                    </div>
                  ))}
                </>
              )}
            </>
          )}
        </nav>
      </div>
      {toast.show && (
        <SnackBarComponent
          message={toast.message}
          type={toast.type}
          onClose={() => setToast({ show: false, message: "", type: "" })}
        />
      )}
    </div>
  );
};

export default ProfileSideNavbar;
